import { ControlledTextAreaV2, TypographyV2 } from "components/v2"
import { useTranslation } from "react-i18next"
import { Control, useController } from "react-hook-form"
import { useScreenSize } from "hooks"
import { TableListV2 } from "components/v2"

interface FreeTextQuestionCardProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: any
    isDisabled?: boolean
}

const FreeTextQuestionCard = ({ control, error, question, isDisabled }: FreeTextQuestionCardProps) => {
    const { is_required, question: question_text } = question
    const { t } = useTranslation()
    const isDesktop = useScreenSize()

    const {
        field: { value }
    } = useController({
        name: `screeningAnswers.${question.id}`,
        control,
        rules: { required: true },
        defaultValue: ""
    })

    if (isDesktop) {
        return (
            <TableListV2
                headerCells={[]}
                listComponent={
                    <tr className="border-b border-gray-200">
                        <td className="py-12 px-6">
                            <div className="flex flex-col gap-4">
                                <TypographyV2 type="base">{question_text}</TypographyV2>
                                {question?.description && (
                                    <TypographyV2 type="xs" className="text-gray-500">
                                        {question.description}
                                    </TypographyV2>
                                )}
                            </div>
                        </td>
                        <td className="py-12 px-6">
                            <ControlledTextAreaV2
                                isDisabled={isDisabled}
                                error={error}
                                isRequired={is_required}
                                rowsCount={8}
                                classList={{ wrapper: "w-full" }}
                                name={`screeningAnswers.${question.id}`}
                                control={control}
                                placeholder={t("intake:common.tell_us")}
                            />
                        </td>
                    </tr>
                }
            />
        )
    }

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <ControlledTextAreaV2
                error={error}
                isRequired={is_required}
                rowsCount={10}
                classList={{ wrapper: "w-full" }}
                name={`screeningAnswers.${question.id}`}
                control={control}
                placeholder={t("intake:common.tell_us")}
            />
        </div>
    )
}

export default FreeTextQuestionCard
