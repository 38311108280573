import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { closeModal } from "store/modal/actions"
import { DialogV2, PreviewCareProtocol, TypographyV2 } from "components/v2"
import { useCarePlanSelector, useCurrentPatient } from "../../store/hooks"
import { getPatientCareProtocol } from "../../api/careProtocol/cpElements"
import { CareProtocolElement, ElementType, EventStatus } from "@prisma/client"
import { EventCompletionStatus } from "../../components/v2/constants"
import { DateString, getDateAtHour0 } from "../../utils/dates"
import { useIsHeLanguage } from "../../hooks"
import { addDays, isBefore } from "date-fns"

interface ViewProtocolModalProps {
    isOpen: boolean
    params?: any
}

const ViewProtocolModal: React.FC<ViewProtocolModalProps> = ({ isOpen, params }) => {
    const patient = useCurrentPatient()
    const { t } = useTranslation("common")
    const dispatch = useDispatch()

    const { activeCareProtocol } = useCarePlanSelector()
    const { isHeLanguage } = useIsHeLanguage()

    const { protocol, scheduleEvents, completedScheduledEvents, completedSequentialEvents, sequentialEvents } =
        activeCareProtocol

    const [activeProtocol, setActiveProtocol] = useState(null)

    const handleClose = () => {
        dispatch(closeModal())
    }

    useEffect(() => {
        const fetchRequest = async () => {
            if (patient?.id) {
                const response = await getPatientCareProtocol(patient.id)
                setActiveProtocol(response)
            }
        }

        fetchRequest()
    }, [patient, protocol])

    const events = activeProtocol?.protocol?.events

    const templateElements = activeProtocol?.protocol?.protocol?.cp_library_template_elements

    const elementIdsToEvents = useMemo(
        () =>
            events?.reduce((accum: { [key: number]: EventCompletionStatus }, event: any) => {
                const elementId = event?.care_protocol_element_id
                if (elementId && DateString.isPastOrToday(event?.date_on_timeline)) {
                    const isSkipped =
                        !event?.completed_at &&
                        event?.expires_within &&
                        isBefore(addDays(new Date(event?.date_on_timeline), event?.expires_within), new Date())

                    accum[elementId] = isSkipped
                        ? EventCompletionStatus.Skipped
                        : event?.status === EventStatus.deleted
                        ? EventCompletionStatus.Deleted
                        : event?.completed_at
                        ? EventCompletionStatus.Completed
                        : EventCompletionStatus.InProgress
                }

                return accum
            }, {}),
        [events]
    )

    const mappedTemplates = useMemo(
        () =>
            templateElements?.map(
                (
                    element: CareProtocolElement & {
                        eventStatus: EventCompletionStatus
                        unitPreview: { allEvents?: number; allCompletedEvents?: number }
                    }
                ) => {
                    if (elementIdsToEvents?.[element.id]) {
                        element.eventStatus = elementIdsToEvents?.[element.id]
                    }

                    if (element.element_type === ElementType.UNIT) {
                        const unit = events.find((event: any) => event.care_protocol_element?.id === element.id)
                        const chapters = unit?.unit_chapters
                        const allEvents = Array.isArray(chapters)
                            ? chapters.reduce((accum, { events }) => [...accum, ...events], [])
                            : []

                        const completedEvent = allEvents.filter((event: any) => !!event.completed_at)
                        element.unitPreview = {}

                        element.unitPreview.allEvents = allEvents.length

                        element.unitPreview.allCompletedEvents = completedEvent.length
                    }
                    return element
                }
            ),
        [templateElements, elementIdsToEvents, events]
    )

    return (
        <DialogV2
            classList={{
                dialog: isHeLanguage ? "!translate-x-[0%]" : "",
                title: isHeLanguage ? "!translate-x-[-20%]" : ""
            }}
            // classList={{ title: isHeLanguage ? "!translate-x-[-20%] !translate-y-[10%]" : "!translate-x-[-50%]" }}
            title={t("assigned_protocol")}
            isFooterHidden
            isOpen={isOpen}
            onClose={handleClose}
            type="wide"
        >
            <div className="px-4 w-full !h-[600px] overflow-y-auto">
                <div className="flex items-center">
                    <div className="mr-8 ml-2">
                        <TypographyV2 width="normal" type="sm">
                            {isHeLanguage ? sequentialEvents : completedSequentialEvents} /{" "}
                            {isHeLanguage ? completedSequentialEvents : sequentialEvents}
                        </TypographyV2>
                        <TypographyV2 width="medium" type="xs" className="text-[#6B7280]">
                            {t("sequentialEvents", { ns: "careProtocol" })}
                        </TypographyV2>
                    </div>
                    <div>
                        <TypographyV2 width="normal" type="sm">
                            {isHeLanguage ? scheduleEvents : completedScheduledEvents} /{" "}
                            {isHeLanguage ? completedScheduledEvents : scheduleEvents}
                        </TypographyV2>
                        <TypographyV2 width="medium" type="xs" className="text-[#6B7280]">
                            {t("scheduledEvents", { ns: "careProtocol" })}
                        </TypographyV2>
                    </div>
                </div>

                <div className="w-full px-2 py-3">
                    {!!mappedTemplates?.length && <PreviewCareProtocol cpTemplateElements={mappedTemplates} />}
                </div>
            </div>
        </DialogV2>
    )
}

export default ViewProtocolModal
