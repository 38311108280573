import { SVGProps } from "react"

const OrganizationIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3.125 17.5H16.875M3.75 2.5H16.25M4.375 2.5V17.5M15.625 2.5V17.5M7.5 5.625H8.75M7.5 8.125H8.75M7.5 10.625H8.75M11.25 5.625H12.5M11.25 8.125H12.5M11.25 10.625H12.5M7.5 17.5V14.6875C7.5 14.1697 7.91973 13.75 8.4375 13.75H11.5625C12.0803 13.75 12.5 14.1697 12.5 14.6875V17.5"
            stroke="#6B7280"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default OrganizationIcon
