import { useState } from "react"

const CLOUDINARY_CLOUD_NAME = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME
const CLOUDINARY_UPLOAD_PRESET = process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET

interface UploadMediaParams {
    folderPath: string
    tags?: string[]
}

interface CloudinaryUploadConfig {
    cloudName: string
    uploadPreset: string
    multiple: boolean
    theme: string
    showPoweredBy: boolean
    showAdvancedOptions: boolean
    sources: string[]
    styles: {
        palette: {
            window: string
            windowBorder: string
            tabIcon: string
            menuIcons: string
            textDark: string
            textLight: string
            link: string
            action: string
            inactiveTabIcon: string
            error: string
            inProgress: string
            complete: string
            sourceBg: string
        }
        frame: {
            background: string
        }
    }
    resourceType?: string
    clientAllowedFormats?: string[]
    maxFileSize?: number
    folder?: string
    tags?: string[]
}

export interface CloudinaryUploadResponse {
    public_id: string
    secure_url: string
    tags?: string[]
    format: string
    original_filename: string
    bytes: number
}

const useMediaUpload = () => {
    const [isLoading, setIsLoading] = useState(false)

    if (!CLOUDINARY_CLOUD_NAME || !CLOUDINARY_UPLOAD_PRESET) {
        console.error("Cloudinary env variables missing")
        throw new Error("Cloudinary environment variables are missing")
    }

    const widgetConfig = {
        cloudName: CLOUDINARY_CLOUD_NAME,
        uploadPreset: CLOUDINARY_UPLOAD_PRESET,
        multiple: false,
        theme: "minimal",
        showPoweredBy: false,
        showAdvancedOptions: false,
        sources: ["local", "url", "camera"],
        styles: {
            palette: {
                window: "#FFF",
                windowBorder: "#90A0B3",
                tabIcon: "#0E2F5A",
                menuIcons: "#5A616A",
                textDark: "#000000",
                textLight: "#FFFFFF",
                link: "#0078FF",
                action: "#FF620C",
                inactiveTabIcon: "#0E2F5A",
                error: "#F44235",
                inProgress: "#0078FF",
                complete: "#20B832",
                sourceBg: "#E4EBF1"
            },
            frame: {
                background: "#0E2F5B99"
            }
        }
    }

    const createUploadWidget = ({
        uploadConfig,
        resolve
    }: {
        uploadConfig: CloudinaryUploadConfig
        resolve: (info: CloudinaryUploadResponse) => void
    }) => {
        const myWidget = window.cloudinary.createUploadWidget(uploadConfig, (error, result) => {
            if (error || !result || result.event !== "success") {
                setIsLoading(false)
                return
            }

            resolve(result.info)
            setIsLoading(false)
        })

        myWidget.open()
    }

    const uploadImage = ({ folderPath, tags }: UploadMediaParams): Promise<CloudinaryUploadResponse> => {
        return new Promise((resolve, reject) => {
            try {
                setIsLoading(true)

                const imageWidgetConfig = {
                    ...widgetConfig,
                    resourceType: "image",
                    clientAllowedFormats: ["png", "gif", "jpeg"],
                    maxFileSize: 10_000_000,
                    folder: folderPath,
                    tags
                }

                createUploadWidget({ uploadConfig: imageWidgetConfig, resolve })
            } catch (error) {
                reject(error)
            }
        })
    }

    const uploadVideo = ({ folderPath, tags }: UploadMediaParams): Promise<CloudinaryUploadResponse> => {
        return new Promise((resolve, reject) => {
            try {
                setIsLoading(true)

                const videoWidgetConfig = {
                    ...widgetConfig,
                    resourceType: "video",
                    clientAllowedFormats: ["mp4", "mov", "avi"],
                    maxFileSize: 50_000_000,
                    folder: folderPath,
                    tags
                }

                createUploadWidget({ uploadConfig: videoWidgetConfig, resolve })
            } catch (error) {
                reject(error)
            }
        })
    }

    return { uploadImage, uploadVideo, isLoading }
}

export default useMediaUpload
