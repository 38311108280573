import React, { FC, useEffect } from "react"
import { LibraryTemplateResponse, UnitChapterResponse } from "types/Redux"
import { getAllUnitChaptersByUnitId } from "api/unit"
import { UnitDetailsChapterItem } from "./components"

interface IUnitPreviewProps {
    id?: number
    libraryTemplate?: LibraryTemplateResponse
}

const PreviewUnit: FC<IUnitPreviewProps> = ({ id, libraryTemplate }) => {
    const [chapters, setChapters] = React.useState<UnitChapterResponse[]>([])

    const libraryTemplateId = libraryTemplate?.id || id

    useEffect(() => {
        const fetchQuestions = async () => {
            if (!libraryTemplateId) return
            const response = await getAllUnitChaptersByUnitId(libraryTemplateId)
            setChapters(response)
        }
        fetchQuestions()
    }, [])

    return (
        <div className="p-4 mt-3 flex gap-2 flex-col">
            {libraryTemplate && <p className="text-med-black pb-6 text-xl font-bold">{libraryTemplate.title}</p>}
            <div className="px-2 flex flex-col gap-4 pb-6">
                {chapters.map((chapter, index) => (
                    <UnitDetailsChapterItem chapter={chapter} key={index} />
                ))}
            </div>
        </div>
    )
}

export default PreviewUnit
