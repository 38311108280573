import { TypographyV2 } from "components/v2/index"
import { useTranslation } from "react-i18next"
import { useLibraryTemplateSelector } from "store/hooks"
import { getLibraryTemplateNameByType } from "components/v2/constants"

const PublishRulesDescription = () => {
    const { t } = useTranslation("common")
    const { type } = useLibraryTemplateSelector()
    const eventType = getLibraryTemplateNameByType(type)
    return (
        <div className="bg-gray-100 p-[18px] rounded">
            <TypographyV2>{t("once_published")}</TypographyV2>
            <ul className="list-disc list-inside mt-1">
                <TypographyV2 tag="li">{t("your_care_team_can_assign", { eventType })}</TypographyV2>
                <TypographyV2 tag="li">{t("editing_will_be_limited", { eventType })}</TypographyV2>
                <TypographyV2 tag="li">{t("adding_or_removing_content", { eventType })}</TypographyV2>
                <TypographyV2 tag="li">{t("you_can_only_create_a_copy", { eventType })}</TypographyV2>
            </ul>
        </div>
    )
}

export default PublishRulesDescription
