import React, { useState } from "react"
import { useDispatch } from "store"
import { deleteQuestionByIdAction } from "store/questions/actions"
import { ExerciseQuestionItem } from "types/Redux"
import { useTranslation } from "react-i18next"
import { useIsHeLanguage } from "hooks"
import { getExerciseQuestionTitle } from "components/v2/utiles"
import { ExerciseStatuses } from "@prisma/client"
import { useLibraryTemplateSelector } from "store/hooks"

export const useExerciseQuestionItem = (questionItem: ExerciseQuestionItem) => {
    const { id, isCreated } = questionItem
    const { t } = useTranslation("question")
    const dispatch = useDispatch()

    const { libraryTemplate } = useLibraryTemplateSelector()

    const isEditDisabled = libraryTemplate?.status === ExerciseStatuses.PUBLISHED

    const { isHeLanguage } = useIsHeLanguage()

    const questionTitle = getExerciseQuestionTitle(questionItem)

    const [isExpanded, setIsExpanded] = useState(isCreated ?? false)

    const onRowClick = () => {
        if (isEditDisabled) return
        setIsExpanded(!isExpanded)
    }

    const onDeleteClick = (e: React.MouseEvent<SVGSVGElement | HTMLButtonElement>) => {
        e.stopPropagation()
        dispatch(deleteQuestionByIdAction(id))
    }

    return {
        t,
        isEditDisabled,
        questionTitle,
        isHeLanguage,
        isExpanded,
        onRowClick,
        onDeleteClick
    }
}
