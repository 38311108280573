import { AnyAction, Reducer } from "redux"
import { UnitState } from "types/Redux"

import * as types from "./types"
import { UnitChapterElementType } from "@prisma/client"

const initialState: UnitState = {
    isLoading: true,
    selectedChapter: null,
    chapters: [],
    elements: [],
    addElementModal: {
        isOpened: false,
        elementType: "EXERCISE",
        selectedElement: null,
        selectedQuestionnaireElement: null
    },
    deleteElementModal: {
        isOpened: false,
        elementId: null
    },
    deleteChapterModal: {
        isOpened: false,
        chapterId: null
    }
}

export const unitReducer: Reducer<UnitState, AnyAction> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_CHAPTERS_LIST: {
            return { ...state, chapters: [...payload], isLoading: false }
        }
        case types.ADD_NEW_CHAPTER_TO_LIST: {
            return { ...state, chapters: [...state.chapters, payload] }
        }
        case types.SET_SELECTED_CHAPTER: {
            return { ...state, selectedChapter: payload }
        }
        case types.SET_ELEMENTS_LIST: {
            return { ...state, elements: [...payload] }
        }
        case types.ADD_NEW_ELEMENT_TO_LIST: {
            return { ...state, elements: [...state.elements, payload] }
        }
        case types.OPEN_ADD_ELEMENT_MODAL: {
            return {
                ...state,
                addElementModal: {
                    ...state.addElementModal,
                    isOpened: true,
                    elementType: payload
                }
            }
        }
        case types.SELECT_ELEMENT_FOR_ADD_VARIABLE_MODAL: {
            return {
                ...state,
                addElementModal: {
                    ...state.addElementModal,
                    ...(state.addElementModal.elementType === UnitChapterElementType.QUESTIONNAIRE
                        ? { selectedQuestionnaireElement: payload }
                        : { selectedElement: payload })
                }
            }
        }
        case types.REMOVE_SELECTED_ELEMENT_FOR_ADD_VARIABLE_MODAL: {
            return {
                ...state,
                addElementModal: {
                    ...state.addElementModal,
                    selectedQuestionnaireElement: null,
                    selectedElement: null
                }
            }
        }
        case types.CLOSE_ADD_ELEMENT_MODAL: {
            return { ...state, addElementModal: initialState.addElementModal }
        }
        case types.OPEN_DELETE_CHAPTER_MODAL: {
            return { ...state, deleteChapterModal: { ...payload, isOpened: true } }
        }
        case types.CLOSE_DELETE_CHAPTER_MODAL: {
            return { ...state, deleteChapterModal: initialState.deleteChapterModal }
        }
        case types.OPEN_DELETE_ELEMENT_MODAL: {
            return {
                ...state,
                deleteElementModal: {
                    ...payload,
                    isOpened: true
                }
            }
        }
        case types.CLOSE_DELETE_ELEMENT_MODAL: {
            return { ...state, deleteElementModal: initialState.deleteElementModal }
        }
        default:
            return state
    }
}
