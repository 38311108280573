import { Modal } from "components/common"
import React from "react"
import { ButtonV2, TypographyV2 } from "../../../common"
import { CloseIcon } from "components/icons"
import { DeleteIconV2 } from "../../../icons"
import { useDispatch } from "store"
import { useUnitSelector } from "store/hooks"
import { closeDeleteUnitChapterModalAction, deleteChapterByIdAction } from "store/unit/actions"
import { useTranslation } from "react-i18next"

const DeleteItemConfirmationDialogue = () => {
    const { t } = useTranslation("unit")

    const {
        deleteChapterModal: { chapterId, isOpened }
    } = useUnitSelector()

    const dispatch = useDispatch()

    const handleClose = () => dispatch(closeDeleteUnitChapterModalAction())

    const handleDelete = () => {
        dispatch(deleteChapterByIdAction(chapterId))
    }

    return (
        <Modal show={isOpened} onClose={handleClose} centered cardClassName="w-3/4 max-w-80">
            <div className="p-4 flex justify-between border-b border-border-blue">
                <TypographyV2 width="bold" type="base" className="text-med-black">
                    {t("common:delete")} {t("chapter")}
                </TypographyV2>
                <div className="flex items-center">
                    <CloseIcon onClick={handleClose} className="cursor-pointer text-[#9CA3AF]" height={20} width={20} />
                </div>
            </div>
            <div className="flex gap-6 p-6 items-center">
                <ButtonV2 onClick={handleClose} text="cancel" className="w-full flex justify-center" />
                <ButtonV2
                    onClick={handleDelete}
                    icon={<DeleteIconV2 />}
                    className="w-full flex justify-center"
                    text="Delete"
                    variant="light-gray"
                />
            </div>
        </Modal>
    )
}

export default DeleteItemConfirmationDialogue
