// modals/EditEventModal/EditEventModal.tsx
import React, { useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { closeModal } from "store/modal/actions"
import {
    BadgeV2,
    ControlledRadioCircleGroupV2,
    ControlledSelectV2,
    DialogV2,
    IOptionV2,
    RadioCircleGroupV2,
    TypographyV2,
    UserAvatarV2
} from "components/v2"
import TimeFields from "components/ContentLibrary/EventConfig/InputComponents/DateOnTimelineField"
import { classNames } from "utils"
import { defaultInputClassNames } from "forms"
import { AVAILABILITY_OPTIONS, DAYS_OPTION, REPEATS_OPTIONS } from "modals/FeaturesSettingsModal/featureSettenigsConsts"
import { useForm } from "react-hook-form"
import { featureSettingsValidationSchema } from "modals/FeaturesSettingsModal/featureSettingsValidationSchema"
import { yupResolver } from "@hookform/resolvers/yup"
import { TFunction } from "i18next"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { EventConfigState } from "components/ContentLibrary/EventConfig/types"
import { format, addDays } from "date-fns"
import { TruncatedString } from "components/common"
import { COLORS } from "components/ContentLibrary/utils"
import { DateData } from "components/ContentLibrary/types"
import { enUS } from "date-fns/locale"
import { AssigneeField } from "components/ContentLibrary/EventConfig/InputComponents/AssigneeField"

interface EditEventModalProps {
    isOpen: boolean
    params?: any
}

interface GanttEvent {
    id: string
    title: string
    startDate: Date
    duration: number // in days
    status: "completed" | "in-progress" | "pending"
}

const localeMap: { [key: string]: Locale } = {
    en: enUS

    // Add more locales as needed
}
// TODO: need refactor this file

const getTimelineForGantt = (startDate: Date, endDate: Date, i18n: any) => {
    const dates: DateData[] = []
    const months: { dateIndex: number; name: string }[] = []
    let currentDate = startDate
    let currentMonth = ""

    // Get the appropriate locale or fallback to enUS
    const locale = localeMap[i18n.language] || enUS

    while (currentDate <= endDate) {
        const monthName = format(currentDate, "MMMM", { locale })
        if (monthName !== currentMonth) {
            months.push({
                dateIndex: dates.length,
                name: monthName
            })
            currentMonth = monthName
        }

        dates.push({
            date: format(currentDate, "yyyy-MM-dd"),
            formattedDate: format(currentDate, "dd")
        })

        currentDate = addDays(currentDate, 1)
    }

    return { dates, months }
}
const EventGantt: React.FC = () => {
    const { t, i18n } = useTranslation("common")
    const isRTL = i18n.dir() === "rtl"

    // Constants from the reference file
    const dateWidthInRem = 8.3
    const dateWidth = "w-[133px]"
    const marginInRem = 0.4

    // Get current date and next 6 days for the timeline
    const startDate = new Date()
    const endDate = addDays(startDate, 6)

    // Generate timeline data
    const { dates, months } = getTimelineForGantt(startDate, endDate, i18n)

    // Dummy events data
    const ganttData = [
        {
            id: 1,
            title: "Event 1",
            startIndex: 0,
            length: 1,
            type: "questionnaire",
            isHighlighted: true
        },
        {
            id: 2,
            title: "Event 2",
            startIndex: 3,
            length: 1,
            type: "questionnaire",
            isHighlighted: false
        }
    ]

    const getItemStyle = (item: any, index: number) => ({
        top: `${2.5 * (index + 1) + 0.5}rem`,
        [isRTL ? "right" : "left"]: `${dateWidthInRem * item.startIndex + marginInRem}rem`,
        width: `${dateWidthInRem * item.length - marginInRem * 2}rem`,
        minHeight: "2rem"
    })

    const heightInRem = 8.5 + 2.5 * ganttData.length

    return (
        <div>
            <div className="overflow-x-hidden overflow-y-hidden w-[934px] ">
                <div className="relative h-8" style={{ width: dates.length * dateWidthInRem + "rem" }}>
                    {months.map(({ dateIndex, name }) => (
                        <div
                            key={name}
                            className="absolute bottom-2 text-sm text-dark-blue"
                            style={{
                                [isRTL ? "right" : "left"]: `calc(${dateWidthInRem * dateIndex}rem + 6px)`
                            }}
                        >
                            {name}
                        </div>
                    ))}
                </div>
            </div>
            <div className="shrink-0 flex w-[934px] relative">
                <div
                    className="flex border border-border-blue bg-sidebar overflow-y-auto overflow-x-hidden w-full relative pb-3"
                    style={{ maxHeight: "15rem" }}
                >
                    {/* Render dates */}
                    {dates.map(({ date, formattedDate }, index) => (
                        <div
                            key={date}
                            className={classNames(
                                "shrink-0 text-xs justify-center text-text-blue font-medium border-border-blue",
                                index && "ltr:border-l rtl:border-r",
                                dateWidth
                            )}
                            style={{ height: heightInRem - 5.5 + "rem" }}
                        >
                            <div
                                className="sticky pt-3 pb-5 top-0 text-center z-[40]"
                                style={{
                                    backgroundImage: `linear-gradient(to bottom, ${COLORS.sidebar}dd, ${COLORS.sidebar}dd 70%, transparent)`
                                }}
                            >
                                {formattedDate}
                            </div>
                        </div>
                    ))}

                    {/* Render events */}
                    {ganttData.map((item, index) => (
                        <div key={item.id} className="h-fit z-10 absolute" style={getItemStyle(item, index)}>
                            <div
                                className={classNames(
                                    "rounded p-2 text-xs font-medium relative flex gap-2 items-center",
                                    !item.isHighlighted ? "text-text-blue" : "text-white"
                                )}
                                style={{
                                    backgroundColor: !item.isHighlighted ? COLORS.borderBlue : COLORS.textBlue
                                }}
                            >
                                <TruncatedString maxLen={13} className="cursor-default" string={item.title} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const EditEventModal: React.FC<EditEventModalProps> = ({ isOpen, params }) => {
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()
    const modalRef = useRef<HTMLDivElement>(null)
    const [selectedItems, setSelectedItems] = useState()

    const {
        control,
        getValues,
        watch,
        resetField,
        trigger,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(featureSettingsValidationSchema(t)),
        mode: "onChange",
        defaultValues: {
            // collaboratorIds: patientCollaborators.map((collaboraator) => collaboraator.user.id),
            questionnaireId: null,
            availability: "until",
            repeats: "",
            time_offset: 1,
            time_unit: "day"
        }
    })

    const [date, setDate] = useState<any>({ date_on_timeline: new Date(), isTimeless: true })
    const [selectedOption, setSelectedOption] = useState("day_specific")

    const [daysOffset, setDaysOffset] = useState<IOptionV2[]>(DAYS_OPTION)
    const ALWAYS_OPTIONS = (t: TFunction) => [
        { id: "day", title: t("featuresSettings.consts.day") },
        { id: "week", title: t("featuresSettings.consts.week") }
        // { id: "hour", title: t("featuresSettings.consts.hour") }
    ]

    const handleClose = () => {
        dispatch(closeModal())
    }

    const handleUpdate = () => {
        // Add update logic here
        handleClose()
    }

    const collaboratorsOptions: IOptionV2[] = useMemo(() => {
        const dummyPatientCollaborators = [
            {
                user: {
                    id: 1,
                    first_name: "John",
                    last_name: "Doe"
                    // profile_image: null
                }
            },
            {
                user: {
                    id: 2,
                    first_name: "Sarah",
                    last_name: "Smith"
                    // profile_image: "https://example.com/profiles/[variant]/sarah.jpg"
                }
            },
            {
                user: {
                    id: 3,
                    first_name: "Michael",
                    last_name: "Johnson"
                    // profile_image: null
                }
            },
            {
                user: {
                    id: 4,
                    first_name: "Emma",
                    last_name: "Wilson"
                    // profile_image: "https://example.com/profiles/[variant]/emma.jpg"
                }
            },
            {
                user: {
                    id: 5,
                    first_name: "James",
                    last_name: "Brown"
                    // profile_image: null
                }
            }
        ]

        return dummyPatientCollaborators.map((collaborator) => ({
            id: collaborator.user.id,
            title: `${collaborator.user.first_name} ${collaborator.user.last_name}`,
            media: (
                <UserAvatarV2
                    size={24}
                    firstName={collaborator.user.first_name}
                    lastName={collaborator.user.last_name}
                />
            )
        }))
    }, []) // Empty dependency array since this is dummy data

    const radioOptions = [
        {
            value: "day_specific",
            labelComponent: (
                <div>
                    <TypographyV2 type="sm" width="medium" className="text-[#111827] ml-1.5">
                        Day-Specific
                    </TypographyV2>
                    <TypographyV2 type="xs" width="normal" className="text-[#6B7280] ml-1.5">
                        Completion of last event will set as transition trigger for the next event
                    </TypographyV2>
                </div>
            )
        },
        {
            value: "availability",
            labelComponent: (
                <div className="flex flex-col gap-y-2">
                    <div className="flex flex-row items-center gap-x-1 w-[324px]">
                        <TypographyV2 type="sm" width="medium" className="text-[#111827] whitespace-nowrap mr-7 ml-1.5">
                            {t("common:after")}
                        </TypographyV2>
                        <div className="w-[73px]">
                            <ControlledSelectV2
                                options={daysOffset}
                                control={control}
                                name="time_offset"
                                placeholder={"10"}
                            />
                        </div>
                        <div className="w-[179px]">
                            <ControlledSelectV2
                                options={ALWAYS_OPTIONS(t)}
                                control={control}
                                name="time_unit"
                                placeholder={t("featuresSettings.consts.days")}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    ]

    return (
        <DialogV2
            title={t("common:edit_event")}
            isOpen={isOpen}
            onClose={handleClose}
            onProceed={handleUpdate}
            proceedText={t("common:update")}
            proceedType="primary"
            dismissType="light-gray"
            dismissText={t("common:cancel")}
            type="wide"
            classList={{ dialog: "z-0" }}
        >
            {
                <div ref={modalRef} className="flex flex-col w-full max-h-[696px] overflow-y-auto z-[1]">
                    <div className="p-6 flex flex-col w-full gap-y-6 ">
                        <div className="flex flex-col w-fit gap-y-2">
                            <TypographyV2 type="xs" width="semibold" className="text-[#4B5563]">
                                {t("featuresSettings.assignee")}
                            </TypographyV2>

                            {/* <BadgeV2
                                className="bg-[#F3F4F6] px-[8px] py-[4px] gap-x-[10px]"
                                icon={<UserAvatarV2 size={24} firstName={"John"} lastName={"Doe"} />}
                                text={`${"John1"} ${"Doe"}`}
                            /> */}
                            <AssigneeField
                                id="1234"
                                value={selectedItems}
                                setValue={setSelectedItems}
                                placeholder="test placeholder"
                            />
                        </div>
                        <div className="flex flex-row w-full gap-x-4">
                            <div className="flex flex-col w-full gap-y-2">
                                <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                    {t("featuresSettings.start_date")}
                                </TypographyV2>

                                <TimeFields
                                    value={date}
                                    setValue={setDate}
                                    id="start_date"
                                    withIcons={true}
                                    classList={{
                                        wrapper: "w-full z-[1]",
                                        input: classNames(
                                            defaultInputClassNames,
                                            "!bg-white flex justify-between items-center rounded-lg"
                                        )
                                        // calendar: "z-[9999]"
                                    }}
                                    modalRef={modalRef}
                                />
                            </div>
                            <div className="flex flex-col w-full gap-y-2">
                                <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                    {t("featuresSettings.repeats")}
                                </TypographyV2>
                                <ControlledSelectV2
                                    options={REPEATS_OPTIONS(t)}
                                    control={control}
                                    name="repeats"
                                    placeholder={t("featuresSettings.therapist_questionnaires")}
                                    error={errors.repeats?.message}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col w-full gap-y-2">
                            <div className="flex flex-row items-center gap-1">
                                <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                    {t("featuresSettings.availability")}
                                </TypographyV2>
                                <InformationCircleIcon className="w-3 h-3  text-[#9CA3AF] " />
                            </div>
                            <RadioCircleGroupV2
                                name="availability_type"
                                onChange={setSelectedOption}
                                options={radioOptions}
                                value={selectedOption}
                            />
                        </div>

                        <div className="flex flex-col w-full gap-y-2">
                            <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                {t("featuresSettings.collaborators")}
                            </TypographyV2>
                            <ControlledSelectV2
                                options={collaboratorsOptions}
                                control={control}
                                // mode="multiple"
                                name="collaboratorIds"
                                placeholder={t("featuresSettings.collaborators")}
                                error={errors.collaboratorIds?.message}
                            />
                        </div>

                        <div className="flex flex-col w-full gap-y-2">
                            <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                {t("featuresSettings.linked_events")}
                            </TypographyV2>
                            <EventGantt />
                        </div>
                    </div>
                </div>
            }
        </DialogV2>
    )
}

export default EditEventModal
