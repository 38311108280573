// modals/UnlockEventModal/UnlockEventModal.tsx
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { closeModal } from "store/modal/actions"
import { DialogV2, TypographyV2 } from "components/v2"
import { unlockManualSequentialEventAction } from "store/care-plan/actions"

interface UnlockEventModalProps {
    isOpen: boolean
    params?: any
}

const UnlockEventModal: React.FC<UnlockEventModalProps> = ({ isOpen, params }) => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(closeModal())
    }

    const handleUnlock = () => {
        if (params.eventId) dispatch(unlockManualSequentialEventAction(params.eventId))
        // Add unlock logic here
        handleClose()
    }

    return (
        <DialogV2
            title={t("unlock_event")}
            isOpen={isOpen}
            onClose={handleClose}
            onProceed={handleUnlock}
            proceedText={t("unlock", { ns: "carePlan" })}
            proceedType="primary"
            dismissType="light-gray"
            dismissText={t("cancel")}
            classList={{ dialog: "!translate-y-[0] !shadow-none" }}
        >
            <div className="px-2 ">
                <div className="my-4 mb-4">
                    <TypographyV2 width="normal" type="base" className="my-2">
                        {t("event")}
                    </TypographyV2>
                    <TypographyV2 width="bold" type="xl">
                        {params?.eventTitle}
                    </TypographyV2>
                </div>

                <div className="w-full p-4 rounded rounded-tl-lg bg-[#F3F4F6]">
                    <TypographyV2 width="normal" type="sm" className="text-med-black">
                        {t("unlock_event_description")}
                    </TypographyV2>
                </div>
            </div>
        </DialogV2>
    )
}

export default UnlockEventModal
