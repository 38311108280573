import { ApiGet, ApiPut } from "./ApiRequest"
import { EducationalContentResponse } from "types/Redux"
import { IEducationalContentRequest } from "../types"

export const getEducationalContentByTemplateId = async (templateId: number): Promise<EducationalContentResponse> => {
    const instance = new ApiGet<EducationalContentResponse>(`/educational-content/${templateId}`)
    instance.callerName = getEducationalContentByTemplateId.name
    return instance.run()
}

export const updateEduContentById = async (
    id: number,
    dara: IEducationalContentRequest
): Promise<EducationalContentResponse> => {
    const instance = new ApiPut<EducationalContentResponse>(`/educational-content/${id}`, dara)
    instance.callerName = updateEduContentById.name
    return instance.run()
}

export const getEducationalContentHtmlById = async (id: number): Promise<EducationalContentResponse> => {
    const instance = new ApiGet<EducationalContentResponse>(`/educational-content/html/${id}`)
    instance.callerName = getEducationalContentHtmlById.name
    return instance.run()
}
