import { PatientModalProps } from "modals/PatientModal/PatientModal"
import { AppAction, EducationalContentResponse } from "types/Redux"
import { CompleteUserInOrg } from "../../types/SchemaExtensions"
import * as types from "./types"
import { TreatmentEndModalParams } from "modals/TreatmentEndModal/TreatmentEndModal"

export const openModal = (type: string, params: any) => ({
    type: types.OPEN_MODAL,
    payload: { type, params }
})
export const closeModal = () => ({ type: types.CLOSE_MODAL })

export const updateParamsModal = (payload: any) => ({ type: types.UPDATE_PARAMS, payload })

export const openUserModal =
    (params?: Partial<CompleteUserInOrg<string>>): AppAction =>
    async (dispatch) => {
        dispatch(openModal("user", params))
    }

export const openUserPreferencesModal =
    (params: unknown): AppAction =>
    async (dispatch) => {
        dispatch(openModal("preferences", params))
    }

export const openPatientModal =
    (params?: PatientModalProps["params"]): AppAction =>
    async (dispatch) => {
        dispatch(openModal("patient", params))
    }

export const openPatientWhatNextModal =
    (params?: { patientId: number; isAfterPatientCreation?: boolean }): AppAction =>
    async (dispatch) => {
        dispatch(openModal("patient_what_next", params))
    }

export const openCollaboratorsModal =
    (params: unknown): AppAction =>
    async (dispatch) => {
        dispatch(openModal("collaborators", params))
    }

export const openFeaturesSettingsModal =
    (params: { patient_id: number }): AppAction =>
    async (dispatch) => {
        dispatch(openModal("features_settings", params))
    }

export const openTreatmentEndModal = (params: TreatmentEndModalParams) => openModal("treatment_end", params)

export const openEducationalContentBuilderModal =
    (params: EducationalContentResponse): AppAction =>
    async (dispatch) => {
        dispatch(openModal("educational_content_builder", params))
    }

export const openAssignCareProtocolModal =
    (params: any): AppAction =>
    async (dispatch) => {
        dispatch(openModal("assign_care_protocol", params))
    }

export const openDeleteEventModal =
    (params: any): AppAction<any> =>
    async (dispatch) => {
        dispatch(openModal("delete_event", params))
    }

export const openUnlockEventModal =
    (params: any): AppAction<any> =>
    async (dispatch) => {
        dispatch(openModal("unlock_event", params))
    }

export const openEditEventModal =
    (params: any): AppAction =>
    async (dispatch) => {
        dispatch(openModal("edit_event", params))
    }

export const openViewProtocolModal =
    (params: any): AppAction =>
    async (dispatch) => {
        dispatch(openModal("view_assigned_protocol", params))
    }

export const openViewIndependentEventsModal =
    (params?: any): AppAction =>
    async (dispatch) => {
        dispatch(openModal("view_independent_events", params))
    }

export const openCarePlanFeatureSettingsModal =
    (params?: any): AppAction =>
    async (dispatch) => {
        dispatch(openModal("care_plan_feature_settings", params))
    }
