import { CardV2, TypographyV2 } from "components/v2/components/common"
import { useIsHeLanguage } from "hooks"
import React from "react"
import { useDispatch } from "store"
import { useUnitSelector } from "store/hooks"
import { setSelectedChapterAction } from "store/unit/actions"
import { UnitChapterResponse } from "types/Redux"
import { classNames } from "utils"

const UnitBuilderPreviewChaptersListItem = ({ index, ...param }: UnitChapterResponse & { index: number }) => {
    const { isHeLanguage } = useIsHeLanguage()

    const { selectedChapter } = useUnitSelector()

    const dispatch = useDispatch()

    const isSelectedChapter = param.id === selectedChapter

    return (
        <CardV2 className="!p-2" onClick={() => dispatch(setSelectedChapterAction(param.id))}>
            <div className="flex justify-between items-center">
                <div className="flex items-center flex-1 overflow-hidden">
                    <div className="flex items-center w-9/12">
                        <div
                            className={classNames(
                                "round aspect-square w-8 flex justify-center items-center rounded-md",
                                isSelectedChapter ? "bg-blue-50" : "bg-gray-50"
                            )}
                        >
                            <TypographyV2
                                className={classNames(isSelectedChapter ? "text-med-blue" : "text-gray-400")}
                                width="semibold"
                            >
                                {index + 1}
                            </TypographyV2>
                        </div>
                        <div
                            className={classNames(
                                "flex-1 overflow-hidden text-ellipsis whitespace-nowrap",
                                isHeLanguage ? "mr-3" : "ml-3"
                            )}
                        >
                            <TypographyV2
                                className={classNames(
                                    "font-bold overflow-hidden text-ellipsis",
                                    isHeLanguage ? "text-right" : "text-left"
                                )}
                            >
                                {param.name}
                            </TypographyV2>
                        </div>
                    </div>
                </div>
            </div>
        </CardV2>
    )
}

export default UnitBuilderPreviewChaptersListItem
