import { DialogV2 } from "components/v2"
import IntakeDialogHeaderStepList from "components/v2/components/IntakeScreening/IntakeDialogHeaderStepList/IntakeDialogHeaderStepList"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { LibraryTemplateResponse } from "types/Redux"
import { LibraryTemplateType, TriggerType } from "@prisma/client"
import i18n from "locales"
import { PreviewCPElement, SelectCPElement, getStepsList } from "../common"
import ConfigureCPElement from "./ConfigureCPElement"
import { TFunction } from "i18next"
import { useCareProtocolElementList } from "../../CareProtocolElemetList/useCareProtocolElementList"
interface AddSequenceElementModalProps {
    isOpened: boolean
    onClose: () => void
    t: TFunction
    list: LibraryTemplateResponse[]
    libraryTemplateType: LibraryTemplateType | "QUESTIONNAIRE"
    onAddNewElement: (body: any) => void
    cpElement: any
}

const AddSequenceElementModal: React.FC<AddSequenceElementModalProps> = ({
    isOpened,
    onClose,
    t,
    list,
    libraryTemplateType,
    onAddNewElement,
    cpElement
}) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const steps = getStepsList()
    const { careProtocolElements } = useCareProtocolElementList(null)

    const getSelectedElementId = () => {
        if (!cpElement) return null
        return (
            cpElement?.questionnaire_element_library_template_id ||
            cpElement?.exercise_element_library_template_id ||
            cpElement?.unit_element_library_template_id ||
            null
        )
    }

    const sequentialElements = careProtocolElements.filter(
        (element) =>
            element.trigger_type === TriggerType.SEQUENTIAL_TRIGGERED ||
            element.trigger_type === TriggerType.SEQUENTIAL_MANUAL
    )

    const {
        control,
        formState: { errors },
        getValues,
        watch,
        reset
    } = useForm({
        mode: "onChange",
        defaultValues: {
            triggerType: TriggerType.SEQUENTIAL_TRIGGERED,
            selectedElementId: getSelectedElementId(),
            numberOfDelay: "0",
            delayType: "1",
            repeatsType: "0",
            numberOfRepeats: "0",
            unitCompletionEventTrigger: null
        }
    })

    useEffect(() => {
        if (cpElement) {
            reset({
                triggerType: cpElement.trigger_type,
                numberOfDelay: cpElement.delay?.toString() || "0",
                delayType: "1",
                repeatsType: cpElement.recurrence_interval?.toString() || "0",
                numberOfRepeats: cpElement.recurrence_end?.toString() || "0",
                unitCompletionEventTrigger: cpElement.unit_completion_trigger_element_id || null,
                selectedElementId: getSelectedElementId()
            })
        }
    }, [cpElement, reset])

    const handleBack = () => {
        setCurrentStepIndex(currentStepIndex - 1)
    }

    const selectedElementId = watch("selectedElementId")

    const handleNext = () => {
        if (!selectedElementId) return
        // TODO: add validation
        setCurrentStepIndex(currentStepIndex + 1)
    }

    const handleDismiss = () => {
        setCurrentStepIndex(0)
        reset()
        onClose()
    }

    const handleAddNewElement = () => {
        const { numberOfDelay, delayType, repeatsType, numberOfRepeats, unitCompletionEventTrigger, triggerType } =
            getValues()

        const delay_type = Number(delayType)
        const delay = Number(numberOfDelay) * delay_type
        const recurrence_interval = Number(repeatsType)
        const recurrence_end = Number(numberOfRepeats) * recurrence_interval
        const elementId = { [`${libraryTemplateType.toLowerCase()}_element_library_template_id`]: selectedElementId }
        const unitCompletionEventTriggerId = unitCompletionEventTrigger
            ? { unit_completion_trigger_element_id: Number(unitCompletionEventTrigger.split("-")[0]) }
            : {}

        try {
            onAddNewElement({
                delay,
                recurrence_interval,
                recurrence_end,
                element_type: libraryTemplateType,
                trigger_type: triggerType,
                position: sequentialElements.length,
                ...unitCompletionEventTriggerId,
                ...elementId
            })
        } catch (error) {
            console.log(error)
        } finally {
            handleDismiss()
        }
    }

    const selectedElement = list?.find((element) => element.id === selectedElementId) || null

    return (
        <DialogV2
            title={
                <IntakeDialogHeaderStepList
                    title={i18n.t("addToCareProtocol", {
                        ns: "careProtocol",
                        element: t(`eventTypes.${libraryTemplateType?.toLowerCase()}`)
                    })}
                    step={currentStepIndex}
                    stepList={steps}
                />
            }
            classList={{
                title: "w-[1200px]"
            }}
            isOpen={isOpened}
            onClose={onClose}
            onProceed={currentStepIndex === steps.length - 1 ? handleAddNewElement : handleNext}
            onDismiss={handleDismiss}
            onBack={currentStepIndex === 0 ? undefined : handleBack}
            backType="light-gray"
            backText={currentStepIndex === 0 ? undefined : t("back")}
            dismissType="light-gray"
            proceedText={currentStepIndex === steps.length - 1 ? t("create_element") : t("continue")}
            type="wide"
        >
            <div className="h-[677px] overflow-y-auto w-full">
                {currentStepIndex === 0 && <SelectCPElement t={t} list={list} control={control} />}
                {currentStepIndex === 1 && <PreviewCPElement libraryElement={selectedElement} />}
                {currentStepIndex === 2 && (
                    <ConfigureCPElement
                        t={t}
                        control={control}
                        errors={errors}
                        libraryTemplateType={libraryTemplateType}
                        selectedElement={selectedElement}
                    />
                )}
            </div>
        </DialogV2>
    )
}

export default AddSequenceElementModal
