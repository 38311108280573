import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { createClinicMediaRequest } from "api"
import useMediaUpload, { CloudinaryUploadResponse } from "hooks/useMediaUpload"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useLibraryTemplateSelector } from "store/hooks"
import { INSERT_IMAGE_COMMAND } from "../../../ImagesPlugin/ImagesPlugin"
import { MediaType, OwnerObjectType } from "@prisma/client"
import { ButtonV2 } from "components/v2/components/common/buttons"
import { INSERT_VIDEO_COMMAND } from "../../../VideosPlugin/VideosPlugin"

const UploadEducationalContentImage = () => {
    const { t } = useTranslation("common")
    const { libraryTemplate, educationalContent } = useLibraryTemplateSelector()
    const [editor] = useLexicalComposerContext()
    const { uploadImage, uploadVideo, isLoading } = useMediaUpload()

    const handleUpload = async (mediaType: MediaType) => {
        try {
            if (!libraryTemplate?.id || !educationalContent?.id) {
                throw new Error("Missing required IDs")
            }

            const uploadFn = mediaType === MediaType.IMAGE ? uploadImage : uploadVideo

            const uploadedFile: CloudinaryUploadResponse = await uploadFn({
                folderPath: `educational_content/${educationalContent.id}`,
                tags: ["educational_content", educationalContent.id.toString()]
            })

            const mediaData = {
                ownerObjectType: OwnerObjectType.EDUCATIONAL_CONTENT,
                ownerObjectId: libraryTemplate.id,
                savedFileName: uploadedFile.public_id,
                savedFilePath: uploadedFile.secure_url,
                storageProviderMetadata: {
                    tags: uploadedFile.tags || [],
                    cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
                    public_id: uploadedFile.public_id
                },
                uploadResultString: JSON.stringify(uploadedFile),
                originalFileType: uploadedFile.format,
                originalFileName: uploadedFile.original_filename,
                size: uploadedFile.bytes,
                mediaType,
                contentId: educationalContent.id.toString()
            }

            const response = await createClinicMediaRequest(mediaData)
            toast(t("common:fileUpload.success"), { type: "success" })
            editor.dispatchCommand(
                mediaType === MediaType.IMAGE ? INSERT_IMAGE_COMMAND : INSERT_VIDEO_COMMAND,
                response
            )
        } catch (error) {
            console.error("Error processing upload:", error)
            toast(t("common:wentWrong"), { type: "error" })
        }
    }

    return (
        <>
            <ButtonV2
                onClick={() => handleUpload(MediaType.IMAGE)}
                text={t("upload_image", { ns: "educationalContent" })}
                className="mb-3"
            />
            <ButtonV2
                onClick={() => handleUpload(MediaType.VIDEO)}
                text={t("upload_video", { ns: "educationalContent" })}
                className="mb-3"
            />
        </>
    )
}

export default UploadEducationalContentImage
