import {
    Diagnosis,
    LibraryTemplate,
    Organization,
    OrgRole,
    Prisma,
    Roles,
    Tag,
    User,
    UserPreferences
} from "@prisma/client"
import { Dictionary } from "lodash"
import { Dispatch, SetStateAction } from "react"
import { DiagnosisListItem } from "./Misc"
import { PatientWithDiagnoses } from "./Patient"
import { QuestionnaireListItem } from "./Questionnaire"
import { BasicUser, UserCreateInput, UserWithRolesDeep } from "./User"
import { FlatCarePlan } from "./CarePlan"
export type { CreateSensitiveMediaParams } from './Media'
export type {
    AddEventResponse,
    AppointmentEvent,
    AppointmentStatus,
    CommentData,
    CommentUpdateData,
    CompleteEvent,
    CompleteEventExercise,
    CompleteEventQuestionnaire,
    EventComment,
    EventHandlePolicy,
    EventsByDate,
    EventsByDateEntries,
    EventUpdateData,
    FetchEventResponse,
    FlatEvent,
    GetEventsPayload,
    Invitee,
    PatientSnapshot,
    QuestionnaireSnapshot,
    RawEventCreateInput,
    ScoreData
} from "./Event"
export type {
    CsvCell,
    CsvExport,
    CsvExportGroup,
    CsvFilter,
    CsvFilters,
    CsvInputField,
    CsvRow,
    DefaultFieldType,
    PatientWithEvents
} from "./Export"
export type { DiagnosisListItem, DiagnosisTreeItem } from "./Misc"
export type {
    BasicCollaborator,
    BasicPermission,
    CollaboratorWithPermissions,
    CollaboratorWithUserId,
    CompleteCollaborator,
    CompletePatientFile,
    ContactUser,
    DashboardCard,
    GraphResolution,
    PatientFileInput,
    PatientFileWithQuestionnaires,
    PatientQuestionnaire,
    PatientWithDiagnoses,
    UserWithPatientFile,
    PatientEngagementMontlyData,
    PatientEngagementMontlyDataItem
} from "./Patient"
export type {
    CompleteQuestionnaire,
    CompleteQuestionnaireDraft,
    CompleteQuestionnaireWithAlerts,
    CorrectedQuestionnaireDef,
    CreateCutoffInput,
    CreateQuestionInput,
    QuestionDefinition,
    QuestionnaireCreateData,
    QuestionnaireDraftCreateData,
    QuestionnaireEventTemplate,
    QuestionnaireListItem,
    QuestionnaireWithRelatedDiagnoses,
    QuestionScaleCreateInput,
    QuestionScaleWithCutoffs
} from "./Questionnaire"
export type {
    BasicUser,
    CreateUserPayload,
    RoleWithOrg,
    TeamUser,
    UpdateTeamMemberParams,
    UserCreateInput,
    UserCreateResult
} from "./User"
export type { Optional, ReplacePropType, XOR } from "./utils"
export type { FlatCarePlan, CarePlanEvent } from "./CarePlan"
export type { RejectCandidateData } from "./Intake"

export type MixpanelUserData = {
    distinct_id: number
    clinic: number
    org_role: string
    permission: string
}

export interface ITwilioWebHookData {
    EventType: string
    InstanceSid: string
    Attributes: Object
    DateCreated: string
    Index: number
    Author: string // uid1
    MessageSid: string
    AccountSid: string
    Source: string // SDK
    ConversationSid: string
    ClientIdentity: "uid1" //uid1
    RetryCount: number
    Body: string
}

export type TwilioMessageAttributes = {
    is_urgent?: boolean
}

export type OrganizationCreateData = {
    manager: UserCreateInput<string, string>
    organization: Prisma.OrganizationCreateInput
    defaultPrefs: Omit<UserPreferences, "id">
}

export const weekdays: WeekDay[] = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]

export type WeekDay = "sun" | "mon" | "tue" | "wed" | "thu" | "fri" | "sat"

export type ActiveHoursData = Record<WeekDay, ActiveHour>

export type ActiveHour = {
    enable: boolean
    start: number
    end: number
}

export type ChatDataPayload = { participants: BasicUser[]; configuration: ChatConfig }

export interface ChatConfig {
    [uid: string]: ChatParticipantConfig
}

export type ChatParticipantConfig = {
    is_muted: false
}

export type ChatParticipantPayload = { sid: string } & ChatParticipantConfig

export type ChatMessageCallbackResponse = Pick<UserPreferences, "chat_automated_reply"> & {
    chat_mark_as_urgent: boolean
}

export type ChatMigrationDataPayload = {
    sid: string
    users: number[]
}

export type OrganizationWithTeam = Organization & { users: OrganizationUser[] }

export type OrganizationUser = { id: number; role: OrgRole; user: BasicUser }

export type PortalNotificationData = {
    id: number
    patient_id: number
    type: PortalNotificationType
    body?: string
    date: string // This will be a date string, e.g. "2022-05-02"
    link: string // This is for the text that's on the right of the date in the design
    read: boolean
    ref_data?: Dictionary<string | number>
    from_user_id?: number
}

export type NotificationAction = {
    type: string
    label: string
    props?: any
}

export type Feature = {
    id: string
    name: string
    "isEnabled.prod": boolean
    "isEnabled.dev": boolean
    "isEnabled.mobile": boolean
}

export type StateProps<S = Dictionary<any>> = {
    state: S
    setState: Dispatch<SetStateAction<S>>
}


const completeExercise = Prisma.validator<Prisma.LibraryTemplateDefaultArgs>()({})
export type CompleteExercise = Prisma.LibraryTemplateGetPayload<typeof completeExercise> & { duration?: number }

export type BasicGoal = {
    id: number
    label: string
    img_id: number
    patient_id?: number
    is_achieved: boolean
    is_deleted: boolean
    type: string
}

export type InitialAppData<DT = Date> = {
    user: UserWithRolesDeep
    patients: PatientWithDiagnoses<string, DT>[]
    questionnaire_list: QuestionnaireListItem[]
    exercise_list: LibraryTemplate[]
    diagnoses: DiagnosisListItem[]
    feature_flags: Dictionary<boolean>
    patient_tags: Tag[]
    care_plans: FlatCarePlan[]
    goals: BasicGoal[]
    roles: Roles[]
}

export type Trend = "positive" | "negative" | "unchanged"

export type TrendWithUnmeasured = Trend | "unmeasured"

export type WorkingAlliance = "high" | "medium" | "low" | "unmeasured"

export type Activity = "active" | "inactive"

export type PatientStatus = "past" | "current"

export type Metric = {
    activity: Activity
    primary_progress: TrendWithUnmeasured
    severe_score: boolean
    treatment_length: number
    working_alliance: WorkingAlliance
    treatment_tags: number[]
    diagnosis: number[]
    status: PatientStatus
}

export type PortalNotificationType =
    | "app_inactivity"
    | "questionnaire_inactivity"
    | "task_inactivity"
    | "questionnaire_dramatic_change"
    | "questionnaire_severe_score"
    | "clinical_symptom_decline"
    | "comment_mention"
    | "question_alert"
    | "comment_followed"
    | "appointment_alert"
    | "questionnaire_dramatic_change_family"
    | "questionnaire_severe_score_family"
    | "new_screening_candidate"
    | "new_intake_screening_candidate"
    | "new_intake_screening_candidate_assigned_collaborators"
    | "new_intake_screening_candidate_assigned_case_manager"
    | "candidate_accepted"
    | "candidate_rejected"
    | "therapist_questionnaire_reminder"

export type TempId = number | `temp_${number}`
