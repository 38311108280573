import { flip, Middleware, Placement, shift, size, useFloating } from "@floating-ui/react-dom"
import { Transition } from "@headlessui/react"
import useBoolean from "hooks/useBoolean"
import { PropsWithChildren, ReactNode, useEffect, useRef } from "react"
import { ModalCompatiblePortal } from "./Modal"

type TooltipProps = PropsWithChildren<{
    content: ReactNode
    placement?: Placement
    wrapperClassNames?: string
    delay?: number
    middleware?: Middleware[]
    ["data-testid"]?: string
}>

const Tooltip = ({
    children,
    content,
    wrapperClassNames,
    placement = "left",
    delay = 400,
    middleware = [],
    "data-testid": testId
}: TooltipProps) => {
    const { update, refs, floatingStyles } = useFloating({
        placement,
        transform: true,
        middleware: [
            shift(),
            flip(),
            size({
                rootBoundary: "viewport",
                padding: 8,
                apply(args: any) {
                    args.elements.floating.style.maxWidth = `${args.availableWidth}px`
                }
            }),
            ...middleware
        ]
    })

    const [hovered, setHoveredTrue, setHoveredFalse] = useBoolean()

    useEffect(() => {
        update()
    }, [hovered])

    const displayTimeout = useRef<any>(null)
    const onMouseEnter = () => {
        displayTimeout.current = setTimeout(setHoveredTrue, delay)
    }
    const onMouseLeave = () => {
        clearTimeout(displayTimeout.current)
        if (hovered) setHoveredFalse()
    }

    return (
        <>
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={wrapperClassNames}
                ref={refs.setReference}
                data-testid={testId}
            >
                {children}
            </div>
            <ModalCompatiblePortal>
                <div ref={refs.setFloating} style={floatingStyles} className="z-[1000]">
                    <Transition
                        show={hovered}
                        enter="transition duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="bg-gray-700 pointer-events-none text-white px-3 p-2 rounded m-2">{content}</div>
                    </Transition>
                </div>
            </ModalCompatiblePortal>
        </>
    )
}

export default Tooltip
