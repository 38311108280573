import { SVGProps } from "react"

const CursorArrowKeysIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.65497 15.3509L8.69249 11.7588M8.69249 11.7588L6.91499 13.3351L7.31761 6.62733L11.0202 12.2351L8.69249 11.7588ZM7.5 1.59375V3.1875M11.6322 3.30534L10.5052 4.4323M13.3438 7.4375H11.75M4.4948 10.4427L3.36784 11.5697M3.25 7.4375H1.65625M4.4948 4.4323L3.36785 3.30534"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)
export default CursorArrowKeysIcon
