import { TypographyV2, ButtonV2 } from "components/v2"
import React from "react"
import { EmptyListIcon, PlusIcon } from "components/icons"

interface IEmptyListPlaceholderProps {
    title: string
    subTitle: string
    btnText: string
    onClick: () => void
    prevBtnText?: string
    prevOnClick?: () => void
}

const EmptyListPlaceholder: React.FC<IEmptyListPlaceholderProps> = ({
    title,
    subTitle,
    btnText,
    prevBtnText,
    prevOnClick,
    onClick
}) => {
    return (
        <div className="w-full flex flex-col justify-center items-center p-[60px]">
            <EmptyListIcon />
            <TypographyV2 width="bold" type="xl" className="mt-6">
                {title}
            </TypographyV2>
            <TypographyV2 className="text-gray-500 mt-1" type="sm">
                {subTitle}
            </TypographyV2>
            <div className="flex gap-x-2">
                {!!prevBtnText && (
                    <ButtonV2
                        icon={<PlusIcon />}
                        variant="blue-100"
                        className="mt-6 text-secondary hover:bg-dark-blue"
                        text={prevBtnText}
                        onClick={prevOnClick}
                    />
                )}

                <ButtonV2
                    icon={<PlusIcon />}
                    className="mt-6 text-secondary hover:bg-dark-blue"
                    text={btnText}
                    onClick={onClick}
                />
            </div>
        </div>
    )
}

export default EmptyListPlaceholder
