import { useEffect, useMemo, useState } from "react"
import { truncate } from "lodash"
import { useDispatch } from "store"
import { useLibraryTemplateSearchSelector, useLibraryTemplateSelector } from "store/hooks"
import { ExerciseSearchParamsKey, ExerciseSearchValue } from "types/Redux"
import { statusSelectOptions } from "./libraryTemplateFiltersConstants"
import { useTranslation } from "react-i18next"
import { setLibraryTemplateSearchParamsAction } from "store/library-template/actions"
import { LibraryTemplateType } from "@prisma/client"

export const useLibraryTemplateFilters = (libraryTemplateType: LibraryTemplateType) => {
    const { t } = useTranslation("exercise")

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState("")
    const { diagnoses, creators } = useLibraryTemplateSelector()
    const { diagnose, status, creator } = useLibraryTemplateSearchSelector()

    const mappedStatusSelectWithLocalization = useMemo(
        () =>
            statusSelectOptions.map(({ title, ...rest }) => ({
                ...rest,
                title: t(`exerciseStatuses.${title}`)
            })),
        [t]
    )

    const creatorOptions = useMemo(
        () => creators?.map(({ id, first_name, last_name }) => ({ id, title: first_name + " " + last_name })),
        [creators]
    )
    const onChangeSearchParams = (key: ExerciseSearchParamsKey, value: ExerciseSearchValue) => {
        dispatch(setLibraryTemplateSearchParamsAction({ key, value }))
    }

    const diagnoseOptions = useMemo(() => diagnoses?.map(({ id, name_en }) => ({ id, title: name_en })), [diagnoses])

    const handleSearchTextChange = (newText: string) => {
        setSearchText(newText)
        dispatch(
            setLibraryTemplateSearchParamsAction({
                key: "text",
                value: newText
            })
        )
    }

    const onSelectChange =
        (key: ExerciseSearchParamsKey) =>
        ([value]: [string | number]) => {
            onChangeSearchParams(key, value)
        }

    useEffect(() => {
        handleSearchTextChange("")
    }, [libraryTemplateType])

    return {
        t,
        creator,
        status,
        diagnose,
        diagnoseOptions,
        creatorOptions,
        mappedStatusSelectWithLocalization,
        onChangeSearchParams,
        onSelectChange,
        setSearchText: handleSearchTextChange,
        searchText
    }
}
