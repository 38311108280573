import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { DRAG_DROP_PASTE } from "@lexical/rich-text"
import { isMimeType, mediaFileReader } from "@lexical/utils"
import { COMMAND_PRIORITY_LOW } from "lexical"
import { useEffect } from "react"
import { INSERT_IMAGE_COMMAND } from "../ImagesPlugin/ImagesPlugin"
import { INSERT_VIDEO_COMMAND } from "../VideosPlugin/VideosPlugin"

const ACCEPTABLE_IMAGE_TYPES = ["image/", "image/heic", "image/heif", "image/gif", "image/webp"]
const ACCEPTABLE_VIDEO_TYPES = ["video/", "video/mp4", "video/quicktime", "video/x-msvideo"]

export default function DragDropPaste(): null {
    const [editor] = useLexicalComposerContext()
    useEffect(() => {
        return editor.registerCommand(
            DRAG_DROP_PASTE,
            (files) => {
                ;(async () => {
                    // Handle Images
                    const imageFiles = await mediaFileReader(
                        files,
                        [ACCEPTABLE_IMAGE_TYPES].flatMap((x) => x)
                    )
                    for (const { file, result } of imageFiles) {
                        if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
                            editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                                altText: file.name,
                                src: result
                            })
                        }
                    }

                    // Handle Videos
                    const videoFiles = await mediaFileReader(
                        files,
                        [ACCEPTABLE_VIDEO_TYPES].flatMap((x) => x)
                    )

                    for (const { file, result } of videoFiles) {
                        if (isMimeType(file, ACCEPTABLE_VIDEO_TYPES)) {
                            editor.dispatchCommand(INSERT_VIDEO_COMMAND, {
                                src: result,
                                type: file.type
                            })
                        }
                    }
                })()
                return true
            },
            COMMAND_PRIORITY_LOW
        )
    }, [editor])
    return null
}
