import patient from "./patient.json"
import user from "./user.json"
import common from "./common.json"
import dates from "./dates.json"
import questionnaire from "./questionnaire.json"
import organization from "./organization.json"
import therapist from "./therapist.json"
import intake from "./intake.json"
import exercise from "./exercise.json"
import question from "./question.json"
import unit from "./unit.json"
import educationalContent from "./educational-content.json"
import careProtocol from "./careProtocol.json"
import carePlan from "./care-plan.json"

export default {
    patient,
    user,
    common,
    dates,
    questionnaire,
    organization,
    therapist,
    exercise,
    question,
    intake,
    unit,
    educationalContent,
    careProtocol,
    carePlan
}
