import { ExerciseStatuses } from "@prisma/client"
export const statusSelectOptions = [
    { title: "draft", id: ExerciseStatuses.INIT },
    {
        title: "published",
        id: ExerciseStatuses.PUBLISHED
    },
    // {
    //     title: "active",
    //     id: ExerciseStatuses.ACTIVE
    // },
]
