import { LibraryTemplateType } from "@prisma/client"
import { toast } from "react-toastify"
import i18n from "locales"

export enum LibraryTemplateAction {
    Archived = "Archived",
    Created = "Created",
    Copied = "Copied",
    Published = "Published",
    Updated = "Updated"
}
export const showLibraryTemplateNotification = (type: LibraryTemplateType, action: LibraryTemplateAction) => {
    switch (type) {
        case LibraryTemplateType.UNIT:
            toast.success(i18n.t(`notifications.unit.successfully${action}`, { ns: "unit" }))
            break
        case LibraryTemplateType.EXERCISE:
            toast.success(i18n.t(`notifications.successfully${action}`, { ns: "exercise" }))
            break
        case LibraryTemplateType.EDUCATIONAL_CONTENT:
            toast.success(i18n.t(`notifications.successfully${action}`, { ns: "educationalContent" }))
            break
        default:
            break
    }
}
