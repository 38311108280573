import { ApiGet, ApiPatch, ApiPost, ApiPut } from "./ApiRequest"
import { LibraryTemplateResponse } from "types/Redux"
import type { CreateExerciseDefPayload } from "../types/exercises/ExerciseDef"
import { LibraryTemplateType } from "@prisma/client"

export const getAllLibraryTemplates = async (type?: LibraryTemplateType): Promise<LibraryTemplateResponse[]> => {
    const params = type ? { type } : {}
    const instance = new ApiGet<LibraryTemplateResponse[]>("/library-template", params)
    instance.callerName = getAllLibraryTemplates.name
    return instance.run()
}

export const getAllLibraryTemplateById = async (id: number): Promise<LibraryTemplateResponse> => {
    const instance = new ApiGet<LibraryTemplateResponse>(`/library-template/${id}`)
    instance.callerName = getAllLibraryTemplateById.name
    return instance.run()
}

export const createLibraryTemplate = async (body: CreateExerciseDefPayload): Promise<LibraryTemplateResponse> => {
    const instance = new ApiPost<LibraryTemplateResponse>("/library-template", body)
    instance.callerName = createLibraryTemplate.name
    return instance.run()
}

export const updateLibraryTemplate = async (
    id: number,
    body: CreateExerciseDefPayload
): Promise<LibraryTemplateResponse> => {
    const instance = new ApiPut<LibraryTemplateResponse>(`/library-template/${id}`, body)
    instance.callerName = updateLibraryTemplate.name
    return instance.run()
}

export const cloneLibraryTemplate = async (id: number): Promise<LibraryTemplateResponse> => {
    const instance = new ApiPost<LibraryTemplateResponse>(`/library-template/clone/${id}`)
    instance.callerName = cloneLibraryTemplate.name
    return instance.run()
}

export const archiveLibraryTemplate = async (id: number) => {
    const instance = new ApiPatch(`/library-template/archive/${id}`)
    instance.callerName = archiveLibraryTemplate.name
    return instance.run()
}

export const publishLibraryTemplate = async (id: number): Promise<LibraryTemplateResponse> => {
    const instance = new ApiPatch<LibraryTemplateResponse>(`/library-template/publish/${id}`)
    instance.callerName = publishLibraryTemplate.name
    return instance.run()
}


export const getLibraryTemplateById = async (id: number): Promise<LibraryTemplateResponse> => {
    const instance = new ApiGet<LibraryTemplateResponse>(`/library-template/${id}`)
    instance.callerName = getLibraryTemplateById.name
    return instance.run()
}

