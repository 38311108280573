import { omit } from "lodash"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useCurrentUser } from "store/hooks"
import { pushToLibraryStack } from "store/patient/actions"
import { ContentLibraryData } from "types/Redux"
import { classNames, eventTypeIconMap } from "utils"
import { FeatureFlags } from "utils/FeatureFlags"
import ContentLibHeader from "./ContentLibHeader"

type AvailableType = "task" | "questionnaire" | "exercise" | "appointment" | "educational_content"

const SelectType = () => {
    const { t } = useTranslation("patient")
    const user = useCurrentUser()
    const dispatch = useDispatch()

    return (
        <>
            <ContentLibHeader
                view="select_type"
                title={t("contentLib.addNewEvent")}
                subtitle={<div className="text-base font-normal pt-2">{t("contentLib.selectType")}</div>}
            />
            <div className="flex flex-col gap-10 px-12 py-16">
                <div className="flex gap-6 ">
                    <RenderTypeCard
                        type="task"
                        className="bg-[#A95487] text-[#A95487]"
                        libDataOnClick={{ eventTemplates: { type: "task" }, view: "config" }}
                    />
                    {FeatureFlags.isEnabled("appointment-events") && (
                        <RenderTypeCard
                            type="appointment"
                            className="bg-[#657FA6] text-[#657FA6]"
                            libDataOnClick={{ eventTemplates: { type: "appointment" }, view: "config" }}
                        />
                    )}
                    <RenderTypeCard
                        type="questionnaire"
                        className="bg-med-blue text-med-blue"
                        libDataOnClick={{ view: "questionnaire.cards" }}
                    />
                    {FeatureFlags.isEnabled("exercise-events") && (
                        <RenderTypeCard
                            type="exercise"
                            className="bg-[#AC81D8] text-[#AC81D8]"
                            libDataOnClick={{ view: "exercise.cards" }}
                        />
                    )}
                    <RenderTypeCard
                        type="educational_content"
                        className="bg-sky-300 text-sky-300"
                        libDataOnClick={{ view: "educational_content.cards" }}
                    />
                </div>
                <hr />
                <span className="text-dark-blue text-sm font-semibold">
                    {t("contentLib.recentlyUsed").toUpperCase()}
                </span>
                <div className="flex flex-col gap-4 text-text-blue text-base">
                    {user.created_events.map((event) => {
                        const { type, title } = event
                        const Icon = eventTypeIconMap?.[type]
                        return (
                            <span
                                key={event.id}
                                className="flex gap-4 items-center cursor-pointer"
                                onClick={() =>
                                    dispatch(
                                        pushToLibraryStack({
                                            eventTemplates: omit(event, ["date_on_timeline", "generated_from"]),
                                            patientId: "preserve",
                                            view: "config"
                                        })
                                    )
                                }
                            >
                                <Icon className="w-5 h-5" /> {title}
                            </span>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

type RenderTypeCardProps = {
    className: string
    type: AvailableType
    libDataOnClick: Omit<ContentLibraryData, "patientId">
}

const RenderTypeCard = ({ className, type, libDataOnClick }: RenderTypeCardProps) => {
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()
    const Icon = eventTypeIconMap[type]

    return (
        <button
            className={classNames(
                "rounded-lg w-64 h-44 bg-opacity-30 flex flex-col gap-3 justify-center items-center font-meidum text-lg",
                "hover:shadow-md active:shadow cursor-pointer transition-shadow",
                className
            )}
            onClick={() => dispatch(pushToLibraryStack({ ...libDataOnClick, patientId: "preserve" }))}
            data-testid={`select_type-${type}`}
        >
            <Icon className="text-5xl" />
            {t(`contentLib.newEventType.${type}`)}
        </button>
    )
}

export default SelectType
