import { ButtonV2 } from "components/v2/components/common/buttons"
import { HexColorPicker } from "react-colorful"
import { Popover } from "@headlessui/react"
import { useState } from "react"
import { TrashIcon } from "components/icons"
import TagV2 from "components/v2/components/common/TagV2"

interface ColorPickerButtonProps {
    bgColor?: string
    onChange: (color: string) => void

    onReset?: () => void
}

const ColorPickerButton: React.FC<ColorPickerButtonProps> = ({ bgColor, onChange, onReset }) => {
    const [inputColor, setInputColor] = useState(bgColor || "#FFFFFF")

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newColor = event.target.value
        setInputColor(newColor)

        if (/^#([0-9A-F]{3}){1,2}$/i.test(newColor)) {
            onChange(newColor)
        }
    }

    return (
        <Popover className="relative">
            <div className="flex items-center justify-between">
                <Popover.Button className="flex items-center gap-4">
                    <ButtonV2
                        text=""
                        variant="transparent"
                        className="relative w-[40px] h-[40px] border border-base-light p-0"
                        style={{ backgroundColor: bgColor || "transparent" }}
                    />

                    <div className="px-4 rounded-md py-2 leading-5 text-[#9CA3AF] border border-base-light font-normal text-base min-w-[130px]">
                        {bgColor ? `#${bgColor.replace("#", "")}` : "#fff"}
                    </div>
                </Popover.Button>
                {onReset && (
                    <TagV2 icon={<TrashIcon className=" hover:bg-gray-100  text-gray-400" />} onClick={onReset} />
                )}
            </div>

            <Popover.Panel className="absolute bottom-full left-0 z-10 mb-2 bg-white rounded-lg shadow-lg p-4">
                <div className="custom-color-picker mb-3 rounded">
                    <HexColorPicker color={bgColor || "#FFFFFF"} onChange={onChange} />
                </div>

                <div className="flex gap-2 w-full">
                    <input
                        type="text"
                        value={inputColor}
                        onChange={handleInputChange}
                        className="px-4 py-2 leading-5 w-full text-[#374151] border border-base-light font-normal text-base rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    />
                </div>
            </Popover.Panel>
        </Popover>
    )
}

export default ColorPickerButton
