import { useEffect } from "react"
import { DragEndEvent, MouseSensor, MouseSensorOptions, useSensor, useSensors } from "@dnd-kit/core"
import { useDispatch } from "store"
import { getQuestionByExerciseIdAction, updateQuestionsPositionsAction } from "store/questions/actions"
import { useLibraryTemplateSelector, useQuestionsSelector } from "store/hooks"
import { useTranslation } from "react-i18next"
import { ExerciseStatuses } from "@prisma/client"

class MyMouseSensor extends MouseSensor {
    static noDragTag = ["input", "textarea", "button"]
    static activators = [
        {
            eventName: "onMouseDown" as const,
            handler: ({ nativeEvent: event }: { nativeEvent: MouseEvent }) => {
                return !(
                    (event.target as HTMLElement).getAttribute("data-no-drag-indicator") ||
                    MyMouseSensor.noDragTag.includes((event.target as HTMLElement).tagName.toLowerCase())
                )
            }
        }
    ]
}

export const useExerciseQuestionsList = () => {
    const { t } = useTranslation("question")
    const dispatch = useDispatch()
    const { libraryTemplate } = useLibraryTemplateSelector()
    const { list: questions, isLoading } = useQuestionsSelector()

    const isEditDisabled = libraryTemplate?.status === ExerciseStatuses.PUBLISHED

    const sensors = useSensors(
        useSensor(MyMouseSensor, {
            activationConstraint: { distance: 5 }
        })
    )

    useEffect(() => {
        if (libraryTemplate) dispatch(getQuestionByExerciseIdAction(libraryTemplate.id))
    }, [libraryTemplate])

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (!over) return

        const activeId = active.id as number
        const overId = over.id as number

        if (activeId !== overId) {
            dispatch(updateQuestionsPositionsAction({ activeId, overId }))
        }
    }

    return { t, isEditDisabled, isLoading, sensors, questions, onDragEnd }
}
