import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2 } from "components/v2"
import { classNames } from "utils"
const MultipleChoiceQuestionResponse = ({
    question,
    screeningAnswer,
    sybQuestion = false,
    isLastQuestion = false
}: {
    question: any
    screeningAnswer: ScreeningAnswer
    sybQuestion?: boolean
    isLastQuestion?: boolean
}) => {
    // Safe JSON parsing with error handling
    const selectedValues = (() => {
        try {
            // First try to parse as JSON array
            if (screeningAnswer?.value) {
                const parsed = JSON.parse(screeningAnswer.value)
                return Array.isArray(parsed) ? parsed : [parsed]
            }
            return []
        } catch (error) {
            // If JSON parsing fails, try to handle it as a comma-separated string
            return screeningAnswer?.value ? screeningAnswer.value.split(",") : []
        }
    })()

    // Get options from question definition
    const options = question.definition?.options || []

    return (
        <div className="">
            <div
                className={classNames(
                    "bg-white border-slate-300 py-4 px-3",
                    sybQuestion ? "border-b" : "border rounded-lg",
                    !isLastQuestion && "border-none"
                )}
            >
                <TypographyV2 type="base" className="text-[#6B7280]">
                    {question.question}
                </TypographyV2>

                <div className="pt-2 flex flex-wrap gap-2">
                    {selectedValues.map((value: string, index: number) => (
                        <div
                            key={`${value}-${index}`}
                            className="bg-[#EFF6FF] border border-[#3C7FF9] rounded-lg py-2 px-4"
                        >
                            <TypographyV2 type="base">{options[parseInt(value)] || value}</TypographyV2>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MultipleChoiceQuestionResponse
