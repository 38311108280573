import { ApiGet, ApiPatch, ApiPost, ApiDelete, ApiPut } from "api/ApiRequest"
import { CarePlanEventResponse, PatientCareProtocolResponse } from "../../forms/types"
import { getCarePlanBYPatientId } from "../CarePlan"

export const getCareProtocolElementsByCareProtocolId = async (careProtocolId: number): Promise<any[]> => {
    const instance = new ApiGet<any[]>(`/care-protocol/elements/${careProtocolId}`)
    instance.callerName = getCareProtocolElementsByCareProtocolId.name
    return instance.run()
}

export const createCareProtocolElement = async (body: any): Promise<any> => {
    const instance = new ApiPost<any>("/care-protocol", body)
    instance.callerName = createCareProtocolElement.name
    return instance.run()
}

export const updateCareProtocolElementPositions = async (
    newList: { id: number; position: number }[],
    careProtocolId: number
) => {
    const instance = new ApiPatch("/care-protocol/update-positions", { newList, careProtocolId })
    instance.callerName = updateCareProtocolElementPositions.name
    return instance.run()
}

export const deleteCareProtocolElement = async (id: number) => {
    const instance = new ApiDelete(`/care-protocol/${id}`)
    instance.callerName = deleteCareProtocolElement.name
    return instance.run()
}

export const updateCareProtocolElement = async (id: number, body: any): Promise<any> => {
    const instance = new ApiPut<any>(`/care-protocol/${id}`, body)
    instance.callerName = updateCareProtocolElement.name
    return instance.run()
}

export const assignCareProtocolToPatient = async (patientId: number, body: { protocolId: number }) => {
    const instance = new ApiPost<CarePlanEventResponse[]>(`/care-protocol/assign/${patientId}`, body)
    instance.callerName = getCarePlanBYPatientId.name
    return instance.run()
}

export const getPatientCareProtocol = async (patientId: number) => {
    const instance = new ApiGet<PatientCareProtocolResponse>(`/care-protocol/assign/${patientId}`)
    instance.callerName = getPatientCareProtocol.name
    return instance.run()
}

export const deleteSequentialEvent = async (eventId: number, patientId: number) => {
    const instance = new ApiDelete<PatientCareProtocolResponse>(`/care-protocol/sequential/${patientId}/${eventId}`)
    instance.callerName = deleteSequentialEvent.name
    return instance.run()
}

export const unlockManualSequentialEventFromProtocol = async (eventId: number) => {
    const instance = new ApiPost(`/care-protocol/sequential/unlock/${eventId}`)
    instance.callerName = unlockManualSequentialEventFromProtocol.name
    return instance.run()
}

export const getCompletedEventStatusesByrPatient = async (patientId: number) => {
    const instance = new ApiGet(`/care-protocol/check-completed/${patientId}`)
    instance.callerName = getCompletedEventStatusesByrPatient.name
    return instance.run()
}
