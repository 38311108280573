import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils"
import {
    $createParagraphNode,
    $createRangeSelection,
    $getSelection,
    $insertNodes,
    $isNodeSelection,
    $isRootOrShadowRoot,
    $setSelection,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_HIGH,
    COMMAND_PRIORITY_LOW,
    createCommand,
    DRAGOVER_COMMAND,
    DRAGSTART_COMMAND,
    DROP_COMMAND,
    getDOMSelectionFromTarget,
    isHTMLElement,
    LexicalCommand,
    LexicalEditor,
    SELECTION_CHANGE_COMMAND
} from "lexical"
import { useEffect } from "react"

import { MediaObject } from "@prisma/client"
import { $createVideoNode, $isVideoNode, VideoNode } from "../../nodes/VideoNode"

export type InsertVideoPayload = Readonly<any>

export const INSERT_VIDEO_COMMAND: LexicalCommand<InsertVideoPayload> = createCommand("INSERT_VIDEO_COMMAND")

export default function VideoPlugin(): JSX.Element | null {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        if (!editor.hasNodes([VideoNode])) {
            throw new Error("VideoPlugin: VideoNode not registered on editor")
        }

        return mergeRegister(
            editor.registerCommand<InsertVideoPayload>(
                INSERT_VIDEO_COMMAND,
                (payload: MediaObject) => {
                    const videoNode = $createVideoNode({ mediaObject: payload })
                    $insertNodes([videoNode])

                    if ($isRootOrShadowRoot(videoNode.getParentOrThrow())) {
                        $wrapNodeInElement(videoNode, $createParagraphNode).selectEnd()
                    }

                    return true
                },
                COMMAND_PRIORITY_EDITOR
            ),

            // Handle selection
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    const selection = $getSelection()
                    if ($isNodeSelection(selection)) {
                        const nodes = selection.getNodes()
                        const node = nodes[0]
                        if ($isVideoNode(node)) {
                            // Handle video selection
                            return true
                        }
                    }
                    return false
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand<DragEvent>(
                DRAGSTART_COMMAND,
                (event) => {
                    return $onDragStart(event)
                },
                COMMAND_PRIORITY_HIGH
            ),
            editor.registerCommand<DragEvent>(
                DRAGOVER_COMMAND,
                (event) => {
                    return $onDragover(event)
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand<DragEvent>(
                DROP_COMMAND,
                (event) => {
                    return $onDrop(event, editor)
                },
                COMMAND_PRIORITY_HIGH
            )
        )
    }, [editor])

    return null
}

const TRANSPARENT_IMAGE = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

function $onDragStart(event: DragEvent): boolean {
    const node = $getVideoNodeInSelection()
    if (!node) {
        return false
    }
    const dataTransfer = event.dataTransfer
    if (!dataTransfer) {
        return false
    }
    dataTransfer.setData("text/plain", "_")
    const img = document.createElement("img")
    img.src = TRANSPARENT_IMAGE
    dataTransfer.setDragImage(img, 0, 0)
    dataTransfer.setData(
        "application/x-lexical-drag",
        JSON.stringify({
            data: {
                key: node.getKey(),
                mediaObject: node.__mediaObject,
                type: "video"
            },
            type: "video"
        })
    )

    return true
}

function $onDragover(event: DragEvent): boolean {
    const node = $getVideoNodeInSelection()
    if (!node) {
        return false
    }
    if (!canDropVideo(event)) {
        event.preventDefault()
    }
    return true
}

function $onDrop(event: DragEvent, editor: LexicalEditor): boolean {
    const node = $getVideoNodeInSelection()
    if (!node) {
        return false
    }
    const data = getDragVideoData(event)
    if (!data) {
        return false
    }
    event.preventDefault()
    if (canDropVideo(event)) {
        const range = getDragSelection(event)
        node.remove()
        const rangeSelection = $createRangeSelection()
        if (range !== null && range !== undefined) {
            rangeSelection.applyDOMRange(range)
        }
        $setSelection(rangeSelection)
        editor.dispatchCommand(INSERT_VIDEO_COMMAND, data)
    }
    return true
}

function $getVideoNodeInSelection(): VideoNode | null {
    const selection = $getSelection()
    if (!$isNodeSelection(selection)) {
        return null
    }
    const nodes = selection.getNodes()
    const node = nodes[0]
    return node instanceof VideoNode ? node : null
}

function getDragVideoData(event: DragEvent): null | InsertVideoPayload {
    const dragData = event.dataTransfer?.getData("application/x-lexical-drag")
    if (!dragData) {
        return null
    }
    const { type, data } = JSON.parse(dragData)
    if (type !== "video") {
        return null
    }

    return data
}

function canDropVideo(event: DragEvent): boolean {
    const target = event.target
    return !!(
        isHTMLElement(target) &&
        !target.closest("code, span.editor-video") &&
        isHTMLElement(target.parentElement) &&
        target.parentElement.closest("div.ContentEditable__root")
    )
}

function getDragSelection(event: DragEvent): Range | null | undefined {
    let range
    const domSelection = getDOMSelectionFromTarget(event.target)
    if (document.caretRangeFromPoint) {
        range = document.caretRangeFromPoint(event.clientX, event.clientY)
    } else if (event.rangeParent && domSelection !== null) {
        domSelection.collapse(event.rangeParent, event.rangeOffset || 0)
        range = domSelection.getRangeAt(0)
    } else {
        throw Error(`Cannot get the selection when dragging`)
    }

    return range
}
