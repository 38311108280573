import React, { ReactNode } from "react"
import { classNames } from "utils"

interface ListItemV2 {
    isVerticalView?: boolean
    children: ReactNode
    className?: string
}

const ListItemV2: React.FC<ListItemV2> = ({ children, isVerticalView = false, className }) => {
    return (
        <div className={classNames(isVerticalView ? "w-full" : "sm:w-[calc(33.333%-8px)]", className)}>{children}</div>
    )
}

export default ListItemV2
