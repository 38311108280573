import { useSelector } from "react-redux"
import { RootState } from "../types/Redux"
import { CarePlanSettingsModal } from "./CarePlanSettingsModal"
import { FeaturesSettingsModal } from "./FeaturesSettingsModal/FeaturesSettingsModal"
import { PatientModal } from "./PatientModal/PatientModal"
import { PatientWhatNextModal } from "./PatientWhatNextModal/PatientWhatNextModal"
import TreatmentEndModal from "./TreatmentEndModal/TreatmentEndModal"
import { UserModal } from "./UserModal/UserModal"
import AssignCareProtocolModal from "./AssignCareProtocolModal"
import DeleteEventModal from "./EventModal/DeleteEventModal"
import UnlockEventModal from "./EventModal/UnlockEventModal"
import EditEventModal from "./EventModal/EditEventModal"
import ViewProtocolModal from "./ViewProtocolModal/ViewProtocolModal"
import ViewIndependentEventsModal from "./ViewIndependantEventsModal/ViewIndependentEventsModal"
import CarePlanFeaturesSettingsModal from "./CarePlanFeaturesSettingsModal/CarePlanFeaturesSettnigsModal"

const ModalContainer = () => {
    const modal = useSelector((state: RootState) => state.modal)

    return (
        <>
            <UserModal isOpen={modal.type === "user"} params={modal.type === "user" && modal.params} />
            <PatientModal isOpen={modal.type === "patient"} params={modal.type === "patient" && modal.params} />
            <PatientWhatNextModal
                isOpen={modal.type === "patient_what_next"}
                params={modal.type === "patient_what_next" && modal.params}
            />
            <FeaturesSettingsModal
                isOpen={modal.type === "features_settings"}
                params={modal.type === "features_settings" && modal.params}
            />

            <AssignCareProtocolModal
                isOpen={modal.type === "assign_care_protocol"}
                params={modal.type === "assign_care_protocol" && modal.params}
            />

            <ViewProtocolModal
                isOpen={modal.type === "view_assigned_protocol"}
                params={modal.type === "view_assigned_protocol" && modal.params}
            />

            <ViewIndependentEventsModal
                isOpen={modal.type === "view_independent_events"}
                params={modal.type === "view_independent_events" && modal.params}
            />

            <CarePlanFeaturesSettingsModal
                isOpen={modal.type === "care_plan_feature_settings"}
                params={modal.type === "care_plan_feature_settings" && modal.params}
            />

            <DeleteEventModal
                isOpen={modal.type === "delete_event"}
                params={modal.type === "delete_event" && modal.params}
            />

            <UnlockEventModal
                isOpen={modal.type === "unlock_event"}
                params={modal.type === "unlock_event" && modal.params}
            />

            <EditEventModal isOpen={modal.type === "edit_event"} params={modal.type === "edit_event" && modal.params} />

            <CarePlanSettingsModal
                isOpen={modal.type === "careplan_settings"}
                params={modal.type === "careplan_settings" && modal.params}
            />
            <TreatmentEndModal
                isOpen={modal.type === "treatment_end"}
                params={modal.type === "treatment_end" && modal.params}
            />
        </>
    )
}

export default ModalContainer
