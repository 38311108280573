import { FC, useMemo, useRef } from "react"
import { useCurrentRole, usePatientList } from "store/hooks"
import { useTranslation } from "react-i18next"
import { useSettingsSections } from "components/Settings"
import { MenuItem, RenderMenuItems } from "./MenuComponents"
import {
    ChatIcon,
    ChevronLeftIcon,
    DashboardIcon,
    DataExportIcon,
    ListIcon,
    PatientsIcon,
    UsersIcon
} from "components/icons"
import { FeatureFlags } from "utils/FeatureFlags"
import { useRouter } from "next/router"
import PatientList from "./PatientList"

const Sidebar: FC = () => {
    const { t } = useTranslation()
    const currentRole = useCurrentRole()
    const sectionRef = useRef<HTMLDivElement>()
    const settingsSections = useSettingsSections()
    const patients = usePatientList()

    const { asPath: pathname } = useRouter()

    const menuItems = useMemo<{ top: MenuItem[]; bottom: MenuItem[] }>(() => {
        const bottom: MenuItem[] = [...settingsSections]
        const chatEnabled = FeatureFlags.isEnabled("global-chat")

        const top = [
            {
                name: t("common:home"),
                href: "/dashboard",
                Icon: DashboardIcon,
                testId: "menu-item-dashboard"
            },
            {
                name: `${t("patient:myPatients")} (${patients.length})`,
                href: "/patients#my-patients",
                Icon: PatientsIcon,
                testId: "menu-item-patients",
                hasExpandableSection: Boolean(patients.length),
                expandedComponent: <PatientList />
            },
            {
                name: `${t("common:candidates")}`,
                href: "/intake/list-screening/initial",
                Icon: UsersIcon,
                testId: "menu-item-candidate",
                activeLinks: ["/intake/list-screening/*"]
            },
            {
                name: t("library"),
                href: "/settings/library/questionnaires",
                Icon: ListIcon,
                activeLinks: ["/settings/library/*"]
            },
            {
                name: t("teamMembers"),
                href: "/settings/team",
                Icon: UsersIcon,
                testId: "users-list-link"
            }
        ]

        if (chatEnabled) {
            top.splice(3, 0, {
                name: t("patient:chat.chat_one"),
                href: "/chat",
                Icon: ChatIcon,
                testId: "menu-item-chat"
            })
        }
        if (currentRole.role === "admin" && FeatureFlags.isEnabled("csv-export"))
            bottom.splice(1, 0, {
                name: t("common:dataExport"),
                href: "/administration/export",
                Icon: DataExportIcon,
                testId: "menu-item-data-export"
            })

        return {
            top: top as MenuItem[],
            bottom
        }
    }, [currentRole, t, settingsSections, pathname])

    return (
        <div className="flex flex-shrink-0 flex-col w-72 h-full bg-bg">
            <div className="flex flex-col p-4 mr-4 bg-light-background h-full rounded-lg border border-light-border">
                <div className="flex flex-col self-start w-full">
                    <RenderMenuItems items={menuItems.top} />
                </div>
                <div
                    className="flex flex-col justify-between h-full flex-1 w-full bottom-0 overflow-auto"
                    ref={sectionRef}
                >
                    {Boolean(menuItems.bottom.length && currentRole.role === "admin") && (
                        <RenderMenuItems items={menuItems.bottom} title={t("organization")} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Sidebar
