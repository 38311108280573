import { XIcon } from "components/icons"
import { BagesListV2, HoverablePopoverV2, IOptionV2 } from "components/v2/components"
import useCalculateVisibleOptions from "hooks/useCalculateVisiableChildren"
import { MouseEvent } from "react"
import { classNames } from "utils"

interface IMultiSelectTriggerV2Props {
    keys: (string | number)[]
    handleRemoveOption: (option: string | number) => void
    getOption: (option: string | number) => IOptionV2
    placeholder?: string
    isDisabled?: boolean
    tooltipVisible?: boolean
}

const MultiSelectTriggerV2 = ({
    keys,
    getOption,
    handleRemoveOption,
    placeholder,
    isDisabled,
    tooltipVisible = false
}: IMultiSelectTriggerV2Props) => {
    const { containerRef, visibleOptions, remainingCount, currentLine } = useCalculateVisibleOptions(keys, getOption)

    const removeOption = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, optionKey: string) => {
        e.stopPropagation()
        if (isDisabled) return
        handleRemoveOption(optionKey)
    }

    const RemainingOptionsContent = ({ options }: { options: any[] }) => {
        return (
            <div className="bg-white rounded-lg p-2 pointer-events-auto">
                <div className="flex flex-wrap gap-2">
                    {options.map((option) => (
                        <div
                            key={option.key || option.id}
                            className="flex items-center bg-[#F3F4F6] w-fit text-[#111827] rounded-lg border-b-base-dark px-2 gap-2.5 py-1 h-fit whitespace-nowrap"
                        >
                            <div className="flex justify-between flex-row gap-x-2 items-center">
                                {option?.media &&
                                    (typeof option.media === "string" ? (
                                        <img src={option.media} alt={option.title} className="w-5 h-5 rounded-full" />
                                    ) : (
                                        option.media
                                    ))}
                                <div className="overflow-hidden text-ellipsis">
                                    <span className="whitespace-nowrap text-[12px]">{option?.title}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const RemainingCountElement = () => (
        <div className="flex items-center bg-[#F3F4F6] w-fit text-[#111827] rounded-lg px-2 gap-2.5 py-1 h-7 cursor-pointer">
            +{remainingCount}
        </div>
    )

    return (
        <div
            ref={containerRef}
            className={classNames(
                "relative flex justify-start flex-grow  flex-row items-center leading-5 gap-2.5 ",
                "h-11 py-2",
                currentLine === 1 ? "h-11 py-2" : " py-3",
                isDisabled ? "bg-[#F9FAFB] text-[#9ca3af]" : "bg-white"
            )}
        >
            {visibleOptions.length === 0 ? (
                <span className="block truncate text-[#9CA3AF] font-normal text-xs">{placeholder}</span>
            ) : (
                visibleOptions.map((optionKey) => {
                    const option = getOption(optionKey)

                    return (
                        <div
                            key={optionKey}
                            onClick={(e) => removeOption(e, optionKey)}
                            className={classNames(
                                "flex items-center bg-[#F3F4F6] w-fit text-[#111827] rounded-lg px-2 gap-2.5 py-1 h-fit cursor-pointer whitespace-nowrap ",
                                isDisabled ? "text-[#9ca3af]" : ""
                            )}
                        >
                            <div className="flex justify-between flex-row gap-x-2.5 items-center">
                                {option?.media &&
                                    (typeof option.media === "string" ? (
                                        <img src={option.media} alt={option.title} className="w-5 h-5 rounded-full" />
                                    ) : (
                                        option.media
                                    ))}
                                <div className="overflow-hidden text-ellipsis">
                                    <span className="whitespace-nowrap">{option?.title}</span>
                                </div>
                            </div>
                            <XIcon />
                        </div>
                    )
                })
            )}

            {remainingCount > 0 && (
                <>
                    {tooltipVisible ? (
                        <HoverablePopoverV2
                            content={
                                <RemainingOptionsContent
                                    options={keys.filter((key) => !visibleOptions.includes(key)).map(getOption)}
                                />
                            }
                            placement="top"
                        >
                            <RemainingCountElement />
                        </HoverablePopoverV2>
                    ) : (
                        <RemainingCountElement />
                    )}
                </>
            )}
        </div>
    )
}

export default MultiSelectTriggerV2
