import { TypographyV2 } from "components/v2/components/common"
import React from "react"
import { EventTypes } from "types"
import StartByChapterElement from "./StartByChapterElement/StartByChapterElement"
import { useTranslation } from "react-i18next"

const InsertFIrstUnitChapterElement = () => {
    const { t } = useTranslation("unit")

    return (
        <div className="bg-light-background rounded-lg flex flex-col items-center justify-center mt-10 py-10 px-4">
            <TypographyV2 width="semibold" type="sm" className="text-med-black">
                {t("insertFirstItem")}
            </TypographyV2>
            <div className="flex flex-col gap-2 w-full items-center mt-6">
                <StartByChapterElement title={t("common:eventType.exercise")} eventType={EventTypes.Exercise} />
                <StartByChapterElement
                    title={t("common:eventType.questionnaire")}
                    eventType={EventTypes.Questionnaire}
                />
                <StartByChapterElement
                    title={t("common:eventType.educational_content")}
                    eventType={EventTypes.EducationalContent}
                />
            </div>
        </div>
    )
}

export default InsertFIrstUnitChapterElement
