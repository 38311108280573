import { SVGProps } from "react"

const EventUnlockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9 7V4.5C9 2.84315 10.3431 1.5 12 1.5C13.6569 1.5 15 2.84315 15 4.5V7M2.5 14.5H9.5C10.3284 14.5 11 13.8284 11 13V8.5C11 7.67157 10.3284 7 9.5 7H2.5C1.67157 7 1 7.67157 1 8.5V13C1 13.8284 1.67157 14.5 2.5 14.5Z"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)
export default EventUnlockIcon
