import React, { ReactElement } from "react"
import { FormControlPropsV2, FormInputWrapper, RadioCircleGroupV2 } from "components/v2/index"
import { useController } from "react-hook-form"

interface IOptionType {
    value: string
    labelComponent: ReactElement | string
}

interface IControlledRadioCircleGroupV2Props<T> extends FormControlPropsV2<T> {
    options: IOptionType[]
    isBordered?: boolean
    onChange?: (value?: string) => void
}

const ControlledRadioCircleGroupV2 = <T,>({
    label,
    name,
    control,
    classList,
    isRequired,
    onChange,
    ...inputProps
}: IControlledRadioCircleGroupV2Props<T>) => {
    const { error } = inputProps
    const {
        field: { value, onChange: onChangeToControllerForm }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    return (
        <FormInputWrapper {...{ isRequired, error, classList: classList?.wrapper, label }}>
            <RadioCircleGroupV2
                name={name}
                value={value as string}
                disabled={inputProps.isDisabled}
                {...inputProps}
                onChange={async (newValue) => {
                    onChangeToControllerForm(newValue)
                    if (onChange) {
                        setTimeout(() => onChange?.(newValue), 100)
                    }
                }}
            />
        </FormInputWrapper>
    )
}

export default ControlledRadioCircleGroupV2
