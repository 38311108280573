import React, { forwardRef } from "react"
import { useController } from "react-hook-form"
import FormInputWrapper from "../FormInputWrapper"
import { defaultInputClassNames } from "forms"
import { classNames } from "utils"
import TimeInputV2 from "../../TimeInputV2/TimeInputV2"
import { FormControlPropsV2 } from "../formTypes"

const ControlledTimeInputV2 = (props: FormControlPropsV2<any>) => {
    const { isRequired, label, classList, name, control, isErrorTextShowing, ...inputProps } = props

    const { error } = inputProps

    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    return (
        <FormInputWrapper {...{ label, error, isRequired, isErrorTextShowing, classList: classList?.wrapper }}>
            <TimeInputV2
                id={name}
                value={value}
                setValue={onChange}
                classList={{
                    input: classNames(defaultInputClassNames, error ? "border-red-500" : "", classList?.input)
                }}
            />
        </FormInputWrapper>
    )
}
export default ControlledTimeInputV2
