import { SVGProps } from "react"

interface ProtocolIconProps extends SVGProps<SVGSVGElement> {
    variant?: "default" | "light-gray"
}

const AppointmentIcon = ({ variant = "default", ...props }: ProtocolIconProps) => {
    const pathFill = variant === "default" ? "#8B5CF6" : "#9CA3AF"
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.01379 2.55486C1.80473 2.55486 1.60423 2.63791 1.4564 2.78574C1.30856 2.93357 1.22551 3.13407 1.22551 3.34314V11.3209C1.22551 11.5299 1.30856 11.7304 1.4564 11.8783C1.60423 12.0261 1.80473 12.1091 2.01379 12.1091H6.53118C6.83016 12.1091 7.07253 12.3515 7.07253 12.6505C7.07253 12.9495 6.83016 13.1918 6.53118 13.1918H2.01379C1.51758 13.1918 1.04169 12.9947 0.690816 12.6438C0.339942 12.293 0.142822 11.8171 0.142822 11.3209V3.34314C0.142822 2.84692 0.339941 2.37104 0.690816 2.02016C1.04169 1.66929 1.51758 1.47217 2.01379 1.47217H9.99152C10.4877 1.47217 10.9636 1.66929 11.3145 2.02016C11.6654 2.37104 11.8625 2.84692 11.8625 3.34314V6.00238C11.8625 6.30136 11.6201 6.54373 11.3211 6.54373C11.0222 6.54373 10.7798 6.30136 10.7798 6.00238V3.34314C10.7798 3.13407 10.6967 2.93357 10.5489 2.78574C10.4011 2.63791 10.2006 2.55486 9.99152 2.55486H2.01379Z"
                fill={pathFill}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6566 8.53875C9.48696 8.53875 8.53875 9.48696 8.53875 10.6566C8.53875 11.8263 9.48696 12.7745 10.6566 12.7745C11.8263 12.7745 12.7745 11.8263 12.7745 10.6566C12.7745 9.48696 11.8263 8.53875 10.6566 8.53875ZM7.45605 10.6566C7.45605 8.88901 8.88901 7.45605 10.6566 7.45605C12.4243 7.45605 13.8572 8.88901 13.8572 10.6566C13.8572 12.4243 12.4243 13.8572 10.6566 13.8572C8.88901 13.8572 7.45605 12.4243 7.45605 10.6566Z"
                fill={pathFill}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.66146 0.142822C8.96044 0.142822 9.20281 0.385191 9.20281 0.684168V3.34341C9.20281 3.64239 8.96044 3.88476 8.66146 3.88476C8.36249 3.88476 8.12012 3.64239 8.12012 3.34341V0.684168C8.12012 0.385191 8.36249 0.142822 8.66146 0.142822Z"
                fill={pathFill}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.34335 0.142822C3.64233 0.142822 3.88469 0.385191 3.88469 0.684168V3.34341C3.88469 3.64239 3.64233 3.88476 3.34335 3.88476C3.04437 3.88476 2.802 3.64239 2.802 3.34341V0.684168C2.802 0.385191 3.04437 0.142822 3.34335 0.142822Z"
                fill={pathFill}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.142822 6.00277C0.142822 5.70379 0.385191 5.46143 0.684168 5.46143H11.3211C11.6201 5.46143 11.8625 5.70379 11.8625 6.00277C11.8625 6.30175 11.6201 6.54412 11.3211 6.54412H0.684168C0.385191 6.54412 0.142822 6.30175 0.142822 6.00277Z"
                fill={pathFill}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6566 9.11523C10.9556 9.11523 11.1979 9.3576 11.1979 9.65658V10.4322L11.7042 10.9385C11.9156 11.1499 11.9156 11.4927 11.7042 11.7041C11.4928 11.9155 11.15 11.9155 10.9386 11.7041L10.2738 11.0392C10.1723 10.9377 10.1152 10.8 10.1152 10.6565V9.65658C10.1152 9.3576 10.3576 9.11523 10.6566 9.11523Z"
                fill={pathFill}
            />
        </svg>
    )
}

export default AppointmentIcon
