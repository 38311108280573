import { useEffect } from "react"
import { DragEndEvent, MouseSensor, useSensor, useSensors } from "@dnd-kit/core"
import { useDispatch } from "store"
import {
    getCareProtocolElementsByCareProtocolIdAction,
    updateCareProtocolElementsPositionsAction
} from "store/care-protocol/actions"
import { useCareProtocolElementsSelector, useLibraryTemplateSelector } from "store/hooks"
import { useTranslation } from "react-i18next"

export const useCareProtocolElementList = (elementId: number | null) => {
    const { libraryTemplate } = useLibraryTemplateSelector()
    const { t } = useTranslation("careProtocol")
    const dispatch = useDispatch()
    const { list: careProtocolElements, isLoading } = useCareProtocolElementsSelector()

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 5 }
        })
    )

    useEffect(() => {
        if (libraryTemplate) dispatch(getCareProtocolElementsByCareProtocolIdAction(libraryTemplate.id))
    }, [libraryTemplate])

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (!over) return

        const activeId = active.id as number
        const overId = over.id as number

        if (activeId !== overId) {
            dispatch(updateCareProtocolElementsPositionsAction({ activeId, overId, elementId: libraryTemplate?.id }))
        }
    }

    return { t, isLoading, sensors, careProtocolElements, onDragEnd }
}
