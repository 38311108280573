import { FC, Fragment } from "react"
import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react"

interface ICpPopoverElementProps {
    PopoverComponent: FC<{ close: () => void }>
    TargetComponent: FC
    withDivider?: boolean
}

const CpPopoverElement: FC<ICpPopoverElementProps> = ({ PopoverComponent, TargetComponent, withDivider = true }) => (
    <div className="flex justify-between items-center mt-2">
        {withDivider && <div className="h-px bg-border-blue w-2/5" />}
        <Popover className="relative">
            {({ close }) => (
                <>
                    <PopoverButton>
                        <TargetComponent />
                    </PopoverButton>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <PopoverPanel className="left-64 absolute z-10 mt-3 w-96 -translate-x-1/2 transform lg:max-w-3xl">
                            <PopoverComponent close={close} />
                        </PopoverPanel>
                    </Transition>
                </>
            )}
        </Popover>
    </div>
)

export default CpPopoverElement
