import { AppAction, UnitChapterElementResponse, UnitChapterResponse } from "types/Redux"

import * as types from "./types"
import { LibraryTemplate, UnitChapterElementType } from "@prisma/client"
import {
    addElementToChapter,
    createUntitledChapterForUnit,
    deleteChapter,
    deleteUnitChapterElement,
    getAllUnitChaptersByUnitId,
    reorderChapter,
    reorderUnitChapterElement,
    updateChapterForUnit
} from "api/unit"
import { toast } from "react-toastify"
import i18n from "locales"
import { arrayMove } from "@dnd-kit/sortable"
import {
    IUnitChapterElementRequest,
    IUnitChapterRequest,
    IUpdateUnitChapterElementPositionsAction,
    IUpdateUnitChapterPositionsAction
} from "types"
import { QuestionnaireListItem } from "@sequel-care/types"

export const setNewChapterListAction = (payload: UnitChapterResponse[]) => {
    return { type: types.SET_CHAPTERS_LIST, payload }
}

export const addNewChapterToListAction = (payload: UnitChapterResponse) => {
    return { type: types.ADD_NEW_CHAPTER_TO_LIST, payload }
}

export const setSelectedChapter = (payload: number | null) => {
    return { type: types.SET_SELECTED_CHAPTER, payload }
}

export const clearChapterListAction = () => {
    return { type: types.SET_CHAPTERS_LIST, payload: [] as [] }
}

export const openAddElementModalAction = (payload: UnitChapterElementType) => {
    return { type: types.OPEN_ADD_ELEMENT_MODAL, payload }
}

export const closeAddElementModalAction = () => {
    return { type: types.CLOSE_ADD_ELEMENT_MODAL }
}

export const selectElementForAddVariableModalAction = (payload: LibraryTemplate | QuestionnaireListItem) => {
    return { type: types.SELECT_ELEMENT_FOR_ADD_VARIABLE_MODAL, payload }
}

export const removeSelectedElementFromAddVariableModalAction = () => {
    return { type: types.REMOVE_SELECTED_ELEMENT_FOR_ADD_VARIABLE_MODAL }
}

export const setNewElementList = (payload: UnitChapterElementResponse[]) => {
    return { type: types.SET_ELEMENTS_LIST, payload }
}

export const addNewElementToList = (payload: UnitChapterElementResponse) => {
    return { type: types.ADD_NEW_ELEMENT_TO_LIST, payload }
}

export const openDeleteUnitChapterModalAction = (id: number) => {
    return { type: types.OPEN_DELETE_CHAPTER_MODAL, payload: { chapterId: id } }
}

export const closeDeleteUnitChapterModalAction = () => {
    return { type: types.CLOSE_DELETE_CHAPTER_MODAL }
}

export const openDeleteUnitChapterElementModalAction = (elementId: number) => {
    return { type: types.OPEN_DELETE_ELEMENT_MODAL, payload: { elementId } }
}

export const closeDeleteUnitChapterElementModalAction = () => {
    return { type: types.CLOSE_DELETE_ELEMENT_MODAL }
}

export const getChaptersListByUnitIdAction =
    (id: number, withoutReset?: boolean): AppAction<boolean> =>
    async (dispatch, getState) => {
        if (!withoutReset) {
            dispatch(setNewChapterListAction([]))
            dispatch(setSelectedChapterAction(null))
        }
        const chapters = await getAllUnitChaptersByUnitId(id)
        const selectedChapter = getState().unit.selectedChapter
        dispatch(setNewChapterListAction(chapters))
        if (!chapters.some((chapter) => chapter.id === selectedChapter)) {
            dispatch(setSelectedChapterAction(chapters[0]?.id ?? null))
        }
        return true
    }

export const deleteChapterByIdAction =
    (id: number): AppAction<boolean | undefined> =>
    async (dispatch, getState) => {
        try {
            let chapters = getState().unit.chapters
            if (chapters.length === 1) {
                toast.error(i18n.t("notifications.chapter.cantDeleteLastChapter", { ns: "unit" }))
                return false
            }
            await deleteChapter(id)
            toast.success(i18n.t("notifications.chapter.successfullyDeleted", { ns: "unit" }))

            let selectedChapter = getState().unit.selectedChapter
            chapters = chapters.filter((chapter) => chapter.id !== id)
            if (selectedChapter === id) {
                dispatch(setSelectedChapterAction(chapters[0]?.id ?? null))
            }
            dispatch(setNewChapterListAction(chapters))
            dispatch(closeDeleteUnitChapterModalAction())

            return true
        } catch (err) {}
    }

export const updateChapterPositionsAction = ({
    activeId,
    overId,
    unitId
}: IUpdateUnitChapterPositionsAction): AppAction<boolean | undefined> => {
    return async (dispatch, getState) => {
        try {
            const chapters = getState().unit.chapters
            const activeIndex = chapters.findIndex((chapter) => chapter.id === activeId)
            const overIndex = chapters.findIndex((chapter) => chapter.id === overId)

            const newChapters = arrayMove(chapters, activeIndex, overIndex)

            const payload = newChapters.map((chapter, index) => ({ id: chapter.id, pos: index + 1 }))
            dispatch(setNewChapterListAction(newChapters))
            await reorderChapter(payload, unitId)
            toast.success(i18n.t("notifications.chapter.positionsWereChangedSuccessfully", { ns: "unit" }))
            return true
        } catch (err) {}
    }
}

export const addNewChapterToUnitAction =
    (unitId: number): AppAction<boolean | undefined> =>
    async (dispatch) => {
        try {
            const chapter = await createUntitledChapterForUnit(unitId)
            toast.success(i18n.t("notifications.chapter.successfullyCreated", { ns: "unit" }))
            dispatch(addNewChapterToListAction(chapter))
            return true
        } catch (err) {}
    }

export const updateChapterAction = (id: number, body: IUnitChapterRequest): AppAction<boolean | undefined> => {
    return async (dispatch) => {
        try {
            await updateChapterForUnit(id, body)
            toast.success(i18n.t("notifications.chapter.successfullyUpdated", { ns: "unit" }))
            dispatch(getChaptersListByUnitIdAction(body.unit_library_template_id, true))
            return true
        } catch (err) {}
    }
}

export const addElementToChapterAction = (data: IUnitChapterElementRequest): AppAction<boolean | undefined> => {
    return async (dispatch, getState) => {
        try {
            const res = await addElementToChapter(data)
            const unitId = getState().libraryTemplate.libraryTemplate!.id
            toast.success(i18n.t("notifications.chapter.successfullyAddedVariable", { ns: "unit" }))
            dispatch(addNewElementToList(res))
            dispatch(getChaptersListByUnitIdAction(unitId, true))
            dispatch(closeAddElementModalAction())
            return true
        } catch (err) {}
    }
}

export const deleteElementFromChapterAction = (id: number): AppAction<boolean | undefined> => {
    return async (dispatch, getState) => {
        try {
            await deleteUnitChapterElement(id)
            toast.success(i18n.t("notifications.element.successfullyDeleted", { ns: "unit" }))
            let elements = getState().unit.elements
            elements = elements.filter((element) => element.id !== id)
            dispatch(setNewElementList(elements))
            dispatch(getChaptersListByUnitIdAction(getState().libraryTemplate.libraryTemplate!.id, true))
            dispatch(closeDeleteUnitChapterElementModalAction())
            return true
        } catch (err) {}
    }
}

export const updateElementPositionsAction = ({
    activeId,
    overId,
    chapterId
}: IUpdateUnitChapterElementPositionsAction): AppAction<boolean | undefined> => {
    return async (dispatch, getState) => {
        try {
            const elements = getState().unit.elements
            const activeIndex = elements.findIndex((element) => element.id === activeId)
            const overIndex = elements.findIndex((element) => element.id === overId)

            const newElements = arrayMove(elements, activeIndex, overIndex)

            const payload = newElements.map((element, index) => ({ id: element.id, pos: index + 1 }))
            dispatch(setNewElementList(newElements))
            await reorderUnitChapterElement(payload, chapterId)
            dispatch(getChaptersListByUnitIdAction(getState().libraryTemplate.libraryTemplate!.id, true))
            toast.success(i18n.t("notifications.element.positionsWereChangedSuccessfully", { ns: "unit" }))
            return true
        } catch (err) {}
    }
}

export const setSelectedChapterAction =
    (id: number | null): AppAction<boolean> =>
    (dispatch, getState) => {
        const chapters = getState().unit.chapters
        const selectedChapter = chapters.find((chapter) => chapter.id === id)
        dispatch(setSelectedChapter(selectedChapter?.id ?? null))
        dispatch(setNewElementList((selectedChapter?.unit_chapter_element ?? []) as UnitChapterElementResponse[]))
        return true
    }
