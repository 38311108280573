export enum HeadingEditorTypes {
    H1 = "h1",
    H2 = "h2",
    H3 = "h3"
}

export enum ListEditorTypes {
    Ul = "ul",
    Ol = "ol"
}

export enum FontEditorTypes {
    Underline = "underline",
    Italic = "italic",
    Bold = "bold"
}

export enum AlignEditorTypes {
    Left = "left",
    Right = "right",
    Center = "center",
    Justify = "justify"
}

export const headingTypesList = [
    {
        type: HeadingEditorTypes.H1,
        icon: "/icons/type-h1.svg"
    },
    {
        type: HeadingEditorTypes.H2,
        icon: "/icons/type-h2.svg"
    },
    {
        type: HeadingEditorTypes.H3,
        icon: "/icons/type-h3.svg"
    }
]
