import { SVGProps } from "react"

const ListIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11.3252 14.0625H14.1377M14.1377 14.0625H16.9502M14.1377 14.0625V11.25M14.1377 14.0625V16.875M5.0752 8.75H6.9502C7.98573 8.75 8.8252 7.91053 8.8252 6.875V5C8.8252 3.96447 7.98573 3.125 6.9502 3.125H5.0752C4.03966 3.125 3.2002 3.96447 3.2002 5V6.875C3.2002 7.91053 4.03966 8.75 5.0752 8.75ZM5.0752 16.875H6.9502C7.98573 16.875 8.8252 16.0355 8.8252 15V13.125C8.8252 12.0895 7.98573 11.25 6.9502 11.25H5.0752C4.03966 11.25 3.2002 12.0895 3.2002 13.125V15C3.2002 16.0355 4.03966 16.875 5.0752 16.875ZM13.2002 8.75H15.0752C16.1107 8.75 16.9502 7.91053 16.9502 6.875V5C16.9502 3.96447 16.1107 3.125 15.0752 3.125H13.2002C12.1647 3.125 11.3252 3.96447 11.3252 5V6.875C11.3252 7.91053 12.1647 8.75 13.2002 8.75Z"
            stroke="#6B7280"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default ListIcon
