import { Organization, Tag, TeamMember, User, TaskAttachment } from "@prisma/client"
import {
    BasicUser,
    ChatDataPayload,
    CompleteQuestionnaireDraft,
    CompleteQuestionnaireWithAlerts,
    InitialAppData,
    OrganizationCreateData,
    OrganizationWithTeam,
    QuestionnaireCreateData,
    QuestionnaireDraftCreateData,
    QuestionnaireEventTemplate
} from "@sequel-care/types"
import { Dictionary } from "lodash"
import { CompleteUserInOrg } from "types/SchemaExtensions"
import { getPatientIdFromPath } from "utils"
import { ApiDelete, ApiGet, ApiPost, ApiPut, SelfAbortingGet } from "./ApiRequest"
import { PatientWithMetrics } from "@sequel-care/types/Patient"
import { getPatientListData } from "./Patient"

export * from "./Collaborator"
export * from "./Event"
export * from "./Patient"
export * from "./User"
export * from "./Intake"
export * from "./EducationalContent"
export * from "./Media"
export * from "./CarePlan"

export * from "./exercises"

export const createOrganization = async (data: OrganizationCreateData) => {
    const instance = new ApiPost<{
        organization: Organization & { users: (TeamMember & { user: User })[] }
        image_upload_url?: string
    }>(`/organization`, data)
    instance.callerName = createOrganization.name

    const response = await instance.run()
    // response.newUser.roles[0].organization = response.newOrganization
    return response
}

export const getAllOrganizations = async (with_team = false) => {
    const instance = new ApiGet<OrganizationWithTeam[]>(`/organization/all`, { with_team })
    instance.callerName = getAllOrganizations.name
    return instance.run()
}

export const getUsersInCurrentOrg = async () => {
    const instance = new ApiGet<CompleteUserInOrg[]>(`/organization/users`)
    instance.callerName = getUsersInCurrentOrg.name
    return instance.run()
}

export const getUserPatientsInstance = new SelfAbortingGet<{ patient: BasicUser; isCaseManager: boolean }[]>(
    "getUserPatients"
)
export const getUserPatients = async (userId: number) => {
    return getUserPatientsInstance.run(`/user/${userId}/patients`)
}

export const createQuestionnaire = async (data: QuestionnaireCreateData, draftId?: number) => {
    let endpoint = `/questionnaire`
    if (draftId) endpoint += `?draft_id=${draftId}`

    const instance = new ApiPost<QuestionnaireEventTemplate>(endpoint, data)
    instance.callerName = createQuestionnaire.name
    return instance.run()
}

export const cloneQuestionnaire = async (questionnaireId: number) => {
    const instance = new ApiPost<QuestionnaireEventTemplate>(`/questionnaire/clone/${questionnaireId}`)
    instance.callerName = createQuestionnaire.name
    return instance.run()
}

export const updateQuestionnaire = async (questionnaireId: number, data: QuestionnaireCreateData) => {
    const instance = new ApiPut<QuestionnaireEventTemplate>(`/questionnaire/${questionnaireId}`, data)
    instance.callerName = updateQuestionnaire.name
    return instance.run()
}

export const getChatToken = async () => {
    const instance = new ApiGet<string>(`/chat/token`)
    instance.callerName = getChatToken.name
    return instance.run()
}

export const createChat = async (participantIds: number[], patientId?: number) => {
    const instance = new ApiPost<{ conversationId: string }>(`/chat`, {
        patientId: patientId ?? getPatientIdFromPath(),
        participantIds
    })
    instance.callerName = createChat.name
    return instance.run()
}

export const createQuestionnaireDraft = async (data: QuestionnaireDraftCreateData) => {
    const instance = new ApiPost<{ success: true }>(`/questionnaire/draft`, data)
    instance.callerName = createQuestionnaireDraft.name
    return instance.run()
}

export const updateQuestionnaireDraft = async (draftId: number, data: QuestionnaireDraftCreateData) => {
    const instance = new ApiPut<{ success: true }>(`/questionnaire/draft/${draftId}`, data)
    instance.callerName = updateQuestionnaireDraft.name
    return instance.run()
}

export const deleteQuestionnaireDraft = async (draftId: number) => {
    const instance = new ApiDelete<{ success: true }>(`/questionnaire/draft/${draftId}`)
    instance.callerName = deleteQuestionnaireDraft.name
    return instance.run()
}

export const getQuestionnaireDrafts = async () => {
    const instance = new ApiGet<CompleteQuestionnaireDraft<string>[]>(`/questionnaire/drafts`)
    instance.callerName = getQuestionnaireDrafts.name
    return instance.run()
}

export const getQuestionnaireDraft = async (draftId: number) => {
    const instance = new ApiGet<CompleteQuestionnaireDraft<string>>(`/questionnaire/draft/${draftId}`)
    instance.callerName = getQuestionnaireDraft.name
    return instance.run()
}

export const getQuestionnaire = async (questionnaireId: number) => {
    const instance = new ApiGet<CompleteQuestionnaireWithAlerts>(`/questionnaire/${questionnaireId}`)
    instance.callerName = getQuestionnaire.name
    return instance.run()
}

export const getInitialAppData = async () => {
    const instance = new ApiGet<InitialAppData<string>>(`/initial-data`, { env: process.env.APP_ENV })
    instance.callerName = getInitialAppData.name
    return instance.run({ withAuth: "noRole" })
}

export const getPatientListByCollaborator = () => {
    const instance = new ApiGet<PatientWithMetrics<string>>(`/patients-list`)
    instance.callerName = getPatientListData.name
    return instance.run()
}

export const getUserRecentEvents = async () => {
    const instance = new ApiGet<InitialAppData<string>>(`/recent-events`, { env: process.env.APP_ENV })
    instance.callerName = getUserRecentEvents.name
    return instance.run()
}

export const getPatientTags = async () => {
    const instance = new ApiGet<Tag[]>(`/patient-tags`)
    instance.callerName = getPatientTags.name
    return instance.run()
}

export const fetchConversationData = async (conversations: string[]) => {
    const instance = new ApiGet<Dictionary<ChatDataPayload>>(`/chat/data`, {
        conversations
    })
    instance.callerName = fetchConversationData.name
    return instance.run()
}

export const uploadFile = async (file: File, entity: string) => {
    const formData = new FormData()
    formData.append("file", file)

    const instance = new ApiPost<TaskAttachment>(`/s3/upload/${entity}`, formData, {
        isNotJSON: true
    })
    return instance.run({ withoutContentType: true })
}

export const getFile = async (fileId: number): Promise<Blob> => {
    const instance = new ApiGet(`/s3/${fileId}`)

    return instance.run({ blobResponse: true }) as Promise<Blob>
}
