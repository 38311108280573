import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useEffect, useRef, FC, memo } from "react"
import { $generateHtmlFromNodes } from "@lexical/html"
import { EditorState } from "lexical"

interface IMyOnChangePluginProps {
    onChange: (editorState: EditorState, html: string) => void
}

const MyOnChangePlugin: FC<IMyOnChangePluginProps> = memo(({ onChange }) => {
    const [editor] = useLexicalComposerContext()
    const isInitialized = useRef(false)
    const previousContentRef = useRef<string>("")

    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editor.read(() => {
                const currentHtml = $generateHtmlFromNodes(editor)
                const previousHtml = previousContentRef.current

                if (!isInitialized.current) {
                    isInitialized.current = true
                    previousContentRef.current = currentHtml
                    return
                }

                if (currentHtml !== previousHtml) {
                    previousContentRef.current = currentHtml
                    onChange(editorState, currentHtml)
                }
            })
        })
    }, [editor, onChange])

    return null
})

export default MyOnChangePlugin
