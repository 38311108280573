import { LibraryTemplateType, UnitChapterElementType } from "@prisma/client"
import { BadgeV2, TypographyV2 } from "components/v2/components/common"
import { PreviewExercise, PreviewQuestionnaire, PreviewUnit } from "components/v2/components/features/library-template"
import TagWithIcon from "components/v2/components/TagWithIcon"
import { useIsHeLanguage } from "hooks"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { EventTypes } from "types"
import { LibraryTemplateResponse } from "types/Redux"
import { classNames } from "utils"

const PreviewCPElement = ({ libraryElement }: { libraryElement: LibraryTemplateResponse }) => {
    const { isHeLanguage } = useIsHeLanguage()

    const { t } = useTranslation("common")

    const template_type = useMemo(() => {
        if (libraryElement) {
            switch (libraryElement.template_type) {
                case LibraryTemplateType.EDUCATIONAL_CONTENT:
                    return EventTypes.EducationalContent
                case LibraryTemplateType.UNIT:
                    return EventTypes.Unit
                case LibraryTemplateType.EXERCISE:
                    return EventTypes.Exercise
                default:
                    return EventTypes.Questionnaire
            }
        }
    }, [])

    const data = useMemo(() => {
        if (libraryElement) {
            return {
                diagnoses: libraryElement.diagnoses.map(({ diagnose }) => ({
                    name: diagnose.name_en,
                    id: diagnose.id
                })),
                title: libraryElement.title,
                description: libraryElement.description
            }
        }
        return null
    }, [libraryElement])

    if(!data) return null

    return (
        <div className="p-6 h-full overflow-hidden">
            <div className="flex gap-2 h-full">
                <div className="w-2/5 p-4 h-full overflow-y-auto">
                    <div className="flex gap-2 pb-4">
                        {data.diagnoses.map(({ id, name }) => (
                            <BadgeV2 key={id} text={name} color="darkBlue" className="px-1.5 py-0.5" />
                        ))}
                    </div>
                    <div>
                        <div className="flex gap-2 items-center pb-1">
                            <TagWithIcon eventType={template_type} />
                            <TypographyV2
                                type="sm"
                                width="bold"
                                className={classNames(
                                    isHeLanguage ? "text-right" : "text-left",
                                    "w-auto overflow-hidden text-ellipsis"
                                )}
                            >
                                {data.title}
                            </TypographyV2>
                        </div>
                        <TypographyV2
                            type="sm"
                            className={classNames(
                                "text-gray-400 mt-1",
                                isHeLanguage ? "text-right" : "text-left",
                                "w-auto overflow-hidden text-ellipsis"
                            )}
                        >
                            {data.description}
                        </TypographyV2>
                    </div>
                </div>
                <div className="w-3/5 flex flex-col h-full overflow-hidden">
                    <TypographyV2 width="semibold">{t("eventBuilder.content")}</TypographyV2>
                    <div className="rounded-lg h-full mt-3 bg-light-background border border-light-border overflow-y-auto">
                        {libraryElement.template_type === UnitChapterElementType.EXERCISE && (
                            <PreviewExercise id={libraryElement.id} />
                        )}
                        {!libraryElement.template_type && <PreviewQuestionnaire withoutTitle id={libraryElement.id} />}
                        {libraryElement.template_type === "UNIT" && <PreviewUnit id={libraryElement.id} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewCPElement
