import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection"
import { useLexicalEditable } from "@lexical/react/useLexicalEditable"
import { mergeRegister } from "@lexical/utils"
import {
    $getSelection,
    $isNodeSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_LOW,
    createCommand,
    DRAGSTART_COMMAND,
    KEY_BACKSPACE_COMMAND,
    KEY_DELETE_COMMAND
} from "lexical"
import { MediaObject } from "@prisma/client"
import { useEffect, useState, useRef, useCallback, Suspense, useMemo } from "react"
import { getClinicMediaRequest, removeClinicMediaRequest } from "api"
import { $isVideoNode, VideoNode } from "./VideoNode"
import { classNames } from "utils"

export const RIGHT_CLICK_VIDEO_COMMAND = createCommand<MouseEvent>("RIGHT_CLICK_VIDEO_COMMAND")

interface VideoComponentProps {
    nodeKey: string
    mediaObject: MediaObject
    width: "inherit" | number
    height: "inherit" | number
}

function LazyVideo({
    mediaObject,
    width,
    height,
    onError,
    onClick,
    videoRef,
    className
}: {
    mediaObject: MediaObject
    width: "inherit" | number
    height: "inherit" | number
    onError: () => void
    onClick: (payload: React.MouseEvent<HTMLVideoElement>) => void
    videoRef: React.RefObject<HTMLVideoElement>
    className?: string
}) {
    const [isLoading, setIsLoading] = useState(true)
    const [videoUrl, setVideoUrl] = useState<null | string>(null)

    useEffect(() => {
        const fetchMedia = async () => {
            if (!mediaObject) return
            const response = await getClinicMediaRequest(mediaObject.id)
            if (response) setVideoUrl(response.saved_file_path)
            setIsLoading(false)
        }

        fetchMedia()
    }, [mediaObject])

    return (
        <>
            {isLoading ? (
                <div className="shimmer" style={{ height, width }}></div>
            ) : (
                <video
                    onClick={onClick}
                    className={classNames(className || undefined, "cursor-pointer")}
                    src={videoUrl || ""}
                    width={width}
                    height={height}
                    controls
                    onError={onError}
                    ref={videoRef}
                    style={{ maxWidth: "100%", maxHeight: "100%", width, height }}
                >
                    Your browser does not support the video tag.
                </video>
            )}
        </>
    )
}

export default function VideoComponent({ nodeKey, mediaObject, width, height }: VideoComponentProps) {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const [editor] = useLexicalComposerContext()
    const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey)
    const [selection, setSelection] = useState(null)
    const [isLoadError, setIsLoadError] = useState(false)
    const isEditable = useLexicalEditable()

    const removeVideo = async (id: number) => {
        try {
            await removeClinicMediaRequest(id)
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const $onDelete = useCallback(
        (payload: KeyboardEvent) => {
            if (!mediaObject) return false
            const deleteSelection = $getSelection()

            if (isSelected && ($isNodeSelection(deleteSelection) || $isRangeSelection(deleteSelection))) {
                payload.preventDefault()
                if (!deleteSelection) return false
                deleteSelection?.getNodes().forEach((node: VideoNode) => {
                    if ($isVideoNode(node)) {
                        if (node.__mediaObject) {
                            removeVideo(node.__mediaObject.id)
                        }
                        node.remove()
                    }
                })
            }
            return false
        },
        [isSelected]
    )

    const onClick = useCallback(
        (payload: React.MouseEvent<HTMLVideoElement>) => {
            const event = payload

            if (event.target === videoRef.current) {
                if (event.shiftKey) {
                    setSelected(!isSelected)
                } else {
                    clearSelection()
                    setSelected(true)
                }
                return true
            }

            return false
        },
        [isSelected, setSelected, clearSelection]
    )

    useEffect(() => {
        let isMounted = true
        const unregister = mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                if (isMounted) {
                    setSelection(editorState.read(() => $getSelection()))
                }
            }),

            editor.registerCommand(KEY_DELETE_COMMAND, $onDelete, COMMAND_PRIORITY_LOW),
            editor.registerCommand(KEY_BACKSPACE_COMMAND, $onDelete, COMMAND_PRIORITY_LOW),
            editor.registerCommand(
                DRAGSTART_COMMAND,
                (event) => {
                    if (event.target === videoRef.current) {
                        event.preventDefault()
                        return true
                    }
                    return false
                },
                COMMAND_PRIORITY_LOW
            )
        )

        return () => {
            isMounted = false
            unregister()
        }
    }, [editor, $onDelete, onClick, setSelected, clearSelection, isSelected, nodeKey])

    const memoizedMediaObject = useMemo(() => mediaObject, [mediaObject?.id])

    const isFocused = isSelected && isEditable

    return (
        <Suspense fallback={null}>
            <div className="relative inline-block">
                <div className="inline-block">
                    {isLoadError ? (
                        <img style={{ width: 200, height: 200 }} src="/images/error-image-load.png" />
                    ) : (
                        <LazyVideo
                            mediaObject={memoizedMediaObject}
                            width={width}
                            height={height}
                            onError={() => setIsLoadError(true)}
                            onClick={onClick}
                            videoRef={videoRef}
                            className={isFocused ? `focused ${$isNodeSelection(selection) ? "draggable" : ""}` : null}
                        />
                    )}
                </div>
            </div>
        </Suspense>
    )
}
