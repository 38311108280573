import { SelectOptionsV2, TypographyV2 } from "components/v2"
import { Control, useController } from "react-hook-form"
import { ScreeningTemplateQuestion } from "@prisma/client"
import { useScreenSize } from "hooks"
import { TableListV2 } from "components/v2"

interface MultipleChoiceQuestionCardProps {
    question: ScreeningTemplateQuestion
    control: Control<Record<string, string | (string | number)[]>>
    error: string | undefined
    isDisabled?: boolean
}

const MultipleChoiceQuestionCard = ({ question, control, error, isDisabled }: MultipleChoiceQuestionCardProps) => {
    const { is_required, question: question_text, definition } = question
    const isDesktop = useScreenSize()
    const options = (definition as any).options.map((option: string, i: number) =>
        option.trim() == "" ? (i + 1).toString() : option
    )

    const {
        field: { value, onChange }
    } = useController({
        name: `screeningAnswers.${question.id}`,
        control,
        rules: { required: is_required }
    })

    const handleValuesChange = (newValues: string[]) => {
        onChange(newValues)
    }

    const currentValues = (() => {
        if (!value) return []
        if (Array.isArray(value)) return value.map(String)
        if (typeof value === "string") {
            try {
                return JSON.parse(value)
            } catch {
                return value.split(",")
            }
        }
        return []
    })()

    if (isDesktop) {
        return (
            <TableListV2
                headerCells={[]}
                listComponent={
                    <tr className="border-b border-gray-200">
                        <td className="py-12 px-6">
                            <div className="flex flex-col gap-4">
                                <TypographyV2 type="base">{question_text}</TypographyV2>
                                {question?.description && (
                                    <TypographyV2 type="xs" className="text-gray-500">
                                        {question.description}
                                    </TypographyV2>
                                )}
                            </div>
                        </td>
                        <td className="py-12 px-6">
                            <SelectOptionsV2
                                disabled={isDisabled}
                                options={options}
                                values={currentValues}
                                setValues={handleValuesChange}
                                withOther={true}
                            />
                            {error && (
                                <TypographyV2 type="xs" width="medium" className="text-red-500">
                                    {error}
                                </TypographyV2>
                            )}
                        </td>
                    </tr>
                }
            />
        )
    }

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <SelectOptionsV2
                disabled={isDisabled}
                options={options}
                values={currentValues}
                setValues={handleValuesChange}
                withOther={true}
            />
            {error && (
                <TypographyV2 type="xs" width="medium" className="text-red-500">
                    {error}
                </TypographyV2>
            )}
        </div>
    )
}

export default MultipleChoiceQuestionCard
