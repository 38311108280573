import { useQuestionsSelector } from "store/hooks"
import { useExercisePreview } from "../../library-template/previews/PreviewExercise/useExercisePreview"

const ExercisePreview = () => {
    const data = useQuestionsSelector()

    const cards = useExercisePreview(data.list)

    return (
        <div className="pt-8">
            {cards.map((card, id) => (
                <div
                    className="p-6 rounded-2xl mb-8 border border-border-blue"
                    key={`exercise_builder_question_preview_${id}`}
                >
                    {card}
                </div>
            ))}
        </div>
    )
}

export default ExercisePreview
