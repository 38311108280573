import { PlusIcon } from "components/icons"
import { TypographyV2 } from "components/v2/components/common"
import { ExerciseQuestionsList } from "../../../../exercises/exercise-questions"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "types/Redux"

const AddExerciseContentForm = () => {
    const { t } = useTranslation("exercise")

    const { params: exerciseDto } = useSelector((state: RootState) => state.modal)

    return (
        <div className="w-200 pt-16 mx-auto my-auto">
            <TypographyV2 className="text-med-black w-auto overflow-hidden text-ellipsis" type="3xl" width="bold">
                {exerciseDto.title}
            </TypographyV2>
            {/*<button className="bg-base-lighter  mt-4 text-base-dark rounded-md p-1 flex items-center gap-0.5">*/}
            {/*    <PlusIcon />*/}
            {/*    <TypographyV2 type="xs">*/}
            {/*        {t("addBriefSummary")} ({t("optional")})*/}
            {/*    </TypographyV2>*/}
            {/*</button>*/}
            <div className="mt-10">
                <ExerciseQuestionsList />
            </div>
        </div>
    )
}

export default AddExerciseContentForm
