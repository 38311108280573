import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2, RadioCircleGroupV2 } from "components/v2"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"

const YesNoQuestionResponse = ({
    question,
    screeningAnswer,
    sybQuestion = false,
    isLastQuestion = false
}: {
    question: any
    screeningAnswer: ScreeningAnswer
    sybQuestion?: boolean
    isLastQuestion?: boolean
}) => {
    const { t } = useTranslation()
    const options = [
        { value: "yes", label: t("intake:common.yes") },
        { value: "no", label: t("intake:common.no") }
    ]

    return (
        <div
            className={classNames(
                "bg-white border-slate-300 py-4 px-3",
                sybQuestion ? "border-b" : "border rounded-lg",
                !isLastQuestion && "border-none"
            )}
        >
            <TypographyV2 type="base" className="text-[#6B7280]">
                {question.question}
            </TypographyV2>

            <div className="pt-2 flex flex-col gap-4">
                <RadioCircleGroupV2
                    name={question.id}
                    onChange={() => {}}
                    readOnly
                    value={screeningAnswer?.value}
                    isBordered
                    options={options.map((option: any) => ({
                        value: option.value,
                        labelComponent: <TypographyV2 type="base">{option.label}</TypographyV2>
                    }))}
                />
            </div>
        </div>
    )
}

export default YesNoQuestionResponse
