import { CardV2, TypographyV2 } from "components/v2/components/common"
import TagWithIcon from "components/v2/components/TagWithIcon"
import React, { FC } from "react"
import { useDispatch } from "store"
import { openAddElementModalAction } from "store/unit/actions"
import { EventTypes } from "types"
import { elementType } from "../../unitConstants"

interface IChapterElementProps {
    title: string
    eventType: Exclude<EventTypes, "Unit">
}

const StartByChapterElement: FC<IChapterElementProps> = ({ eventType, title }) => {
    const dispatch = useDispatch()

    return (
        <CardV2
            className="max-w-80 w-full"
            onClick={() => {
                dispatch(openAddElementModalAction(elementType[eventType]))
            }}
        >
            <div className="flex items-center">
                <TagWithIcon eventType={eventType} />
                <TypographyV2 className="ml-3" width="bold">
                    {title}
                </TypographyV2>
            </div>
        </CardV2>
    )
}

export default StartByChapterElement
