import { ListV2, SearchInputV2, TypographyV2 } from "components/v2/components/common"
import React, { useState, useCallback } from "react"
import { LibraryTemplateResponse } from "types/Redux"
import { Control } from "react-hook-form"
import ElementAddCPItem from "../ElementAddCPItem/ElementAddCPItem"
import { TFunction } from "i18next"

interface IContentItem {
    title: string
    subtitle: string
    author: string
    categories: string[]
}

interface ISelectCPElementProps {
    t: TFunction
    list: LibraryTemplateResponse[]
    control: Control<any>
}

const SelectCPElement = ({ t, list, control }: ISelectCPElementProps) => {
    const [filterQuery, setFilterQuery] = useState("")

    const filterFunction = useCallback(
        (item: IContentItem) => {
            const regex = new RegExp(filterQuery, "i")
            return regex.test(item.title)
        },
        [filterQuery]
    )

    return (
        <div className="p-6 h-full w-full overflow-y-auto bg-gray-50">
            <TypographyV2 width="bold" type="xs" className="mb-2">
                {t("selectFromLibrary")}
            </TypographyV2>

            <SearchInputV2
                className="text-sm"
                iconClassName="w-5"
                searchText={filterQuery}
                onChange={(value) => setFilterQuery(value)}
            />

            <div className="mt-2 h-full w-full">
                <ListV2 renderItem={ElementAddCPItem} {...{ list, isFiltering: true, filterFunction, control }} />
            </div>
        </div>
    )
}

export default SelectCPElement
