import React from "react"
import { BreadcrumbsSlashIcon } from "components/icons"
import { classNames } from "utils"
import { TypographyV2 } from "components/v2"
import type { IBreadcrumbsV2Props } from "./breadcrumbsTypeV2"
import { useRouter } from "next/router"

const BreadcrumbsV2: React.FC<IBreadcrumbsV2Props> = ({ pathList }) => {
    const router = useRouter()

    const handleClick = (href: string) => {
        router.push(href)
    }
    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center">
                {pathList.map(({ title, id, href, onClick }, idx) => {
                    const isLast = pathList.length - 1 === idx
                    return (
                        <li className="inline-flex items-center" key={id}>
                            {idx !== 0 && !!title && (
                                <div className="mx-4">
                                    <BreadcrumbsSlashIcon />
                                </div>
                            )}
                            <div onClick={() => (onClick ? onClick() : handleClick(href))}>
                                <TypographyV2
                                    type="sm"
                                    width={isLast ? "bold" : "normal"}
                                    className={classNames(
                                        isLast ? "text-gray-900" : "text-gray-500",
                                        isLast ? "text-base" : "text-gray-500",
                                        "inline-flex items-center hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                                    )}
                                >
                                    {title}
                                </TypographyV2>
                            </div>
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}

export default BreadcrumbsV2
