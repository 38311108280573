import { useRouterPatientId } from "hooks/useRouterPatientId"
import Link from "next/link"
import { useTranslation } from "react-i18next"
import { useCurrentUser, usePatientList } from "store/hooks"
import { classNames, getUserName } from "utils"
import SidebarList from "./SidebarList"
import { FeatureFlags } from "../../utils/FeatureFlags"
import UserAvatar from "components/UserAvatar"
import { TypographyV2 } from "components/v2"

const id = "patient-profile-list"
const PatientList = () => {
    const patientId = useRouterPatientId()
    const patients = usePatientList()
    const currentUser = useCurrentUser()
    const isOverviewEnabled = FeatureFlags.isEnabled("overview")
    return (
        <div className="flex flex-col pl-4 transition-opacity overflow-y-scroll max-h-20">
            {patients?.map(({ id, user, collaborators }) => {
                const [currentPermission] = collaborators.find(({ user }) => user.id === currentUser.id).permissions

                const page = currentPermission.permission !== "limited" && isOverviewEnabled ? "overview" : "timeline"
                return (
                    <Link key={id} href={`/patient/${id}/${page}`} passHref legacyBehavior>
                        <a
                            className={classNames(
                                "flex gap-2 items-center cursor-pointer rounded-lg py-2 px-4",
                                patientId === id ? "bg-white text-med-blue" : "text-[#6B7280]"
                            )}
                        >
                            <UserAvatar size={5} user={user} />
                            <TypographyV2 type="sm" width="semibold">{getUserName(user)}</TypographyV2>
                        </a>
                    </Link>
                )
            })}
        </div>
    )
}

export default PatientList
