import React, { useCallback, useState } from "react"
import { ListV2, SearchInputV2 } from "components/v2/index"
import ActivityItemCard from "../ActivityItemCard"

interface IContentItem {
    title: string
    subtitle: string
    author: string
    categories: string[]
}

const list = [
    {
        title: "Daily Stress Check-In",
        subtitle: "Develop effective stress management skills.",
        categories: ["GAD", "Acute Stress Disorder", "11", "22", "33"],
        author: {
            first_name: "Test",
            last_name: "Test",
            email: "",
            role: "therapist",
            id: 1,
            profile_image: ""
        }
    },
    {
        title: "Understanding Sleep and Its Importance",
        subtitle: "A foundational sequence introducing patients to Cognitive Behavioral Therapy",
        categories: ["CBT"],
        author: {
            first_name: "Test",
            last_name: "Test",
            email: "",
            role: "therapist",
            id: 1,
            profile_image: ""
        }
    }
]

interface IContentListProps {
    isVerticalView?: boolean
    isFiltering?: boolean
}

const ActivityList: React.FC<IContentListProps> = ({ isFiltering = false }) => {
    const [filterQuery, setFilterQuery] = useState("")

    const filterFunction = useCallback(
        (item: IContentItem) => {
            const regex = new RegExp(filterQuery, "i")
            return regex.test(item.title)
        },
        [filterQuery]
    )

    return (
        <>
            {isFiltering && (
                <SearchInputV2
                    placeholder="Search"
                    searchText={filterQuery}
                    onChange={(value) => setFilterQuery(value)}
                />
            )}
            <ListV2 renderItem={ActivityItemCard} {...{ list, isFiltering, filterFunction }} />
        </>
    )
}

export default ActivityList
