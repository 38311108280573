import { ExerciseQuestionType } from "@prisma/client"
import {
    FreeTextIcon,
    ImageIcon,
    MinutesIcon,
    MultiSelectIcon,
    NumberIcon,
    PercentageIcon,
    ScaleIcon,
    SingleSelectIcon,
    TimeOfDayIcon,
    YesOrNoIcon
} from "components/icons"
import i18n from "locales"

export const questionTypesList = [
    { id: ExerciseQuestionType.scale, title: "scaleSlider", icon: ScaleIcon },
    { id: ExerciseQuestionType.free_text, title: "freeText", icon: FreeTextIcon },
    { id: ExerciseQuestionType.select, title: "singleSelect", icon: SingleSelectIcon },
    { id: ExerciseQuestionType.multiple_choice, title: "multiSelect", icon: MultiSelectIcon },
    { id: ExerciseQuestionType.yes_no, title: "yesOrNo", icon: YesOrNoIcon },
    { id: ExerciseQuestionType.percent, title: "percentage", icon: PercentageIcon },
    { id: ExerciseQuestionType.number, title: "number", icon: NumberIcon },
    { id: ExerciseQuestionType.minutes, title: "minutes", icon: MinutesIcon },
    { id: ExerciseQuestionType.time_of_day, title: "timeOfDay", icon: TimeOfDayIcon },
    // ToDo (DM) [SEQ-3027] Temporary hide image type from builder (until image upload is implemented on mobile)
    // { id: ExerciseQuestionType.image, title: "image", icon: ImageIcon },
    { id: ExerciseQuestionType.text, title: "text", icon: FreeTextIcon }
]

export const getQuestionTypeWithLocalization = () => ({
    [ExerciseQuestionType.scale]: i18n.t("questionTypes.scaleSlider", { ns: "question" }),
    [ExerciseQuestionType.free_text]: i18n.t("questionTypes.freeText", { ns: "question" }),
    [ExerciseQuestionType.select]: i18n.t("questionTypes.singleSelect", { ns: "question" }),
    [ExerciseQuestionType.multiple_choice]: i18n.t("questionTypes.multiSelect", { ns: "question" }),
    [ExerciseQuestionType.yes_no]: i18n.t("questionTypes.yesOrNo", { ns: "question" }),
    [ExerciseQuestionType.percent]: i18n.t("questionTypes.percentage", { ns: "question" }),
    [ExerciseQuestionType.number]: i18n.t("questionTypes.number", { ns: "question" }),
    [ExerciseQuestionType.minutes]: i18n.t("questionTypes.minutes", { ns: "question" }),
    [ExerciseQuestionType.time_of_day]: i18n.t("questionTypes.timeOfDay", { ns: "question" }),
    [ExerciseQuestionType.image]: i18n.t("questionTypes.image", { ns: "question" }),
    [ExerciseQuestionType.text]: i18n.t("questionTypes.text", { ns: "question" })
})
