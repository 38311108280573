import { useDispatch } from "store"
import {
    cloneLibraryTemplateAction,
    makeLibraryTemplateArchivedAction,
    openLibraryTemplateBuilder
} from "store/library-template/actions"
import { ArchiveIcon, CopyIcon, EditUnitDetailsIcon } from "components/icons"
import { useIsHeLanguage } from "hooks"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { LibraryTemplateResponse } from "types/Redux"

export const useLibraryTemplateItem = (libraryTemplate: LibraryTemplateResponse) => {
    const { id, diagnoses } = libraryTemplate
    const { t } = useTranslation("exercise")
    const dispatch = useDispatch()
    const { isHeLanguage } = useIsHeLanguage()

    const onMakeACopyClick = () => {
        dispatch(cloneLibraryTemplateAction(id))
    }

    const onArchiveClick = () => {
        dispatch(makeLibraryTemplateArchivedAction(id))
    }

    const openExerciseBuilder = () => {
        dispatch(openLibraryTemplateBuilder(libraryTemplate))
    }

    const dropDownActionsList = [
        {
            title: t("edit", { ns: "common" }),
            icon: EditUnitDetailsIcon,
            onClick: () => {
                openExerciseBuilder()
            }
        },
        {
            title: t("makeACopy", { ns: "common" }),
            icon: CopyIcon,
            onClick: onMakeACopyClick
        },
        {
            border: true,
            title: t("archive", { ns: "common" }),
            icon: ArchiveIcon,
            onClick: onArchiveClick
        }
    ]

    const mappedDiagnosesForBagesList = useMemo(
        () => diagnoses.map(({ diagnose: { name_en, id } }) => ({ id, title: name_en })),
        [diagnoses]
    )

    return {
        t,
        isHeLanguage,
        mappedDiagnosesForBagesList,
        dropDownActionsList,
        openExerciseBuilder
    }
}
