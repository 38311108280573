import React, { SVGProps } from "react"

const ChatIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.875 10.6328C1.875 11.967 2.81118 13.1284 4.13122 13.3225C4.98089 13.4474 5.83925 13.5456 6.70542 13.6162C7.17209 13.6543 7.59898 13.8985 7.8587 14.288L10 17.5L12.1413 14.2881C12.401 13.8985 12.8279 13.6543 13.2946 13.6163C14.1607 13.5457 15.0191 13.4475 15.8687 13.3226C17.1888 13.1285 18.125 11.9671 18.125 10.6329V5.61713C18.125 4.28288 17.1888 3.12152 15.8687 2.92745C13.9534 2.64584 11.9938 2.5 10.0002 2.5C8.00647 2.5 6.04676 2.64587 4.13122 2.92752C2.81118 3.12161 1.875 4.28297 1.875 5.6172V10.6328Z"
                stroke="#6B7280"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default ChatIcon
