import React, { useEffect, useState } from "react"

import { getEducationalContentByTemplateId } from "api"
import Spinner from "../../../../../common/Spinner"

interface IEducationalContentPreviewProps {
    id?: number | null
}

const EducationalContentPreview: React.FC<IEducationalContentPreviewProps> = ({ id }) => {
    const [htmlEduc, setHtmlEduc] = useState<null | string>(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchHtml = async () => {
            if (!id || htmlEduc || isLoading) return
            setIsLoading(true)
            const response = await getEducationalContentByTemplateId(id)
            setHtmlEduc(response.html)
            setIsLoading(false)
        }
        fetchHtml()
    }, [isLoading, htmlEduc, id])

    return (
        <>
            <div className="w-full flex justify-center">
                {isLoading ? (
                    <div className="absolute w-10 top-1/2 right-1/2 -translate-y-1/2">
                        <Spinner size={12} />
                    </div>
                ) : (
                    <>
                        {htmlEduc && (
                            <div
                                className="no-tailwind p-[20px] px-[40px] w-[500px] break-words"
                                dangerouslySetInnerHTML={{ __html: htmlEduc }}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default EducationalContentPreview
