import { EventUpdateData, RawEventCreateInput } from "@sequel-care/types"
import { createByWeekRange, useInitialWeekRange } from "components/PatientOverview/utils"
import { initialValuesFromFields, RenderFields, validate } from "forms"
import { FormContext } from "forms/FormWithSteps"
import { Dictionary, omit } from "lodash"
import { useRouter } from "next/router"
import { FormEventHandler, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import store, { useDispatch } from "store"
import { useAllGoals, useAllQuestionnaires, useCurrentPatient, useCurrentUser } from "store/hooks"
import {
    addEvent,
    closeContentLibrary,
    eventExistAction,
    getEventsByDate,
    getOverviewData,
    getPatientIdFromRouter,
    setEventsForGant,
    updateEvent
} from "store/patient/actions"
import { EventTemplate, PatientPage } from "types/Misc"
import { ContentLibraryData } from "types/Redux"
import { getInitialConfigState, eventToTemplate } from "../utils"
import EventConfigFooter from "./EventConfigFooter"
import { getAppointmentFields, getQuestionnaireFields, getTaskFields } from "./EventFields"
import { EventConfigState } from "./types"
import { configToRawEvent } from "./utils"
import { validateEvent } from "api"
import { Button, Dialog } from "components/common"
import { useRecurrenceDescriptionLong } from "hooks/useRecurrenceDescription"
import { fetchCarePlanEventsListAction } from "../../../store/care-plan/actions"

type CreatableTypes = "task" | "appointment" | "exercise" | "questionnaire" | "educational_content" | "unit"

const getFieldsByType: Record<CreatableTypes, Function> = {
    task: getTaskFields,
    appointment: getAppointmentFields,
    exercise: getQuestionnaireFields,
    educational_content: getQuestionnaireFields,
    unit: getQuestionnaireFields,
    questionnaire: getQuestionnaireFields
}
const EventConfig = ({
    eventTemplate,
    libData,
    patientId,
    onFormSubmitCallback
}: {
    eventTemplate: EventTemplate
    libData: ContentLibraryData
    patientId: number
    onFormSubmitCallback?: () => void
}) => {
    const isEdit = libData.configProps?.mode === "edit"
    const editPolicy = libData.configProps?.editPolicy
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation("common")
    const { type, questionnaire_id, exercise_id, educational_content_id, unit_id } = eventTemplate
    const patient = useCurrentPatient()
    const currentUser = useCurrentUser()
    const allGoals = useAllGoals()
    const [errors, setErrors] = useState<Dictionary<any>>({})
    const [loading, setLoading] = useState(false)
    const router = useRouter()
    const [currentPage] = (router.query.page as [PatientPage]) || []
    const initialWeekRange = useInitialWeekRange()
    const [existing, setExisting] = useState(null)
    const [newEvent, setNewEvent] = useState(null)
    const [open, setOpen] = useState(false)
    const fields = getFieldsByType[type as CreatableTypes]({
        t,
        i18n,
        patient,
        user: currentUser,
        eventTemplate,
        editPolicy,
        allGoals,
        setLoading
    })

    const [state, setState] = useState<EventConfigState>(
        isEdit
            ? getInitialConfigState(i18n, { eventTemplate, editPolicy })
            : { ...(initialValuesFromFields(fields) as EventConfigState), type }
    )
    const questionnaries = useAllQuestionnaires()

    if (!fields[0]?.readOnly && state.title?.length === 35 && !errors?.title)
        setErrors({ ...errors, title: t("patient:eventManager.validationMessages.titleTooLong") })

    const fetchEvents = async () => {
        if (!isNaN(getPatientIdFromRouter())) {
            const lastFetch = store.getState().patient.lastEventFetchData
            if (currentPage === "timeline")
                await dispatch(getEventsByDate({ date: lastFetch.date, page: "timeline", forceFetch: true }))
            if (currentPage === "overview")
                await dispatch(getOverviewData({ eventsByWeek: createByWeekRange(initialWeekRange) }))
        }
    }

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault()
        setErrors({})
        const newErrors = validate(state, fields)
        if (newErrors) setErrors(newErrors)

        if (newErrors) return
        const newEvent: RawEventCreateInput = {
            ...configToRawEvent(state, { questionnaire_id, exercise_id, educational_content_id, unit_id }),
            conditioned_events: state.linked_events.map(({ questionnaire_id, condition_type, ...condition }) => {
                const questionnaire = questionnaire_id
                    ? questionnaries.find(({ id }) => id === questionnaire_id)
                    : undefined
                return {
                    event: configToRawEvent(
                        getInitialConfigState(i18n, {
                            eventTemplate: eventToTemplate("questionnaire", omit(questionnaire, "recurrence"))
                        }),
                        { questionnaire_id: questionnaire.id, is_conditioned: true }
                    ),
                    type: condition_type,
                    time_unit: condition.time_unit,
                    time_offset: condition.time_offset
                }
            })
        }

        setLoading(true)

        if (!isEdit && ["exercise", "questionnaire"].includes(state.type)) {
            const event =
                existing ||
                (await validateEvent(patientId, {
                    date_on_timeline: newEvent.date_on_timeline,
                    exercise_id: newEvent.exercise_id,
                    questionnaire_id: newEvent.questionnaire_id
                }))

            if (event) {
                setOpen(true)
                setExisting(event)
                setNewEvent(newEvent)
                return
            }
        }

        try {
            if (!isEdit) {
                const eventAdded = await dispatch(addEvent(newEvent))
                if (!eventAdded) throw "add_failed"
                toast(t("patient:eventManager.addSuccess"), { type: "success" })
            } else {
                const updateData: EventUpdateData = {
                    event: newEvent,
                    patient_id: patientId
                }
                if (editPolicy) {
                    updateData.policy = editPolicy
                    if (editPolicy === "single") {
                        updateData.original_date = state.original_date
                        delete updateData.event.recurrence
                    }
                }

                const eventUpdated = await dispatch(updateEvent(updateData))
                if (!eventUpdated) throw "updated_failed"
                toast(t("patient:eventManager.updateSuccess"), { type: "success" })
            }

            await fetchEvents()

            dispatch(closeContentLibrary())
            dispatch(setEventsForGant(null))
        } catch (error) {
            setLoading(false)
            console.error(error)
        }

        if (onFormSubmitCallback) onFormSubmitCallback()
    }

    const currentEventRecurrence = useRecurrenceDescriptionLong(state.recurrence)
    const existingEventRecurrence = useRecurrenceDescriptionLong(existing?.recurrence)
    return (
        <form className="flex flex-col grow h-full" onSubmit={onSubmit}>
            <FormContext.Provider value={{ errors, setErrors, state: {}, setState: () => {} }}>
                <div className="grid grid-cols-12 p-12 pb-0 gap-x-16 gap-y-5">
                    <RenderFields {...{ fields, state, setState, errors, setErrors }} />
                </div>
            </FormContext.Provider>
            <EventConfigFooter
                eventTemplate={state}
                loading={loading}
                patientId={patientId}
                isButtonDisabled={!!onFormSubmitCallback}
            />
            <Dialog
                type="warn"
                open={open}
                title={t("patient:eventManager.eventExists.title")}
                buttonText={t("patient:eventManager.eventExists.keepBoth")}
                cancelText={t("patient:eventManager.eventExists.replace")}
                additionalActions={
                    <Button
                        className="text-negative border-negative hover:!bg-negative"
                        theme="secondary"
                        onClick={() => {
                            setOpen(false)
                            dispatch(closeContentLibrary())
                        }}
                    >
                        {t("patient:eventManager.eventExists.keepOld")}
                    </Button>
                }
                onClose={() => {
                    setLoading(false)
                    setOpen(false)
                }}
                onDismiss={async () => {
                    setOpen(false)
                    const res = await dispatch(
                        eventExistAction({ event: newEvent, action: "replace", existing_id: existing.id })
                    )
                    fetchEvents()
                    if (onFormSubmitCallback) onFormSubmitCallback()
                    return res
                }}
                onConfirm={async () => {
                    setOpen(false)
                    const res = await dispatch(
                        eventExistAction({ event: newEvent, action: "keep_both", existing_id: existing.id })
                    )
                    fetchEvents()
                    if (onFormSubmitCallback) onFormSubmitCallback()
                    return res
                }}
            >
                <div className="flex flex-col gap-4 mt-4">
                    {t("patient:eventManager.eventExists.body", { type: state.type })}
                    <div className="grid grid-cols-2 gap-4" style={{ gridTemplateColumns: "0.5fr 1fr" }}>
                        <span className="text-text-blue">{t("patient:eventManager.eventExists.newEvent")}</span>
                        {currentEventRecurrence}
                        <span className="text-text-blue">{t("patient:eventManager.eventExists.existingEvent")}</span>
                        {existingEventRecurrence}
                    </div>
                </div>
            </Dialog>
        </form>
    )
}

export default EventConfig
