import React, { Fragment, useMemo } from "react"
import UnitBuilderPreviewSidebar from "./UnitBuilderPreviewSidebar"
import { CardV2 } from "components/v2/components/common"
import { useUnitSelector } from "store/hooks"
import { useUnitBuilderPreviewElements } from "./useUnitBuilderPreviewElements"

const UnitBuilderPreview = () => {
    const { chapters, selectedChapter: selectedChapterId, elements } = useUnitSelector()

    const selectedChapter = useMemo(
        () => chapters.find((chapter) => chapter.id === selectedChapterId),
        [chapters, selectedChapterId]
    )

    const previewElements = useUnitBuilderPreviewElements(elements)

    return (
        <div className="flex gap-6 h-full overflow-hidden">
            <UnitBuilderPreviewSidebar />
            <div className="overflow-y-auto flex-1 py-4 pr-4">
                <CardV2 className="p-14 !cursor-default overflow-y-auto">
                    <p className="text-med-black text-3xl font-bold">{selectedChapter.name}</p>
                    {previewElements.length > 0 &&
                        previewElements.map((element, id) => (
                            <Fragment key={`unit_builder_preview_element_${id}`}>{element}</Fragment>
                        ))}
                </CardV2>
            </div>
        </div>
    )
}

export default UnitBuilderPreview
