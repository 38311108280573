import { ApiDelete, ApiGet, ApiPost } from "./ApiRequest"
import { MediaObject } from "@prisma/client"
import { CreateClinicMediaParams } from "@sequel-care/types/Media"

export const createClinicMediaRequest = async (body: CreateClinicMediaParams) => {
    const instance = new ApiPost<MediaObject>("/clinicMedia/create", body)
    return instance.run()
}

export const removeClinicMediaRequest = async (id: number) => {
    const instance = new ApiDelete<MediaObject>(`/clinicMedia/mediaObjectId/${id}`)
    instance.callerName = removeClinicMediaRequest.name
    return instance.run()
}

export const getClinicMediaRequest = async (id: number) => {
    const instance = new ApiGet<MediaObject>(`/clinicMedia/mediaObjectId/${id}`)
    instance.callerName = getClinicMediaRequest.name
    return instance.run()
}
