import { ScreeningTemplateQuestion, ScreeningQuestionType } from "@prisma/client"
import {
    CardV2,
    ControlledDateInputV2,
    ControlledScaleSliderV2,
    ControlledSelectV2,
    ControlledTextAreaV2,
    SelectOptionsV2,
    TextInputV2,
    TypographyV2,
    UploadFileV2
} from "components/v2"
import { Control, Controller } from "react-hook-form"
import { fetchQuestionnaire } from "api/Intake"
import { useState, useEffect } from "react"
import { useScreenSize } from "hooks"
import { TableListV2 } from "components/v2"
import {
    FreeTextQuestionCard,
    SelectQuestionCard,
    MultipleChoiceQuestionCard,
    NumberQuestionCard,
    PercentQuestionCard,
    MinutesQuestionCard,
    TimeOfDayQuestionCard,
    ScaleQuestionCard,
    ImageQuestionCard,
    ScaleSliderQuestionCard,
    YesNoQuestionCard
} from "../"
import { t } from "i18next"
import { useCurrentPreferences } from "store/hooks"
import { classNames } from "utils"

interface IQuestionnaireCardProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: ScreeningTemplateQuestion
    questionnaire?: Record<string, any>
    questionnaireIndex?: number
    organization?: any
}

const arraysEqual = (a: any[], b: any[]) => {
    return JSON.stringify(a) === JSON.stringify(b)
}

const QuestionnaireCard = ({ control, error, question, questionnaireIndex, organization }: IQuestionnaireCardProps) => {
    const { is_required, definition } = question
    const questionnaire_id =
        (definition as { questionnaire_id: number })?.questionnaire_id || (definition as any)?.["questionnaire_id"]
    const [questionnaire, setQuestionnaire] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)
    const isDesktop = useScreenSize()
    const [processedQuestions, setProcessedQuestions] = useState<
        {
            headers: string[]
            questions: any[]
            type: string
            guideline?: string
        }[]
    >([])

    const defaultDateFormat = "dd/MM/yyyy"

    const [file, setFile] = useState(null)

    const parentQuestionId = question.id

    useEffect(() => {
        if (!questionnaire_id) return

        const loadQuestionnaire = async () => {
            setIsLoading(true)
            try {
                const data = await fetchQuestionnaire(questionnaire_id)
                setQuestionnaire(data)
            } catch (error) {
                console.error("Failed to fetch questionnaire:", error)
            } finally {
                setIsLoading(false)
            }
        }
        loadQuestionnaire()
    }, [questionnaire_id])

    const renderQuestionCell = (question: any, group: any) => {
        switch (group.type) {
            case ScreeningQuestionType.scale:
                return group.headers.map((_: any, index: number) => (
                    <td key={index} className="p-2 text-center">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field }) => {
                                const questionScale = group.questions.find((q: any) => q.id === question.id)?.scale
                                const scaleValues = new Array(questionScale?.max - questionScale?.min + 1)
                                    .fill(0)
                                    .map((_, i) => i + questionScale?.min)
                                return <input type="radio" {...field} value={scaleValues[index]} className="h-4 w-4" />
                            }}
                        />
                    </td>
                ))

            case ScreeningQuestionType.scale_slider:
                return (
                    <td className="p-2">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field }) => {
                                const scaleMin = question?.scale?.min ?? 0
                                const scaleMax = question?.scale?.max ?? 10
                                const firstLabel = question?.scale?.labels?.[0]?.[0] || scaleMin
                                const lastLabel =
                                    question?.scale?.labels?.[0]?.[question?.scale?.labels[0]?.length - 1] || scaleMax

                                return (
                                    <ControlledScaleSliderV2
                                        {...field}
                                        isRequired={question.is_required}
                                        min={scaleMin}
                                        max={scaleMax}
                                        step={1}
                                        minLabel={firstLabel}
                                        maxLabel={lastLabel}
                                        error={error}
                                        classList={{ wrapper: "w-full" }}
                                        control={control}
                                    />
                                )
                            }}
                        />
                    </td>
                )

            case ScreeningQuestionType.yes_no:
                return group.headers.map((_: any, index: number) => (
                    <td key={index} className="py-6 px-6 text-center">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field }) => (
                                <input type="radio" {...field} value={index === 0 ? "yes" : "no"} className="h-4 w-4" />
                            )}
                        />
                    </td>
                ))

            case ScreeningQuestionType.image:
                return (
                    <td className="py-6 px-6">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <div className="h-[136px] w-full">
                                    <UploadFileV2 value={file} setValue={onChange} />
                                </div>
                            )}
                        />
                    </td>
                )

            case ScreeningQuestionType.time_of_day:
                return (
                    <td className="py-6 px-6">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field }) => (
                                <ControlledDateInputV2
                                    {...field}
                                    classList={{ wrapper: "w-full" }}
                                    error={error}
                                    isRequired={question.is_required}
                                    dateFormat={organization?.default_preferences?.date_format || defaultDateFormat}
                                    control={control}
                                />
                            )}
                        />
                    </td>
                )

            case ScreeningQuestionType.select:
                return (
                    <td className="py-6 px-6">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field }) => (
                                <ControlledSelectV2
                                    {...field}
                                    classList={{ wrapper: "w-full" }}
                                    isRequired={question.is_required}
                                    options={question?.definition?.options?.map((option: string) => ({
                                        id: option,
                                        title: option
                                    }))}
                                    label=""
                                    placeholder={question.question_text}
                                    error={error}
                                    control={control}
                                />
                            )}
                        />
                    </td>
                )

            case ScreeningQuestionType.multiple_choice:
                return (
                    <td className="py-6 px-6">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <SelectOptionsV2
                                    options={(question.definition as any).options.map((option: string, i: number) =>
                                        option.trim() === "" ? (i + 1).toString() : option
                                    )}
                                    values={(() => {
                                        const formValue = value as string | string[] | undefined

                                        if (!formValue) return []
                                        if (Array.isArray(formValue)) return formValue.map(String)
                                        if (typeof formValue === "string") {
                                            try {
                                                const parsed = JSON.parse(formValue)
                                                return Array.isArray(parsed) ? parsed.map(String) : []
                                            } catch {
                                                return formValue.split(",")
                                            }
                                        }
                                        return []
                                    })()}
                                    setValues={(newValues: string[]) => onChange(newValues)}
                                    withOther={true}
                                />
                            )}
                        />
                        {error && (
                            <TypographyV2 type="xs" width="medium" className="text-red-500">
                                {error}
                            </TypographyV2>
                        )}
                    </td>
                )

            case ScreeningQuestionType.number:
                return (
                    <td className="py-6 px-6">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <TextInputV2
                                    value={value as string}
                                    onChange={(newValue) => {
                                        if (newValue === "") {
                                            onChange("")
                                            return
                                        }
                                        if (!/^\d*$/.test(newValue)) return
                                        onChange(newValue)
                                    }}
                                    className="mt-0 outline-none  border border-base-light bg-transparent text-lg focus:ring-0 text-center focus:border-none focus:shadow-[0_0_0_0_#000] shadow-none text-[44px] font-bold"
                                    placeholder="0"
                                />
                            )}
                        />
                    </td>
                )

            case ScreeningQuestionType.percent:
                return (
                    <td className="py-6 px-6">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <TextInputV2
                                    value={value as string}
                                    onChange={(newValue) => {
                                        if (newValue === "") {
                                            onChange("")
                                            return
                                        }
                                        if (!/^\d*$/.test(newValue)) return
                                        const numValue = parseInt(newValue)
                                        if (numValue > 100) return
                                        onChange(newValue)
                                    }}
                                    className="mt-0  border border-base-light  bg-transparent 0 text-center font-bold"
                                    placeholder="0%"
                                />
                            )}
                        />
                    </td>
                )

            case ScreeningQuestionType.minutes:
                return (
                    <td className="py-6 px-6">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <TextInputV2
                                    value={value as string}
                                    onChange={(newValue) => {
                                        if (newValue === "") {
                                            onChange("")
                                            return
                                        }
                                        if (!/^\d*$/.test(newValue)) return
                                        onChange(newValue)
                                    }}
                                    className="mt-0 outline-none  border border-base-light bg-transparent text-lg focus:ring-0 text-center focus:border-none focus:shadow-[0_0_0_0_#000] shadow-none text-[44px] font-bold"
                                    placeholder="0m"
                                />
                            )}
                        />
                    </td>
                )

            default:
                return (
                    <td className="py-6 px-6">
                        <Controller
                            name={`screeningAnswers.${parentQuestionId}_${question.id}`}
                            control={control}
                            render={({ field }) => (
                                <ControlledTextAreaV2
                                    {...field}
                                    error={error}
                                    isRequired={question.is_required}
                                    rowsCount={4}
                                    classList={{ wrapper: "w-full" }}
                                    placeholder={t("intake:common.tell_us")}
                                    control={control}
                                />
                            )}
                        />
                    </td>
                )
        }
    }

    useEffect(() => {
        if (questionnaire?.questions) {
            let currentGroup: any = null
            const groups: any = []

            questionnaire.questions.forEach((question: any) => {
                switch (question.type) {
                    case ScreeningQuestionType.scale:
                        const scaleLabels = question.scale.labels[1] || question.scale.labels[0]
                        if (
                            !currentGroup ||
                            currentGroup.type !== ScreeningQuestionType.scale ||
                            !arraysEqual(currentGroup.headers, scaleLabels) ||
                            !(currentGroup.guideline === question.scale.guideline)
                        ) {
                            if (currentGroup) {
                                groups.push(currentGroup)
                            }
                            currentGroup = {
                                type: ScreeningQuestionType.scale,
                                headers: scaleLabels,
                                guideline: question.scale.guideline,
                                questions: [question]
                            }
                        } else {
                            currentGroup.questions.push(question)
                        }
                        break

                    case ScreeningQuestionType.scale_slider:
                        const scaleLabelsSlider = question.scale.labels[1] || question.scale.labels[0]
                        if (
                            !currentGroup ||
                            currentGroup.type !== ScreeningQuestionType.scale_slider ||
                            !arraysEqual(currentGroup.headers, scaleLabelsSlider) ||
                            !(currentGroup.guideline === question.scale.guideline)
                        ) {
                            if (currentGroup) {
                                groups.push(currentGroup)
                            }
                            currentGroup = {
                                type: ScreeningQuestionType.scale_slider,
                                headers: scaleLabelsSlider,
                                guideline: question.scale.guideline,
                                questions: [question]
                            }
                        } else {
                            currentGroup.questions.push(question)
                        }
                        break

                    case ScreeningQuestionType.free_text:
                        if (!currentGroup || currentGroup.type !== ScreeningQuestionType.free_text) {
                            if (currentGroup) {
                                groups.push(currentGroup)
                            }
                            currentGroup = {
                                type: ScreeningQuestionType.free_text,
                                questions: [question]
                            }
                        } else {
                            currentGroup.questions.push(question)
                        }
                        break

                    case ScreeningQuestionType.yes_no:
                        if (!currentGroup || currentGroup.type !== ScreeningQuestionType.yes_no) {
                            if (currentGroup) {
                                groups.push(currentGroup)
                            }
                            currentGroup = {
                                type: ScreeningQuestionType.yes_no,
                                headers: ["Yes", "No"],
                                questions: [question]
                            }
                        } else {
                            currentGroup.questions.push(question)
                        }
                        break

                    case ScreeningQuestionType.percent:
                    case ScreeningQuestionType.number:
                    case ScreeningQuestionType.select:
                    case ScreeningQuestionType.time_of_day:
                    case ScreeningQuestionType.multiple_choice:
                    case ScreeningQuestionType.minutes:
                    case ScreeningQuestionType.image:
                        if (!currentGroup || currentGroup.type !== question.type) {
                            if (currentGroup) {
                                groups.push(currentGroup)
                            }
                            currentGroup = {
                                type: question.type,
                                questions: [question]
                            }
                        } else {
                            currentGroup.questions.push(question)
                        }
                        break
                }
            })

            if (currentGroup) {
                groups.push(currentGroup)
            }

            setProcessedQuestions(groups)
        }
    }, [questionnaire])

    if (!questionnaire_id) {
        return <div>No questionnaire ID provided</div>
    }

    if (isLoading) {
        return <div>Loading questionnaire...</div>
    }

    const renderQuestionByType = (q: any, index: number) => {
        const questionProps = {
            key: `${question.id}_${index}`,
            question: {
                ...q,
                is_required,
                id: `${question.id}_${q.id}_${q.type}_${index}`
            },
            control,
            error
        }

        switch (q.type) {
            case ScreeningQuestionType.free_text:
                return <FreeTextQuestionCard {...questionProps} />
            case ScreeningQuestionType.select:
                return <SelectQuestionCard {...questionProps} />
            case ScreeningQuestionType.multiple_choice:
                return <MultipleChoiceQuestionCard {...questionProps} />
            case ScreeningQuestionType.yes_no:
                return <YesNoQuestionCard {...questionProps} />
            case ScreeningQuestionType.scale:
                return <ScaleQuestionCard {...questionProps} />
            case ScreeningQuestionType.number:
                return <NumberQuestionCard {...questionProps} />
            case ScreeningQuestionType.percent:
                return <PercentQuestionCard {...questionProps} />
            case ScreeningQuestionType.minutes:
                return <MinutesQuestionCard {...questionProps} />
            case ScreeningQuestionType.time_of_day:
                return <TimeOfDayQuestionCard {...questionProps} />
            case ScreeningQuestionType.image:
                return <ImageQuestionCard {...questionProps} />
            case ScreeningQuestionType.scale_slider:
                return <ScaleSliderQuestionCard {...questionProps} />
            default:
                return null
        }
    }

    if (isDesktop) {
        return (
            <div className="flex flex-col w-full">
                <div className="flex items-start gap-3">
                    <div
                        className={classNames(
                            "flex items-center justify-center",
                            "w-[30px] h-[30px]",
                            "rounded bg-lightest-blue",
                            "p-1"
                        )}
                    >
                        <span className="text-med-blue font-medium text-sm">{questionnaireIndex}</span>
                    </div>
                    <div className="flex-1 mb-6">
                        <TypographyV2 type="base">{questionnaire?.friendly_name}</TypographyV2>
                    </div>
                </div>

                <CardV2>
                    <div className="bg-white rounded-lg">
                        {processedQuestions.map((group, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${index !== processedQuestions.length - 1 ? "border-b" : ""}`}
                                >
                                    {group?.guideline && (
                                        <div className="my-6 p-4 bg-lightest-blue rounded-[10px]">
                                            <TypographyV2 type="xs" className="text-med-black">
                                                {group.guideline}
                                            </TypographyV2>
                                        </div>
                                    )}
                                    <TableListV2
                                        headerCells={[
                                            { className: "w-1/3 text-left" },
                                            ...(group.type === "scale" || group.type === "yes_no"
                                                ? group.headers.map((label) => ({
                                                      title: label,
                                                      className: "text-center"
                                                  }))
                                                : [{ className: "w-2/3" }])
                                        ]}
                                        tableClassName="!border-none"
                                        listComponent={
                                            <>
                                                {group.questions.map((question: any) => (
                                                    <tr key={question.id}>
                                                        <td className="py-2 px-2">
                                                            <div className="flex flex-col gap-2">
                                                                <TypographyV2 type="base">
                                                                    {question.question}
                                                                </TypographyV2>
                                                                {question.description && (
                                                                    <TypographyV2 type="xs" className="text-gray-500">
                                                                        {question.description}
                                                                    </TypographyV2>
                                                                )}
                                                            </div>
                                                        </td>
                                                        {renderQuestionCell(question, group)}
                                                    </tr>
                                                ))}
                                            </>
                                        }
                                    />
                                </div>
                            )
                        })}
                    </div>
                </CardV2>
            </div>
        )
    }

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{questionnaire?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{questionnaire?.friendly_name}</TypographyV2>
            {questionnaire?.questions?.map((q: any, index: number) => renderQuestionByType(q, index))}
        </div>
    )
}

export default QuestionnaireCard
