import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { closeModal } from "store/modal/actions"
import { DialogV2, RadioCircleGroupV2, SelectV2, TypographyV2 } from "components/v2"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { useCurrentPatient } from "../../store/hooks"
import { deleteCarePlanPatientEventAction, deleteSequentialEventAction } from "../../store/care-plan/actions"
import { useIsHeLanguage } from "../../hooks"
import { classNames } from "../../utils"
import { TriggerType } from "@prisma/client"
import { EventHandlePolicy } from "@sequel-care/types"
import EditPolicySelector from "../../components/Patient/EditPolicySelector"
import event from "../../sidebars/Event"
import { deleteEvent } from "../../api"

interface DeleteEventModalProps {
    isOpen: boolean
    params?: any
}

const DeleteEventModal: React.FC<DeleteEventModalProps> = ({ isOpen, params }) => {
    const { t } = useTranslation("common")
    const [singleSelect, setSingleSelect] = useState([])

    const [groupRadio, setGroupRadio] = useState("single")
    const dispatch = useDispatch()
    const patient = useCurrentPatient()

    const actionString = t(`common:delete`, { ns: "patient" })

    const handleClose = () => {
        dispatch(closeModal())
    }

    const handleDelete = () => {
        const fetchRequest = async () => {
            const isSequentialEvent =
                params?.event?.care_protocol_element?.trigger_type === TriggerType.SEQUENTIAL_TRIGGERED ||
                params?.event?.care_protocol_element?.trigger_type === TriggerType.SEQUENTIAL_MANUAL

            if (!!params?.event?.recurrence) {
                await deleteEvent({ event: params?.event, policy: groupRadio })
            }

            if (params?.isSequential) {
                dispatch(deleteSequentialEventAction(params?.event?.id))
            } else if (isSequentialEvent) {
                dispatch(deleteSequentialEventAction(params?.event?.sequential_event?.id))
            } else {
                dispatch(deleteCarePlanPatientEventAction(params?.event?.id, patient.id))
            }

            // dispatch(deleteCarePlanPatientEventAction(params?.eventId, patient.id))
            // Add delete logic here
            handleClose()
        }

        fetchRequest()
    }

    const radioGroupOptions = (
        selectedValue: (string | number)[],
        setSelectedValue: (value: (string | number)[]) => void
    ) => [
        {
            value: "single",
            labelComponent: t(`eventManager.recurringDialog.options.single`, { action: actionString, ns: "patient" })
        },
        {
            value: "all",
            labelComponent: t(`eventManager.recurringDialog.options.all`, { action: actionString, ns: "patient" })
        }
    ]

    return (
        <DialogV2
            title={t("delete_event")}
            isOpen={isOpen}
            onClose={handleClose}
            onProceed={handleDelete}
            proceedText={t("delete_event")}
            proceedType="danger"
            dismissType="light-gray"
            dismissText={t("cancel")}
            classList={{
                dialog: classNames("!translate-y-[0] !shadow-none")
            }}
        >
            <div className="px-2 ">
                <div className="my-4">
                    <TypographyV2 width="normal" type="base" className="my-2">
                        {t("event")}
                    </TypographyV2>
                    <TypographyV2 width="bold" type="xl">
                        {params?.title}
                    </TypographyV2>
                </div>
                {!!params?.event?.recurrence && (
                    <>
                        <div className="flex flex-row items-center gap-1">
                            <TypographyV2 width="normal" type="sm">
                                {t("eventManager.deletePolicy", { ns: "patient" })}
                            </TypographyV2>
                            <InformationCircleIcon className="w-3 h-3  text-[#9CA3AF] " />
                        </div>
                        <RadioCircleGroupV2
                            name="general"
                            onChange={setGroupRadio}
                            options={radioGroupOptions(singleSelect, setSingleSelect)}
                            value={groupRadio}
                        />
                    </>
                )}
            </div>
        </DialogV2>
    )
}

export default DeleteEventModal
