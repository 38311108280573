import { LibraryTemplateType } from "@prisma/client"

export const getEmptyTile = (type: LibraryTemplateType) =>
    ({
        [LibraryTemplateType.EXERCISE]: "emptyList.exercise.title",
        [LibraryTemplateType.EDUCATIONAL_CONTENT]: "emptyList.educational-content.title",
        [LibraryTemplateType.UNIT]: "emptyList.unit.title",
        [LibraryTemplateType.CARE_PROTOCOL]: "emptyList.care-protocol.title"
    }[type])

export const getEmptyButtonTile = (type: LibraryTemplateType) =>
    ({
        [LibraryTemplateType.EXERCISE]: "emptyList.exercise.button",
        [LibraryTemplateType.EDUCATIONAL_CONTENT]: "emptyList.educational-content.button",
        [LibraryTemplateType.UNIT]: "emptyList.unit.button",
        [LibraryTemplateType.CARE_PROTOCOL]: "emptyList.care-protocol.button"
    }[type])

export const getEmptySubTitle = (type: LibraryTemplateType) =>
    ({
        [LibraryTemplateType.EXERCISE]: "emptyList.exercise.subTitle",
        [LibraryTemplateType.EDUCATIONAL_CONTENT]: "emptyList.educational-content.subTitle",
        [LibraryTemplateType.UNIT]: "emptyList.unit.subTitle",
        [LibraryTemplateType.CARE_PROTOCOL]: "emptyList.care-protocol.subTitle"
    }[type])

