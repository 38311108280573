import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useCallback, useEffect, useState } from "react"
import {
    $createParagraphNode,
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    $isRootOrShadowRoot,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    SELECTION_CHANGE_COMMAND
} from "lexical"

import { $isLinkNode } from "@lexical/link"
import {
    $getSelectionStyleValueForProperty,
    $isParentElementRTL,
    $patchStyleText,
    $wrapNodes
} from "@lexical/selection"
import { $findMatchingParent, $getNearestNodeOfType, $isEditorIsNestedEditor, mergeRegister } from "@lexical/utils"
import { $isTableNode, $isTableSelection } from "@lexical/table"

import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND
} from "@lexical/list"
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text"
import { $isCodeNode, CODE_LANGUAGE_MAP } from "@lexical/code"
import { blockTypeToBlockName, useToolbarState } from "../../context/ToolbarContext"
import { getSelectedNode } from "../../utils/getSelectedNode"
import { AlignEditorTypes, FontEditorTypes, HeadingEditorTypes, headingTypesList, ListEditorTypes } from "./constants"
import { ToolbarIcon, ToolbarSection, UploadEducationalContentImage } from "./components"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "types/Redux"
import { useTranslation } from "react-i18next"
import { CHANGE_PARAGRAPH_DIRECTION_COMMAND } from "../DirectionPlugin/DirectionPlugin"
import { INSERT_COLLAPSIBLE_COMMAND } from "../CollapsiblePlugin/CollapsiblePlugin"
import { ButtonV2 } from "../../../buttons"
import { PlusIcon } from "components/icons"
import ColorPickerButton from "./components/ColorPicker"
import { INSERT_BOX_COLOR_COMMAND } from "../BoxColorPlugin/BoxColorPlugin"
import { $isBoxColorNode, BoxColorNode } from "../../nodes/BoxColorNode"

const LowPriority = 1

export const COMMAND_PRIORITY_CRITICAL = 4

export default function ToolbarPlugin() {
    const dispatch = useDispatch()
    const { t } = useTranslation("educationalContent")
    const [editor] = useLexicalComposerContext()
    const [activeEditor, setActiveEditor] = useState(editor)
    const [blockType, setBlockType] = useState("paragraph")
    const [isBold, setIsBold] = useState(false)
    const [isItalic, setIsItalic] = useState(false)
    const [isUnderline, setIsUnderline] = useState(false)
    const { toolbarState, updateToolbarState } = useToolbarState()

    const modal = useSelector((state: RootState) => state.modal)

    const updateToolbar = useCallback(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode()
            const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow()
            const elementKey = element.getKey()
            const elementDOM = editor.getElementByKey(elementKey)
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode)
                    const type = parentList ? parentList.getTag() : element.getTag()
                    setBlockType(type)
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType()
                    setBlockType(type)
                }
            }
            setIsBold(selection.hasFormat("bold"))
            setIsItalic(selection.hasFormat("italic"))
            setIsUnderline(selection.hasFormat("underline"))
        }
    }, [editor])

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar()
                })
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, newEditor) => {
                    updateToolbar()
                    return false
                },
                LowPriority
            )
        )
    }, [editor, updateToolbar])

    const formatHeading = (type: HeadingEditorTypes) => () => {
        editor.update(() => {
            const selection = $getSelection()

            if ($isRangeSelection(selection)) {
                if (blockType !== type) {
                    $wrapNodes(selection, () => $createHeadingNode(type))
                } else {
                    $wrapNodes(selection, () => $createParagraphNode())
                }
            }
        })
    }

    const formatBulletList = () => {
        if (blockType !== "ul") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
        }
    }

    const formatNumberedList = () => {
        if (blockType !== "ol") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
        }
    }

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            if (activeEditor !== editor && $isEditorIsNestedEditor(activeEditor)) {
                const rootElement = activeEditor.getRootElement()
                updateToolbarState(
                    "isImageCaption",
                    !!rootElement?.parentElement?.classList.contains("image-caption-container")
                )
            } else {
                updateToolbarState("isImageCaption", false)
            }

            const anchorNode = selection.anchor.getNode()
            let element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                          const parent = e.getParent()
                          return parent !== null && $isRootOrShadowRoot(parent)
                      })

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow()
            }

            const elementKey = element.getKey()
            const elementDOM = activeEditor.getElementByKey(elementKey)

            updateToolbarState("isRTL", $isParentElementRTL(selection))

            const node = getSelectedNode(selection)
            const parent = node.getParent()
            const isLink = $isLinkNode(parent) || $isLinkNode(node)
            updateToolbarState("isLink", isLink)

            const tableNode = $findMatchingParent(node, $isTableNode)
            if ($isTableNode(tableNode)) {
                updateToolbarState("rootType", "table")
            } else {
                updateToolbarState("rootType", "root")
            }

            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
                    const type = parentList ? parentList.getListType() : element.getListType()

                    updateToolbarState("blockType", type)
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType()
                    if (type in blockTypeToBlockName) {
                        updateToolbarState("blockType", type as keyof typeof blockTypeToBlockName)
                    }
                    if ($isCodeNode(element)) {
                        const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP
                        updateToolbarState("codeLanguage", language ? CODE_LANGUAGE_MAP[language] || language : "")
                        return
                    }
                }
            }
            // Handle buttons
            updateToolbarState("fontColor", $getSelectionStyleValueForProperty(selection, "color", "#000"))
            updateToolbarState(
                "highlightColor",
                $getSelectionStyleValueForProperty(selection, "background-color", "#fff")
            )
            updateToolbarState("fontFamily", $getSelectionStyleValueForProperty(selection, "font-family", "Arial"))
            let matchingParent
            if ($isLinkNode(parent)) {
                // If node is a link, we need to fetch the parent paragraph node to set format
                matchingParent = $findMatchingParent(
                    node,
                    (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
                )
            }

            // If matchingParent is a valid node, pass it's format type
            updateToolbarState(
                "elementFormat",
                $isElementNode(matchingParent)
                    ? matchingParent.getFormatType()
                    : $isElementNode(node)
                    ? node.getFormatType()
                    : parent?.getFormatType() || "left"
            )
        }
        if ($isRangeSelection(selection) || $isTableSelection(selection)) {
            // Update text format
            updateToolbarState("isBold", selection.hasFormat("bold"))
            updateToolbarState("isItalic", selection.hasFormat("italic"))
            updateToolbarState("isUnderline", selection.hasFormat("underline"))
            updateToolbarState("isStrikethrough", selection.hasFormat("strikethrough"))
            updateToolbarState("isSubscript", selection.hasFormat("subscript"))
            updateToolbarState("isSuperscript", selection.hasFormat("superscript"))
            updateToolbarState("isCode", selection.hasFormat("code"))
            updateToolbarState("fontSize", $getSelectionStyleValueForProperty(selection, "font-size", "15px"))
            updateToolbarState("isLowercase", selection.hasFormat("lowercase"))
            updateToolbarState("isUppercase", selection.hasFormat("uppercase"))
            updateToolbarState("isCapitalize", selection.hasFormat("capitalize"))
        }
    }, [activeEditor, editor, updateToolbarState])

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                setActiveEditor(newEditor)
                $updateToolbar()
                return false
            },
            COMMAND_PRIORITY_CRITICAL
        )
    }, [editor, $updateToolbar, setActiveEditor])

    useEffect(() => {
        activeEditor.getEditorState().read(() => {
            $updateToolbar()
        })
    }, [activeEditor, $updateToolbar])

    useEffect(() => {
        return mergeRegister(
            activeEditor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar()
                })
            }),
            activeEditor.registerCommand<boolean>(
                CAN_UNDO_COMMAND,
                (payload) => {
                    updateToolbarState("canUndo", payload)
                    return false
                },
                COMMAND_PRIORITY_CRITICAL
            ),
            activeEditor.registerCommand<boolean>(
                CAN_REDO_COMMAND,
                (payload) => {
                    updateToolbarState("canRedo", payload)
                    return false
                },
                COMMAND_PRIORITY_CRITICAL
            )
        )
    }, [$updateToolbar, activeEditor, editor, updateToolbarState])

    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const selection = $getSelection()

                if ($isRangeSelection(selection)) {
                    const nodes = selection.getNodes()

                    if (nodes.length === 0) return

                    // Get the parent node
                    const firstNode = nodes[0]
                    const parentNode = firstNode.getParentOrThrow()

                    // Check if parent or its parent is a BoxColorNode
                    const boxColorNode: BoxColorNode = $isBoxColorNode(parentNode)
                        ? parentNode
                        : $isBoxColorNode(parentNode.getParent())
                        ? parentNode.getParent()
                        : null

                    updateToolbarState("boxColor", boxColorNode ? boxColorNode.getBackgroundColor() : "")
                }
            })
        })
    }, [editor])

    const applyStyleText = useCallback(
        (styles: Record<string, string>, skipHistoryStack?: boolean) => {
            editor.update(
                () => {
                    const selection = $getSelection()
                    if (selection !== null) {
                        $patchStyleText(selection, styles)
                    }
                },
                skipHistoryStack ? { tag: "historic" } : {}
            )
        },
        [editor]
    )

    const onFontColorSelect = useCallback(
        (value: string, skipHistoryStack: boolean) => {
            applyStyleText({ color: value }, skipHistoryStack)
        },
        [applyStyleText]
    )

    const onHighlightColorSelect = useCallback(
        (value: string, skipHistoryStack: boolean) => {
            applyStyleText({ "background-color": value }, skipHistoryStack)
        },
        [applyStyleText]
    )

    const onBoxColorSelect = (color: string) => {
        editor.dispatchCommand(INSERT_BOX_COLOR_COMMAND, {
            backgroundColor: color,
            padding: "6px"
        })
    }

    const onTextFormatClick = (format: FontEditorTypes) => () => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, format)
    }

    const onTextAlignClick = (format: AlignEditorTypes) => () => {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, format)
    }

    const onDirectionChange = (direction: "ltr" | "rtl") => () => {
        editor.dispatchCommand(CHANGE_PARAGRAPH_DIRECTION_COMMAND, direction)
    }

    const onCollapsibleToggle = () => () => {
        editor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined)
    }

    return (
        <div className="gap-3 flex-col h-full bg-white py-4 w-1/5 px-6">
            <div className="flex-col">
                <ToolbarSection title={t("heading")}>
                    {headingTypesList.map(({ type, icon }) => (
                        <ToolbarIcon
                            key={type}
                            isActive={blockType === type}
                            icon={icon}
                            onClick={formatHeading(type)}
                        />
                    ))}
                </ToolbarSection>
                <ToolbarSection title={t("text")}>
                    <ToolbarIcon
                        isActive={isBold}
                        icon="/icons/type-bold.svg"
                        onClick={onTextFormatClick(FontEditorTypes.Bold)}
                    />
                    <ToolbarIcon
                        isActive={isItalic}
                        icon="/icons/type-italic.svg"
                        onClick={onTextFormatClick(FontEditorTypes.Italic)}
                    />
                    <ToolbarIcon
                        isActive={isUnderline}
                        icon="/icons/type-underline.svg"
                        onClick={onTextFormatClick(FontEditorTypes.Underline)}
                    />
                </ToolbarSection>
                <ToolbarSection title={t("list")}>
                    <ToolbarIcon
                        isActive={blockType === ListEditorTypes.Ul}
                        icon="/icons/list-ul.svg"
                        onClick={formatBulletList}
                    />
                    <ToolbarIcon
                        isActive={blockType === ListEditorTypes.Ol}
                        icon="/icons/list-ol.svg"
                        onClick={formatNumberedList}
                    />
                </ToolbarSection>
                <ToolbarSection title={t("align")}>
                    <ToolbarIcon icon="/icons/text-left.svg" onClick={onTextAlignClick(AlignEditorTypes.Left)} />
                    <ToolbarIcon icon="/icons/text-center.svg" onClick={onTextAlignClick(AlignEditorTypes.Center)} />
                    <ToolbarIcon icon="/icons/text-right.svg" onClick={onTextAlignClick(AlignEditorTypes.Right)} />
                    <ToolbarIcon icon="/icons/justify.svg" onClick={onTextAlignClick(AlignEditorTypes.Justify)} />
                </ToolbarSection>
                <ToolbarSection title={t("arrangement")}>
                    <ToolbarIcon icon="/icons/ltr.svg" onClick={onDirectionChange("ltr")} />
                    <ToolbarIcon icon="/icons/rtl.svg" onClick={onDirectionChange("rtl")} />
                </ToolbarSection>

                <ToolbarSection title="Collapsible">
                    <ButtonV2
                        variant="light-gray"
                        icon={<PlusIcon />}
                        onClick={onCollapsibleToggle()}
                        text="Collapsible container"
                    />
                </ToolbarSection>
            </div>
            <ToolbarSection title={t("highlight_color")}>
                <ColorPickerButton
                    bgColor={toolbarState?.highlightColor}
                    onChange={(color) => {
                        onHighlightColorSelect(color, false)
                    }}
                />
            </ToolbarSection>

            <ToolbarSection title={t("box_color")}>
                <ColorPickerButton
                    bgColor={toolbarState?.boxColor}
                    onChange={(color) => {
                        onBoxColorSelect(color)
                    }}
                    onReset={() => {
                        editor.dispatchCommand(INSERT_BOX_COLOR_COMMAND, {
                            backgroundColor: "",
                            padding: "0px"
                        })
                    }}
                />
            </ToolbarSection>

            <ToolbarSection title={t("font_color")}>
                <ColorPickerButton
                    bgColor={toolbarState?.fontColor}
                    onChange={(color) => {
                        onFontColorSelect(color, false)
                    }}
                    onReset={() => {
                        applyStyleText({ color: "#OOO" }, false)
                    }}
                />
            </ToolbarSection>
            <UploadEducationalContentImage />
        </div>
    )
}
