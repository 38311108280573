import { parseDate } from "@internationalized/date"
import { Language } from "@prisma/client"
import { FlatEvent } from "@sequel-care/types"
import {
    addDays,
    addMonths,
    differenceInDays,
    format,
    formatDistanceToNow,
    getHours,
    getMinutes,
    isAfter,
    isBefore,
    setHours,
    setMinutes,
    subDays,
    subMilliseconds
} from "date-fns"
import { enUS, he } from "date-fns/locale"
import { i18n } from "i18next"
import { PatientDashboardRanges } from "types/Misc"
import { RangeObj } from "types/Redux"

export const locales = { he, en: enUS }
export const localeFormat = (date: Date | number, formatStr: string, i18n: i18n) =>
    format(date, formatStr, { locale: locales[i18n.language as Language] })

export const localeDistanceToNow = (date: Date | number, i18n: i18n) => {
    return formatDistanceToNow(date, { locale: locales[i18n.language as Language] })
}

export const getAppointmentEndTime = (event: FlatEvent<string, string>) => {
    const endTime = new Date(event.end_time)
    return setHours(setMinutes(new Date(event.date_on_timeline), getMinutes(endTime)), getHours(endTime))
}

export class DateString {
    public static validate(date: string) {
        if (/^([0-9]){4}-([0-9]){2}-([0-9]){2}/.test(date)) return true
        else throw new Error("Date string must be in ISO format")
    }
    public static from(date: Date | string) {
        if (date instanceof Date) {
            const addLeadingZero = (n: number) => (n.toString().length === 1 ? `0${n}` : n)
            return `${date.getFullYear()}-${addLeadingZero(date.getMonth() + 1)}-${addLeadingZero(date.getDate())}`
        }

        if (this.validate(date)) return date.slice(0, 10)
    }

    public static today() {
        return this.from(new Date())
    }

    public static isBefore(dateA: string, dateB: string) {
        if (this.validate(dateA) && this.validate(dateB)) return isBefore(getDateAtHour0(dateA), getDateAtHour0(dateB))
    }

    public static isAfter(dateA: string, dateB: string) {
        if (this.validate(dateA) && this.validate(dateB)) return isAfter(getDateAtHour0(dateA), getDateAtHour0(dateB))
    }

    public static isPastOrToday(date: Date) {
        const today = new Date()
        const inputDate = new Date(date)

        today.setHours(0, 0, 0, 0)
        inputDate.setHours(0, 0, 0, 0)
        return inputDate <= today
    }

    public static isInDateRange(startDate: Date, days: number) {
        const today = new Date()
        const start = new Date(startDate)
        today.setHours(0, 0, 0, 0)
        start.setHours(0, 0, 0, 0)

        const endDate = new Date(start)
        endDate.setDate(start.getDate() + days)

        return today >= start && today <= endDate
    }

    public static differenceInDays(dateA: string, dateB: string) {
        if (this.validate(dateA) && this.validate(dateB))
            return differenceInDays(getDateAtHour0(dateA), getDateAtHour0(dateB))
    }

    public static addMonths(date: string, monthsToAdd: number, returnAsDate: true): Date
    public static addMonths(date: string, monthsToAdd: number): string
    public static addMonths(date: string, monthsToAdd: number, returnAsDate = false) {
        if (this.validate(date)) {
            const withAddedMonths = addMonths(getDateAtHour0(date), monthsToAdd)
            return !returnAsDate ? this.from(withAddedMonths) : withAddedMonths
        }
    }

    public static addDays(date: string, daysToAdd: number, returnAsDate: true): Date
    public static addDays(date: string, daysToAdd: number): string
    public static addDays(date: string, daysToAdd: number, returnAsDate = false) {
        if (this.validate(date)) {
            const withAddedDays = addDays(getDateAtHour0(date), daysToAdd)
            return !returnAsDate ? this.from(withAddedDays) : withAddedDays
        }
    }

    public static getTime(date: Date | string) {
        if (date instanceof Date) {
            return date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0")
        } else if (this.validate(date)) {
            return (
                new Date(date).getHours().toString().padStart(2, "0") +
                ":" +
                new Date(date).getMinutes().toString().padStart(2, "0")
            )
        }
    }
}

export const getDateAtHour0 = (date: Date | string = new Date(), isUtc: boolean = false) => {
    return new Date(`${DateString.from(date)}T00:00:00.000${isUtc ? "Z" : ""}`)
}

export function createRangesObj<T>(initialValue: T) {
    return Object.fromEntries(
        Object.values(PatientDashboardRanges).map((range) => {
            return [range, initialValue]
        })
    ) as RangeObj<T>
}

export const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
export const weekDaysInNumber = [1, 2, 3, 4, 5, 6, 7]
export const shortenedDistanceToNow = (date: Date) => {
    const distanceToNow = formatDistanceToNow(date).split(" ")

    if (distanceToNow[0] === "less") return "1m"
    if (isNaN(parseInt(distanceToNow[0]))) distanceToNow.shift()

    return distanceToNow[0][0] + (!distanceToNow[1].includes("month") ? distanceToNow[1][0] : "mo")
}

export const createFetchRange = (
    callback: ({ dayStart, dayEnd, now }: { dayStart: Date; dayEnd: Date; now: Date }) => { start: Date; end?: Date },
    dateString?: string
) => {
    const now = dateString ? new Date(dateString) : new Date()
    return callback({ now, dayStart: getDateAtHour0(now), dayEnd: subMilliseconds(addDays(getDateAtHour0(), 1), 1) })
}

export const createTimelineRange = (date: string) => {
    return createFetchRange(({ dayEnd, dayStart }) => {
        const dateAtHour0 = getDateAtHour0(date)
        const difference = differenceInDays(dayStart, dateAtHour0)

        return { start: subDays(dayStart, 7 + difference), end: addDays(dayEnd, 7 - difference) }
    })
}

export const dateToCalendarDate = (date: Date | string) => parseDate(DateString.from(date))

export const getClientTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

const weekDaysFromMonday = {
    1: "mon",
    2: "tue",
    3: "wed",
    4: "thu",
    5: "fri",
    6: "sat",
    7: "sun"
} as const

const weekDaysFromSunday = {
    1: "sun",
    2: "mon",
    3: "tue",
    4: "wed",
    5: "thu",
    6: "fri",
    7: "sat"
}

export const getWeekDayByDayNumber = (weekDayNumber: number, weekStart: "mon" | "sun") => {
    const dayNumber = weekDayNumber as keyof typeof weekDaysFromMonday | keyof typeof weekDaysFromSunday

    if (weekStart === "mon") {
        return weekDaysFromMonday[dayNumber]
    }

    return weekDaysFromSunday[dayNumber]
}
