import { responseComponents } from "components/ContentLibrary/Details/QuestionnaireDetails"
import { QuestionGrid, QuestionGridContext, RenderScaleGroupFn } from "components/Questionnaires/QuestionGrid"
import { useQuestionsGroupedByScale } from "components/Questionnaires/QuestionGrid/useQuestionsGroupedByScale"
import React, { FC } from "react"
import { useAllQuestionnaires } from "store/hooks"

interface IUnitElementPreviewQuestionnaireProps {
    id: number
    withoutTitle?: boolean
}

const PreviewQuestionnaire: FC<IUnitElementPreviewQuestionnaireProps> = ({ id, withoutTitle }) => {
    const questionnaires = useAllQuestionnaires()
    const selectedQuestionnaire = questionnaires.find((questionnaire) => questionnaire.id === id)
    console.log(id, questionnaires, selectedQuestionnaire)
    const questions = useQuestionsGroupedByScale(selectedQuestionnaire)

    return (
        <div className="p-4 mt-3">
            {!withoutTitle && <p className="text-med-black pb-6 text-xl font-bold">{selectedQuestionnaire.title}</p>}
            <div className="px-2 pb-6">
                <QuestionGridContext.Provider value={{ questionnaire: selectedQuestionnaire }}>
                    <QuestionGrid
                        items={questions}
                        responseComponents={responseComponents}
                        RenderScaleGroup={RenderScaleGroup}
                        gridTemplateColumns="grid-cols-[2fr_3fr]"
                    />
                </QuestionGridContext.Provider>
            </div>
        </div>
    )
}

export default PreviewQuestionnaire

export const RenderScaleGroup: RenderScaleGroupFn = ({ items }) => {
    return (
        <div className="flex flex-col gap-y-5 text-secondary-2">
            {items.map((item) => (
                <div key={item.id}>{item.question}</div>
            ))}
        </div>
    )
}
