import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2, RadioCircleGroupV2 } from "components/v2"
import { classNames } from "utils"
import { useTranslation } from "react-i18next"

const ScaleQuestionnaireResponse = ({
    question,
    screeningAnswer,
    sybQuestion = false,
    isLastQuestion = false
}: {
    question: any
    screeningAnswer: ScreeningAnswer
    sybQuestion?: boolean
    isLastQuestion?: boolean
}) => {
    const { i18n } = useTranslation()
    const options = question.scale.labels[0] || new Array(question.scale.max - question.scale.min + 1).fill("")
    return (
        <div
            className={classNames(
                "grid py-4",
                sybQuestion ? "border-b" : "border rounded-lg",
                isLastQuestion && "border-none"
            )}
            style={{ 
                gridTemplateColumns: `minmax(0, 1fr) repeat(${options.length}, 60px)`,
                direction: i18n.language === "he" ? "rtl" : "ltr"
            }}
        >
            <div className={classNames("pr-4", i18n.language === "he" ? "" : "pl-4 pr-0")}>
                <TypographyV2 type="sm" className="text-[#6B7280]">
                    {question.question}
                </TypographyV2>
            </div>
            {options.map((option: string, index: number) => {
                const effectiveIndex = String(index + question.scale.min); 
                return <div key={index} className="flex justify-center">
                    <div className="relative w-4 h-4 flex-shrink-0">
                        <div
                            className={`w-4 h-4 rounded-full border transition-all duration-200 ${
                                screeningAnswer?.value === effectiveIndex
                                    ? "border-gray-300"
                                    : "border-gray-300 hover:border-gray-400"
                            }`}
                        />
                        {screeningAnswer?.value === effectiveIndex && (
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2 h-2 rounded-full bg-med-blue" />
                        )}
                    </div>
                </div>
})}
        </div>
    )
}

export default ScaleQuestionnaireResponse
