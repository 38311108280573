import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { ExerciseQuestionResponse } from "types/Redux"
import cardBuilder from "./util/previewExerciseCardBuilder"

export const useExercisePreview = (questionList: ExerciseQuestionResponse[]) => {
    const questionData = useMemo(() => {
        return questionList.map((question) => {
            if (question.type === "select") {
                return {
                    ...question,
                    definition: {
                        options:
                            (question.definition as { options?: string[] })?.options?.map((option) => ({
                                value: option,
                                label: option
                            })) ?? []
                    }
                }
            }
            if (question.type === "scale") {
                return {
                    ...question,
                    scale: question.definition,
                    type: "scale_slider"
                }
            }
            return question
        })
    }, [questionList])

    const { control } = useForm<any>({})

    return cardBuilder(questionData, control)
}
