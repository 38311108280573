import * as types from "./types"
import { EventTypes } from "types"
import { AppAction } from "types/Redux"
import { deletePatientEvent, getCarePlanBYPatientId } from "api"
import { CarePlanEventResponse, PatientCareProtocolResponse } from "forms/types"
import {
    assignCareProtocolToPatient,
    deleteSequentialEvent,
    getPatientCareProtocol,
    unlockManualSequentialEventFromProtocol
} from "api/careProtocol/cpElements"
import { toast } from "react-toastify"
import i18n from "../../locales"
import { pushToLibraryStack } from "../patient/actions"
import { getEventDbType, getEventType } from "../../components/CareplanSection/constants"

export const openAssignEventModalAction = (payload: EventTypes) => {
    return { type: types.OPEN_ASSIGN_EVENT_MODAL, payload }
}

export const closeAssignEventModalAction = () => {
    // @ts-ignore
    return { type: types.CLOSE_ASSIGN_EVENT_MODAL, payload: null }
}

export const setEventsListAction = (list: CarePlanEventResponse[]) => {
    return { type: types.SET_CARE_PLAN_EVENTS_LIST, payload: list }
}

export const setPatientActiveCareProtocol = (payload: PatientCareProtocolResponse) => {
    return { type: types.SET_PATIENT_ACTIVE_CARE_PROTOCOL, payload }
}

export const fetchCarePlanEventsListAction = (): AppAction<boolean> => async (dispatch, getState) => {
    try {
        const { currentPatient } = getState().patient
        const response = await getCarePlanBYPatientId(currentPatient.id)
        dispatch(setEventsListAction(response))

        return true
    } catch (err) {}
}

export const fetchPatientActiveCarePlanAction = (): AppAction<boolean> => async (dispatch, getState) => {
    try {
        const { currentPatient } = getState().patient
        const activeProtocol = await getPatientCareProtocol(currentPatient.id)

        if (activeProtocol) {
            dispatch(setPatientActiveCareProtocol(activeProtocol))
        }

        return true
    } catch (err) {}
}

export const deleteSequentialEventAction =
    (id: number): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            const { currentPatient } = getState().patient
            await deleteSequentialEvent(id, currentPatient.id)

            dispatch(fetchCarePlanEventsListAction())
            dispatch(fetchPatientActiveCarePlanAction())
            return true
        } catch (err) {}
    }

export const assignCareProtocolAction =
    (patientId: number, protocolId: number): AppAction<any> =>
    async (dispatch, getState) => {
        try {
            await assignCareProtocolToPatient(patientId, { protocolId })
            dispatch(fetchCarePlanEventsListAction())
            dispatch(fetchPatientActiveCarePlanAction())
            dispatch(closeAssignEventModalAction())

            return true
        } catch (err) {}
    }

export const unlockManualSequentialEventAction =
    (eventId: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await unlockManualSequentialEventFromProtocol(eventId)
            dispatch(fetchCarePlanEventsListAction())
            dispatch(fetchPatientActiveCarePlanAction())

            return true
        } catch (err) {}
    }

export const deleteCarePlanPatientEventAction =
    (eventId: number, patientId: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await deletePatientEvent(patientId, eventId)
            toast.success(i18n.t("notifications.successfullyUpdated", { ns: "careProtocol" }))
            dispatch(fetchCarePlanEventsListAction())
            dispatch(fetchPatientActiveCarePlanAction())
            return true
        } catch (err) {}
    }

export const editEventCarePlanAction =
    (event: any): AppAction<any> =>
    async (dispatch) => {
        try {
            dispatch(
                pushToLibraryStack({
                    view: event.recurrence ? "select_policy" : "config",
                    eventTemplates: { ...event, type: getEventDbType(event.eventType) },
                    configProps: {
                        mode: "edit"
                    },
                    patientId: event?.patient_id
                })
            )
            return true
        } catch (err) {}
    }
