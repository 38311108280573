import React from "react"
import { ListV2, ScheduleItemCard, TypographyV2 } from "components/v2"
import { EventTypes } from "types"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { CareProtocolElement, ElementType, TriggerType } from "@prisma/client"
import _ from "lodash"
import i18n from "locales"
import { CollapsableMenu, PreviewProtocolSequentialElement } from "./components"
import { useTranslation } from "react-i18next"

interface IPreviewCareProtocolProps {
    cpTemplateElements: CareProtocolElement[]
}

const PreviewCareProtocol: React.FC<IPreviewCareProtocolProps> = ({ cpTemplateElements }) => {
    const { t } = useTranslation("careProtocol")

    const mapScheduledElement = (element: any) => {
        const elementTitle: Record<ElementType, string> = {
            [ElementType.UNIT]: element.unit_element?.title ?? "",
            [ElementType.EXERCISE]: element.exercise_element?.title ?? "",
            [ElementType.QUESTIONNAIRE]: element.questionnaire_element?.title ?? ""
        }

        return {
            ...element,
            id: element.id,
            title: elementTitle[element.element_type as keyof typeof elementTitle],
            time: element.recurrence_interval
                ? i18n.t("withRecurrence", { ns: "careProtocol", recurrence: element.recurrence_interval })
                : i18n.t("oneTime", { ns: "careProtocol" }),
            when: element.delay
                ? i18n.t("withDelay", { ns: "careProtocol", delay: element.delay })
                : i18n.t("fromStart", { ns: "careProtocol" }),
            eventType: _.capitalize(element.element_type) as EventTypes
        }
    }

    const mapSequentialElement = (element: any) => {
        const elementTitle: Record<ElementType, string> = {
            [ElementType.UNIT]: element.unit_element?.title ?? "",
            [ElementType.EXERCISE]: element.exercise_element?.title ?? "",
            [ElementType.QUESTIONNAIRE]: element.questionnaire_element?.title ?? ""
        }

        return {
            ...element,
            id: element.id,
            title: elementTitle[element.element_type as keyof typeof elementTitle],
            time: element.recurrence_interval
                ? i18n.t("withRecurrence", { ns: "careProtocol", recurrence: element.recurrence_interval })
                : i18n.t("oneTime", { ns: "careProtocol" }),
            when: element.delay
                ? i18n.t("withDelay", { ns: "careProtocol", delay: element.delay })
                : i18n.t("fromStart", { ns: "careProtocol" }),
            eventType: _.capitalize(element.element_type) as EventTypes,
            delay: element.delay,
            trigger_type: element.trigger_type
        }
    }

    const scheduleElements = cpTemplateElements
        .filter((element) => element.trigger_type === TriggerType.SCHEDULED)
        .map(mapScheduledElement)

    const sequentialElements = cpTemplateElements
        .filter(
            (element) =>
                element.trigger_type === TriggerType.SEQUENTIAL_MANUAL ||
                element.trigger_type === TriggerType.SEQUENTIAL_TRIGGERED
        )
        .map(mapSequentialElement)

    return (
        <div className="p-4">
            <div className="w-full p-1">
                <CollapsableMenu title={t("sequential")}>
                    <div className="pb-4">
                        <ListV2
                            renderItem={PreviewProtocolSequentialElement}
                            {...{
                                list: sequentialElements,
                                variant: "light-gray",
                                isEditOptionEnabled: "true",
                                listItemClassName: "!w-full",
                                isVerticalView: true
                            }}
                        />
                    </div>
                </CollapsableMenu>
                <CollapsableMenu title={t("scheduled")}>
                    <div className="grid grid-cols-2 gap-2">
                        {scheduleElements?.map((item, idx) => (
                            <ScheduleItemCard
                                {...{
                                    ...item,
                                    list: scheduleElements,
                                    variant: "light-gray",
                                    isEditOptionEnabled: "true",
                                    listItemClassName: "!w-full !sm:w-auto"
                                }}
                            />
                        ))}
                    </div>
                </CollapsableMenu>
            </div>
        </div>
    )
}

export default PreviewCareProtocol
