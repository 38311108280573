import { CompleteExercise } from "@sequel-care/types"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "store"
import { useAllExercises } from "store/hooks"
import { pushToLibraryStack } from "store/patient/actions"
import { ContentLibraryData } from "types/Redux"
import { RenderLibrary } from "./CardsView"
import { eventToTemplate } from "./utils"
import { getAllLibraryTemplates } from "../../api/LibraryTemplate"
import { LibraryTemplateType } from "@prisma/client"

const EducationalContentLibrary = ({ searchInitialValue }: { searchInitialValue?: string }) => {
    const dispatch = useDispatch()
    const [allEducationalContent, setAllEducationalContent] = useState([])
    const [search, setSearch] = useState(searchInitialValue)

    const { onAdd, onLearnMore } = useMemo(() => {
        const goToView = (view: ContentLibraryData["view"]) => (exercise: CompleteExercise) =>
            dispatch(
                pushToLibraryStack({
                    eventTemplates: eventToTemplate("educational_content", exercise),
                    view: view,
                    patientId: "preserve"
                })
            )

        return { onAdd: goToView("config"), onLearnMore: goToView("learn_more") }
    }, [search])

    useEffect(() => {
        const fetchRequest = async () => {
            const list = await getAllLibraryTemplates(LibraryTemplateType.EDUCATIONAL_CONTENT)
            setAllEducationalContent(list)
        }
        fetchRequest()
    }, [])

    return (
        <RenderLibrary
            type="educational_content"
            allItems={allEducationalContent}
            cardItems={allEducationalContent}
            gridColumns="grid-cols-1"
            {...{ onAdd, onLearnMore, search, setSearch }}
        />
    )
}

export default EducationalContentLibrary
