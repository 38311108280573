import { TagWithIcon, TypographyV2 } from "../v2"
import { classNames } from "../../utils"
import { getEventDbType, getEventType, getEventTypeName } from "../CareplanSection/constants"
import { useIsHeLanguage } from "../../hooks"
import { EventTypes } from "../../types"
import { EventType } from "@prisma/client"

const EventTypeGrayLabel = ({ type }: { type: EventTypes }) => {
    const { isHeLanguage } = useIsHeLanguage()

    const eventType: EventType | "care_protocol" = getEventDbType(type)

    return (
        <div className="bg-base-lighter px-[6px] py-[2px] rounded-sm flex items-center w-max">
            <TagWithIcon variant="light-gray" className="!w-[12px] !h-[12px] !pt-0" eventType={type} />
            <TypographyV2
                type="xm"
                width="medium"
                className={classNames("text-gray-500", isHeLanguage ? "mr-1" : "ml-1")}
            >
                {getEventTypeName(eventType)}
            </TypographyV2>
        </div>
    )
}

export default EventTypeGrayLabel
