import { EventType } from "@prisma/client"
import { FormControlProps } from "forms"
import Input from "forms/Input"
import SuperSelect from "forms/SuperSelect"
import { useSearchForItem } from "forms/SuperSelect/utils"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAllExercises, useAllAvailableQuestionnaires } from "store/hooks"
import { classNames, eventTypeIconMap } from "utils"
import { ReccurrenceField } from "../types"

type SelectorTypes = "questionnaire" | "exercise" | "task" | "educational_content"

export const EventSelector = ({
    types,
    value,
    setValue,
    classList,
    setState,
    placeholder,
    readOnly,
    getAdjacentFieldValue,
    ...props
}: FormControlProps<string> & { types: SelectorTypes[] }) => {
    const { t } = useTranslation("patient")
    const childrenByType: Record<
        "questionnaire" | "exercise",
        { id: number; recommended_recurrence: number; title: string }[]
    > = { questionnaire: useAllAvailableQuestionnaires(), exercise: useAllExercises() }

    const prepareItem = (type: SelectorTypes) => {
        return {
            label: t(`eventTypes.${type}`),
            id: type,
            recommended_recurrence: 1,
            children:
                type !== "task" && type !== "educational_content"
                    ? childrenByType?.[type].map(({ id, title, recommended_recurrence }) => ({
                          id: `${type}_${id}`,
                          label: title,
                          recommended_recurrence
                      }))
                    : undefined
        }
    }
    const items = useMemo(
        () => (types.length === 1 ? prepareItem(types[0]).children ?? [] : types.map(prepareItem)),
        [childrenByType, types]
    )
    const searchForItem = useSearchForItem(items)

    const type = getAdjacentFieldValue("type") as EventType
    const recurrence = getAdjacentFieldValue("recurrence") as ReccurrenceField
    const Icon = value && eventTypeIconMap[type]
    return !value ? (
        <SuperSelect
            {...props}
            value={value}
            disabled={readOnly}
            setValue={(value: string) => {
                const [type, id] = value.split("_")
                const item = searchForItem(value)

                const unit = item.recommended_recurrence % 7 === 0 ? "weeks" : "days"
                setState((event) => ({
                    ...event,
                    type,
                    [`${type}_id`]: parseInt(id),
                    title: type !== "task" ? item?.label : "",
                    recurrence: {
                        ...recurrence,
                        interval: unit === "days" ? item.recommended_recurrence : item.recommended_recurrence / 7,
                        unit
                    }
                }))
            }}
            placeholder={placeholder}
            classList={classList}
            items={items}
            style="classic"
            menuOffset={0}
        />
    ) : (
        <div className={classNames(classList.wrapper, "flex items-center")}>
            <Icon className="w-5 h-5" />
            <Input
                {...props}
                autoFocus={true}
                placeholder={t("common:untitled", { type: t("common:eventType.task") })}
                readOnly={readOnly || type !== "task"}
                classList={classList}
                setValue={(title) => setState((state) => ({ ...state, title }))}
                value={getAdjacentFieldValue("title")}
                onClear={() => setState((state) => ({ ...state, type: undefined }))}
            />
        </div>
    )
}
