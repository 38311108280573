import { IOptionV2 } from "components/v2/components/common"
import { FieldErrors } from "react-hook-form"
import { TFunction } from "i18next"
import { Control } from "react-hook-form"
import { TypographyV2 } from "components/v2/components/common"
import { ControlledSelectV2 } from "components/v2/components/common/form-controls"
import i18n from "locales"

export const getStepsList = () => [
    i18n.t("select", { ns: "careProtocol" }),
    i18n.t("preview", { ns: "careProtocol" }),
    i18n.t("configure", { ns: "careProtocol" })
]

export const NUMBER_OF_REPEATS_OPTIONS = (t: TFunction) =>
    new Array(31).fill(0).map((_, index) => ({
        id: index.toString(),
        title: index === 0 ? t("consts.none") : index.toString()
    }))

export const REPEATS_OPTIONS = (t: TFunction) => [
    { id: "0", title: t("consts.never"), subtitle: t("consts.repeatsNever") },
    { id: "1", title: t("consts.daily"), subtitle: t("consts.repeatsDaily") },
    { id: "7", title: t("consts.weekly"), subtitle: t("consts.repeatsWeekly") },
    { id: "30", title: t("consts.monthly"), subtitle: t("consts.repeatsMonthly") }
]

export const TIME_OPTIONS = (t: TFunction) => [
    { id: "1", title: t("consts.days") },
    { id: "7", title: t("consts.weeks") },
    { id: "30", title: t("consts.months") }
]

// export const UNIT_TRIGGER_TYPE_OPTIONS = (t: TFunction, control: Control, errors: FieldErrors) => [
//     {
//         value: "fullCompletion",
//         labelComponent: (
//             <div className="flex flex-col items-start gap-y-1 w-[324px]">
//                 <TypographyV2 type="sm" width="medium" className="text-[#111827] whitespace-nowrap">
//                     {t("consts.fullCompletionTtle")}
//                 </TypographyV2>
//                 <TypographyV2 type="xs" width="normal" className="text-[#111827] whitespace-nowrap">
//                     {t("consts.fullCompletionSubtitle")}
//                 </TypographyV2>
//             </div>
//         )
//     },
//     {
//         value: "custom",
//         labelComponent: (
//             <div className="flex flex-row items-center gap-x-[10px] w-[324px]">
//                 <TypographyV2 type="sm" width="medium" className="text-[#111827] whitespace-nowrap">
//                     {t("consts.custom")}
//                 </TypographyV2>
//                 <ControlledSelectV2
//                     control={control}
//                     name="numberOfDelay"
//                     options={NUMBER_OF_REPEATS_OPTIONS(t)}
//                     // error={errors.numberOfDelay?.message}
//                     classList={{ wrapper: "w-[244px]" }}
//                     placeholder={t("consts.selectEvent")}
//                 />
//             </div>
//         )
//     }
// ]


export const AVAILABILITY_OPTIONS = (t: TFunction, control: Control, errors: FieldErrors) => [
    {
        value: "none",
        labelComponent: t("consts.none")
    },
    {
        value: "by",
        labelComponent: (
            <div className="flex flex-row items-center gap-x-1 w-[324px]">
                <TypographyV2 type="sm" width="medium" className="text-[#111827] whitespace-nowrap mr-[20px]">
                    {t("consts.delayBy")}
                </TypographyV2>
                <ControlledSelectV2
                    control={control}
                    name="numberOfDelay"
                    options={NUMBER_OF_REPEATS_OPTIONS(t)}
                    // error={errors.numberOfDelay?.message}
                    classList={{ wrapper: "w-[109px]" }}
                    placeholder={t("0")}
                />
                <ControlledSelectV2
                    control={control}
                    name="delayType"
                    options={REPEATS_OPTIONS(t)}
                    // error={errors.delayType?.message}
                    classList={{ wrapper: "w-[140px]" }}
                    placeholder={t("consts.days")}
                />
            </div>
        )
    }
]
