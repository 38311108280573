import { useState, useEffect } from "react"

export const useScreenSize = () => {
    const [isDesktop, setIsDesktop] = useState(true)
    const [hasMounted, setHasMounted] = useState(false)

    useEffect(() => {
        setHasMounted(true)
        const checkScreenSize = () => {
            setIsDesktop(window.innerWidth >= 768)
        }

        checkScreenSize()
        window.addEventListener("resize", checkScreenSize)

        return () => window.removeEventListener("resize", checkScreenSize)
    }, [])

    // Return true during SSR, and actual value after mounting
    return hasMounted ? isDesktop : true
}
