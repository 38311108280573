import React, { useEffect } from "react"
import { LibraryElementBuilder, QuestionAddVariableModal } from "components/v2"
import { useDispatch } from "store"
import { clearQuestionsList } from "store/questions/actions"
import { LibraryTemplateForm } from "./components"
import { useLibraryTemplateSelector } from "store/hooks"
import { LibraryTemplateType } from "@prisma/client"
import CareProtocolAddNewElementModal from "../../careProtocol/careProtocolElement/CareProtocolAddElementModal/CareProtocolAddNewElementModal"
import { SelectChapterElementModal } from "../../unit/SelectChapterElementModal"

const LibraryTemplateBuilderComponent = () => {
    // we need to fetch the LibraryTemplate by the path (exercise | unit | educational-content...). It will return the info only. We should also fetch the unique content (for exercises, fetch the quesitons). For EducationalContent, fetch the articleContent. For the Unit, fetch...
    // Keep the uniqu content in the store so that when we move along the tabs, we don't reload the basic-info nor the unique content.

    const dispatch = useDispatch()
    const { isBuilderOpen, libraryTemplate, type } = useLibraryTemplateSelector()

    useEffect(() => {
        return () => {
            dispatch(clearQuestionsList())
        }
    }, [])

    if (!isBuilderOpen) return null

    return (
        <div>
            {type === LibraryTemplateType.EXERCISE && (
                <>
                    <QuestionAddVariableModal />
                    <LibraryElementBuilder
                        basicInfoFormId={"create-exercise-form"}
                        basicInfoForm={<LibraryTemplateForm formId="create-exercise-form" />}
                    />
                </>
            )}
            {type === LibraryTemplateType.EDUCATIONAL_CONTENT && (
                <LibraryElementBuilder
                    basicInfoFormId={"create-educational-content-form"}
                    basicInfoForm={<LibraryTemplateForm formId="create-educational-content-form" />}
                />
            )}
            {type === LibraryTemplateType.CARE_PROTOCOL && (
                <>
                    <CareProtocolAddNewElementModal />
                    <LibraryElementBuilder
                        basicInfoFormId={"create-care-protocol-form"}
                        basicInfoForm={<LibraryTemplateForm formId="create-care-protocol-form" />}
                    />
                </>
            )}
            {type === LibraryTemplateType.UNIT && (
                <>
                    <SelectChapterElementModal />
                    <LibraryElementBuilder
                        basicInfoFormId={"create-unit-form"}
                        basicInfoForm={<LibraryTemplateForm formId="create-unit-form" />}
                    />
                </>
            )}
        </div>
    )
}

export default LibraryTemplateBuilderComponent
