import React from "react"
import { SearchInputV2, SelectV2 } from "components/v2/index"
import { useLibraryTemplateFilters } from "./useLibraryTemplateFilters"
import { LibraryTemplateType } from "@prisma/client"

const LibraryTemplateFilters = ({ libraryTemplateType }: { libraryTemplateType: LibraryTemplateType }) => {
    const {
        t,
        creator,
        searchText,
        setSearchText,
        status,
        diagnose,
        diagnoseOptions,
        creatorOptions,
        mappedStatusSelectWithLocalization,
        onChangeSearchParams,
        onSelectChange
    } = useLibraryTemplateFilters(libraryTemplateType)

    return (
        <div className="flex items-center justify-between mb-2.5">
            <div className="self-stretch w-2/6">
                <SearchInputV2
                    isClearEnabled
                    className="text-sm"
                    iconClassName="w-5"
                    wrapperClassname="h-full"
                    searchText={searchText}
                    onChange={(value) => {
                        setSearchText(value)
                        onChangeSearchParams("text", value)
                    }}
                />
            </div>
            <div className="flex gap-2.5">
                <div className="w-80">
                    <SelectV2
                        mode="single"
                        isClearIconVisible
                        placeholder={t("diagnoses")}
                        options={diagnoseOptions}
                        selectedKeys={[diagnose]}
                        onChange={onSelectChange("diagnose")}
                    />
                </div>
                <div className="w-36">
                    <SelectV2
                        placeholder={t("common:status")}
                        isClearIconVisible
                        options={mappedStatusSelectWithLocalization}
                        mode="single"
                        selectedKeys={[status]}
                        onChange={onSelectChange("status")}
                    />
                </div>
                <div className="w-36">
                    <SelectV2
                        placeholder={t("creator")}
                        isClearIconVisible
                        options={creatorOptions}
                        mode="single"
                        selectedKeys={[creator]}
                        onChange={onSelectChange("creator")}
                    />
                </div>
            </div>
        </div>
    )
}

export default LibraryTemplateFilters
