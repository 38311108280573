import UserAvatar from "components/UserAvatar"
import { DropdownMenuV2, TypographyV2 } from "components/v2"
import Link from "next/link"
import React, { useMemo } from "react"
import { useCurrentRole, useCurrentUser } from "store/hooks"
import NotificationDrawer from "./NotificationDrawer"
import Settings from "components/icons/Settings"
import { useRouter } from "next/router"
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useAuthContext } from "utils/auth/AuthProvider"

const AppHeader = () => {
    const currentRole = useCurrentRole()
    const currentUser = useCurrentUser()
    const { signOut } = useAuthContext()
    const dispatch = useDispatch()

    const { t } = useTranslation("user")

    const { push, replace } = useRouter()

    const dropDownActionsList = useMemo(
        () => [
            {
                title: t("userSettings.pageTitle"),
                icon: Settings,
                onClick: () => {
                    push("/settings/user")
                }
            },
            {
                border: true,
                title: t("auth.logout"),
                icon: ArrowLeftOnRectangleIcon,
                onClick: () => {
                    signOut()
                    replace("/")
                    dispatch({ type: "store/RESET" })
                }
            }
        ],
        []
    )

    return (
        <div className="bg-bg p-4 flex justify-between">
            <div className="flex gap-4 items-center">
                <div className="h-9 flex items-center">
                    <Link href={"/"} passHref>
                        <img
                            src="/logo/1x.png"
                            srcSet="/logo/2x.png 2x, /logo/3x.png 3x"
                            className="h-6 w-auto sm:h-8 cursor-pointer"
                            alt="SequelCare Logo"
                        />
                    </Link>
                </div>
                <TypographyV2 type="sm" width="semibold" className="text-[#374151]">
                    {currentRole?.organization.name}
                </TypographyV2>
            </div>
            <div className="flex flex-row gap-2.5 items-center">
                <NotificationDrawer />
                <DropdownMenuV2 menuItems={dropDownActionsList} clickComponent={<UserAvatar user={currentUser} />} />
            </div>
        </div>
    )
}

export default AppHeader
