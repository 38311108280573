import { addNewQuestionAction } from "store/questions/actions"
import { ExerciseQuestionType, Language } from "@prisma/client"
import { useDispatch } from "store"
import { ICreateQuestionPopoverProps } from "./createQuestionPopoverTypes"
import { useMemo } from "react"
import { questionTypesList } from "components/v2/constants"
import { useTranslation } from "react-i18next"
import { useCurrentLanguage, useLibraryTemplateSelector } from "store/hooks"

export const useCreateExerciseQuestionPopover = ({ onClose }: ICreateQuestionPopoverProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("question")
    const language = useCurrentLanguage()
    const isHeLanguage = language === Language.he
    const { libraryTemplate } = useLibraryTemplateSelector()

    const questionTypesWithLocale = useMemo(
        () => questionTypesList.map(({ title, ...rest }) => ({ ...rest, title: t(`questionTypes.${title}`) })),
        []
    )
    const onAddEventClick = (type: ExerciseQuestionType) => () => {
        dispatch(
            addNewQuestionAction({
                library_template_id: libraryTemplate.id,
                question: "New question",
                type,
                is_required: true
            })
        )
        onClose()
    }
    return { isHeLanguage, questionTypesWithLocale, onAddEventClick }
}
