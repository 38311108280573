import cx from "clsx"
import { ReactElement } from "react"
import { classNames } from "utils"

export interface IRadioCircleV2Props {
    disabled?: boolean
    labelComponent: string | ReactElement
    name?: string
    onChange: (value: string) => void
    value?: string
    radioCircleWrapperStyle?: string
    isChecked?: boolean
    readOnly?: boolean
}

const RadioCircleV2 = ({
    radioCircleWrapperStyle,
    disabled,
    labelComponent,
    name,
    onChange,
    value,
    isChecked,
    readOnly = false
}: IRadioCircleV2Props) => (
    <div
        onClick={() => !disabled && onChange(value)}
        className={classNames("flex gap-2 items-center py-2.5 px-3", radioCircleWrapperStyle)}
    >
        <div className="grid place-items-center">
            <input
                type="radio"
                name={name}
                checked={isChecked}
                // defaultChecked={isChecked}
                readOnly={readOnly}
                disabled={disabled}
                className={classNames(
                    "peer bg-white col-start-1 row-start-1 appearance-none shrink-0 w-4 h-4 border border-border-blue rounded-full focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:border-gray-400 checked:bg-white shadow-[0px 1px 2px 0px rgba(0, 0, 0, 0.07)] focus:bg-white hover:bg-white checked:focus:bg-white checked:hover:bg-white checked:border-border-blue disabled:border-border-blue"
                )}
                value={value}
            />
            <div
                className={cx(
                    "pointer-events-none col-start-1 row-start-1 w-2 h-2 rounded-full",
                    disabled ? "peer-checked:bg-border-blue" : "peer-checked:bg-med-blue",
                    isChecked ? "bg-med-blue" : ""
                )}
            />
        </div>
        <div
            className={cx("text-start hover:cursor-pointer", {
                "text-gray-400": disabled
            })}
        >
            {labelComponent}
        </div>
    </div>
)

export default RadioCircleV2
