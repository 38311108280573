import { FC, ReactElement, ReactNode, SVGProps, useState } from "react"
import Link from "next/link"
import { classNames } from "utils"
import { useTranslation } from "react-i18next"
import SidebarList from "./SidebarList"
import { useRouter } from "next/router"
import { ChevronRightIcon } from "@heroicons/react/20/solid"
import { matchPatternWithURL } from "./appSidebarConst"

export type MenuItem = {
    name: string
    href: string
    Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
    testId?: string
    activeLinks?: string[]
    expandedComponent?: ReactElement
    hasExpandableSection?: boolean
}

export const MenuTitle: FC<{ className?: string; children?: ReactNode }> = ({ children, className }) => {
    return <h3 className={classNames("px-6 py-2 text-secondary font-semibold text-sm", className)}>{children}</h3>
}

type RenderMenuItemsProps = { items: MenuItem[]; beforeItems?: ReactNode; title?: string }
export const RenderMenuItems = ({ items, beforeItems, title }: RenderMenuItemsProps) => {
    const { t } = useTranslation("common")

    const { asPath: pathname } = useRouter()

    const children = items.map(({ name, href, Icon, testId, activeLinks, expandedComponent, hasExpandableSection }) => {
        const isActiveLink = activeLinks
            ? activeLinks.some((link) => matchPatternWithURL(link, pathname))
            : pathname === href

        if (expandedComponent && hasExpandableSection) {
            return (
                <ExpandableSection
                    key={href}
                    name={name}
                    href={href}
                    Icon={Icon}
                    testId={testId}
                    activeLinks={activeLinks}
                    expandedComponent={expandedComponent}
                />
            )
        }
        return (
            <Link key={href} href={href} passHref legacyBehavior>
                <a
                    className={`flex items-center gap-x-2 text-sm font-semibold cursor-pointer rounded-lg py-2 px-4 ${
                        isActiveLink ? "bg-white text-med-blue" : "text-[#6B7280]"
                    }`}
                    data-testid={testId}
                >
                    <Icon className={`text-xl ${isActiveLink ? "[&>*]:stroke-med-blue" : ""}`} />
                    <div>{name}</div>
                </a>
            </Link>
        )
    })

    const titleText = t(`sidebar.titles.${title}`)
    return title ? (
        <SidebarList id={title} title={titleText}>
            {children}
        </SidebarList>
    ) : (
        <div className="flex flex-col pb-7">
            {title && <MenuTitle>{titleText}</MenuTitle>}
            {beforeItems}
            {children}
        </div>
    )
}

const ExpandableSection = ({ name, href, Icon, testId, expandedComponent }: MenuItem) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            <div className="flex items-center justify-between">
                <Link key={href} href={href} passHref legacyBehavior>
                    <a
                        className="flex items-center gap-x-2 text-sm font-semibold cursor-pointer rounded-lg py-2 px-4 text-[#6B7280]"
                        data-testid={testId}
                    >
                        <Icon className="text-xl" />
                        <div>{name}</div>
                    </a>
                </Link>
                <ChevronRightIcon
                    className={classNames("ml-auto transition-all duration-200 cursor-pointer", isOpen && "rotate-90")}
                    height={20}
                    width={20}
                    onClick={() => setIsOpen(!isOpen)}
                />
            </div>
            <div
                className={`grid transition-all duration-500 ${
                    isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
                } overflow-hidden`}
            >
                {expandedComponent}
            </div>
        </div>
    )
}
