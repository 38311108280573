import { useDispatch } from "store"
import React, { useMemo, useRef } from "react"
import { Resolver, useForm, useWatch } from "react-hook-form"
import { useDebouncedEffect } from "hooks/useDebouncedEffect"
import { deleteVariableLinkageAction, openAddVariableModalAction, updateQuestionAction } from "store/questions/actions"
import { QuestionFormValues } from "types"
import { questionTypesList } from "components/v2/constants"
import { useTranslation } from "react-i18next"
import { yupResolver } from "@hookform/resolvers/yup"
import { getQuestionSchemaValidation } from "./questionFormConstants"
import { ExerciseQuestionResponse } from "types/Redux"
import { getExerciseQuestionTitle } from "components/v2/utiles"

export const useQuestionForm = (exerciseQuestion: ExerciseQuestionResponse) => {
    const {
        id,
        is_required,
        library_template_id,
        type,
        question,
        definition,
        linkage_id,
        post_question,
        linked_question
    } = exerciseQuestion
    const { t } = useTranslation("question")
    const isFirstInit = useRef(true)
    const dispatch = useDispatch()

    const {
        control,
        getValues,
        formState: { errors }
    } = useForm<QuestionFormValues>({
        defaultValues: {
            type,
            question,
            definition,
            post_question
        },
        resolver: yupResolver(getQuestionSchemaValidation()) as Resolver<QuestionFormValues>,
        mode: "onChange"
    })

    const linageElementTitle = getExerciseQuestionTitle(linked_question as ExerciseQuestionResponse)

    const formValues = useWatch({
        control
    })

    const questionTypesWithLocale = useMemo(
        () => questionTypesList.map(({ title, ...rest }) => ({ ...rest, title: t(`questionTypes.${title}`) })),
        []
    )

    useDebouncedEffect(
        () => {
            const handleFormChange = () => {
                if (isFirstInit.current || Object.keys(errors).length > 0) return
                const { definition, ...restValues } = getValues()
                dispatch(
                    updateQuestionAction(id, {
                        library_template_id,
                        is_required,
                        definition: definition ?? {},
                        linkage_id,
                        ...restValues
                    })
                )
            }
            handleFormChange()
            if (isFirstInit.current) {
                isFirstInit.current = false
            }
        },
        [formValues, errors],
        300
    )

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }

    const onAddVariableClick = () => {
        dispatch(openAddVariableModalAction(exerciseQuestion))
    }

    const onRemoveVariableClick = () => {
        dispatch(deleteVariableLinkageAction(id))
    }

    return {
        t,
        errors,
        linageElementTitle,
        questionTypesWithLocale,
        control,
        onClick,
        onAddVariableClick,
        onRemoveVariableClick
    }
}
