import { DOMConversionMap, EditorConfig, ElementNode, LexicalNode, SerializedElementNode, Spread } from "lexical"

type SerializedCollapsibleContentNode = Spread<
    {
        type: "collapsible-content"
        version: 1
    },
    SerializedElementNode
>

export class CollapsibleContentNode extends ElementNode {
    static getType(): string {
        return "collapsible-content"
    }

    static clone(node: CollapsibleContentNode): CollapsibleContentNode {
        return new CollapsibleContentNode(node.__key)
    }

    createDOM(config: EditorConfig): HTMLElement {
        const dom = document.createElement("div")
        dom.classList.add("collapsible-content")
        return dom
    }

    updateDOM(prevNode: CollapsibleContentNode, dom: HTMLElement): boolean {
        return false
    }

    static importDOM(): DOMConversionMap | null {
        return {}
    }

    static importJSON(serializedNode: SerializedCollapsibleContentNode): CollapsibleContentNode {
        return $createCollapsibleContentNode()
    }

    isShadowRoot(): boolean {
        return true
    }

    exportJSON(): SerializedCollapsibleContentNode {
        return {
            ...super.exportJSON(),
            type: "collapsible-content",
            version: 1
        }
    }
}

export function $createCollapsibleContentNode(): CollapsibleContentNode {
    return new CollapsibleContentNode()
}

export function $isCollapsibleContentNode(node: LexicalNode | null | undefined): node is CollapsibleContentNode {
    return node instanceof CollapsibleContentNode
}
