import { CursorArrowRaysIcon } from "@heroicons/react/24/outline"
import { TriggerType } from "@prisma/client"
import { ClockIcon } from "components/icons"
import { TypographyV2 } from "components/v2/components/common"
import { ScheduleItemCard } from "components/v2/components/features/exercises"
import { useTranslation } from "react-i18next"
import { EventTypes } from "types"
import { LibraryTemplateResponse } from "types/Redux"
import { EventCompletionStatus } from "../../../../../../constants"

type IPreviewProtocolSequentialElementProps = LibraryTemplateResponse["cp_library_template_elements"][0] & {
    id: number
    title: string
    time: string
    when: string
    eventStatus?: EventCompletionStatus
    isLocked: bigint
    eventType: EventTypes
    isVerticalView?: boolean
    isDraggable?: boolean
    variant?: "light-gray" | "default"
    isEditOptionEnabled?: boolean
    numberOfEvents?: number
    classNames?: string
    withLabel?: boolean
    isUnitItem?: boolean
}

const PreviewProtocolSequentialElement: React.FC<IPreviewProtocolSequentialElementProps> = ({
    isDraggable = false,
    isVerticalView = false,
    isLocked = false,
    isUnitItem = false,
    variant = "default",
    isEditOptionEnabled = false,
    withLabel = false,
    classNames,
    id,
    ...reset
}) => {
    const { t } = useTranslation("careProtocol")

    const isElementwithSign = (element: any) =>
        Boolean(element.trigger_type === TriggerType.SEQUENTIAL_MANUAL || element.delay)

    const isShowSign = isElementwithSign(reset)
    const isElementManual = reset.trigger_type === TriggerType.SEQUENTIAL_MANUAL
    return (
        <div>
            {isShowSign && (
                <div className="flex flex-row items-center gap-1 mb-2">
                    {isElementManual ? (
                        <CursorArrowRaysIcon className="w-[17px] h-[17px] text-[#9CA3AF]" />
                    ) : (
                        <ClockIcon className="w-[17px] h-[17px] text-[#9CA3AF]" />
                    )}
                    <TypographyV2 type="xs" width="normal" className="text-[#6B7280]">
                        {isElementManual
                            ? t("manualTrigger")
                            : reset.delay
                            ? `${reset.delay} ${t("daysDelay")}`
                            : t("noDelay")}
                    </TypographyV2>
                </div>
            )}
            <div className={`flex flex-row items-center gap-1 ${isShowSign ? "" : "ml-9"}`}>
                {isShowSign && (
                    <div className="w-9 flex justify-center relative">
                        <div className="absolute left-[8px] top-[-31px] w-[22px] h-[32px] border-l-2 border-b-2 border-[#E5E7EB] rounded-bl-lg"></div>
                    </div>
                )}
                <ScheduleItemCard
                    {...{
                        ...(reset as any),
                        variant: "light-gray",
                        listItemClassName: "!w-full",
                        isVerticalView: true
                    }}
                    classNames="h-max"
                    isVerticalView
                    hideAction
                    title={reset.title}
                    time={reset.time}
                    when={reset.when}
                    eventType={reset.eventType}
                    eventStatus={reset?.eventStatus}
                    isEditOptionEnabled={true}
                />
            </div>
        </div>
    )
}

export default PreviewProtocolSequentialElement
