import { LibraryTemplateType } from "@prisma/client"
import { EventTypes } from "types"

export enum CareProtocolEventType {
    QUESTIONNAIRE = "questionnaire",
    EXERCISE = "exercise",
    UNIT = "unit"
}

export enum CareProtocolElementModalType {
    SCHEDULE = "schedule",
    SEQUENCE = "sequence"
}

export const careProtocolEventTypesList: { id: CareProtocolEventType; title: string; icon: EventTypes; type: LibraryTemplateType | "QUESTIONNAIRE" }[] = [
    { id: CareProtocolEventType.QUESTIONNAIRE, title: "questionnaire", icon: EventTypes.Questionnaire, type: "QUESTIONNAIRE" as const },
    { id: CareProtocolEventType.EXERCISE, title: "exercise", icon: EventTypes.Exercise, type: LibraryTemplateType.EXERCISE },
    { id: CareProtocolEventType.UNIT, title: "unit", icon: EventTypes.Unit, type: LibraryTemplateType.UNIT }
]
