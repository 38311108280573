import React, { Fragment, useRef } from "react"
import { TimeValue, useDateSegment, useLocale, useTimeField } from "react-aria"
import { useTimeFieldState } from "@react-stately/datepicker"
import { defaultInputClassNames, FormControlProps } from "forms"
import { RenderLabel } from "forms/RenderLabel"
import { classNames, useGetTimeFieldSegments } from "utils"

const TimeInputV2 = ({
    value,
    error,
    noWrapper = false,
    hourCycle = 12,
    ...props
}: FormControlProps<TimeValue> & { noWrapper?: boolean } & { hourCycle?: 12 | 24 }) => {
    let { locale } = useLocale()
    let state = useTimeFieldState({
        ...props,
        value,
        locale,
        hourCycle,
        onChange: props.setValue
    })
    

    let ref = useRef()
    let { labelProps, fieldProps } = useTimeField({ ...props }, state, ref)
    const Component = noWrapper ? Fragment : "div"

    const segments = useGetTimeFieldSegments(state.segments)

    return (
        <Component className={classNames("relative", props.classList?.wrapper)}>
            {!noWrapper && <RenderLabel {...props} elProps={labelProps} />}
            <div
                {...fieldProps}
                ref={ref}
                className={classNames("flex items-center", props.classList?.input ?? defaultInputClassNames, "h-11")}
                data-testid={fieldProps.id}
                data-value={value}
            >
                {segments.map((segment, i) => (
                    <DateSegment key={i} segment={segment} state={state} />
                ))}
            </div>
        </Component>
    )
}

type DateSegmentParams = Parameters<typeof useDateSegment>

export function DateSegment({ segment, state }: { segment: DateSegmentParams[0]; state: DateSegmentParams[1] }) {
    let ref = useRef()
    let { segmentProps } = useDateSegment(segment, state, ref)

    return (
        <div
            {...segmentProps}
            ref={ref}
            style={{
                ...segmentProps.style,
                minWidth: segment.maxValue != null && String(segment.maxValue).length + "ch"
            }}
            className={`px-0.5 box-content tabular-nums text-right outline-none rounded-sm focus:bg-text-blue focus:text-white group ${
                !segment.isEditable ? "text-gray-500" : "text-gray-800"
            }`}
        >
            {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
            {segment.isPlaceholder && (
                <span
                    aria-hidden="true"
                    className="block w-full text-center italic text-gray-400 text-sm font-medium group-focus:text-white pointer-events-none"
                    style={{
                        visibility: segment.isPlaceholder ? "visible" : "hidden",
                        height: segment.isPlaceholder ? "" : 0,
                        pointerEvents: "none"
                    }}
                >
                    {segment.placeholder}
                </span>
            )}
            {segment.isPlaceholder ? "" : segment.text}
        </div>
    )
}

export default TimeInputV2
