import { classNames } from "utils"
import { RenderLabel } from "./RenderLabel"
import { FormControlProps } from "./types"
import { defaultInputClassNames, errorClassNames } from "./utils"
import { useTranslation } from "react-i18next"
import InputMask from "@mona-health/react-input-mask"
import { ChangeEvent } from "react"

export const PhoneField = ({
    id,
    value,
    placeholder,
    label,
    setValue,
    error,
    classList,
    readOnly
}: FormControlProps<string>) => {
    const { i18n } = useTranslation()
    return (
        <div className={classNames(classList?.wrapper, "relative")}>
            {label && <RenderLabel id="phoneLabel" label={label} classList={classList} />}
            <div className={classNames(classList?.inputWrapper, "relative")} dir="ltr">
                <InputMask
                    mask="+999999999999"
                    id={`input-${id}`}
                    type="phone"
                    maskChar={null}
                    data-testid={`input-${id}`}
                    value={value ?? ""}
                    maskPlaceholder={null}
                    autoComplete="off"
                    className={classNames(
                        i18n.language === "he" && "text-end",
                        classList?.input ?? defaultInputClassNames,
                        error && "!border-red-500"
                    )}
                    placeholder={placeholder}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setValue(event.target.value.replaceAll(" ", ""))
                    }
                    disabled={readOnly}
                />
                {error && <div className={classNames(errorClassNames)}>{error}</div>}
            </div>
        </div>
    )
}
