export function matchPatternWithURL(link: string, pathname: string) {
    // Check if the pattern contains a '*'
    if (link.includes("*")) {
        // Convert the pattern into a regex by replacing '*' with '.*'
        const regexPattern = link.replace("*", ".*")
        const regex = new RegExp("^" + regexPattern + "$") // Add anchors to match the full string

        // Test if the link matches the regex
        return regex.test(pathname)
    } else {
        // If no '*' in the pattern, do a direct comparison
        return link === pathname
    }
}
