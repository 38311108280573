import {
    $applyNodeReplacement,
    ElementNode,
    LexicalNode,
    NodeKey,
    SerializedElementNode,
    Spread,
    DOMExportOutput,
    DOMConversionMap,
    DOMConversionOutput
} from "lexical"

export type SerializedBoxColorNode = Spread<
    {
        type: "box-color"
        version: 1
        backgroundColor: string
        padding: string
    },
    SerializedElementNode
>

function convertBoxColorElement(domNode: Node): null | DOMConversionOutput {
    const element = domNode as HTMLElement
    const backgroundColor = element.style.getPropertyValue("--box-color") || "transparent"
    const padding = element.style.getPropertyValue("--box-padding") || "10px"
    const node = $createBoxColorNode(backgroundColor, padding)
    return { node }
}

export class BoxColorNode extends ElementNode {
    __backgroundColor: string
    __padding: string

    static getType(): string {
        return "box-color"
    }

    static clone(node: BoxColorNode): BoxColorNode {
        return new BoxColorNode(node.__backgroundColor, node.__padding, node.__key)
    }

    constructor(backgroundColor: string = "#ffffff", padding: string = "10px", key?: NodeKey) {
        super(key)
        this.__backgroundColor = backgroundColor
        this.__padding = padding
    }

    createDOM(): HTMLElement {
        const div = document.createElement("p")
        div.className = "box-color-node"
        div.style.backgroundColor = this.__backgroundColor
        div.style.padding = this.__padding
        div.style.borderRadius = "4px"
        return div
    }

    remove(): void {
        const children = this.getChildren()
        const parent = this.getParentOrThrow()
        children.forEach((child) => parent.insertBefore(child))
        super.remove()
    }

    updateDOM(prevNode: BoxColorNode): boolean {
        return this.__backgroundColor !== prevNode.__backgroundColor || this.__padding !== prevNode.__padding
    }

    static importJSON(serializedNode: SerializedBoxColorNode): BoxColorNode {
        return new BoxColorNode(serializedNode?.backgroundColor, serializedNode?.padding)
    }

    exportJSON(): SerializedBoxColorNode {
        return {
            ...super.exportJSON(),
            type: "box-color",
            version: 1,
            backgroundColor: this.__backgroundColor,
            padding: this.__padding
        }
    }

    static importDOM(): DOMConversionMap | null {
        return {
            div: (node: Node) => {
                const element = node as HTMLElement
                if (element.className === "box-color") {
                    return {
                        conversion: convertBoxColorElement,
                        priority: 0
                    }
                }
                return null
            }
        }
    }

    // BoxColor only needs color
    setBackgroundColor(color: string): void {
        const writable = this.getWritable()
        writable.__backgroundColor = color
    }

    setPadding(padding: string): void {
        const writable = this.getWritable()
        writable.__padding = padding
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement("p")
        element.style.backgroundColor = this.__backgroundColor
        element.style.padding = this.__padding
        return { element }
    }
    isInline(): boolean {
        return true // Add this method
    }
    isEmpty(): boolean {
        return this.getTextContent().trim().length === 0
    }

    getBackgroundColor(): string {
        return this.__backgroundColor
    }
}

export function $createBoxColorNode(backgroundColor: string, padding: string): BoxColorNode {
    return $applyNodeReplacement(new BoxColorNode(backgroundColor, padding))
}

export function $isBoxColorNode(node: LexicalNode | null | undefined): node is BoxColorNode {
    return node instanceof BoxColorNode
}
