import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { TypographyV2 } from "components/v2/components/common"
import React, { useState } from "react"

interface ICollapsableMenuProps {
    title: string
    children: React.ReactNode
}

const CollapsableMenu: React.FC<ICollapsableMenuProps> = ({ children, title }) => {
    const [isOpen, setIsOpen] = useState(true)

    return (
        <div className="mb-2">
            <button className="flex gap-1 w-full mb-1" onClick={() => setIsOpen((prev) => !prev)}>
                <ChevronDownIcon
                    className={`transition-all duration-200 ${isOpen ? "rotate-0" : "-rotate-90"}`}
                    height={16}
                />
                <TypographyV2 className="text-med-black" type="xs" width="semibold">
                    {title}
                </TypographyV2>
            </button>
            <div
                className={`grid transition-all duration-500 overflow-hidden ${
                    isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
                }`}
            >
                <div className="overflow-hidden">{children}</div>
            </div>
        </div>
    )
}

export default CollapsableMenu
