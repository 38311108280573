import React from "react"
import { BreadcrumbsV2 } from "components/v2"
import { StatusV2, TabsHeaderV2, DropdownMenuV2 } from "components/v2"
import {
    exerciseStatusesWithLocales,
    getLibraryTemplateNameByType,
    TemplatePublishingStatus
} from "components/v2/constants"
import { ArchiveIcon, CopyIcon, ElipsisIcon } from "components/icons"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import {
    cloneLibraryTemplateAction,
    closeLibraryTemplateBuilderAction,
    makeLibraryTemplateArchivedAction
} from "store/library-template/actions"
import { useLibraryTemplateSelector } from "store/hooks"
import { closeModal } from "store/modal/actions"
import { LibraryTemplateTab } from "store/library-template/types"

const LibraryElementBuilderHeader = ({
    eventName,
    activeTab,
    onActiveTabClick,
    elementStatus
}: {
    eventName: string
    activeTab: string
    onActiveTabClick: (tab: string) => void
    elementStatus: string
}) => {
    const { t } = useTranslation("common")
    const { libraryTemplate, type } = useLibraryTemplateSelector()

    const dispatch = useDispatch()
    const eventType = getLibraryTemplateNameByType(type!)

    const onMakeACopyClick = () => {
        if (libraryTemplate?.id) dispatch(cloneLibraryTemplateAction(libraryTemplate.id))
    }

    const dropDownActionsList = [
        {
            title: t("makeACopy"),
            icon: CopyIcon,
            onClick: onMakeACopyClick
        },
        {
            border: true,
            title: t("archive"),
            icon: ArchiveIcon,
            onClick: () => {
                if (libraryTemplate?.id) {
                    dispatch(makeLibraryTemplateArchivedAction(libraryTemplate.id))
                }
                dispatch(closeModal())
            }
        }
    ]

    return (
        <div className="pt-3 px-4 bg-light-background border-b border-base-light flex justify-between">
            <div className="flex flex-1 pb-3 gap-2 items-center">
                <BreadcrumbsV2
                    pathList={[
                        {
                            title: t(`eventType.${eventType}`),
                            id: 1,
                            href: "#",
                            onClick: () => dispatch(closeLibraryTemplateBuilderAction())
                        },
                        { title: eventName, id: 2, href: "#" }
                    ]}
                />
            </div>
            <div className="self-end">
                <TabsHeaderV2
                    tabItems={[
                        { key: LibraryTemplateTab.BASIC_INFO, title: t("eventBuilder.basicInfo") },
                        { key: LibraryTemplateTab.CONTENT, title: t("eventBuilder.content") },
                        { key: LibraryTemplateTab.PREVIEW, title: t("eventBuilder.preview") }
                    ]}
                    {...{ activeTab, onActiveTabClick }}
                />
            </div>
            <div className="flex flex-1 pb-3 items-center justify-end">
                <StatusV2
                    status={elementStatus as TemplatePublishingStatus}
                    className="mr-10"
                    text={t(
                        `exercise:exerciseStatuses.${
                            exerciseStatusesWithLocales[elementStatus as TemplatePublishingStatus]
                        }`
                    )}
                />
                <DropdownMenuV2
                    menuItems={dropDownActionsList}
                    clickComponent={<ElipsisIcon width={24} height={24} />}
                />
            </div>
        </div>
    )
}

export default LibraryElementBuilderHeader
