import React, { useEffect, useState } from "react"
import { fetchQuestionnaire } from "api/Intake"
import { ScreeningQuestionType } from "@prisma/client"
import { TypographyV2 } from "components/v2"
import { classNames } from "utils"
import {
    FreeTextQuestionResponse,
    MinutesQuestionResponse,
    PercentageQuestionResponse,
    ScreeningQuestionResponse,
    ScaleSliderQuestionResponse,
    MultipleChoiceQuestionResponse
} from "."
import YesNoQuestionResponse from "./YesNoQuestionResponse"
import { ChevronUpIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import ScaleQuestionnaireResponse from "./ScaleQuestionnaireResponse"
import { useCurrentPreferences } from "store/hooks"
import { getDateLocaleFormat } from "utils/date"

interface QuestionnaireResponseProps {
    question: any
    screeningAnswers: any
}

interface Cutoff {
    min: number
    color: string
}

const QuestionnaireResponse = ({ question, screeningAnswers }: QuestionnaireResponseProps) => {
    const [subQuestions, setSubQuestions] = useState<any[]>([])
    const [questionnaireTitle, setQuestionnaireTitle] = useState("")
    const [isCollapsed, setIsCollapsed] = useState(false)
    const { t, i18n } = useTranslation()
    const questionnaireId = question.definition?.questionnaire_id
    const dateFormat = useCurrentPreferences()?.date_format
    useEffect(() => {
        const getQuestionnaireDetails = async () => {
            if (questionnaireId) {
                const questionnaire = await fetchQuestionnaire(questionnaireId)
                setSubQuestions(questionnaire.questions || [])
                setQuestionnaireTitle(questionnaire.title || question?.question)
            }
        }
        getQuestionnaireDetails()
    }, [questionnaireId, question])

    const renderQuestionByType = (currentQuestion: any, isLastQuestion: boolean) => {
        const answer = screeningAnswers?.responses?.find((a: any) => a.question_id === currentQuestion.id)

        switch (currentQuestion.type) {
            case ScreeningQuestionType.free_text:
            case ScreeningQuestionType.number:
                return (
                    <FreeTextQuestionResponse
                        question={currentQuestion}
                        screeningAnswer={answer}
                        sybQuestion
                        isLastQuestion={isLastQuestion}
                        placeholder={t("intake:common.free_text_placeholder")}
                    />
                )
            case ScreeningQuestionType.time_of_day:
                const timeOfDayAnswer = answer.value

                const date =
                    timeOfDayAnswer == "undefined" || timeOfDayAnswer == null
                        ? ""
                        : getDateLocaleFormat(new Date(timeOfDayAnswer), dateFormat, i18n)

                return (
                    <FreeTextQuestionResponse
                        question={currentQuestion}
                        screeningAnswer={{ ...answer, value: date }}
                        sybQuestion
                        isLastQuestion={isLastQuestion}
                        placeholder={t("intake:common.free_text_placeholder")}
                    />
                )
            case ScreeningQuestionType.select:
                return (
                    <ScreeningQuestionResponse
                        question={currentQuestion}
                        screeningAnswer={answer}
                        sybQuestion
                        isLastQuestion={isLastQuestion}
                    />
                )
            case ScreeningQuestionType.multiple_choice:
                return (
                    <MultipleChoiceQuestionResponse
                        question={currentQuestion}
                        screeningAnswer={answer}
                        sybQuestion
                        isLastQuestion={isLastQuestion}
                    />
                )
            case ScreeningQuestionType.minutes:
                const minutesAnswer = answer.value
                const minutes = minutesAnswer == "undefined" || minutesAnswer == null ? "" : minutesAnswer
                return (
                    <MinutesQuestionResponse
                        question={currentQuestion}
                        screeningAnswer={{ ...answer, value: minutes }}
                        sybQuestion
                        isLastQuestion={isLastQuestion}
                        placeholder={t("intake:common.free_text_placeholder")}
                    />
                )
            case ScreeningQuestionType.percent:
                return (
                    <PercentageQuestionResponse
                        question={currentQuestion}
                        screeningAnswer={answer}
                        sybQuestion
                        isLastQuestion={isLastQuestion}
                        placeholder={t("intake:common.free_text_placeholder")}
                    />
                )
            case ScreeningQuestionType.scale:
                return (
                    <ScaleQuestionnaireResponse
                        question={currentQuestion}
                        screeningAnswer={answer}
                        isLastQuestion={isLastQuestion}
                        sybQuestion
                    />
                )
            case ScreeningQuestionType.yes_no:
                return (
                    <YesNoQuestionResponse
                        question={currentQuestion}
                        screeningAnswer={answer}
                        sybQuestion
                        isLastQuestion={isLastQuestion}
                    />
                )
            case ScreeningQuestionType.scale_slider:
                return (
                    <ScaleSliderQuestionResponse
                        question={currentQuestion}
                        screeningAnswer={answer}
                        sybQuestion
                        isLastQuestion={isLastQuestion}
                    />
                )
            default:
                return (
                    <div className="">
                        <div
                            className={classNames(
                                "bg-white border-slate-300 pt-4 pl-3 border-b",
                                isLastQuestion && "border-none"
                            )}
                        >
                            <TypographyV2 type="sm" className="font-bold">
                                {currentQuestion.question}
                            </TypographyV2>
                            <TypographyV2 className="text-[#6B7280] mt-2" type="base">
                                {answer?.value || t("intake:common.free_text_placeholder")}
                            </TypographyV2>
                        </div>
                    </div>
                )
        }
    }

    const getScoreColor = () => {
        const scale = screeningAnswers?.questionnaire_def?.questionnaire_scale
        const cutoffs: Cutoff[] = scale?.cutoffs || []
        const score = screeningAnswers?.score

        if (!score || !cutoffs.length) return "#808080" // default gray color if no score or cutoffs

        const cutoff = cutoffs.sort((a: Cutoff, b: Cutoff) => b.min - a.min).find((c: Cutoff) => score >= c.min)

        return cutoff?.color || "#808080"
    }

    return (
        <div className="bg-white p-5 rounded-lg border">
            <div className="flex items-center justify-between">
                <TypographyV2 type="lg" width="semibold" className="text-[#111827]">
                    {questionnaireTitle}
                </TypographyV2>
                <div className="flex items-center gap-2" onClick={() => setIsCollapsed(!isCollapsed)}>
                    {screeningAnswers?.score && (
                        <div className="flex flex-row items-center gap-2">
                            <TypographyV2 type="sm" width="medium" className="text-[#4B5563]">
                                {t("intake:common.score")}
                            </TypographyV2>
                            <div
                                className="rounded-[2px] px-[6px] h-6 flex items-center justify-center"
                                style={{ backgroundColor: `${getScoreColor()}20` }}
                            >
                                <TypographyV2 type="sm" width="semibold" style={{ color: getScoreColor() }}>
                                    {screeningAnswers?.score}
                                </TypographyV2>
                            </div>
                        </div>
                    )}
                    <ChevronUpIcon
                        className="w-6 h-6"
                        style={{ transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)" }}
                    />
                </div>
            </div>
            {isCollapsed ? (
                <div>
                    <div className="py-[10px] flex flex-col gap-[10px]">
                        {screeningAnswers?.group_scores?.map((group: any, index: number) => (
                            <div key={index} className="flex items-center gap-10">
                                <TypographyV2 type="sm" width="medium" className="text-[#4B5563] w-1/3">
                                    {group?.scale?.name}
                                </TypographyV2>
                                <TypographyV2 type="sm" width="medium" className="text-[#4B5563] w-2/3">
                                    {group?.score}
                                </TypographyV2>
                            </div>
                        ))}
                    </div>
                    <div className="border border-[#E5E7EB] rounded-lg">
                        {subQuestions.map((q: any, index: number) => {
                            const prevQuestionScaleSet = subQuestions[index - 1]?.definition?.scale_set || 0
                            const currQuestionScaleSet = q?.definition?.scale_set || 0
                            const prevQuestionScale = subQuestions[index - 1]?.scale?.labels?.[prevQuestionScaleSet]
                            const currQuestionScale =
                                q?.scale?.labels?.[currQuestionScaleSet] ||
                                new Array(q?.scale?.max - q?.scale?.min + 1 || 0).fill("").map((_, i) => String(i))

                            if (q?.type === ScreeningQuestionType.scale) {
                                if (
                                    subQuestions[index - 1]?.type === ScreeningQuestionType.scale &&
                                    _.isEqual(prevQuestionScale, currQuestionScale)
                                ) {
                                    return renderQuestionByType(q, subQuestions.length === index + 1)
                                } else {
                                    return (
                                        <React.Fragment key={index}>
                                            <div
                                                className="grid pt-4"
                                                style={{
                                                    gridTemplateColumns: `minmax(0, 1fr) repeat(${currQuestionScale.length}, 60px)`,
                                                    direction: i18n.language === "he" ? "rtl" : "ltr"
                                                }}
                                            >
                                                <div></div>
                                                {currQuestionScale.map((label: any) => (
                                                    <div key={label} className="flex justify-center">
                                                        <TypographyV2
                                                            type="sm"
                                                            width="medium"
                                                            className="text-gray-600 text-center whitespace-pre-line"
                                                        >
                                                            {label}
                                                        </TypographyV2>
                                                    </div>
                                                ))}
                                            </div>
                                            {renderQuestionByType(q, subQuestions.length === index + 1)}
                                        </React.Fragment>
                                    )
                                }
                            }
                            return <div key={index}>{renderQuestionByType(q, subQuestions.length === index + 1)}</div>
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default QuestionnaireResponse
