const HtmlRenderer = ({ html }: { html: string }) => {
    if (!html) return null;
    
    return (
        <div 
            className="html-content" 
            dangerouslySetInnerHTML={{ __html: html }} 
        />
    );
};

export default HtmlRenderer;