export const searchExerciseByText = <T extends { title: string; description: string }>(
    exercises: T[],
    query: string
): T[] => {
    if (!query?.length) return exercises
    const lowerCaseQuery = query.toLowerCase()

    return exercises.filter(
        ({ title, description }) =>
            title.toLowerCase().includes(lowerCaseQuery) || description.toLowerCase().includes(lowerCaseQuery)
    )
}
