import { addEvent, updatePatientFeatures } from "api"
import { Spinner } from "components/common"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { toast } from "react-toastify"
import { closeModal } from "store/modal/actions"
import { useCurrentPatient, useCurrentPatientFeatures, useCurrentUser } from "../../store/hooks"
import { setCurrentPatientFeatures } from "../../store/patient/actions"
import {
    BadgeV2,
    ControlledRadioCircleGroupV2,
    ControlledSelectV2,
    DialogV2,
    IOptionV2,
    SwitchV2,
    TypographyV2,
    UserAvatarV2
} from "components/v2"
import { useForm } from "react-hook-form"
import { fetchQuestionnairesForRole, deleteTherapistQuestionnaireEvent } from "api/Therapist"
import { QuestionnaireDef } from "@prisma/client"
import {
    AVAILABILITY_OPTIONS,
    DAYS_OPTION,
    HOURS_OPTION,
    REPEATS_OPTIONS,
    WEEKS_OPTION
} from "./featureSettenigsConsts"
import { classNames } from "utils"
import { defaultInputClassNames } from "forms/utils"
import TimeFields from "components/ContentLibrary/EventConfig/InputComponents/DateOnTimelineField"
import { yupResolver } from "@hookform/resolvers/yup"
import { featureSettingsValidationSchema } from "./featureSettingsValidationSchema"
import { addMonths } from "date-fns"
import { DateString, getDateAtHour0 } from "utils/dates"
import { useIsHeLanguage } from "../../hooks"

export const FeaturesSettingsModal = ({ isOpen, params }: { isOpen: boolean; params: { patient_id: number } }) => {
    const dispatch = useDispatch()
    const { isHeLanguage } = useIsHeLanguage()
    const handleClose = () => dispatch(closeModal())
    const [features, setFeatures] = useState<{ [key: string]: boolean }>(null)
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation("patient")
    const featuresStored = useCurrentPatientFeatures()
    const patient = useCurrentPatient()
    const [questionnaires, setQuestionnaires] = useState<QuestionnaireDef[]>([])
    const [daysOffset, setDaysOffset] = useState<IOptionV2[]>(DAYS_OPTION)
    const modalRef = useRef<HTMLDivElement>(null)

    const currentUser = useCurrentUser()

    const patientCaseManager = useMemo(
        () =>
            patient?.collaborators.find((collaborator) =>
                collaborator.permissions.find((permission) => permission.permission === "case_manager")
            ),
        [patient?.id]
    )

    const ifCurrentUserIsCaseManager = currentUser?.id === patientCaseManager?.user.id || currentUser?.role === "admin"
    const isTherapistQuestionnaireEnabled =
        (features?.["assign_therapist_questionnaires"] || false) && ifCurrentUserIsCaseManager

    const patientCollaborators =
        patient?.collaborators.filter(
            (collaborator) => collaborator.id !== patientCaseManager?.id && collaborator.permissions.length
        ) || []

    const {
        control,
        getValues,
        watch,
        resetField,
        trigger,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(featureSettingsValidationSchema(t)),
        mode: "onChange",
        defaultValues: {
            collaboratorIds: patientCollaborators.map((collaboraator) => collaboraator.user.id),
            questionnaireId: null,
            availability: "until",
            repeats: "",
            time_offset: 1,
            time_unit: "day"
        }
    })

    const timeUnit = watch("time_unit")
    useEffect(() => {
        const unitToDate = {
            hour: HOURS_OPTION,
            day: DAYS_OPTION,
            week: WEEKS_OPTION
        }
        resetField("time_offset")
        setDaysOffset(unitToDate[timeUnit as keyof typeof unitToDate])
    }, [timeUnit])

    useEffect(() => {
        const patientFeatures = { ...featuresStored }
        if (!ifCurrentUserIsCaseManager) {
            delete patientFeatures["assign_therapist_questionnaires"]
        }
        setFeatures(patientFeatures)
    }, [isOpen, featuresStored])

    useEffect(() => {
        const getTherapistQuestionnaires = async () => {
            const response = await fetchQuestionnairesForRole()
            setQuestionnaires(response)
        }
        getTherapistQuestionnaires()
    }, [])

    const handleChange = (type: string) => {
        setFeatures({ ...features, [type]: !features[type] })
    }

    const handleSubmit = async () => {
        try {
            if ((isTherapistQuestionnaireEnabled && !(await trigger())) || isLoading) return
            setIsLoading(true)
            await updatePatientFeatures(params.patient_id, features)
            dispatch(setCurrentPatientFeatures(features))
            toast(t("featuresSettings.success"))
            if (isTherapistQuestionnaireEnabled) {
                await createTherapistQuestionnaireEvent()
            } else {
                await deleteTherapistQuestionnaireEvent(patient.id, patientCaseManager?.user.id)
            }
            reset()
            setIsLoading(false)
            handleClose()
        } catch (error) {
            console.error(error)
        }
    }

    const createTherapistQuestionnaireEvent = async () => {
        const eventValue = getValues()
        const timeUnit = eventValue.time_unit
        const timeOffset = eventValue.time_offset
        const expiresUntil = timeUnit === "day" ? timeOffset : timeUnit === "week" ? timeOffset * 7 : 1
        const expireOnSpecificDate = eventValue.availability === "day" ? 1 : expiresUntil
        const isRecurring = Number(eventValue.repeats)
        await addEvent(params.patient_id, Number(patient.user.id), {
            assignee_id: Number(patientCaseManager?.user.id),
            type: "questionnaire",
            collaborators: eventValue.collaboratorIds,
            title: questionnaires.find((questionnaire) => questionnaire.id === eventValue.questionnaireId)?.title,
            questionnaire_id: eventValue.questionnaireId,
            ...(isRecurring && {
                recurrence: {
                    interval: Number(eventValue.repeats),
                    end: DateString.from(getDateAtHour0(addMonths(new Date(date.date_on_timeline), 3)))
                }
            }),
            expires_within: Number(expireOnSpecificDate),
            date_on_timeline: `${DateString.from(getDateAtHour0(date.date_on_timeline))}T00:00:00.000Z`
        })
    }

    const questionnaireOptions: IOptionV2[] = useMemo(() => {
        return questionnaires.map((questionnaire) => ({
            id: questionnaire.id,
            title: questionnaire.title
        }))
    }, [questionnaires])

    const collaboratorsOptions: IOptionV2[] = useMemo(() => {
        return (
            patientCollaborators?.map((collaborator) => ({
                id: collaborator.user.id,
                title: `${collaborator.user.first_name} ${collaborator.user.last_name}`,
                media: collaborator.user.profile_image ? (
                    collaborator.user.profile_image.replace("[variant]", "small")
                ) : (
                    <UserAvatarV2
                        size={24}
                        firstName={collaborator.user.first_name}
                        lastName={collaborator.user.last_name}
                    />
                )
            })) || []
        )
    }, [patientCollaborators])
    const [date, setDate] = useState<any>({ date_on_timeline: new Date(), isTimeless: true })

    const handleModalClose = () => {
        reset()
        handleClose()
    }

    return (
        <DialogV2
            title={t("featuresSettings.title")}
            type="wide"
            isOpen={isOpen}
            onClose={handleModalClose}
            onProceed={handleSubmit}
            proceedText={t("common:done")}
            proceedType="primary"
            dismissType="light-gray"
            dismissText={t("common:back")}
            classList={{
                title: classNames(
                    "w-[1200px] h-[800px] max-h-9/10screen overflow-y-hidden flex flex-col",
                    isHeLanguage ? "!translate-x-[-20%]" : ""
                ),
                content: "h-full overflow-y-scroll",
                dialog: isHeLanguage ? "!translate-x-[0%]" : ""
            }}
        >
            <div ref={modalRef} className="flex flex-col w-full overflow-y-auto">
                <TypographyV2 type="base" className="text-[#111827]" width="bold">
                    {t("featuresSettings.subtitle")}
                </TypographyV2>
                {features ? (
                    <div className="p-6 flex flex-col w-full">
                        {Object.entries(features).map(([key, isEnabled]) => {
                            return key === "feature-settings" ? null : (
                                <div
                                    className="flex items-center border-b border-[#F3F4F6] h-[104px] gap-2 w-full"
                                    key={key}
                                >
                                    <SwitchV2 isLarge isChecked={isEnabled} onChange={() => handleChange(key)} />
                                    <span className="w-full font-medium text-sm">{t(`featuresSettings.${key}`)}</span>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <Spinner size={10} className="m-auto" />
                )}
                {isTherapistQuestionnaireEnabled && (
                    <div className="p-6 flex flex-col w-full gap-y-6">
                        <div className="flex flex-col w-fit gap-y-2">
                            <TypographyV2 type="xs" width="semibold" className="text-[#4B5563]">
                                {t("featuresSettings.assignee")}
                            </TypographyV2>
                            <BadgeV2
                                className="bg-[#F3F4F6] px-[8px] py-[4px] gap-x-[10px]"
                                icon={
                                    <UserAvatarV2
                                        size={24}
                                        firstName={patientCaseManager?.user?.first_name}
                                        lastName={patientCaseManager?.user?.last_name}
                                    />
                                }
                                text={`${patientCaseManager?.user?.first_name} ${patientCaseManager?.user?.last_name}`}
                            />
                        </div>
                        <div className="flex flex-col w-full gap-y-2">
                            <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                {t("featuresSettings.therapist_questionnaires")}
                            </TypographyV2>
                            <ControlledSelectV2
                                options={questionnaireOptions}
                                control={control}
                                name="questionnaireId"
                                placeholder={t("featuresSettings.therapist_questionnaires")}
                                error={errors.questionnaireId?.message}
                            />
                        </div>
                        <div className="flex flex-row w-full gap-x-4">
                            <div className="flex flex-col w-full gap-y-2">
                                <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                    {t("featuresSettings.start_date")}
                                </TypographyV2>
                                <TimeFields
                                    value={date}
                                    setValue={setDate}
                                    id="start_date"
                                    withIcons={true}
                                    classList={{
                                        wrapper: "w-full z-[100]",
                                        input: classNames(
                                            defaultInputClassNames,
                                            "!bg-white flex justify-between items-center rounded-lg"
                                        )
                                    }}
                                    modalRef={modalRef}
                                />
                            </div>
                            <div className="flex flex-col w-full gap-y-2">
                                <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                    {t("featuresSettings.repeats")}
                                </TypographyV2>
                                <ControlledSelectV2
                                    options={REPEATS_OPTIONS(t)}
                                    control={control}
                                    name="repeats"
                                    placeholder={t("featuresSettings.therapist_questionnaires")}
                                    error={errors.repeats?.message}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-y-2">
                            <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                {t("featuresSettings.availability")}
                            </TypographyV2>
                            <ControlledRadioCircleGroupV2
                                control={control}
                                name="availability"
                                options={AVAILABILITY_OPTIONS(t, control, daysOffset, errors)}
                                error={
                                    errors.availability?.message ??
                                    errors.time_offset?.message?.toString() ??
                                    errors.time_unit?.message?.toString()
                                }
                            />
                        </div>
                        <div className="flex flex-col w-full gap-y-2">
                            <TypographyV2 type="xs" width="medium" className="text-[#111827]">
                                {t("featuresSettings.collaborators")}
                            </TypographyV2>
                            <ControlledSelectV2
                                options={collaboratorsOptions}
                                control={control}
                                mode="multiple"
                                name="collaboratorIds"
                                placeholder={t("featuresSettings.collaborators")}
                                error={errors.collaboratorIds?.message}
                            />
                        </div>
                    </div>
                )}
            </div>
        </DialogV2>
    )
}
