import { ListV2, SearchInputV2 } from "components/v2/components/common"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useAllQuestionnaires, useUnitSelector } from "store/hooks"
import { getAllLibraryTemplates } from "api/LibraryTemplate"
import ChapterElementItemQuestionnaire from "./ChapterElementItemQuestionnaire"
import ChapterElementItemLibraryElement from "./ChapterElementItemLibraryElement"
import { LibraryTemplateResponse } from "types/Redux"

const AddChapterElementList = () => {
    const [filterQuery, setFilterQuery] = useState("")

    const [libraryElementList, setLibraryElementList] = useState<LibraryTemplateResponse[]>([])

    const questionnaires = useAllQuestionnaires()
    const {
        addElementModal: { elementType }
    } = useUnitSelector()

    const filterFunction = useCallback(
        (item: any) => {
            const regex = new RegExp(filterQuery, "i")
            return regex.test(item.title)
        },
        [filterQuery]
    )

    const list = useMemo(() => {
        if (elementType === "QUESTIONNAIRE") {
            return questionnaires
        }
        return libraryElementList
    }, [elementType, questionnaires, libraryElementList])

    useEffect(() => {
        if (elementType === "QUESTIONNAIRE") return
        ;(async () =>
            setLibraryElementList(
                (await getAllLibraryTemplates(elementType as any)).filter((item) => item.status === "PUBLISHED")
            ))()
    }, [])

    const renderElement =
        elementType === "QUESTIONNAIRE" ? ChapterElementItemQuestionnaire : ChapterElementItemLibraryElement

    return (
        <>
            <SearchInputV2
                className="text-sm"
                iconClassName="w-5"
                searchText={filterQuery}
                onChange={(value) => setFilterQuery(value)}
            />
            <div className="mt-2">
                <ListV2 renderItem={renderElement} {...{ list: list as any, filterFunction }} />
            </div>
        </>
    )
}

export default AddChapterElementList
