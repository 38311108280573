import { OrganizationIcon, PatientsIcon, UsersIcon } from "components/icons"
import { SVGProps, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRole, usePatientList } from "store/hooks"
import OrganizationSettings from "./Organization"
import OrganizationList from "./OrganizationList"
import Settings from "../icons/Settings"
import UserSettings from "./User"
import TeamSettings from "./TeamSettings"

export type SettingsSection = {
    name: string
    href: string
    Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
    content?: () => JSX.Element
    testId?: string
    activeLinks?: string[]
}

export const useSettingsSections = (allDefinitions = false) => {
    const currentRole = useCurrentRole()
    const { t } = useTranslation()

    const patients = usePatientList()

    return useMemo(() => {
        const sections: SettingsSection[] = []

        if (currentRole.role === "admin") {
            sections.push({
                name: `${t("patient:allPatients")}`,
                href: "/patients",
                Icon: PatientsIcon,
                testId: "menu-item-patients",
            })
        }

        if (allDefinitions) {
            sections.push({
                name: t("userSettings.title"),
                href: "/settings/user",
                Icon: Settings,
                content: UserSettings
            })
            sections.push({
                name: t("teamMembers"),
                href: "/settings/team",
                Icon: UsersIcon,
                content: TeamSettings,
                testId: "users-list-link"
            })
        }

        if (currentRole.role === "admin") {
            sections.push({
                name: t("organizationSettings"),
                href: "/settings/organization",
                Icon: OrganizationIcon,
                content: OrganizationSettings
            })
        }

        // === SequelCare
        if (currentRole.organization_id === 3)
            sections.push({
                name: t("allOrganizations"),
                href: "/settings/organizations",
                Icon: OrganizationIcon,
                content: OrganizationList
            })

        return sections
    }, [currentRole])
}
