import ScheduleItemCard from "../../ScheduleItemCard"
import { mapUnitChapterSequentialEvent } from "../../../../../../../../CareplanSection/constants"
import { TypographyV2 } from "components/v2"
import React, { useMemo } from "react"
import { Prisma } from "@prisma/client"

type UnitSequentialChaptersResponse = Prisma.UnitChapterGetPayload<{
    include: {
        unit_chapter_element: true
    }
}>

interface IUnitSequentialChaptersListProps {
    chapters: UnitSequentialChaptersResponse[]
}

const UnitSequentialChaptersList: React.FC<IUnitSequentialChaptersListProps> = ({ chapters }) => {
    const sortedСhapters = useMemo(() => [...chapters].sort((a, b) => a.pos - b.pos), [chapters])
    return (
        <div className="mt-5 px-1">
            {sortedСhapters?.map(({ name, unit_chapter_element }) => {
                const totalCount = unit_chapter_element?.length
                const completedEvents = unit_chapter_element?.filter((event: any) => !!event?.completed_at)
                return (
                    <div className="mb-4">
                        <div className="flex justify-between items-center">
                            <TypographyV2 width="semibold" type="xs" className="mb-2.5 ">
                                {name}
                            </TypographyV2>
                            <TypographyV2 className="text-gray-500">
                                {completedEvents.length} / {totalCount}
                            </TypographyV2>
                        </div>

                        <div>
                            {unit_chapter_element?.map((event) => {
                                return (
                                    <ScheduleItemCard
                                        {...mapUnitChapterSequentialEvent(event)}
                                        isUnitItem
                                        isVerticalView
                                        variant="light-gray"
                                        classNames="mb-2"
                                    />
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default UnitSequentialChaptersList
