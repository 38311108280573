import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"
import { ListItemNode, ListNode } from "@lexical/list"
import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ImageNode } from "../nodes/ImageNode"
import { VideoNode } from "../nodes/VideoNode"
import PlaygroundEditorTheme from "../themes/PlaygroundEditorTheme"

const LexicalRenderer = ({ contentTree }: { contentTree: any }) => {
    if (!contentTree) return null;
    
    // Configure Lexical editor
    const editorConfig = {
        namespace: "ReadOnlyEditor",
        editorState: typeof contentTree === 'string' ? contentTree : JSON.stringify(contentTree),
        theme: PlaygroundEditorTheme,
        editable: false,
        onError: (error: Error) => console.error(error),
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            ImageNode,
            VideoNode
        ]
    };

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <div className="editor-shell">
                <RichTextPlugin
                    contentEditable={
                        <div className="editor-input">
                            <ContentEditable className="ContentEditable__root" />
                        </div>
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                />
            </div>
        </LexicalComposer>
    );
};

export default LexicalRenderer;