import { CardV2, TypographyV2 } from "components/v2/components/common"
import { eventType } from "components/v2/components/features/unit/unitConstants"
import TagWithIcon from "components/v2/components/TagWithIcon"
import React from "react"
import { UnitChapterElementResponse } from "types/Redux"

const UnitDetailsElementItem = ({ element }: { element: UnitChapterElementResponse }) => {
    return (
        <CardV2 className="w-full !p-2">
            <div className="flex items-center">
                <TagWithIcon
                    className="!bg-base-lighter [&_path]:fill-[#9CA3AF]"
                    eventType={eventType[element.element_type]}
                />
                <TypographyV2 className="ml-3" width="bold">
                    {element.library_template?.title || element.questionnaire?.title}
                </TypographyV2>
            </div>
        </CardV2>
    )
}

export default UnitDetailsElementItem
