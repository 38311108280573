import { BoltIcon } from "components/icons"
import { TypographyV2 } from "components/v2/components/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useLibraryTemplateSelector } from "store/hooks"
import { UnitBuilderPreviewChaptersList } from "./UnitBuilderPreviewChaptersList"

const UnitBuilderPreviewSidebar = () => {
    const { libraryTemplate } = useLibraryTemplateSelector()
    const { t } = useTranslation("unit")

    if (!libraryTemplate) {
        return <></>
    }

    const { title, description } = libraryTemplate

    return (
        <div className="h-full py-4 pl-4">
            <div className="py-4 bg-light-background border border-light-border rounded-lg h-full w-72 flex flex-col">
                <TypographyV2 type="sm" width="semibold" className="text-med-black py-1.5 px-3.5">
                    {title}
                </TypographyV2>
                <TypographyV2 type="sm" className="text-base-black line-clamp-2 py-1.5 px-3.5 pb-3">
                    {description}
                </TypographyV2>
                <TypographyV2 className="text-base-dark pt-4 px-4 pb-2" width="semibold">
                    {t("chapters")}
                </TypographyV2>
                <UnitBuilderPreviewChaptersList />
                <div className="flex gap-1 p-2">
                    <div>
                        <BoltIcon width={12} height={12} />
                    </div>
                    <TypographyV2 className="text-med-black">
                        <strong>{t("tip.title")} </strong>
                        {t("tip.text")}
                    </TypographyV2>
                </div>
            </div>
        </div>
    )
}

export default UnitBuilderPreviewSidebar
