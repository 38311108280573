import React from "react"
import { CardV2, TypographyV2 } from "../../common"
import TagWithIcon from "../../TagWithIcon"
import { unitChapterElementTypeList } from "components/v2/constants"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { openAddElementModalAction } from "store/unit/actions"
import { elementType } from "./unitConstants"
import { EventTypes } from "types"

const CreateUnitChapterElementPopover = () => {
    const { t } = useTranslation("common")

    const dispatch = useDispatch()

    return (
        <CardV2 className="px-0 py-0 pr-0 max-w-64">
            <div className="flex flex-col">
                {unitChapterElementTypeList.map(({ title, id }) => (
                    <div
                        className="flex items-center py-4 px-3"
                        onClick={() => dispatch(openAddElementModalAction(elementType[id] as any))}
                    >
                        <TagWithIcon eventType={id} />
                        <TypographyV2 className="ml-3" width="semibold">
                            {t(`eventType.${title}`)}
                        </TypographyV2>
                    </div>
                ))}
            </div>
        </CardV2>
    )
}

export default CreateUnitChapterElementPopover
