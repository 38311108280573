import React from "react"
import { useCareProtocaolAddElementModal } from "./useCareProtocaolAddElementModal"
import { AddScheduleElementModal } from "./AddScheduleElementModal"
import { AddSequenceElementModal } from "./AddSequenceElementModal"

const CareProtocolAddNewElementModal = () => {
    const {
        isOpenedScheduleModal,
        isOpenedSequenceModal,
        onClose,
        t,
        list,
        libraryTemplateType,
        onAddNewElement,
        cpElement
    } = useCareProtocaolAddElementModal()
   
    return (
        <>
            <AddScheduleElementModal
                t={t}
                isOpened={isOpenedScheduleModal}
                onClose={onClose}
                list={list}
                libraryTemplateType={libraryTemplateType}
                onAddNewElement={onAddNewElement}
                cpElement={cpElement}
            />
            <AddSequenceElementModal
                isOpened={isOpenedSequenceModal}
                onClose={onClose}
                t={t}
                list={list}
                libraryTemplateType={libraryTemplateType}
                onAddNewElement={onAddNewElement}
                cpElement={cpElement}
            />
        </>
    )
}

export default CareProtocolAddNewElementModal
