import React from "react"

import { useLibraryTemplateList } from "./useLibraryTemplateList"
import { LibraryTemplateFilters, EmptyListPlaceholder, TableListV2 } from "components/v2"
import LibraryTemplateItem from "../LibraryTemplateItem"
import { LibraryTemplateType } from "@prisma/client"

const LibraryTemplateList = ({ libraryTemplateType }: { libraryTemplateType: LibraryTemplateType }) => {
    const { list, isLoading, visibleList, headerCell, emptyTextProps } = useLibraryTemplateList(libraryTemplateType)

    return (
        <div className="w-full">
            {!!list.length && <LibraryTemplateFilters libraryTemplateType={libraryTemplateType} />}
            <TableListV2
                isLoading={isLoading}
                isEmpty={!visibleList.length}
                headerCells={headerCell}
                emptyListComponent={<EmptyListPlaceholder {...emptyTextProps} />}
                listComponent={visibleList?.map((item) => (
                    <LibraryTemplateItem key={item.id} {...item} />
                ))}
            />
        </div>
    )
}

export default LibraryTemplateList
