import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { AudienceIcon, EyeIcon, ProfessionalInfo } from "components/icons"
import { useTranslation } from "react-i18next"
import { useDiagnoses, useLibraryTemplateSelector, useRoles } from "store/hooks"
import { truncate } from "lodash"
import { useIsHeLanguage } from "hooks"
import { userRoleTitles } from "components/v2/constants"
import { ExerciseType, Gender, Language, LibraryTemplateType, PreviousInstance, UserRole } from "@prisma/client"
import { genders, getExerciseSchemaValidation, getLanguageList } from "./exerciseBasicInfoFormConst"
import { useForm } from "react-hook-form"
import { CreateExerciseFormSubmitPayload, RadioGroupMode } from "types/exercises/ExerciseDef"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from "store"
import {
    createLibraryTemplateAction,
    setLibraryTemplateFormDirtyAction,
    setLibraryTemplateFormErrorAction,
    updateLibraryTemplateAction
} from "store/library-template/actions"
import transfromDiagnosis from "utils/intake/transfromDiagnosis"

export const useLibraryTemplateForm = () => {
    const dispatch = useDispatch()
    const { libraryTemplate, type } = useLibraryTemplateSelector()
    const { t } = useTranslation("exercise")

    const rolesData = useRoles()

    const diagnosesData = useDiagnoses()

    const { isHeLanguage } = useIsHeLanguage()

    const [activeStepIndex, setActiveStepIndex] = useState(0)

    const isEducationalContent = type === LibraryTemplateType.EDUCATIONAL_CONTENT
    const isCareProtocol = type === LibraryTemplateType.CARE_PROTOCOL
    const isExercise = type === LibraryTemplateType.EXERCISE
    const isUnit = type === LibraryTemplateType.UNIT

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        reset
    } = useForm({
        defaultValues: {
            gender_mode: RadioGroupMode.ALL,
            min_age: 18,
            max_age: 65,
            age_mode: RadioGroupMode.ALL,
            title: "",
            description: "",
            public_name: "",
            brief_summary: "",
            gender_custom: Gender.male,
            language: Language.en,
            diagnose_ids: [],
            role_ids: [],
            exercise_type: ExerciseType.EXERCISE_JOURNAL,
            previous_instance: PreviousInstance.CURRENT_ANSWERS_ONLY
        },
        resolver: yupResolver(getExerciseSchemaValidation())
    })

    const defaultFormValues = useMemo(() => {
        return {
            gender_mode: libraryTemplate?.gender === Gender.all ? RadioGroupMode.ALL : RadioGroupMode.CUSTOM,
            min_age: libraryTemplate?.min_age ?? 18,
            max_age: libraryTemplate?.max_age ?? 65,
            age_mode: libraryTemplate?.max_age ? RadioGroupMode.CUSTOM : RadioGroupMode.ALL,
            title: libraryTemplate?.title ?? "",
            description: libraryTemplate?.description ?? "",
            public_name: libraryTemplate?.public_name ?? "",
            brief_summary: libraryTemplate?.brief_summary ?? "",
            gender_custom: libraryTemplate?.gender ?? Gender.male,
            language: libraryTemplate?.language ?? Language.en,
            diagnose_ids: libraryTemplate?.diagnoses?.map(({ diagnose_id }) => diagnose_id) ?? [],
            role_ids: libraryTemplate?.target_roles?.map(({ role_id }) => role_id) ?? [],
            exercise_type: libraryTemplate?.exercise_type ?? ExerciseType.EXERCISE_JOURNAL,
            previous_instance: libraryTemplate?.previous_instance ?? PreviousInstance.CURRENT_ANSWERS_ONLY
        }
    }, [libraryTemplate])

    const isFormError = !!Object.entries(errors).length

    useEffect(() => {
        dispatch(setLibraryTemplateFormErrorAction(isFormError))
    }, [isFormError])

    useEffect(() => {
        dispatch(setLibraryTemplateFormDirtyAction(isDirty))
    }, [isDirty])

    useEffect(() => {
        if (defaultFormValues) {
            reset(defaultFormValues)
        }
    }, [reset, defaultFormValues])

    const sectionRefs = useRef([])
    const observer = useRef<IntersectionObserver | null>(null)

    const languagesList = useMemo(() => getLanguageList(), [isHeLanguage])

    const diagnosesList = useMemo(() => diagnosesData.map(({ id, name }) => ({ id, title: name })), [diagnosesData])
    const gendersWithLocale = useMemo(
        () => genders.map(({ title, ...rest }) => ({ ...rest, title: t(`common:genders.${title}`) })),
        [t]
    )

    const rolesList = useMemo(
        () => rolesData.map(({ id, role }) => ({ id, title: t(`common:roles.${userRoleTitles[role as UserRole]}`) })),
        [rolesData]
    )

    useEffect(() => {
        if (observer.current) {
            return
        }
        const observerOptions = {
            threshold: 0.5
        }

        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = sectionRefs.current.indexOf(entry.target)
                    setActiveStepIndex(index)
                }
            })
        }

        observer.current = new IntersectionObserver(observerCallback, observerOptions)
        sectionRefs.current.forEach((section) => section && observer.current.observe(section))

        return () => {
            observer.current?.disconnect()
            observer.current = null
        }
    }, [sectionRefs.current])

    const handleScreeningScroll = useCallback(
        (sectionIndex: number) => {
            if (sectionRefs.current && sectionRefs.current[sectionIndex]) {
                sectionRefs.current[sectionIndex].scrollIntoView({ behavior: "smooth" })
            }
        },
        [sectionRefs.current]
    )

    const stepsList = useMemo(() => {
        return [
            {
                title: t("createModal.professionalInfo"),
                icon: ProfessionalInfo,
                onClick: () => {
                    handleScreeningScroll(0)
                }
            },
            {
                title: t("audience"),
                icon: AudienceIcon,
                onClick: () => {
                    handleScreeningScroll(1)
                }
            },
            {
                title: t("createModal.audienceView"),
                icon: EyeIcon,
                onClick: () => {
                    handleScreeningScroll(2)
                }
            }
        ]
    }, [setActiveStepIndex, handleScreeningScroll])

    const submitHandler = ({
        gender_mode,
        age_mode,
        min_age,
        max_age,
        gender_custom,
        ...data
    }: CreateExerciseFormSubmitPayload) => {
        const { public_name, description } = data
        const gender = gender_mode === RadioGroupMode.ALL ? Gender.all : gender_custom
        const newMinAge = age_mode !== RadioGroupMode.ALL ? Number(min_age) : null
        const newMaxAge = age_mode !== RadioGroupMode.ALL ? Number(max_age) : null
        const payload = {
            gender,
            min_age: newMinAge,
            max_age: newMaxAge,
            patient_description: description,
            patient_title: public_name,
            recommended_recurrence: 0,
            type,
            ...data
        }
        if (libraryTemplate) {
            dispatch(updateLibraryTemplateAction(libraryTemplate.id, payload))
        } else {
            dispatch(createLibraryTemplateAction(payload))
        }
    }

    return {
        t,
        control,
        errors,
        stepsList,
        isEducationalContent,
        activeStepIndex,
        sectionRefs,
        diagnosesList,
        languagesList,
        rolesList,
        gendersWithLocale,
        isCareProtocol,
        isExercise,
        type,
        submitHandler,
        handleSubmit,
        isUnit
    }
}
