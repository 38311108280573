import { flip, useFloating } from "@floating-ui/react-dom"
import { Button, ModalCompatiblePortal, TruncatedString } from "components/common"
import { FormControlProps } from "forms"
import NumberField from "forms/NumberField"
import { RenderLabel } from "forms/RenderLabel"
import SuperSelect from "forms/SuperSelect"
import { BasicItem, ReferenceEl } from "forms/SuperSelect/types"
import useRecurrenceUnits from "hooks/useRecurrenceUnits"
import { lowerFirst, upperFirst } from "lodash"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { RecurrenceUnit } from "types/Misc"
import { classNames, createSetter } from "utils"
import { getWeekDayByDayNumber, localeFormat, weekDaysInNumber } from "utils/dates"
import { EventConfigState, ReccurrenceField } from "../types"
import { useCurrentUser } from "store/hooks"
import { useRecurrenceDescriptionShort } from "hooks/useRecurrenceDescription"

type RepeatInputProps = FormControlProps<EventConfigState["recurrence"]> & {
    customWeekDay?: boolean
}

const RepeatInput = ({
    setValue,
    value,
    classList,
    readOnly,
    customWeekDay,
    getAdjacentFieldValue,
    ...props
}: RepeatInputProps) => {
    const { t } = useTranslation("patient")
    const [open, setOpen] = useState(false)
    const dateOnTimeline = getAdjacentFieldValue("time")?.date_on_timeline ?? new Date()
    const recurrenceUnits = useRecurrenceUnits(true)
    const calculatedInterval = value.unit === "weeks" ? value.interval * 7 : value.interval
    const recurrenceDescription = useRecurrenceDescriptionShort(calculatedInterval, value.weekDay)
    const { recurrenceItems, frequencyItems } = useMemo(() => {
        const items = recurrenceUnits.map(({ value, label }) => ({ id: value as string, label }))
        return {
            frequencyItems: items,
            recurrenceItems: items.concat([{ id: "custom", label: t("recurrence.custom") }])
        }
    }, [recurrenceUnits, t])

    const { floatingStyles, refs } = useFloating<ReferenceEl>({
        placement: "bottom-start",
        middleware: [flip()]
    })

    const onChangeWeekDay = (weekDay: number) => {
        setValue((value) => ({ ...value, weekDay }))
    }

    if (customWeekDay && readOnly) {
        return <TruncatedString className="text-text-blue" string={recurrenceDescription} maxLen={23} />
    }

    return (
        <>
            <div ref={refs.setReference} className={classNames("flex items-center gap-4", classList.wrapper)}>
                <RenderLabel {...props} classList={classList} />
                <SuperSelect
                    id="repeat-select"
                    placeholder={t("recurrence.none")}
                    usePlaceholderAsAll
                    setValue={(value: string) => {
                        if (!value) {
                            setValue((value) => ({ ...value, enabled: false, interval: 0, unit: null }))
                        } else if (value === "custom") {
                            setValue((value) => ({ ...value, unit: "days", enabled: true }))
                            setOpen(true)
                        } else {
                            setOpen(false)
                            setValue((recurrence) => ({
                                ...recurrence,
                                unit: value as RecurrenceUnit,
                                interval: 1,
                                enabled: true,
                                weekDay: 1
                            }))
                        }
                    }}
                    RenderButton={({ item }) => (
                        <>{open || value.interval > 1 ? t("recurrence.custom") + "..." : item.label}</>
                    )}
                    disabled={readOnly}
                    value={value?.enabled ? (value?.interval > 1 ? "custom" : value.unit) : undefined}
                    items={recurrenceItems}
                    classList={classList}
                    withSearch={false}
                />
                <RecurrenceDescription
                    {...value}
                    inputClassList={classList.input}
                    onChangeWeekDay={onChangeWeekDay}
                    date={dateOnTimeline}
                    customWeekDay={customWeekDay}
                />
            </div>
            <ModalCompatiblePortal>
                {open && (
                    <div
                        ref={refs.setFloating}
                        style={{
                            ...floatingStyles,
                            border: "1px solid rgb(222 229 242)"
                        }}
                        className="flex flex-col gap-7 p-6 rounded-lg bg-white text-text-blue"
                    >
                        <CustomReccurrence {...{ open, setOpen, value, setValue }} items={frequencyItems} />
                    </div>
                )}
            </ModalCompatiblePortal>
        </>
    )
}

export default RepeatInput

const timeformatByRecurrenceUnit: Record<RecurrenceUnit, string> = {
    days: undefined,
    weeks: "eeee"
}

export const RecurrenceDescription = ({
    unit,
    interval,
    date,
    weekDay,
    customWeekDay,
    onChangeWeekDay,
    inputClassList
}: {
    date: Date
    unit: RecurrenceUnit
    interval: number
    weekDay?: number
    customWeekDay?: boolean
    onChangeWeekDay: (day: number) => void
    inputClassList?: string
}) => {
    const { t, i18n } = useTranslation("patient")
    const currentUser = useCurrentUser()
    const weekDays = useMemo(
        () =>
            weekDaysInNumber.map((weekDayNumber) => ({
                id: weekDayNumber,
                label: t(`dates:dayLabels.${getWeekDayByDayNumber(weekDayNumber, currentUser.preferences.week_start)}`)
            })),
        []
    )

    if (!unit) return null

    const timeFormat = timeformatByRecurrenceUnit[unit]
    const on = timeFormat && `${t("common:onUpper")} ${localeFormat(date, timeFormat, i18n)}`
    const isSelectWeekDay = unit === "weeks" && customWeekDay

    return (
        <>
            {isSelectWeekDay ? (
                <div className="flex items-center gap-4">
                    <div>{t("common:on")}</div>
                    <SuperSelect
                        id="week-day"
                        value={weekDay}
                        setValue={onChangeWeekDay}
                        items={weekDays}
                        withSearch={false}
                        classList={{ wrapper: "w-28", input: inputClassList }}
                    />
                </div>
            ) : (
                <TruncatedString
                    className="text-text-blue"
                    string={
                        interval === 1
                            ? on
                            : `${t(`recurrence.everyX${upperFirst(unit)}`, { interval })} ${lowerFirst(on) ?? ""}`
                    }
                    maxLen={23}
                />
            )}
        </>
    )
}

const CustomReccurrence = ({
    value,
    items,
    setOpen,
    setValue
}: {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    value: ReccurrenceField
    setValue: Dispatch<SetStateAction<ReccurrenceField>>
    items: BasicItem[]
}) => {
    const [state, setState] = useState(value)
    const { t } = useTranslation("patient")
    return (
        <>
            <div className="flex gap-2 items-center">
                {t("recurrence.frequency")}
                <SuperSelect
                    id="frequency-select"
                    setValue={createSetter(setState, "unit")}
                    value={state.unit}
                    items={items}
                    classList={{
                        input: "text-dark-blue border border-border-blue py-2.5 px-4 rounded-md"
                    }}
                    withSearch={false}
                />
            </div>
            <div className="flex gap-2 items-center">
                {t("common:every")}
                <NumberField
                    id="recurrence-interval"
                    value={state.interval}
                    min={1}
                    setValue={createSetter(setState, "interval")}
                    aria-disabled={!state.enabled}
                />
                {t(`recurrence.intervals.${state.unit}`)}
            </div>

            <div className="flex gap-2 justify-end">
                <Button theme="secondary_dark" className="rounded-md w-28" onClick={() => setOpen(false)}>
                    {t("common:cancel")}
                </Button>
                <Button
                    theme="dark_primary"
                    className="w-28"
                    onClick={() => {
                        setValue(state)
                        setOpen(false)
                    }}
                >
                    {t("common:done")}
                </Button>
            </div>
        </>
    )
}
