import * as types from "./types"
import { arrayMove } from "@dnd-kit/sortable"
import { AppAction } from "types/Redux"
import { toast } from "react-toastify"
import i18n from "locales"
import { IUpdateCareProtocolElementsPositionsAction } from "types/CareProtocol"
import { LibraryTemplateType, TriggerType } from "@prisma/client"
import {
    createCareProtocolElement,
    deleteCareProtocolElement,
    getCareProtocolElementsByCareProtocolId,
    updateCareProtocolElement,
    updateCareProtocolElementPositions
} from "api/careProtocol/cpElements"

type CareProtocolElement = any

export const setNewCareProtocolElementsListAction = (payload: CareProtocolElement[]) => {
    return { type: types.SET_CARE_PROTOCOL_ELEMENTS_LIST, payload }
}

export const addNewCareProtocolElementToList = (payload: CareProtocolElement) => {
    return { type: types.ADD_NEW_CARE_PROTOCOL_ELEMENT_TO_LIST, payload }
}

export const editCareProtocolElementToList = (payload: CareProtocolElement) => {
    return { type: types.EDIT_CARE_PROTOCOL_ELEMENT_TO_LIST, payload }
}

export const archiveCareProtocolElementToList = (payload: CareProtocolElement) => {
    return { type: types.ARCHIVE_CARE_PROTOCOL_ELEMENT_TO_LIST, payload }
}

export const clearCareProtocolElementsList = () => {
    return { type: types.CLEAR_CARE_PROTOCOL_ELEMENTS_LIST }
}

export const getCareProtocolElementsByCareProtocolIdAction =
    (id: number): AppAction<boolean> =>
    async (dispatch) => {
        const elements = await getCareProtocolElementsByCareProtocolId(id)
        dispatch(setNewCareProtocolElementsListAction(elements))
        return true
    }

export const deleteCareProtocolElementByIdAction =
    (id: number): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            await deleteCareProtocolElement(id)
            toast.success(i18n.t("notifications.successfullyDeleted", { ns: "careProtocol" }))
            let elements = getState().careProtocol.list
            elements = elements.filter((element) => element.id !== id)
            dispatch(setNewCareProtocolElementsListAction(elements))

            return true
        } catch (err) {}
    }

export const updateCareProtocolElementsPositionsAction =
    ({ activeId, overId, elementId }: IUpdateCareProtocolElementsPositionsAction): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            const sequentialElements = [...getState().careProtocol.list].filter(
                (element) =>
                    element.trigger_type == TriggerType.SEQUENTIAL_MANUAL ||
                    element.trigger_type == TriggerType.SEQUENTIAL_TRIGGERED
            )
            const scheduledElements = [...getState().careProtocol.list].filter(
                (element) => element.trigger_type == TriggerType.SCHEDULED
            )
            const oldIdx = sequentialElements.findIndex(({ id }) => id === activeId)
            const newIdx = sequentialElements.findIndex(({ id }) => id === overId)

            // If positions are the same, no need to update
            if (oldIdx === newIdx) {
                return true
            }

            const newSequentialList = arrayMove(sequentialElements, oldIdx, newIdx)

            // Update positions in the elements
            const updatedSequentialList = newSequentialList.map((element, idx) => ({
                ...element,
                position: idx + 1
            }))

            const requestNewList = updatedSequentialList.map(({ id, position }) => ({
                id,
                position
            }))

            dispatch(setNewCareProtocolElementsListAction([...scheduledElements, ...updatedSequentialList]))

            await updateCareProtocolElementPositions(requestNewList, elementId)

            toast.success(i18n.t("notifications.positionsWereChangedSuccessfully", { ns: "careProtocol" }))
            return true
        } catch (err) {}
    }

export const addNewCareProtocolElementAction =
    (body: any): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const newElement = await createCareProtocolElement(body)
            toast.success(i18n.t("notifications.successfullyCreated", { ns: "careProtocol" }))
            dispatch(addNewCareProtocolElementToList({ ...newElement, isCreated: true }))
            return true
        } catch (err) {}
    }

export const editCareProtocolElementAction =
    (body: any): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const newElement = await updateCareProtocolElement(body.id, body)
            toast.success(i18n.t("notifications.successfullyUpdated", { ns: "careProtocol" }))
            dispatch(editCareProtocolElementToList({ ...newElement, isCreated: true }))
            return true
        } catch (err) {}
    }

export const archiveCareProtocolElementAction =
    (body: any): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await deleteCareProtocolElement(body.id)
            toast.success(i18n.t("notifications.successfullyArchived", { ns: "careProtocol" }))
            dispatch(archiveCareProtocolElementToList(body))
            return true
        } catch (err) {}
    }

export const updateCareProtocolElementAction =
    (
        id: number,
        body: any
    ): AppAction<boolean> => //TODO: change type
    async (dispatch) => {
        try {
            const response = await updateCareProtocolElement(id, body)
            toast.success(i18n.t("notifications.successfullyUpdated", { ns: "careProtocol" }))
            dispatch(getCareProtocolElementsByCareProtocolIdAction(response.library_template_id))
            return true
        } catch (err) {}
    }

export const openAddCareProtocolElementModalAction = (payload: {
    isOpenedScheduleModal?: boolean
    isOpenedSequenceModal?: boolean
    libraryTemplateType?: LibraryTemplateType | "QUESTIONNAIRE"
    elementId?: number
}) => {
    return { type: types.OPEN_ADD_CARE_PROTOCOL_ELEMENT_MODAL, payload }
}

export const closeAddCareProtocolElementModalAction = () => {
    return { type: types.CLOSE_ADD_CARE_PROTOCOL_ELEMENT_MODAL }
}
