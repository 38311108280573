export const SET_CHAPTERS_LIST = "chapter/SET_CHAPTERS_LIST"
export const ADD_NEW_CHAPTER_TO_LIST = "chapter/ADD_NEW_CHAPTER_TO_LIST"
export const SET_SELECTED_CHAPTER = "chapter/SET_SELECTED_CHAPTER"
export const SET_ELEMENTS_LIST = "element/SET_ELEMENTS_LIST"
export const ADD_NEW_ELEMENT_TO_LIST = "element/ADD_NEW_ELEMENT_TO_LIST"
export const OPEN_ADD_ELEMENT_MODAL = "element/OPEN_ADD_ELEMENT_MODAL"
export const CLOSE_ADD_ELEMENT_MODAL = "element/CLOSE_ADD_ELEMENT_MODAL"
export const SELECT_ELEMENT_FOR_ADD_VARIABLE_MODAL = "element/SELECT_ELEMENT_FOR_ADD_VARIABLE_MODAL"
export const REMOVE_SELECTED_ELEMENT_FOR_ADD_VARIABLE_MODAL = "element/REMOVE_SELECTED_ELEMENT_FOR_ADD_VARIABLE_MODAL"
export const OPEN_DELETE_CHAPTER_MODAL = "unit/OPEN_DELETE_CHAPTER_MODAL"
export const CLOSE_DELETE_CHAPTER_MODAL = "unit/CLOSE_DELETE_CHAPTER_MODAL"
export const OPEN_DELETE_ELEMENT_MODAL = "unit/OPEN_DELETE_ELEMENT_MODAL"
export const CLOSE_DELETE_ELEMENT_MODAL = "unit/CLOSE_DELETE_ELEMENT_MODAL"
