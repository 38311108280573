import React, { useState } from "react"
import { DndContext } from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { CircleCount, DraggableItemV2, TagWithIcon, TypographyV2 } from "components/v2"
import { useCareProtocolElementList } from "./useCareProtocolElementList"
import { Loader } from "@aws-amplify/ui-react"
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers"
import CreateFirstCareProtocolEventList from "../CreateFirstCareProtocolEventList"
import { ElementType, TriggerType } from "@prisma/client"
import _ from "lodash"
import { EventTypes } from "types"
import i18n from "locales"
import { ChevronDownIcon, ClockIcon, CursorArrowRaysIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import { CareProtocolElementModalType } from "components/v2/constants/careProtocol"
import CpPopoverElement from "../../components/CpPopoverElement"
import { useCreateFirstCareProtocolEventList } from "../CreateFirstCareProtocolEventList/useCreateFirstCareProtocolEventList"
import { CopyIcon, EditUnitDetailsIcon, ArchiveIcon } from "components/icons"
import ScheduleItemCard from "../../components/schedule/ScheduleItemCard"
interface IElementListProps {
    elementId: number
}

const CareProtocolElementList: React.FC<IElementListProps> = ({ elementId }) => {
    const { t, isLoading, sensors, careProtocolElements, onDragEnd } = useCareProtocolElementList(elementId)
    const { isHeLanguage, careProtocolElementTypesWithLocale, onAddElementClick, onEditElementClick, onCopyElementClick, onArchiveElementClick } =
        useCreateFirstCareProtocolEventList()
    const [isScheduledExpanded, setIsScheduledExpanded] = useState(true)
    const [isSequentialExpanded, setIsSequentialExpanded] = useState(true)

    if (isLoading)
        return (
            <div className="w-full flex justify-center">
                <Loader />
            </div>
        )

    if (!careProtocolElements.length) return <CreateFirstCareProtocolEventList />

    const mapScheduledElement = (element: any) => {
        const elementTitle: Record<ElementType, string> = {
            [ElementType.UNIT]: element.unit_element?.title ?? "",
            [ElementType.EXERCISE]: element.exercise_element?.title ?? "",
            [ElementType.QUESTIONNAIRE]: element.questionnaire_element?.title ?? ""
        }

        return {
            ...element,
            id: element.id,
            title: elementTitle[element.element_type as keyof typeof elementTitle],
            time: element.recurrence_interval
                ? i18n.t("withRecurrence", { ns: "careProtocol", recurrence: element.recurrence_interval })
                : i18n.t("oneTime", { ns: "careProtocol" }),
            when: element.delay
                ? i18n.t("withDelay", { ns: "careProtocol", delay: element.delay })
                : i18n.t("fromStart", { ns: "careProtocol" }),
            eventType: _.capitalize(element.element_type) as EventTypes
        }
    }

    const mapSequentialElement = (element: any) => {
        const elementTitle: Record<ElementType, string> = {
            [ElementType.UNIT]: element.unit_element?.title ?? "",
            [ElementType.EXERCISE]: element.exercise_element?.title ?? "",
            [ElementType.QUESTIONNAIRE]: element.questionnaire_element?.title ?? ""
        }

        return {
            ...element,
            id: element.id,
            title: elementTitle[element.element_type as keyof typeof elementTitle],
            time: element.recurrence_interval
                ? i18n.t("withRecurrence", { ns: "careProtocol", recurrence: element.recurrence_interval })
                : i18n.t("oneTime", { ns: "careProtocol" }),
            when: element.delay
                ? i18n.t("withDelay", { ns: "careProtocol", delay: element.delay })
                : i18n.t("fromStart", { ns: "careProtocol" }),
            eventType: _.capitalize(element.element_type) as EventTypes,
            delay: element.delay,
            trigger_type: element.trigger_type
        }
    }

    const scheduleElements = careProtocolElements
        .filter((element) => element.trigger_type === TriggerType.SCHEDULED)
        .map(mapScheduledElement)
    const sequentialElements = careProtocolElements
        .filter(
            (element) =>
                element.trigger_type === TriggerType.SEQUENTIAL_MANUAL ||
                element.trigger_type === TriggerType.SEQUENTIAL_TRIGGERED
        )
        .map(mapSequentialElement)

    const isElementwithSign = (element: any) =>
        Boolean(element.trigger_type === TriggerType.SEQUENTIAL_MANUAL || element.delay)

    const dropDownActionsList = (element: any) => [
        {
            title: t("editElementDetails"),
            icon: EditUnitDetailsIcon,
            onClick: onEditElementClick(element)
        },
        {
            title: "Make a copy",
            icon: CopyIcon,
            onClick: onCopyElementClick(element)
        },
        {
            border: true,
            title: "Archive",
            icon: ArchiveIcon,
            onClick: onArchiveElementClick(element)
        }
    ]

    return (
        <div className="flex flex-col w-full gap-6">
            <div className="flex flex-col w-full border border-light-border rounded-lg bg-light-background p-4">
                <div className="flex flex-row w-full items-center justify-between mb-2">
                    <div
                        className="flex flex-row items-center gap-2 cursor-pointer"
                        onClick={() => setIsScheduledExpanded(!isScheduledExpanded)}
                    >
                        <ChevronDownIcon
                            className={classNames("w-4 h-4 transition-transform", !isScheduledExpanded && "rotate-180")}
                        />
                        <TypographyV2 type="xs" width="bold">
                            {t("scheduledEvents")}
                        </TypographyV2>
                        <CircleCount count={scheduleElements?.length} />
                    </div>
                    <CpPopoverElement
                        withDivider={false}
                        TargetComponent={({ close }: { close: () => void }) => (
                            <div className="flex flex-row items-center cursor-pointer">
                                <TypographyV2 type="xs" width="medium" className="text-[#3C7FF9]">
                                    {t("addContent")}
                                </TypographyV2>
                            </div>
                        )}
                        PopoverComponent={() => (
                            <div className="flex flex-col bg-white rounded-lg p-4 border border-lightest-gray w-[249px]">
                                {careProtocolElementTypesWithLocale.map(({ title, id, icon, type }) => (
                                    <div
                                        key={id}
                                        className="w-[316px] h-[62px] flex items-center cursor-pointer"
                                        onClick={onAddElementClick(CareProtocolElementModalType.SCHEDULE, type)}
                                    >
                                        <div className="flex items-center w-50">
                                            <TagWithIcon eventType={icon} />
                                            <TypographyV2 className={isHeLanguage ? "mr-2.5" : "ml-2.5"} type="sm">
                                                {title}
                                            </TypographyV2>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    />
                </div>
                {isScheduledExpanded && (
                    <>
                        {scheduleElements.length > 0 ? (
                            <div className="flex flex-col w-full">
                                <div className="flex flex-row w-full gap-2 flex-wrap">
                                    {scheduleElements.map((element) => (
                                        <div key={element.id + element.title} className="w-[270px]">
                                            <ScheduleItemCard
                                                title={element.title}
                                                time={element.time}
                                                when={element.when}
                                                eventType={element.eventType}
                                                dropDownActionsList={dropDownActionsList(element)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col">
                                <TypographyV2 type="xs" width="bold">
                                    {t("noScheduledEvents")}
                                </TypographyV2>
                                <TypographyV2 type="xs" width="normal">
                                    {t("noScheduledEventsSubtitle")}
                                </TypographyV2>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="flex flex-col w-full border border-light-border rounded-lg bg-light-background p-4">
                <div className="flex flex-row w-full items-center justify-between mb-2">
                    <div
                        className="flex flex-row items-center gap-2 cursor-pointer"
                        onClick={() => setIsSequentialExpanded(!isSequentialExpanded)}
                    >
                        <ChevronDownIcon
                            className={classNames(
                                "w-4 h-4 transition-transform",
                                !isSequentialExpanded && "rotate-180"
                            )}
                        />
                        <TypographyV2 type="xs" width="bold">
                            {t("sequentialEvents")}
                        </TypographyV2>
                        <CircleCount count={sequentialElements?.length} />
                    </div>
                    <CpPopoverElement
                        withDivider={false}
                        TargetComponent={({ close }: { close: () => void }) => (
                            <div className="flex flex-row items-center cursor-pointer">
                                <TypographyV2 type="xs" width="medium" className="text-[#3C7FF9]">
                                    {t("addContent")}
                                </TypographyV2>
                            </div>
                        )}
                        PopoverComponent={() => (
                            <div className="flex flex-col bg-white rounded-lg p-4 border border-lightest-gray w-[249px]">
                                {careProtocolElementTypesWithLocale.map(({ title, id, icon, type }) => (
                                    <div
                                        key={id}
                                        className="w-[316px] h-[62px] flex items-center cursor-pointer"
                                        onClick={onAddElementClick(CareProtocolElementModalType.SEQUENCE, type)}
                                    >
                                        <div className="flex items-center w-50">
                                            <TagWithIcon eventType={icon} />
                                            <TypographyV2 className={isHeLanguage ? "mr-2.5" : "ml-2.5"} type="sm">
                                                {title}
                                            </TypographyV2>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    />
                </div>
                {isSequentialExpanded && (
                    <DndContext
                        sensors={sensors}
                        onDragEnd={onDragEnd}
                        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                    >
                        {sequentialElements.length > 0 ? (
                            <div className="flex flex-col gap-2.5">
                                <SortableContext items={careProtocolElements} strategy={verticalListSortingStrategy}>
                                    {sequentialElements
                                        ?.sort((a, b) => a.position - b.position)
                                        ?.map((item) => {
                                            const isShowSign = isElementwithSign(item)
                                            const isElementManual = item.trigger_type === TriggerType.SEQUENTIAL_MANUAL
                                            return (
                                                <DraggableItemV2 id={item.id} key={item.id + item.title}>
                                                    <div>
                                                        {isShowSign && (
                                                            <div className="flex flex-row items-center gap-1">
                                                                {careProtocolElements ? (
                                                                    <CursorArrowRaysIcon className="w-[17px] h-[17px] text-[#9CA3AF]" />
                                                                ) : (
                                                                    <ClockIcon className="w-[17px] h-[17px] text-[#9CA3AF]" />
                                                                )}
                                                                <TypographyV2
                                                                    type="xs"
                                                                    width="normal"
                                                                    className="text-[#6B7280]"
                                                                >
                                                                    {isElementManual
                                                                        ? t("manualTrigger")
                                                                        : item.delay
                                                                        ? `${item.delay} ${t("daysDelay")}`
                                                                        : t("noDelay")}
                                                                </TypographyV2>
                                                            </div>
                                                        )}
                                                        <div className="flex flex-row items-center gap-1 mt-2">
                                                            {isShowSign && (
                                                                <div className="w-[35px] flex justify-center relative">
                                                                    <div className="absolute left-[8px] top-[-31px] w-[22px] h-[32px] border-l-2 border-b-2 border-[#E5E7EB] rounded-bl-lg"></div>
                                                                </div>
                                                            )}
                                                            <ScheduleItemCard
                                                                isVerticalView
                                                                isDraggable
                                                                title={item.title}
                                                                time={item.time}
                                                                when={item.when}
                                                                eventType={item.eventType}
                                                                dropDownActionsList={dropDownActionsList(item)}
                                                            />
                                                        </div>
                                                    </div>
                                                </DraggableItemV2>
                                            )
                                        })}
                                </SortableContext>
                            </div>
                        ) : (
                            <div className="flex flex-col h-[91px] justify-center w-full">
                                <TypographyV2 type="xs" width="bold" className="text-center">
                                    {t("noSequentialEvents")}
                                </TypographyV2>
                                <TypographyV2 type="xs" width="normal" className="text-center">
                                    {t("noSequentialEventsSubtitle")}
                                </TypographyV2>
                            </div>
                        )}
                    </DndContext>
                )}
            </div>
        </div>
    )
}

export default CareProtocolElementList
