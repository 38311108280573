import React, { useMemo } from "react"
import { CardV2, getExerciseQuestionTitle, TypographyV2 } from "components/v2/index"
import { classNames } from "utils"
import { questionTypeIcons } from "../../../questionConstants"
import { useDispatch } from "store"
import { addVariableLinkageAction } from "store/questions/actions"
import { getQuestionTypeWithLocalization } from "components/v2/constants"
import { useIsHeLanguage } from "hooks"
import { ExerciseQuestionResponse } from "types/Redux"

const QuestionsAddVariableItem = (questionItem: ExerciseQuestionResponse) => {
    const { id, type } = questionItem
    const dispatch = useDispatch()
    const ExerciseTypeIcon = questionTypeIcons[type]

    const questionTitle = getExerciseQuestionTitle(questionItem)

    const questionTypes = useMemo(() => getQuestionTypeWithLocalization(), [])

    const { isHeLanguage } = useIsHeLanguage()

    const onQuestionClick = () => {
        dispatch(addVariableLinkageAction(id))
    }

    return (
        <CardV2 className="flex justify-between items-center" onClick={onQuestionClick}>
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <div className="flex items-center">
                        <div className={classNames("p-2 rounded", isHeLanguage ? "bg-blue-50 " : "bg-gray-50 ")}>
                            <ExerciseTypeIcon className={isHeLanguage ? "text-med-blue" : "text-gray-400"} />
                        </div>
                        <TypographyV2
                            className={classNames(
                                isHeLanguage ? "mr-3 text-right" : "ml-3 text-left",
                                "overflow-hidden text-ellipsis w-96 whitespace-nowrap"
                            )}
                            width="bold"
                        >
                            {questionTitle}
                        </TypographyV2>
                    </div>
                </div>
            </div>
            <TypographyV2 width="normal" type="sm" className="text-gray-400">
                {questionTypes[type]}
            </TypographyV2>
        </CardV2>
    )
}

export default QuestionsAddVariableItem
