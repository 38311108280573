import { DndContext, DragEndEvent, MouseSensor, useSensor, useSensors } from "@dnd-kit/core"
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import React from "react"
import UnitChapterElementListItem from "./UnitChapterElementListItem/UnitChapterElementListItem"
import { useUnitSelector } from "store/hooks"
import { useDispatch } from "store"
import { updateElementPositionsAction } from "store/unit/actions"

const UnitChapterElementList = () => {
    const { elements, selectedChapter } = useUnitSelector()

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 5 }
        })
    )

    const dispatch = useDispatch()

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (!over) return

        const activeId = active.id as number
        const overId = over.id as number

        if (activeId !== overId) {
            dispatch(updateElementPositionsAction({ activeId, overId, chapterId: selectedChapter }))
        }
    }
    return (
        <div className="flex flex-col w-full">
            <DndContext
                sensors={sensors}
                onDragEnd={onDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <div className="flex flex-col gap-2.5 pt-10">
                    <SortableContext items={elements} strategy={verticalListSortingStrategy}>
                        {elements?.map((item) => (
                            <UnitChapterElementListItem key={item.id} {...item} />
                        ))}
                    </SortableContext>
                </div>
            </DndContext>
        </div>
    )
}

export default UnitChapterElementList
