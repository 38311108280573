import { SVGProps } from "react"

interface ExerciseIconProps extends SVGProps<SVGSVGElement> {
    variant?: "default" | "light-gray"
}

const ExerciseIcon = ({ variant = "default", ...props }: ExerciseIconProps) => {
    const pathFill = variant === "default" ? "#8B5CF6" : "#9CA3AF"

    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.00111 0.607422C7.21404 0.607422 7.41066 0.721519 7.51631 0.906399L10.6811 6.44459C10.7861 6.62824 10.7853 6.85388 10.6791 7.03683C10.573 7.21978 10.3774 7.33239 10.1659 7.33239H3.83631C3.62478 7.33239 3.42925 7.21978 3.32308 7.03683C3.21691 6.85388 3.21616 6.62824 3.32111 6.44459L6.4859 0.906399C6.59155 0.721519 6.78817 0.607422 7.00111 0.607422ZM4.85884 6.14561H9.14337L7.00111 2.39679L4.85884 6.14561Z"
                fill={pathFill}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6929 9.82647C9.64415 9.82647 8.79399 10.6766 8.79399 11.7253C8.79399 12.7741 9.64415 13.6242 10.6929 13.6242C11.7416 13.6242 12.5918 12.7741 12.5918 11.7253C12.5918 10.6766 11.7416 9.82647 10.6929 9.82647ZM7.52808 11.7253C7.52808 9.97747 8.945 8.56055 10.6929 8.56055C12.4407 8.56055 13.8577 9.97747 13.8577 11.7253C13.8577 13.4732 12.4407 14.8901 10.6929 14.8901C8.945 14.8901 7.52808 13.4732 7.52808 11.7253Z"
                fill={pathFill}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.62095 9.82647C1.50442 9.82647 1.40996 9.92093 1.40996 10.0375V13.4132C1.40996 13.5298 1.50442 13.6242 1.62095 13.6242H4.99673C5.11326 13.6242 5.20772 13.5298 5.20772 13.4132V10.0375C5.20772 9.92093 5.11326 9.82647 4.99673 9.82647H1.62095ZM0.144043 10.0375C0.144043 9.22178 0.805276 8.56055 1.62095 8.56055H4.99673C5.8124 8.56055 6.47364 9.22178 6.47364 10.0375V13.4132C6.47364 14.2289 5.8124 14.8901 4.99673 14.8901H1.62095C0.805276 14.8901 0.144043 14.2289 0.144043 13.4132V10.0375Z"
                fill={pathFill}
            />
        </svg>
    )
}

export default ExerciseIcon
