import { DragIndicator } from "components/icons"
import { CardV2, DraggableItemV2, TagWithIcon, TypographyV2 } from "components/v2"
import { DeleteIconV2 } from "components/v2/components/icons"
import { useIsHeLanguage } from "hooks"
import React from "react"
import { UnitChapterElementResponse } from "types/Redux"
import { classNames } from "utils"
import { eventType } from "../../unitConstants"
import { useDispatch } from "store"
import { openDeleteUnitChapterElementModalAction } from "store/unit/actions"
import { UnitChapterElementType } from "@prisma/client"

const UnitChapterElementListItem = (param: UnitChapterElementResponse) => {
    const { isHeLanguage } = useIsHeLanguage()

    const dispatch = useDispatch()

    const onDeleteClick = (e: React.MouseEvent<SVGSVGElement | HTMLButtonElement>) => {
        e.stopPropagation()
        dispatch(openDeleteUnitChapterElementModalAction(param.id))
    }

    const title = param.element_type === "QUESTIONNAIRE" ? param.questionnaire.title : param.library_template.title

    return (
        <DraggableItemV2 id={param.id}>
            <CardV2>
                <div className="flex justify-between items-center">
                    <div className="flex items-center flex-1 overflow-hidden">
                        <DragIndicator className={isHeLanguage ? "ml-3" : "mr-3"} />
                        <div className="flex items-center w-9/12">
                            <TagWithIcon eventType={eventType[param.element_type]} />
                            <div
                                className={classNames(
                                    "flex-1 overflow-hidden text-ellipsis whitespace-nowrap",
                                    isHeLanguage ? "mr-3" : "ml-3"
                                )}
                            >
                                <TypographyV2
                                    className={classNames(
                                        "font-bold overflow-hidden text-ellipsis",
                                        isHeLanguage ? "text-right" : "text-left"
                                    )}
                                >
                                    {title}
                                </TypographyV2>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end">
                        <DeleteIconV2 onClick={onDeleteClick} />
                    </div>
                </div>
            </CardV2>
        </DraggableItemV2>
    )
}

export default UnitChapterElementListItem
