import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useIsHeLanguage } from "hooks"
import { useAllAvailableQuestionnaires, useCareProtocolElementsSelector, useLibraryTemplateSelector } from "store/hooks"
import {
    addNewCareProtocolElementAction,
    closeAddCareProtocolElementModalAction,
    editCareProtocolElementAction
} from "store/care-protocol/actions"
import { useEffect, useMemo, useState } from "react"
import { LibraryTemplateResponse } from "types/Redux"
import { getAllLibraryTemplates } from "api/LibraryTemplate"
import { LibraryTemplateType } from "@prisma/client"

export const useCareProtocaolAddElementModal = () => {
    const { t } = useTranslation("careProtocol")
    const [exercises, setExercises] = useState<LibraryTemplateResponse[]>([])
    const [units, setUnits] = useState<LibraryTemplateResponse[]>([])
    const questionnaire = useAllAvailableQuestionnaires()
    const { libraryTemplate } = useLibraryTemplateSelector()
    const mappedQuestionnaire = questionnaire.map((q) => ({
        ...q,
        creator: {
            user: { first_name: q.creator.user.first_name, last_name: q.creator.user.last_name }
        },
        diagnoses: q.related_diagnoses.map((d) => ({ diagnose: { ...d, name_en: d.name, id: d.id } }))
    }))

    useEffect(() => {
        const fetchLibraryTemplate = async () => {
            const lt = await getAllLibraryTemplates()
            setExercises(lt.filter((lt) => lt.template_type === LibraryTemplateType.EXERCISE))
            setUnits(lt.filter((lt) => lt.template_type === LibraryTemplateType.UNIT))
        }
        fetchLibraryTemplate()
    }, [])

    const dispatch = useDispatch()
    const {
        addNewElementModal: { isOpenedScheduleModal, isOpenedSequenceModal, libraryTemplateType, elementId },
        list
    } = useCareProtocolElementsSelector()

    const { isHeLanguage } = useIsHeLanguage()

    const onClose = () => {
        dispatch(closeAddCareProtocolElementModalAction())
    }

    const getListByLibraryTemplateType = (libraryTemplateType: LibraryTemplateType | "QUESTIONNAIRE") => {
        const libraryTemplateElements: Partial<
            Record<LibraryTemplateType | "QUESTIONNAIRE", LibraryTemplateResponse[]>
        > = {
            [LibraryTemplateType.EXERCISE]: exercises,
            [LibraryTemplateType.UNIT]: units,
            QUESTIONNAIRE: mappedQuestionnaire as any
        }

        return libraryTemplateElements[libraryTemplateType]
    }

    const onAddNewElement = (body: any) => {
        try {
            if (body.id) {
                dispatch(editCareProtocolElementAction({ ...body, cp_library_template_id: libraryTemplate.id }))
            } else {
                dispatch(addNewCareProtocolElementAction({ ...body, cp_library_template_id: libraryTemplate.id }))
            }
        } catch (error) {
            console.error(error)
        }
    }

    const cpElement = useMemo(() => list.find((element) => element.id === elementId), [list, elementId])

    return {
        t,
        isHeLanguage,
        isOpenedScheduleModal,
        isOpenedSequenceModal,
        onClose,
        list: getListByLibraryTemplateType(libraryTemplateType),
        libraryTemplateType,
        onAddNewElement,
        cpElement
    }
}
