import { ApiDelete, ApiGet, ApiPatch, ApiPost, ApiPut } from "api/ApiRequest"
import { IUnitChapterRequest } from "types"
import { UnitChapterResponse } from "types/Redux"

export const getAllUnitChaptersByUnitId = async (unitId: number): Promise<UnitChapterResponse[]> => {
    const instance = new ApiGet<UnitChapterResponse[]>(`/unit-chapter/${unitId}`)
    instance.callerName = getAllUnitChaptersByUnitId.name
    return instance.run()
}

export const createUntitledChapterForUnit = async (unitId: number): Promise<UnitChapterResponse> => {
    const instance = new ApiPost<UnitChapterResponse>("/unit-chapter", {
        name: "Untitled Chapter",
        unit_library_template_id: unitId
    })
    instance.callerName = createChapterForUnit.name
    return instance.run()
}
export const createChapterForUnit = async (body: IUnitChapterRequest): Promise<UnitChapterResponse> => {
    const instance = new ApiPost<UnitChapterResponse>("/unit-chapter", body)
    instance.callerName = createChapterForUnit.name
    return instance.run()
}

export const updateChapterForUnit = async (id: number, body: IUnitChapterRequest): Promise<UnitChapterResponse> => {
    const instance = new ApiPut<UnitChapterResponse>(`/unit-chapter/${id}`, body)
    instance.callerName = updateChapterForUnit.name
    return instance.run()
}

export const deleteChapter = async (id: number) => {
    const instance = new ApiDelete(`/unit-chapter/${id}`)
    instance.callerName = deleteChapter.name
    return instance.run()
}

export const reorderChapter = async (newList: { id: number; pos: number }[], unitId: number) => {
    const instance = new ApiPatch("/unit-chapter/update-positions", { newList, unitId })
    instance.callerName = reorderChapter.name
    return instance.run()
}
