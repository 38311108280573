import React, { forwardRef } from "react"
import { classNames } from "utils"
import { TypographyV2 } from "components/v2"
import { CompletedIcon } from "components/icons"

interface IStep {
    label: string
    isCompleted: boolean
}

interface IStepsProps {
    steps: IStep[]
    viewType?: viewType
    currentStep: number
    variant?: "default" | "intake"
    onStepClick?: (index: number) => void
    sidebarRef?: React.RefObject<HTMLDivElement>
}

type viewType = "row" | "col"

const Steps = forwardRef(
    ({ steps, viewType = "col", currentStep, variant = "default", onStepClick, sidebarRef }: IStepsProps) => (
        <div
            className={classNames(
                "flex rounded-lg",
                viewType === "row" ? "flex-row" : "flex-col",
                variant === "intake"
                    ? "bg-[#F1F5F9] border mb-10 border-[#E2E8F0] p-4 gap-2 max-h-[70vh] overflow-y-auto"
                    : "gap-10"
            )}
            ref={sidebarRef}
        >
            {steps.map(({ label }, index) => {
                const isCompleted = index + 1 < currentStep
                const isActive = index + 1 === currentStep

                if (variant === "intake") {
                    return (
                        <button
                            key={label}
                            onClick={() => onStepClick?.(index)}
                            className={classNames(
                                "text-left px-6 py-4 rounded-lg transition-all w-full flex items-center gap-4",
                                isActive ? "bg-white text-blue-600" : "hover:bg-white/50"
                            )}
                        >
                            <div className="flex-shrink-0">
                                <TypographyV2 type="sm" className="font-medium">
                                    {index + 1}
                                </TypographyV2>
                            </div>
                            <div className="flex-1 flex items-center">
                                <TypographyV2 type="sm" className="leading-relaxed">
                                    {label}
                                </TypographyV2>
                            </div>
                        </button>
                    )
                }

                return (
                    <div key={label} className="relative flex items-center gap-2">
                        {isCompleted ? (
                            <CompletedIcon />
                        ) : (
                            <div
                                className={classNames(
                                    "w-3 h-3 rounded-full border-2 flex items-center justify-center",
                                    index + 1 <= currentStep ? "bg-med-blue border-blue-100" : " bg-gray-400"
                                )}
                            />
                        )}
                        <TypographyV2
                            width="medium"
                            className={classNames(
                                "text-gray-600",
                                !isCompleted && index + 1 <= currentStep ? "text-blue-500" : ""
                            )}
                        >
                            {label}
                        </TypographyV2>
                    </div>
                )
            })}
        </div>
    )
)

export default Steps
