import React from "react"
import { useCreateFirstCareProtocolEventList } from "./useCreateFirstCareProtocolEventList"
import { CardV2, TagV2, TagWithIcon, TypographyV2 } from "components/v2/index"
import CpPopoverElement from "../../components/CpPopoverElement"
import { ClockIcon, ForwardIcon } from "@heroicons/react/24/outline"
import { CareProtocolElementModalType } from "components/v2/constants"

const CreateFirstCareProtocolEventList = () => {
    const { t, isHeLanguage, careProtocolElementTypesWithLocale, onAddElementClick } =
        useCreateFirstCareProtocolEventList()

    return (
        <div className="bg-slate-50 flex-col items-center py-10">
            <TypographyV2 className="text-center" width="bold">
                {t("startByInset")}
            </TypographyV2>
            <div className="flex flex-col justify-center items-center mt-6">
                {careProtocolElementTypesWithLocale.map(({ title, id, icon, type }) => (
                    <CpPopoverElement
                        key={`${id}-${icon}`}
                        TargetComponent={({ close }: { close: () => void }) => (
                            <div key={id} className="w-[316px] h-[62px] flex items-center cursor-pointer">
                                <CardV2 className="flex items-center w-50">
                                    <TagWithIcon eventType={icon} />
                                    <TypographyV2 className={isHeLanguage ? "mr-2.5" : "ml-2.5"} type="sm">
                                        {title}
                                    </TypographyV2>
                                </CardV2>
                            </div>
                        )}
                        PopoverComponent={() => (
                            <div className="w-[339px] flex flex-col items-center cursor-pointer rounded-lg bg-white border border-lighest-gray">
                                <div className="p-2 w-full">
                                    <TypographyV2 className="text-start text-[#6B7280]" type="xs" width="normal">
                                        {t("insertAs")}
                                    </TypographyV2>
                                </div>
                                <div
                                    className="flex items-center flex-row py-4 px-3 w-full gap-3 border-b border-lighest-gray"
                                    onClick={onAddElementClick(CareProtocolElementModalType.SEQUENCE, type)}
                                >
                                    <TagV2
                                        className="bg-[#DBEAFE]"
                                        icon={<ForwardIcon className="text-med-blue" width={16} height={16} />}
                                    />
                                    <div className="flex flex-col items-start">
                                        <TypographyV2 className="text-center text-[#111827]" type="xs" width="semibold">
                                            {t("addSequentialEventTitle")}
                                        </TypographyV2>
                                        <TypographyV2 className="text-center text-[#6B7280]" type="xs" width="normal">
                                            {t("addSequentialEventSubtitle")}
                                        </TypographyV2>
                                    </div>
                                </div>
                                <div
                                    className="flex items-center flex-row py-4 px-3 w-full gap-3"
                                    onClick={onAddElementClick(CareProtocolElementModalType.SCHEDULE, type)}
                                >
                                    <TagV2
                                        className="bg-[#DBEAFE]"
                                        icon={<ClockIcon className="text-med-blue" width={16} height={16} />}
                                    />
                                    <div className="flex flex-col items-start">
                                        <TypographyV2 className="text-center text-[#111827]" type="xs" width="semibold">
                                            {t("addScheduleEventTitle")}
                                        </TypographyV2>
                                        <TypographyV2 className="text-center text-[#6B7280]" type="xs" width="normal">
                                            {t("addScheduleEventSubtitle")}
                                        </TypographyV2>
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                ))}
            </div>
        </div>
    )
}

export default CreateFirstCareProtocolEventList
