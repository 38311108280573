import React, { useCallback, useState } from "react"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import ToolbarPlugin from "./plugins/ToolbarPlugin"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"
import { ListItemNode, ListNode } from "@lexical/list"
import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin"
import { TRANSFORMERS } from "@lexical/markdown"
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin"
import AutoLinkPlugin from "./plugins/AutoLinkPlugin"
import ImagesPlugin from "./plugins/ImagesPlugin"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { EditorState } from "lexical"
import { ToolbarContext } from "./context/ToolbarContext"
import { ImageNode } from "./nodes/ImageNode"
import { SelectionAlwaysOnDisplay } from "@lexical/react/LexicalSelectionAlwaysOnDisplay"
import DragDropPaste from "./plugins/DragDropPastePlugin"
import ContentEditable from "./ui/ContentEditable"
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme"
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin"
import { VideoNode } from "./nodes/VideoNode"
import VideosPlugin from "./plugins/VideosPlugin"
import DirectionPlugin from "./plugins/DirectionPlugin/DirectionPlugin"
import CollapsiblePlugin from "./plugins/CollapsiblePlugin"
import { CollapsibleContainerNode } from "./nodes/Collapsible/CollapsibleContainerNode"
import { CollapsibleContentNode } from "./nodes/Collapsible/CollapsibleContentNode"
import { CollapsibleTitleNode } from "./nodes/Collapsible/CollapsibleTitleNode"
import { BoxColorNode } from "./nodes/BoxColorNode"
import { MyOnChangePlugin } from "./plugins"
import BoxColorPlugin from "./plugins/BoxColorPlugin"
import { useTranslation } from "react-i18next"

interface IRichTextEditorV2Props {
    initialValue: string
    onChange: (contentTree: string, html: string) => void
}

const RichTextEditorV2: React.FC<IRichTextEditorV2Props> = ({ initialValue, onChange }) => {
    const { t } = useTranslation("educationalContent")
    const editorConfig = {
        namespace: "Playground",
        editorState: JSON.stringify(initialValue),
        theme: PlaygroundEditorTheme,
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            ImageNode,
            VideoNode,
            CollapsibleContainerNode,
            CollapsibleContentNode,
            CollapsibleTitleNode,
            BoxColorNode
        ],
        onError: () => {},
        rtl: true
    }

    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)

    const handleChange = useCallback((newEditorState: EditorState, html: string) => {
        const editorStateJSON = JSON.stringify(newEditorState.toJSON())
        onChange(editorStateJSON, html)
    }, [])

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem)
        }
    }

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <ToolbarContext>
                <div className="editor-shell">
                    <div className="flex h-full">
                        <div className="no-tailwind w-full p-3 cursor-auto">
                            <RichTextPlugin
                                contentEditable={
                                    <div className="editor-scroller">
                                        <div className="editor" ref={onRef}>
                                            <ContentEditable placeholder={t("type_text_here")} />
                                        </div>
                                    </div>
                                }
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            {floatingAnchorElem && (
                                <>
                                    <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                                </>
                            )}
                            <DragDropPaste />
                            <AutoFocusPlugin />
                            <SelectionAlwaysOnDisplay />
                            <HistoryPlugin />
                            <CodeHighlightPlugin />
                            <ListPlugin />
                            <LinkPlugin />
                            <AutoLinkPlugin />
                            <ImagesPlugin />
                            <VideosPlugin />
                            <DirectionPlugin />
                            <CollapsiblePlugin />
                            <BoxColorPlugin />
                            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                            <MyOnChangePlugin onChange={handleChange} />
                        </div>
                        <ToolbarPlugin />
                    </div>
                </div>
            </ToolbarContext>
        </LexicalComposer>
    )
}

export default RichTextEditorV2
