import { Filters } from "pages/administration/export"

export const AgeFilter = ({
    ages,
    setFilter
}: {
    ages: Filters["age_range"]
    setFilter: (ages: Filters["age_range"]) => void
}) => {
    return (
        <div className="flex flex-col items-center py-4 gap-2">
            <div id="ageFilter" className="relative w-[20rem]">
                <input
                    id="fromRange"
                    type="range"
                    min={0}
                    max={120}
                    value={ages[0]}
                    step={1}
                    className="bg-dark-blue z-1"
                    onChange={(event) => {
                        const newValue = parseInt(event.target.value)
                        ages[1] > newValue && setFilter([newValue, ages[1]])
                    }}
                />
                <input
                    id="toRange"
                    type="range"
                    min={0}
                    max={120}
                    value={ages[1]}
                    onChange={(event) => {
                        const newValue = parseInt(event.target.value)
                        ages[0] < newValue && setFilter([ages[0], newValue])
                    }}
                    step={1}
                    className="bg-dark-blue"
                />
            </div>
            <div className="w-[100%]">
                <span className="ltr:float-left rtl:float-right">{ages[0]}</span>
                <span className="ltr:float-right rtl:float-left">{ages[1]}</span>
            </div>
        </div>
    )
}
