import React, { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { classNames } from "utils"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useTranslation } from "react-i18next"
import { XMarkIcon } from "@heroicons/react/24/solid"

type SearchInputProps = Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "placeholder" | "onChange" | "value"
> & {
    wrapperClassname?: string
    iconClassName?: string
    searchText: string
    placeholder?: string
    isClearEnabled?: boolean
    onChange: (value: string) => void
}

const SearchInputV2: React.FC<SearchInputProps> = ({
    searchText,
    isClearEnabled = false,
    placeholder,
    iconClassName,
    wrapperClassname,
    className,
    onChange,
    ...props
}) => {
    const { t } = useTranslation("common")

    return (
        <label
            className={classNames(
                "flex items-center gap-[10px] bg-white  relative !text-gray-400 border border-gray-300  rounded-lg  w-full !px-[14px] py-3",
                wrapperClassname
            )}
        >
            <MagnifyingGlassIcon className={classNames("w-6 text-gray-500", iconClassName)} />
            <input
                className={classNames(
                    "w-full bg-transparent focus:ring-0 focus:outline-none text-black placeholder:text-gray-400  placeholder:font-normal border-none",
                    className
                )}
                placeholder={placeholder ?? t("search")}
                value={searchText}
                onChange={(e) => onChange(e.target.value)}
                {...props}
            />
            {isClearEnabled && (
                <XMarkIcon
                    className="w-5 cursor-pointer hover:scale-110 hover:text-dark-blue transition-all"
                    onMouseDown={(e) => {
                        onChange("")
                        e.preventDefault()
                    }}
                />
            )}
        </label>
    )
}

export default SearchInputV2
