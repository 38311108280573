import { useDispatch } from "store"
import { useLibraryTemplateSelector } from "store/hooks"
import { useEffect, useMemo } from "react"
import { filterExercisesByDiagnoseId, searchExerciseByText } from "utils/exercises"
import i18n from "locales"
import { useTranslation } from "react-i18next"
import {
    closeLibraryTemplateBuilderAction,
    getLibraryTemplateListAction,
    openLibraryTemplateBuilder
} from "store/library-template/actions"
import { getEmptyButtonTile, getEmptySubTitle, getEmptyTile } from "./libraryTemplateListConstants"
import { LibraryTemplateType } from "@prisma/client"

export const useLibraryTemplateList = (libraryTemplateType: LibraryTemplateType) => {
    const { t } = useTranslation("exercise")
    const dispatch = useDispatch()
    const { isLoading, list, type, searchParams } = useLibraryTemplateSelector()

    const visibleList = useMemo(() => {
        let newList = [...list]
        const { text, diagnose, status, creator } = searchParams

        if (diagnose) {
            newList = filterExercisesByDiagnoseId(newList, diagnose)
        }

        if (status) {
            newList = newList.filter((exercise) => exercise.status === status)
        }

        if (creator) {
            newList = newList.filter(({ creator: { user } }) => user.id === creator)
        }

        if (text) {
            newList = searchExerciseByText(newList, text)
        }
        return newList
    }, [list, searchParams])

    const getHeaderTitle = (type: LibraryTemplateType) => {
        switch (type) {
            case LibraryTemplateType.EXERCISE:
                return i18n.t("eventType.exercise", { ns: "common" })
            case LibraryTemplateType.EDUCATIONAL_CONTENT:
                return i18n.t("eventType.educational_content", { ns: "common" })
            case LibraryTemplateType.UNIT:
                return i18n.t("eventType.unit", { ns: "common" })
            default:
                return i18n.t("eventType.exercise", { ns: "common" })
        }
    }

    const headerCell = useMemo(
        () => [
            {
                title: getHeaderTitle(libraryTemplateType),
                className: "w-2/5"
            },
            {
                title: i18n.t("diagnoses", { ns: "exercise" }),
                className: "w-1/5"
            },
            {
                title: i18n.t("status", { ns: "common" }),
                className: "w-1/5"
            },
            {
                title: i18n.t("creator", { ns: "exercise" }),
                className: "w-1/5"
            },
            { className: "w-[10px]" }
        ],
        []
    )

    const emptyTextProps = {
        title: t(getEmptyTile(type)),
        subTitle: t(getEmptySubTitle(type)),
        btnText: t(getEmptyButtonTile(type)),
        onClick: () => {
            dispatch(openLibraryTemplateBuilder(null))
        }
    }

    useEffect(() => {
        if (type) dispatch(getLibraryTemplateListAction())
    }, [type])

    useEffect(() => {
        return () => {
            dispatch(closeLibraryTemplateBuilderAction())
        }
    }, [])

    return {
        list,
        isLoading,
        visibleList,
        headerCell,
        searchParams,
        emptyTextProps
    }
}
