import { UnitChapterElementType } from "@prisma/client"
import { BadgeV2, ButtonV2, TypographyV2 } from "components/v2/components/common"
import { useIsHeLanguage } from "hooks"
import React, { useEffect, useMemo } from "react"
import { useAllQuestionnaires, useUnitSelector } from "store/hooks"
import { classNames } from "utils"
import TagWithIcon from "components/v2/components/TagWithIcon"
import { eventType } from "../../unitConstants"
import { getAllLibraryTemplates } from "api/LibraryTemplate"
import { LibraryTemplateResponse } from "types/Redux"
import { DeleteIconV2 } from "components/v2/components/icons"
import { deleteElementFromChapterAction } from "store/unit/actions"
import { useDispatch } from "store"
import { PreviewEducationalContent, PreviewQuestionnaire, PreviewExercise } from "../../../library-template"
import { useTranslation } from "react-i18next"

const PreviewSelectedElement = () => {
    const {
        deleteElementModal: { elementId },
        elements
    } = useUnitSelector()

    const { t } = useTranslation("common")

    const selectedElement = elements.find((element) => element.id === elementId)

    const { isHeLanguage } = useIsHeLanguage()

    const questionnaires = useAllQuestionnaires()

    const dispatch = useDispatch()

    const [selectedLibraryElement, setLibrarySelectedElement] = React.useState<null | LibraryTemplateResponse>(null)

    const selectedQuestionnaireElement = useMemo(() => {
        if (questionnaires.length > 0) {
            return questionnaires.find(({ id }) => id === selectedElement?.questionnaire_id)
        }
        return null
    }, [questionnaires, selectedElement?.questionnaire_id])

    useEffect(() => {
        if (
            selectedElement?.element_type === UnitChapterElementType.EDUCATIONAL_CONTENT &&
            selectedElement?.library_template_id
        ) {
            getAllLibraryTemplates(UnitChapterElementType.EDUCATIONAL_CONTENT).then((data) => {
                setLibrarySelectedElement(data.find(({ id }) => id === selectedElement?.library_template_id))
            })
        } else if (
            selectedElement?.element_type === UnitChapterElementType.EXERCISE &&
            selectedElement?.library_template_id
        ) {
            getAllLibraryTemplates(UnitChapterElementType.EXERCISE).then((data) => {
                setLibrarySelectedElement(data.find(({ id }) => id === selectedElement?.library_template_id))
            })
        }
    }, [selectedElement?.element_type, selectedElement?.library_template_id])

    const data = useMemo(() => {
        if (selectedLibraryElement) {
            return {
                diagnoses: selectedLibraryElement.diagnoses.map(({ diagnose }) => ({
                    name: diagnose.name_en,
                    id: diagnose.id
                })),
                title: selectedLibraryElement.title,
                description: selectedLibraryElement.description
            }
        }
        if (selectedQuestionnaireElement) {
            return {
                diagnoses: selectedQuestionnaireElement.related_diagnoses,
                title: selectedQuestionnaireElement.title,
                description: selectedQuestionnaireElement.professional_description
            }
        }
        return null
    }, [selectedLibraryElement, selectedQuestionnaireElement])

    const handleDelete = () => {
        if (elementId) dispatch(deleteElementFromChapterAction(elementId))
    }

    if (!selectedElement) return null

    return (
        <div className="p-6 h-150 overflow-hidden">
            <div className="flex gap-2 h-full">
                <div className="w-2/5 p-4 h-full overflow-hidden flex gap-4 flex-col justify-between">
                    <div className="overflow-y-auto">
                        <div className="flex gap-2 pb-4">
                            {data &&
                                data.diagnoses.map(({ id, name }) => (
                                    <BadgeV2 key={id} text={name} color="darkBlue" className="px-1.5 py-0.5" />
                                ))}
                        </div>
                        <div>
                            <div className="flex gap-2 items-center pb-1">
                                {selectedElement?.element_type && (
                                    <TagWithIcon eventType={eventType[selectedElement?.element_type]} />
                                )}
                                <TypographyV2
                                    type="sm"
                                    width="bold"
                                    className={classNames(
                                        isHeLanguage ? "text-right" : "text-left",
                                        "w-auto overflow-hidden text-ellipsis"
                                    )}
                                >
                                    {data && data.title}
                                </TypographyV2>
                            </div>
                            <TypographyV2
                                type="sm"
                                className={classNames(
                                    "text-gray-400 mt-1",
                                    isHeLanguage ? "text-right" : "text-left",
                                    "w-auto overflow-hidden text-ellipsis"
                                )}
                            >
                                {data && data.description}
                            </TypographyV2>
                        </div>
                    </div>
                    <div>
                        <ButtonV2
                            text="Delete from chapter"
                            icon={<DeleteIconV2 />}
                            variant="light-gray"
                            onClick={handleDelete}
                        />
                    </div>
                </div>
                <div className="w-3/5 flex flex-col h-full overflow-hidden">
                    <TypographyV2 width="semibold">{t("eventBuilder.content")}</TypographyV2>
                    <div className="rounded-lg h-full mt-3 bg-light-background border border-light-border overflow-auto">
                        {selectedElement.element_type === UnitChapterElementType.EDUCATIONAL_CONTENT && (
                            <PreviewEducationalContent id={selectedElement.library_template_id} />
                        )}
                        {selectedElement.element_type === UnitChapterElementType.QUESTIONNAIRE && (
                            <PreviewQuestionnaire withoutTitle id={selectedQuestionnaireElement.id} />
                        )}
                        {selectedElement.element_type === UnitChapterElementType.EXERCISE && (
                            <PreviewExercise id={selectedElement.library_template_id} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewSelectedElement
