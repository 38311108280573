import { Modal } from "components/common"
import { useCallback } from "react"
import {
    LibraryElementBuilderFooter,
    LibraryElementBuilderHeader,
    AddExerciseContentForm,
    AddCareProtocolElementForm
} from "./components"
import { useLibraryTemplateSelector } from "store/hooks"
import { LibraryTemplateType } from "@prisma/client"
import AddUnitContentForm from "./components/AddUnitContentForm"
import { ExerciseStatuses } from "@prisma/client"
import { EducationalContentEditor, EducationalContentPreview } from "components/v2"
import classNames from "classnames"
import { useDispatch } from "react-redux"
import { setLibraryTemplateActiveTab } from "../../../../../../store/library-template/actions"
import ExercisePreview from "../../exercises/ExercisePreview"
import { LibraryTemplateTab } from "store/library-template/types"
import { UnitBuilderPreview } from "../../unit/components"

const LibraryElementBuilder = ({
    basicInfoForm,
    basicInfoFormId
}: {
    basicInfoForm: React.ReactElement
    basicInfoFormId: string
}) => {
    const dispatch = useDispatch()
    const { libraryTemplate, type, activeTab } = useLibraryTemplateSelector()

    const isEducationalContent = type === LibraryTemplateType.EDUCATIONAL_CONTENT
    const isUnit = type === LibraryTemplateType.UNIT

    const onActiveTabClick = useCallback(
        (newTab: LibraryTemplateTab) => {
            if (libraryTemplate) {
                dispatch(setLibraryTemplateActiveTab(newTab))
            }
        },
        [libraryTemplate]
    )

    return (
        <Modal cardClassName="w-full !max-h-screen !h-screen !mt-0 rounded-none" show={true} onClose={() => {}}>
            <div className="max-h-screen absolute top-0 bottom-0 left-0 right-0 flex flex-col">
                <LibraryElementBuilderHeader
                    {...{
                        eventName: libraryTemplate?.title ?? "",
                        activeTab,
                        onActiveTabClick,
                        elementStatus: libraryTemplate?.status ?? ExerciseStatuses.INIT
                    }}
                />
                <div
                    className={classNames(
                        "flex-1 overflow-auto",
                        isEducationalContent || isUnit ? "bg-slate-50 pt-0 pb-0" : ""
                    )}
                >
                    {
                        activeTab === LibraryTemplateTab.BASIC_INFO && basicInfoForm // Todo: Once DK finishes INFO API, inject all the api logic into this component.
                    }
                    {activeTab === LibraryTemplateTab.CONTENT &&
                        libraryTemplate &&
                        (LibraryTemplateType.EXERCISE === type ? (
                            <AddExerciseContentForm />
                        ) : type === LibraryTemplateType.UNIT ? (
                            <AddUnitContentForm />
                        ) : type === LibraryTemplateType.CARE_PROTOCOL ? (
                            <AddCareProtocolElementForm />
                        ) : (
                            <EducationalContentEditor />
                        ))}
                    {activeTab === LibraryTemplateTab.PREVIEW && (
                        <>
                            {LibraryTemplateType.EDUCATIONAL_CONTENT === type && <EducationalContentPreview />}
                            {LibraryTemplateType.EXERCISE === type && <ExercisePreview />}
                            {LibraryTemplateType.UNIT === type && <UnitBuilderPreview />}
                        </>
                    )}
                </div>
                <LibraryElementBuilderFooter
                    {...{
                        isCreateNew: !libraryTemplate,
                        basicInfoFormId,
                        elementStatus: libraryTemplate?.status ?? ExerciseStatuses.INIT
                    }}
                />
            </div>
        </Modal>
    )
}

export default LibraryElementBuilder
