import * as types from "./types"
import { AppAction, EducationalContentResponse, ExerciseUpdateSearchAction, LibraryTemplateResponse } from "types/Redux"
import { CreateExerciseDefPayload } from "types/exercises/ExerciseDef"
import { toast } from "react-toastify"
import i18n from "locales"
import {
    archiveLibraryTemplate,
    cloneLibraryTemplate,
    createLibraryTemplate,
    getAllLibraryTemplates,
    publishLibraryTemplate,
    updateLibraryTemplate
} from "api/LibraryTemplate"
import { Diagnosis, LibraryTemplateType, User } from "@prisma/client"
import { closeModal, updateParamsModal } from "../modal/actions"
import { createUntitledChapterForUnit } from "api/unit"

import { getEducationalContentByTemplateId, updateEduContentById } from "api"
import { IEducationalContentRequest } from "types/EducationalContent"
import { showLibraryTemplateNotification, LibraryTemplateAction } from "utils/library-template/notificationsUtils"

export const setLibraryTemplateType = (payload: LibraryTemplateType) => {
    return { type: types.SET_LIBRARY_TEMPLATE_TYPE, payload }
}

export const setLibraryTemplateFormErrorAction = (payload: boolean) => {
    return { type: types.SET_LIBRARY_TEMPLATE_FORM_ERROR, payload }
}

export const setLibraryTemplateFormDirtyAction = (payload: boolean) => {
    return { type: types.SET_LIBRARY_TEMPLATE_FORM_DIRTY, payload }
}

export const closeLibraryTemplateBuilderAction = () => {
    return { type: types.CLOSE_LIBRARY_TEMPLATE_BUILDER }
}

export const setLibraryTemplateAction = (payload: LibraryTemplateResponse[]) => {
    return { type: types.SET_LIBRARY_TEMPLATE_LIST, payload }
}

export const setLibraryTemplateSearchParamsAction = (payload: ExerciseUpdateSearchAction) => {
    return { type: types.SET_LIBRARY_TEMPLATE_SEARCH_PARAMS, payload }
}

export const setDiagnosisList = (payload: Diagnosis[]) => {
    return { type: types.SET_DIAGNOSES_LIST, payload }
}

export const setCreatorsList = (payload: User[]) => {
    return { type: types.SET_CREATORS_LIST, payload }
}

export const setLibraryTemplateActiveTab = (payload: types.LibraryTemplateTab) => {
    return { type: types.SET_ACTIVE_TAB, payload }
}

export const setEducationalContent = (payload: EducationalContentResponse) => {
    return { type: types.SET_EDUCATIONAL_CONTENT, payload }
}

export const setLibraryTemplateItemAction = (payload: LibraryTemplateResponse) => {
    return { type: types.SET_LIBRARY_TEMPLATE, payload }
}

export const setIsLibraryListLoading = (payload: boolean) => {
    return { type: types.SET_IS_LIBRARY_LIST_LOADING, payload }
}

export const openLibraryTemplateBuilder =
    (payload: LibraryTemplateResponse | null): AppAction<void> =>
    async (dispatch) => {
        dispatch(
            setLibraryTemplateActiveTab(
                payload ? types.LibraryTemplateTab.CONTENT : types.LibraryTemplateTab.BASIC_INFO
            )
        )
        if (payload?.template_type === LibraryTemplateType.EDUCATIONAL_CONTENT) {
            const educationalContent = await getEducationalContentByTemplateId(payload.id)
            dispatch(setEducationalContent(educationalContent))
        }
        dispatch({ type: types.OPEN_LIBRARY_TEMPLATE_BUILDER, payload })
    }

export const getLibraryTemplateListAction = (): AppAction<boolean> => async (dispatch, getState) => {
    const { type } = getState().libraryTemplate
    dispatch(setIsLibraryListLoading(true))
    dispatch(setLibraryTemplateAction([]))
    const libraryTemplates = await getAllLibraryTemplates(type)

    const diagnoses = libraryTemplates
        .map(({ diagnoses }) => diagnoses.map(({ diagnose }) => diagnose))
        .flat()
        .filter(({ id }, index, self) => index === self.findIndex((d) => d.id === id))

    const creators = libraryTemplates
        ?.map(({ creator: { user } }) => user)
        .filter(({ id }, index, self) => index === self.findIndex((d) => d.id === id))
    if (getState().libraryTemplate.type !== type) return false
    dispatch(setCreatorsList(creators))
    dispatch(setDiagnosisList(diagnoses))
    dispatch(setLibraryTemplateAction(libraryTemplates))
    dispatch(setIsLibraryListLoading(false))

    return true
}

export const makeLibraryTemplateArchivedAction =
    (id: number): AppAction<void> =>
    async (dispatch, getState) => {
        const { type } = getState().libraryTemplate

        try {
            await archiveLibraryTemplate(id)
            showLibraryTemplateNotification(type, LibraryTemplateAction.Archived)
            dispatch(getLibraryTemplateListAction())
        } catch (err) {}
    }

export const createLibraryTemplateAction =
    (body: CreateExerciseDefPayload): AppAction<void> =>
    async (dispatch, getState) => {
        const { type } = getState().libraryTemplate

        try {
            const res = await createLibraryTemplate(body)
            showLibraryTemplateNotification(type, LibraryTemplateAction.Created)
            dispatch(getLibraryTemplateListAction())
            dispatch(setLibraryTemplateType(res.template_type))
            dispatch(setLibraryTemplateItemAction(res))
            dispatch(setLibraryTemplateActiveTab(types.LibraryTemplateTab.CONTENT))
            dispatch(setLibraryTemplateFormDirtyAction(false))
            if (res.template_type === LibraryTemplateType.UNIT) {
                await createUntitledChapterForUnit(res.id)
            }
        } catch (err) {}
    }

export const cloneLibraryTemplateAction =
    (id: number): AppAction<void> =>
    async (dispatch, getState) => {
        const { type } = getState().libraryTemplate

        try {
            await cloneLibraryTemplate(id)
            showLibraryTemplateNotification(type, LibraryTemplateAction.Copied)
            dispatch(getLibraryTemplateListAction())
        } catch (err) {}
    }

export const publishLibraryTemplateAction =
    (id: number): AppAction<void> =>
    async (dispatch, getState) => {
        const { type } = getState().libraryTemplate

        try {
            const libraryTemplate = await publishLibraryTemplate(id)
            showLibraryTemplateNotification(type, LibraryTemplateAction.Published)
            dispatch(updateParamsModal(libraryTemplate))
            dispatch(getLibraryTemplateListAction())
            dispatch(closeModal())
        } catch (err) {}
    }

export const updateLibraryTemplateAction =
    (id: number, body: CreateExerciseDefPayload): AppAction<void> =>
    async (dispatch, getState) => {
        const { type } = getState().libraryTemplate

        try {
            const res = await updateLibraryTemplate(id, body)
            showLibraryTemplateNotification(type, LibraryTemplateAction.Created)
            dispatch(getLibraryTemplateListAction())
            dispatch(setLibraryTemplateItemAction(res))
            dispatch(setLibraryTemplateActiveTab(types.LibraryTemplateTab.CONTENT))
            dispatch(setLibraryTemplateFormDirtyAction(false))
        } catch (err) {}
    }

export const updateEduContentByIdAction =
    (id: number, data: IEducationalContentRequest): AppAction<void> =>
    async (dispatch) => {
        try {
            const updatedContent = await updateEduContentById(id, data)
            toast.success(i18n.t("notifications.successfullyUpdated", { ns: "educationalContent" }), {
                autoClose: 1000
            })
            dispatch(setEducationalContent(updatedContent))
        } catch (err) {}
    }
