import { ScreeningTemplateQuestion } from "@prisma/client"
import { TypographyV2, ControlledDateInputV2 } from "components/v2"
import { Control } from "react-hook-form"
import { useScreenSize } from "hooks"
import { TableListV2 } from "components/v2"

interface ITimeOfDayQuestionCardProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: ScreeningTemplateQuestion
    isDisabled?: boolean
    dateFormat?: any
}

const TimeOfDayQuestionCard = ({ control, error, question, isDisabled, dateFormat }: ITimeOfDayQuestionCardProps) => {
    const { is_required, question: question_text } = question
    const defaultDateFormat = "dd/MM/yyyy"
    const isDesktop = useScreenSize()

    if (isDesktop) {
        return (
            <TableListV2
                headerCells={[]}
                listComponent={
                    <tr className="border-b border-gray-200">
                        <td className="py-12 px-6">
                            <div className="flex flex-col gap-4">
                                <TypographyV2 type="base">{question_text}</TypographyV2>
                                {question?.description && (
                                    <TypographyV2 type="xs" className="text-gray-500">
                                        {question.description}
                                    </TypographyV2>
                                )}
                            </div>
                        </td>
                        <td className="py-12 px-6">
                            <ControlledDateInputV2
                                isDisabled={isDisabled}
                                name={`screeningAnswers.${question.id}`}
                                classList={{ wrapper: "w-full" }}
                                control={control}
                                error={error}
                                isRequired={is_required}
                                dateFormat={dateFormat || defaultDateFormat}
                            />
                        </td>
                    </tr>
                }
            />
        )
    }

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <ControlledDateInputV2
                isDisabled={isDisabled}
                name={`screeningAnswers.${question.id}`}
                classList={{ wrapper: "flex-1" }}
                control={control}
                error={error}
                isRequired={is_required}
                dateFormat={dateFormat || defaultDateFormat}
            />
        </div>
    )
}

export default TimeOfDayQuestionCard
