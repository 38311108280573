const EventUnlockIcon = ({ variant = "default", ...props }) => {
    const pathFill = variant === "default" ? "#8B5CF6" : "#9CA3AF"

    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 1C0 0.723858 0.223858 0.5 0.5 0.5H13.5C13.7761 0.5 14 0.723858 14 1C14 1.27614 13.7761 1.5 13.5 1.5H13V8.5C13 9.60457 12.1046 10.5 11 10.5H10.1937L10.9743 12.8419C11.0617 13.1039 10.9201 13.387 10.6581 13.4743C10.3961 13.5617 10.113 13.4201 10.0257 13.1581L9.80629 12.5H4.19371L3.97434 13.1581C3.88702 13.4201 3.60386 13.5617 3.34189 13.4743C3.07991 13.387 2.93833 13.1039 3.02566 12.8419L3.80629 10.5H3C1.89543 10.5 1 9.60457 1 8.5V1.5H0.5C0.223858 1.5 0 1.27614 0 1ZM2 1.5V8.5C2 9.05229 2.44772 9.5 3 9.5H11C11.5523 9.5 12 9.05229 12 8.5V1.5H2ZM4.86038 10.5L4.52705 11.5H9.47295L9.13962 10.5H4.86038ZM10.4328 3.8199C10.5711 4.05893 10.4894 4.36479 10.2504 4.50306C9.29427 5.05613 8.47131 5.81439 7.84196 6.71758C7.75735 6.83901 7.62329 6.91671 7.47588 6.92978C7.32846 6.94284 7.18283 6.88993 7.07818 6.78528L6 5.70711L4.35355 7.35355C4.15829 7.54882 3.84171 7.54882 3.64645 7.35355C3.45118 7.15829 3.45118 6.84171 3.64645 6.64645L5.64645 4.64645C5.84171 4.45118 6.15829 4.45118 6.35355 4.64645L7.37781 5.67071C8.03444 4.85575 8.83848 4.16453 9.74964 3.63745C9.98867 3.49918 10.2945 3.58086 10.4328 3.8199Z"
                fill={pathFill}
            />
        </svg>
    )
}
export default EventUnlockIcon
