import { TypographyV2 } from "components/v2"
import Image from "next/image"
import { useScreenSize } from "hooks"

interface IEndCardProps {
    title: string
    subtitle: string
}

const EndCard = ({ title, subtitle }: IEndCardProps) => {
    const isDesktop = useScreenSize()

    if (isDesktop) {
        return (
            <div className="min-h-[600px] flex items-center justify-center bg-white p-6">
                <div className="text-center flex flex-col items-center gap-4">
                    <Image src="/images/logo.png" alt="Intake" width={47} height={70} />
                    <TypographyV2 type="2xl" className="mb-4">
                        {title}
                    </TypographyV2>
                    <TypographyV2 type="base" className="text-gray-600">
                        {subtitle}
                    </TypographyV2>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col items-center justify-center gap-[16px]">
            <Image src="/images/logo.png" alt="Intake" width={47} height={70} />
            <h2 className="text-4xl font-normal text-center">{title}</h2>
            <p className="text-sm font-normal text-center">{subtitle}</p>
        </div>
    )
}

export default EndCard
