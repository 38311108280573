import React from "react"
import { classNames } from "utils"
import { EventTypes } from "types"
import { eventTypeColors, eventTypeIcons } from "./tagWithIconConstants"

interface IEventTypeProps {
    eventType: EventTypes
    className?: string
    variant?: "light-gray" | "default"
}

const TagWithIcon: React.FC<IEventTypeProps> = ({ eventType, className, variant = "default" }) => {
    const eventTypeColor = variant === "light-gray" ? "bg-gray-100" : eventTypeColors[eventType]
    const EventTypeIcons = eventTypeIcons[eventType]

    return (
        <div
            className={classNames(
                eventTypeColor,
                "w-[30px] h-[30px] pt-1 gap-sm rounded flex justify-center items-center",
                className
            )}
        >
            <EventTypeIcons variant={variant} />
        </div>
    )
}

export default TagWithIcon
