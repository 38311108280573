import { Reducer } from "redux"

import { CarePlanState } from "types/Redux"
import * as types from "./types"
import { CarePlanActionTypes } from "./types"

const initialState: CarePlanState = {
    assignModal: {
        isOpen: false,
        eventType: null
    },
    activeCareProtocol: {
        protocol: null,
        completedScheduledEvents: null,
        completedSequentialEvents: null,
        scheduleEvents: null,
        sequentialEvents: null
    },
    eventsList: null
}

const CarePlanReducer: Reducer<CarePlanState, any> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.OPEN_ASSIGN_EVENT_MODAL: {
            return {
                ...state,
                assignModal: {
                    isOpen: true,
                    eventType: payload
                }
            }
        }
        case types.CLOSE_ASSIGN_EVENT_MODAL: {
            return {
                ...state,
                assignModal: {
                    isOpen: false,
                    eventType: null
                }
            }
        }
        case types.SET_CARE_PLAN_EVENTS_LIST: {
            return {
                ...state,
                eventsList: payload
            }
        }
        case types.SET_PATIENT_ACTIVE_CARE_PROTOCOL: {
            return {
                ...state,
                activeCareProtocol: payload
            }
        }
        default:
            return state
    }
}

export default CarePlanReducer
