import { SVGProps } from "react"

const BoltIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.30761 0.797476C6.45823 0.881447 6.53217 1.05731 6.48679 1.22368L5.49098 4.87501H9.12501C9.27433 4.87501 9.40943 4.9636 9.46894 5.10056C9.52845 5.23751 9.50104 5.39672 9.39915 5.50588L4.14915 11.1309C4.03149 11.2569 3.84302 11.2865 3.6924 11.2025C3.54178 11.1186 3.46785 10.9427 3.51322 10.7763L4.50904 7.12501H0.875008C0.725684 7.12501 0.590591 7.03642 0.531078 6.89947C0.471565 6.76252 0.498976 6.60331 0.600863 6.49414L5.85086 0.869143C5.96852 0.743077 6.15699 0.713505 6.30761 0.797476Z"
            fill="#153E7B"
        />
    </svg>
)
export default BoltIcon
