import { useDispatch } from "store"
import { addNewQuestionAction } from "store/questions/actions"
import { ExerciseQuestionType } from "@prisma/client"
import { useMemo } from "react"
import { questionTypesList } from "components/v2/constants"
import { useTranslation } from "react-i18next"
import { useIsHeLanguage } from "hooks"
import { useLibraryTemplateSelector } from "../../../../../../../store/hooks"

export const useCreateFirstExerciseQuestionList = () => {
    const { t } = useTranslation("question")
    const dispatch = useDispatch()
    const { isHeLanguage } = useIsHeLanguage()

    const { libraryTemplate } = useLibraryTemplateSelector()
    const questionTypesWithLocale = useMemo(
        () => questionTypesList.map(({ title, ...rest }) => ({ ...rest, title: t(`questionTypes.${title}`) })),
        []
    )

    const onAddEventClick = (type: ExerciseQuestionType) => () => {
        dispatch(
            addNewQuestionAction({
                library_template_id: libraryTemplate.id,
                question: "New question",
                type,
                is_required: true
            })
        )
    }
    return { t, isHeLanguage, questionTypesWithLocale, onAddEventClick }
}
