import classNames from "classnames"
import { CheckCircle } from "components/icons"

interface IntakeDialogHeaderStepListProps {
    title: string
    step: number
    stepList: string[]
}

const IntakeDialogHeaderStepList = ({ title, step, stepList }: IntakeDialogHeaderStepListProps) => {
    return (
        <div className="flex items-center justify-between w-full">
            <div>{title}</div>
            <div className="flex items-center gap-6 px-[10px]">
                {stepList.map((sl, index) => (
                    <div
                        key={index}
                        className={classNames(
                            "text-xs font-medium flex items-center gap-1",
                            step === index ? "text-[#3C7FF9]" : "text-[#6B7280]"
                        )}
                    >
                        <div
                            className={classNames(
                                "flex justify-center items-center p-[7.5px] rounded-full",
                                step === index ? "bg-[#3C7FF933]" : ""
                            )}
                        >
                            {index < step ? (
                                <CheckCircle className="w-4 h-4" />
                            ) : step === index ? (
                                <div className="w-2 h-2 rounded-full bg-[#3C7FF9]" />
                            ) : (
                                <div className="w-2 h-2 rounded-full bg-[#9CA3AF]" />
                            )}
                        </div>
                        {sl}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default IntakeDialogHeaderStepList
