import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { closeModal } from "store/modal/actions"
import { CardV2, DialogV2, ListV2, ScheduleItemCard, TagV2, TagWithIcon, TypographyV2 } from "components/v2"
import { classNames } from "utils"
import { EventTypes } from "types"
import { ClockIcon } from "components/icons"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import CursorArrowKeysIcon from "components/icons/CursorArrowKeysIcon"

interface AssignCareProtocolModalProps {
    isOpen: boolean
    params?: any
}

const sequentialList = [
    { title: "CBT Introduction", time: "Every Friday", when: "", eventType: EventTypes.Exercise, numberOfEvents: 5 },
    { title: "Anxiety Awareness", time: "Every Friday", when: "From Start", eventType: EventTypes.Questionnaire }
]

export const upcomingList = [
    { title: "GAD-22", time: "Friday, 04 July", when: "From Start", eventType: EventTypes.Questionnaire },
    {
        title: "Identifying Emotional Triggers",
        time: "Weekly, every Friday",
        when: "From Start",
        eventType: EventTypes.Exercise
    }
]

const manualList = [
    { title: "CBT Introduction", time: "Every Friday", when: "", eventType: EventTypes.Exercise, numberOfEvents: 5 }
]

const scheduledList = [
    { title: "Anxiety Awareness", time: "Every Friday", when: "From Start", eventType: EventTypes.Questionnaire },
    { title: "Anxiety Awareness", time: "Every Friday", when: "From Start", eventType: EventTypes.Questionnaire }
]

const AssignCareProtocolModal: React.FC<AssignCareProtocolModalProps> = ({ isOpen, params }) => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(closeModal())
    }

    const handleSave = () => {
        handleClose()
    }

    return (
        <DialogV2
            title={t("assign_care_protocol")}
            type="wide"
            isOpen={isOpen}
            onClose={handleClose}
            onProceed={handleSave}
            onBack={handleClose}
            proceedText={t("common:done")}
            proceedType="primary"
            dismissType="light-gray"
            dismissText={t("common:cancel")}
            backText={t("common:back")}
            backType="light-gray"
        >
            <div className="flex flex-col w-full max-h-[696px] overflow-y-auto p-4">
                <div className="flex flex-row w-full gap-x-6">
                    <div className="flex flex-col w-full">
                        <div className="flex gap-2 flex-row">
                            <TagV2 text={"CBT"} className="text-dark-gray bg-med-gray w-auto px-2 text-xs h-full" />
                            <TagV2
                                text={"Depression"}
                                className="text-dark-gray bg-med-gray w-auto text-xs  px-2 h-full"
                            />
                        </div>

                        <div className={"w-full pt-4 pb-2 flex items-center gap-3 flex-row"}>
                            <TagWithIcon className="w-auto" eventType={EventTypes.Protocol} />
                            <TypographyV2 tag="div" width="bold" type="sm">
                                Anxiety Management Protocol
                            </TypographyV2>
                        </div>
                        <TypographyV2
                            width="medium"
                            type="sm"
                            className={classNames("overflow-hidden leading-5 text-[#6B7280]")}
                        >
                            This protocol is designed to help patients manage anxiety through a combination of
                            cognitive-behavioral therapy (CBT) techniques, relaxation exercises, and regular
                            assessments. The plan includes daily tasks, weekly exercises, and regular check-ins to
                            monitor progress and adjust the treatment as needed.
                        </TypographyV2>
                    </div>
                    <div className="flex flex-col w-full gap-y-2">
                        <TypographyV2 tag="div" width="semibold" type="xs">
                            Content
                        </TypographyV2>

                        <CardV2 className="flex !bg-light-background items-center pr-2 p-[8px]">
                            <div className="w-full p-[5px]">
                                <TypographyV2 tag="div" width="semibold" type="xs" className="flex flex-row ">
                                    <ChevronDownIcon className="w-4 h-4 mr-1.5" />
                                    Sequential
                                </TypographyV2>
                                <div className="py-[5px] px-[25px]">
                                    <ListV2
                                        renderItem={ScheduleItemCard}
                                        {...{
                                            list: sequentialList,
                                            variant: "light-gray",
                                            isEditOptionEnabled: "true",
                                            listItemClassName: "!w-full",
                                            isVerticalView: true
                                        }}
                                    />
                                </div>

                                <div className="flex flex-row items-center gap-1.5">
                                    <ClockIcon />
                                    <TypographyV2 tag="div" className="text-[#9CA3AF]" width="normal" type="xs">
                                        10 days delay
                                    </TypographyV2>
                                </div>

                                <div className="flex flex-row">
                                    <div className="relative ml-[7px] mt-2">
                                        <div className="opacity-70 relative w-[15px] h-[30px] text-[#9CA3AF]/60 border-l-[2px] border-b-[2px] border-current border-solid rounded-bl-lg" />
                                    </div>

                                    <div className="py-[5px] pr-[25px]  pl-[3.5px] w-full">
                                        <ListV2
                                            renderItem={ScheduleItemCard}
                                            {...{
                                                list: sequentialList,
                                                variant: "light-gray",
                                                isEditOptionEnabled: "true",
                                                listItemClassName: "!w-full",
                                                isVerticalView: true
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row items-center gap-1.5">
                                    <CursorArrowKeysIcon />
                                    <TypographyV2 tag="div" className="text-[#9CA3AF]" width="normal" type="xs">
                                        Manually Triggering
                                    </TypographyV2>
                                </div>

                                <div className="flex flex-row">
                                    <div className="relative ml-[7px] mt-2">
                                        <div className="opacity-70 relative w-[15px] h-[30px] text-[#9CA3AF]/60 border-l-[2px] border-b-[2px] border-current border-solid rounded-bl-lg" />
                                    </div>

                                    <div className="py-[5px] pr-[25px]  pl-[3.5px] w-full">
                                        <ListV2
                                            renderItem={ScheduleItemCard}
                                            {...{
                                                list: manualList,
                                                variant: "light-gray",
                                                isEditOptionEnabled: "true",
                                                listItemClassName: "!w-full",
                                                isVerticalView: true
                                            }}
                                        />
                                    </div>
                                </div>

                                <TypographyV2 tag="div" width="semibold" type="xs" className="flex flex-row ">
                                    <ChevronDownIcon className="w-4 h-4 mr-1.5 mb-1 " />
                                    Scheduled
                                </TypographyV2>
                                <div className="w-full">
                                    <ListV2
                                        renderItem={ScheduleItemCard}
                                        {...{
                                            list: scheduledList,
                                            variant: "light-gray",
                                            isEditOptionEnabled: "true",
                                            listItemClassName: "!w-full !sm:w-auto"
                                        }}
                                    />
                                </div>
                            </div>
                        </CardV2>
                    </div>
                </div>
            </div>
        </DialogV2>
    )
}

export default AssignCareProtocolModal
