import React from "react"
import ChapterListItem from "./UnitBuilderPreviewChaptersListItem"
import { useLibraryTemplateSelector, useUnitSelector } from "store/hooks"

const UnitBuilderPreviewChaptersList = () => {
    const { chapters } = useUnitSelector()

    const {
        libraryTemplate: { id }
    } = useLibraryTemplateSelector()

    return (
        <div className="flex flex-col w-full px-4 pb-4 h-full overflow-scroll">
            <div className="flex flex-col gap-2.5">
                {chapters?.map((item, index) => (
                    <ChapterListItem index={index} key={item.id} {...item} />
                ))}
            </div>
        </div>
    )
}

export default UnitBuilderPreviewChaptersList
