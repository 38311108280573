export const btnVariantStyles = {
    primary: "bg-med-blue text-white",
    danger: "bg-red-600  text-white",
    disabled: "bg-gray-300 text-gray-400",
    transparentDisabled: "text-gray-400",
    "light-danger": "bg-red-50 text-red-800 border border-red-200 hover:bg-light-red transition duration-300",
    "light-gray":
        "bg-inherit border-2 border-gray-300 text-gray-500 border hover:bg-light-gray transition duration-300",
    transparent: "text-med-blue",
    "light-blue": "bg-blue-50 text-med-blue border border-blue-200  transition duration-300",
    "blue-100": "bg-blue-100 text-med-blue border border-blue-200  transition duration-300"
}
