import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { closeModal } from "store/modal/actions"
import { DialogV2, TableCellV2, TableListV2, TagWithIcon, TypographyV2 } from "components/v2"
import {
    getEventTitle,
    getEventType,
    getEventTypeName,
    getLocalDateWithYear
} from "../../components/CareplanSection/constants"
import { CarePlanEventResponse } from "../../forms/types"
import { ClockIcon, ScheduleIcon } from "../../components/icons"
import { getRecurrenceDescriptionShort } from "../../hooks/useRecurrenceDescription"
import { useIsHeLanguage } from "../../hooks"
import { classNames } from "../../utils"

interface ViewIndependentEventsModalProps {
    isOpen: boolean
    params?: CarePlanEventResponse[]
}

const ViewIndependentEventsModal: React.FC<ViewIndependentEventsModalProps> = ({ isOpen, params: events }) => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()

    const { isHeLanguage } = useIsHeLanguage()
    const handleClose = () => {
        dispatch(closeModal())
    }

    const headerCell = useMemo(
        () => [
            {
                title: t("event", { ns: "carePlan" })
            },
            {
                title: t("event_type", { ns: "carePlan" })
            },
            {
                title: t("configuration", { ns: "carePlan" })
            },
            {
                title: t("assign_date", { ns: "carePlan" })
            },
            {
                title: t("last_engagement", { ns: "carePlan" })
            }
        ],
        []
    )

    return (
        <DialogV2
            classList={{
                dialog: isHeLanguage ? "!translate-x-[0%]" : "",
                title: isHeLanguage ? "!translate-x-[-20%]" : "",
                content: "overflow-y-auto"
            }}
            title={t("independent_events", { ns: "carePlan" })}
            isFooterHidden
            isOpen={isOpen}
            onClose={handleClose}
            type="wide"
        >
            <div className="px-4 w-full !h-[600px] overflow-y-auto">
                <div className="flex items-center">
                    {events?.length && (
                        <TableListV2
                            isLoading={false}
                            isEmpty={!events.length}
                            headerCells={headerCell}
                            listComponent={events?.map((event: CarePlanEventResponse & { title: string }) => {
                                const time = event?.recurrence?.interval
                                    ? getRecurrenceDescriptionShort(event?.recurrence?.interval)
                                    : null

                                return (
                                    <tr>
                                        <TableCellV2>
                                            <TypographyV2 width="semibold">
                                                {event?.title ?? getEventTitle(event)}
                                            </TypographyV2>
                                        </TableCellV2>
                                        <TableCellV2>
                                            <div className="flex items-center bg-gray-100 rounded-sm w-min py-1 px-2">
                                                <TagWithIcon
                                                    className="!bg-none !w-[14px] !h-[14px] !pt-0"
                                                    variant="light-gray"
                                                    eventType={getEventType(event?.type)}
                                                />
                                                <TypographyV2
                                                    className={classNames(
                                                        "text-gray-500",
                                                        isHeLanguage ? "mr-2" : "ml-2"
                                                    )}
                                                >
                                                    {getEventTypeName(event.type)}
                                                </TypographyV2>
                                            </div>
                                        </TableCellV2>
                                        <TableCellV2>
                                            {!!time && (
                                                <div className="flex items-center">
                                                    <ScheduleIcon />
                                                    <TypographyV2 className="ml-1 text-gray-400">{time}</TypographyV2>
                                                </div>
                                            )}
                                        </TableCellV2>
                                        <TableCellV2>
                                            <TypographyV2 className="text-gray-400">
                                                {getLocalDateWithYear(event.created_at)}
                                            </TypographyV2>
                                        </TableCellV2>
                                        <TableCellV2>
                                            <TypographyV2 className="text-gray-400">
                                                {getLocalDateWithYear(event.completed_at)}
                                            </TypographyV2>
                                        </TableCellV2>
                                    </tr>
                                )
                            })}
                        />
                    )}
                </div>
            </div>
        </DialogV2>
    )
}

export default ViewIndependentEventsModal
