import { TableCellV2, TypographyV2 } from "components/v2"
import React, { ReactNode } from "react"
import { classNames } from "utils"
import BallLoading from "../../../../../icons/BallLoading"
import { Spinner } from "../../../../../common"
import Skeleton from "react-loading-skeleton"

interface IHeaderProps {
    title?: string
    className?: string
}

interface ITableV2Props {
    isLoading?: boolean
    isEmpty?: boolean
    headerCells: IHeaderProps[]
    listComponent?: ReactNode
    emptyListComponent?: ReactNode
    tableClassName?: string
}

const TableListV2: React.FC<ITableV2Props> = ({
    isLoading = false,
    isEmpty = false,
    headerCells,
    emptyListComponent,
    listComponent,
    tableClassName
}) => {
    if (isLoading)
        return (
            <div className="w-full">
                <Skeleton width="100%" height="8em" count={5} />
            </div>
        )

    if (isEmpty) return <>{emptyListComponent}</>

    return (
        <table
            className={classNames(
                "table-auto bg-white border rounded-lg  border-separate border-spacing-4 w-full",
                tableClassName
            )}
        >
            <thead>
                <tr>
                    {headerCells.map(({ title, className }, idx) => (
                        <TableCellV2 key={idx} className={classNames("px-4", className)}>
                            <TypographyV2 width="medium" className="text-gray-400">
                                {title}
                            </TypographyV2>
                        </TableCellV2>
                    ))}
                </tr>
            </thead>
            <tbody>{listComponent}</tbody>
        </table>
    )
}

export default TableListV2
