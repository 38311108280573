import { ScreeningQuestionScale, ScreeningTemplateQuestion } from "@prisma/client"
import { TypographyV2, ControlledRadioCircleGroupV2 } from "components/v2"
import { Control, Controller } from "react-hook-form"
import { useScreenSize } from "hooks"
import { TableListV2 } from "components/v2"
import { useEffect } from "react"

interface IScaleQuestionProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: ScreeningTemplateQuestion & { scale: ScreeningQuestionScale }
    onChange?: () => void
}

enum ScaleDirection {
    POSITIVE = "positive",
    NEGATIVE = "negative"
}

const ScaleQuestionCard = ({ control, error, question, onChange }: IScaleQuestionProps) => {
    const { is_required, question: question_text, scale } = question
    const isDesktop = useScreenSize()

    const scaleMinValue = scale?.min || 0
    const scaleMaxValue = scale?.max || 5
    const scaleLabels =
        (scale?.labels as any)?.[0]?.map((label: string, i: number) =>
            label.trim() == "" ? (i + 1).toString() : label
        ) || Array.from({ length: scaleMaxValue - scaleMinValue + 1 }, (_, i) => (i + 1).toString())
    const scaleDirection = scale?.direction === ScaleDirection.POSITIVE
    const options = scaleLabels?.map((label: string, index: number) => {
        const step = (scaleMaxValue - scaleMinValue) / (scaleLabels.length - 1)
        const value = scaleDirection
            ? Math.round(scaleMaxValue - step * index)
            : Math.round(scaleMinValue + step * index)

        return {
            value: value.toString(),
            labelComponent: <TypographyV2 type="base">{label}</TypographyV2>,
            key: `scale_${question.id}_${value}`
        }
    })

    if (isDesktop) {
        return (
            <TableListV2
                headerCells={[]}
                listComponent={
                    <tr className="border-b border-gray-200">
                        <td className="py-12 px-6">
                            <div className="flex flex-col gap-4">
                                <TypographyV2 type="base">{question_text}</TypographyV2>
                                {question?.description && (
                                    <TypographyV2 type="xs" className="text-gray-500">
                                        {question.description}
                                    </TypographyV2>
                                )}
                            </div>
                        </td>
                        <td className="py-12 px-6">
                            <ControlledRadioCircleGroupV2
                                name={`screeningAnswers.${question.id}`}
                                classList={{ wrapper: "w-full" }}
                                isBordered
                                isRequired={is_required}
                                options={options}
                                label=""
                                control={control}
                                error={error}
                                onChange={onChange}
                            />
                        </td>
                    </tr>
                }
            />
        )
    }
    return (
        <div className="flex flex-col items-start justify-center gap-[12px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <ControlledRadioCircleGroupV2
                name={`screeningAnswers.${question.id}`}
                classList={{ wrapper: "w-full" }}
                isBordered
                isRequired={is_required}
                options={options}
                label={""}
                control={control}
                error={error}
                onChange={onChange}
            />
        </div>
    )
}

export default ScaleQuestionCard
