import { TypographyV2 } from "components/v2"
import { ReactNode, useRef } from "react"
import { classNames } from "utils"

export type SidebarListProps = {
    id: string
    title: string
    children: ReactNode
    className?: string
}

const SidebarList = ({ id, title, children, className }: SidebarListProps) => {
    const ref = useRef<HTMLDivElement>()
    const buttonRef = useRef<HTMLDivElement>()

    const style = {
        transitionDelay: "0ms",
        transitionDuration: "300ms"
    }

    return (
        <div
            className={classNames(className, "text-secondary pb-2 relative transition-all h-full overflow-hidden")}
            ref={ref}
            id={id}
            style={style}
        >
            <div className="h-full overflow-hidden flex flex-col">
                <div className="px-2 flex py-0 justify-between cursor-pointer" ref={buttonRef}>
                    <TypographyV2 type="xs" width="medium" className="text-text-blue">
                        {" "}
                        {title}
                    </TypographyV2>
                </div>

                <div className="flex flex-col pt-1 pb-7 transition-opacity overflow-y-auto" data-testid={`left-bar-${id}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default SidebarList
