import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2, RadioCircleGroupV2 } from "components/v2"

enum ScaleDirection {
    POSITIVE = "positive",
    NEGATIVE = "negative"
}

const ScaleQuestionResponse = ({ question, screeningAnswer, sybQuestion = false }: { question: any; screeningAnswer: ScreeningAnswer, sybQuestion?: boolean }) => {
    const scaleMinValue = question?.scale?.min || 0
    const scaleMaxValue = question?.scale?.max || 10
    const rawLabels = question?.scale?.labels?.[0] || []
    const scaleDirection = question?.scale?.direction === ScaleDirection.POSITIVE

    // Check if all labels are empty strings and provide fallback, usually happens when we save the scale from a questionnaire with no labels
    const scaleLabels = rawLabels.every((label: string) => label.trim() === "")
        ? Array.from({ length: scaleMaxValue - scaleMinValue + 1 }, (_, i) => (i + scaleMinValue).toString())
        : rawLabels

    // Only create options if we have valid labels
    const options =
        scaleLabels.length > 0
            ? scaleLabels.map((label: string, index: number) => {
                  const step = (scaleMaxValue - scaleMinValue) / (scaleLabels.length - 1)
                  const value = scaleDirection ? scaleMaxValue - step * index : scaleMinValue + step * index

                  return {
                      value: value.toString(),
                      labelComponent: <TypographyV2 type="base">{label}</TypographyV2>
                  }
              })
            : []

    return (
        <div className="">
            <div className="bg-white border border-slate-300 rounded-lg pt-4 pl-3">
                <TypographyV2 type="base" className="text-[#6B7280]">
                    {question.question}
                </TypographyV2>

                <div className="pt-2 flex flex-col gap-4">
                    {options.length > 0 ? (
                        <RadioCircleGroupV2
                            name={question.id}
                            onChange={() => {}}
                            readOnly
                            value={screeningAnswer?.value}
                            options={options}
                        />
                    ) : (
                        <TypographyV2 className="text-[#6B7280] mt-2" type="base">
                            {screeningAnswer?.value || "-"}
                        </TypographyV2>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ScaleQuestionResponse
