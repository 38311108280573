import { Modal } from "components/common"
import { CloseIcon } from "components/icons"
import { TypographyV2 } from "components/v2/components/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useUnitSelector } from "store/hooks"
import { closeDeleteUnitChapterElementModalAction } from "store/unit/actions"
import { eventTypeLocals } from "../unitConstants"
import PreviewSelectedElement from "./PreviewSelectedElement"

const DeleteElementModal = () => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()

    const { deleteElementModal, elements } = useUnitSelector()

    const selectedElement = elements.find((element) => element.id === deleteElementModal.elementId)

    const onClose = () => {
        dispatch(closeDeleteUnitChapterElementModalAction())
    }

    return (
        <Modal show={deleteElementModal.isOpened} onClose={onClose} centered cardClassName="w-3/4 max-w-screen-lg">
            <div className="p-4 flex justify-between border-b border-border-blue">
                <TypographyV2 width="bold" type="base" className="text-med-black">
                    {selectedElement?.element_type
                        ? t(`eventType.${eventTypeLocals[selectedElement.element_type]}`)
                        : ""}
                </TypographyV2>
                <CloseIcon onClick={onClose} className="cursor-pointer text-[#9CA3AF]" height={20} width={20} />
            </div>
            <PreviewSelectedElement />
        </Modal>
    )
}

export default DeleteElementModal
