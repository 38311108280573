import { useRef, useState } from "react"
import { useClickOutside } from "hooks/useClickOutside"
import { classNames } from "utils"
import { TextInputV2, TypographyV2 } from "components/v2"

interface ISelectOptionsV2Props {
    options: string[]
    values: any
    setValues: (values: string[]) => void
    withOther: boolean
    disabled?: boolean
}

const SelectOptionsV2 = ({ options, values = [], setValues, withOther, disabled }: ISelectOptionsV2Props) => {
    const [showOther, setShowOther] = useState(false)
    const [otherValue, setOtherValue] = useState("")

    const ref = useRef<HTMLInputElement>(null)

    const handleOnBlur = () => {
        if (otherValue) {
            setValues([...values, otherValue])
        }
        setShowOther(false)
    }
    useClickOutside([ref], handleOnBlur)

    const handleOptionClick = (option: string) => {
        if (values.includes(option)) {
            setValues(values.filter((value: string) => value !== option))
        } else {
            setValues([...values, option])
        }
    }

    return (
        <div className="flex flex-row items-start justify-start gap-[8px] w-full flex-wrap">
            {options.map((option) => (
                <div
                    key={option}
                    onClick={() => !disabled && handleOptionClick(option)}
                    className={classNames(
                        "flex flex-row items-center justify-center border rounded-lg py-4 px-6 cursor-pointer",
                        values.includes(option) ? "bg-[#EFF6FF] border-[#3C7FF9]" : "border-[#E5E7EB]"
                    )}
                >
                    <TypographyV2 type="base">{option}</TypographyV2>
                </div>
            ))}
            {withOther && !showOther && (
                <div
                    onClick={() => !disabled && setShowOther(true)}
                    className={classNames(
                        "flex flex-row items-center justify-center border rounded-lg py-4 px-6 cursor-pointer",
                        otherValue ? "bg-[#EFF6FF] border-[#3C7FF9]" : "border-[#E5E7EB]"
                    )}
                >
                    <TypographyV2 type="base">{otherValue || "+"}</TypographyV2>
                </div>
            )}
            {showOther && (
                <div className="flex-1 min-w-[100px]">
                    <TextInputV2
                        ref={ref}
                        value={otherValue}
                        className="h-[57.25px]"
                        placeholder="Other..."
                        onChange={(value) => setOtherValue(value)}
                        onBlur={handleOnBlur}
                    />
                </div>
            )}
        </div>
    )
}

export default SelectOptionsV2
