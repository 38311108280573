import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2, ScaleSliderV2 } from "components/v2"
import { classNames } from "utils"

const ScaleSliderQuestionResponse = ({
    question,
    screeningAnswer,
    sybQuestion = false,
    isLastQuestion = false
}: {
    question: any
    screeningAnswer: ScreeningAnswer
    sybQuestion?: boolean
    isLastQuestion?: boolean
}) => {
    const { is_required, question: question_text, definition, scale } = question

    const min = scale?.min ?? definition?.min ?? 0
    const max = scale?.max ?? definition?.max ?? 100
    const step = scale?.step ?? definition?.step ?? 1
    const minLabel = scale?.labels?.[0] ?? definition?.minLabel ?? min.toString()
    const maxLabel = scale?.labels?.[scale?.labels?.length - 1] ?? definition?.maxLabel ?? max.toString()

    return (
        <div
            className={classNames(
                "bg-white border-slate-300 pt-4 pl-3",
                sybQuestion && !isLastQuestion ? "border-b" : "border rounded-lg",
                isLastQuestion && "border-none"
            )}
        >
            <TypographyV2 type="base" className="text-[#6B7280]">
                {question_text}
            </TypographyV2>

            <div className="pt-2 flex flex-col gap-4">
                <ScaleSliderV2
                    value={Number(screeningAnswer?.value ?? 0)}
                    onChange={(value) => {}}
                    min={min}
                    max={max}
                    step={step}
                    minLabel={minLabel}
                    maxLabel={maxLabel}
                />
            </div>
        </div>
    )
}

export default ScaleSliderQuestionResponse
