import { useSelector } from "react-redux"
import { useDispatch } from "store"
import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { closeModal } from "store/modal/actions"
import { useForm, useWatch } from "react-hook-form"
import { PublishStatuses } from "components/v2/types"
import { useLibraryTemplateSelector, useUnitSelector } from "store/hooks"
import { RootState } from "types/Redux"
import { formatDate } from "utils"
import { yupResolver } from "@hookform/resolvers/yup"
import {
    getLibraryElementPublishAndReplaceTitle,
    getPublishExerciseSchema,
    getPublishOptions,
    getPublishSteps
} from "./publishLibraryTemplateModalConstants"
import { useIsHeLanguage } from "hooks"
import { closeLibraryTemplateBuilderAction, publishLibraryTemplateAction } from "store/library-template/actions"
import { LibraryTemplateType } from "@prisma/client"

export const usePublishLibraryTemplateModal = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation("exercise")

    const modal = useSelector((state: RootState) => state.modal)

    const [isReviewed, setIsReviewed] = useState(false)

    const { list } = useLibraryTemplateSelector()

    const { chapters: unitChapters } = useUnitSelector()

    const { isHeLanguage } = useIsHeLanguage()

    const publishSteps = useMemo(() => getPublishSteps(), [isHeLanguage])
    const publishOptions = useMemo(() => getPublishOptions(), [isHeLanguage])

    const selectedLibraryTemplate = modal.params

    const exerciseId = selectedLibraryTemplate.id

    const isUnit = selectedLibraryTemplate.template_type === LibraryTemplateType.UNIT

    const mappedExerciseForSelect = useMemo(
        () =>
            list
                .filter((item) => item.id !== exerciseId)
                .map(({ title, id, created_at }) => ({
                    title,
                    id,
                    subtitle: `${t("common:create")} ${formatDate(created_at)}`
                })),
        [list, exerciseId]
    )

    const { control } = useForm({
        defaultValues: {
            status: PublishStatuses.Publish,
            replacedExerciseId: null
        },
        resolver: yupResolver(getPublishExerciseSchema())
    })

    const formValues = useWatch({
        control
    })

    const activeStatus = formValues.status
    const replacedExerciseId = formValues.replacedExerciseId

    const isPublishAndReset = activeStatus === PublishStatuses.PublishAndReset

    const submitBtnTitle = t(
        isReviewed
            ? isPublishAndReset
                ? getLibraryElementPublishAndReplaceTitle(selectedLibraryTemplate.template_type)
                : "common:publish"
            : "common:continue"
    )

    const replacedExercise = useMemo(() => list.find(({ id }) => id === replacedExerciseId), [replacedExerciseId, list])

    const onClose = () => {
        dispatch(closeModal())
    }

    const onContinueClick = () => {
        if (!isReviewed) {
            setIsReviewed(true)
            return
        }
        dispatch(publishLibraryTemplateAction(exerciseId))
        dispatch(closeLibraryTemplateBuilderAction())
        onClose()
    }

    const onBackClick = () => {
        setIsReviewed(false)
    }

    return {
        t,
        control,
        publishSteps,
        publishOptions,
        selectedExercise: selectedLibraryTemplate,
        replacedExercise,
        isPublishAndReset,
        submitBtnTitle,
        isReviewed,
        mappedExerciseForSelect,
        onClose,
        onBackClick,
        onContinueClick,
        isUnit,
        unitChapters
    }
}
