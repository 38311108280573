import {
    CareProtocolSequentialEvents,
    ElementType,
    EventType,
    SequentialEventStatuses,
    TriggerType,
    UnitChapterElementType
} from "@prisma/client"
import {
    CarePlanEventResponse,
    CareProtocolElementResponse,
    CareProtocolSequentialEventResponse,
    SequentialEventResponse
} from "forms/types"
import { EventTypes } from "types"
import { localeFormat } from "utils/dates"
import { getRecurrenceDescriptionShort } from "hooks/useRecurrenceDescription"
import i18next from "i18next"

export const getEventType = (type: EventType | "care_protocol") => {
    return {
        [EventType.exercise]: EventTypes.Exercise,
        [EventType.questionnaire]: EventTypes.Questionnaire,
        [EventType.reflection]: EventTypes.Exercise,
        [EventType.tracker]: EventTypes.Tracker,
        [EventType.appointment]: EventTypes.Appointment,
        [EventType.mood]: EventTypes.Exercise,
        [EventType.task]: EventTypes.Task,
        [EventType.educational_content]: EventTypes.EducationalContent,
        [EventType.unit]: EventTypes.Unit,
        care_protocol: EventTypes.Protocol
    }[type]
}

export const getEventDbType = (type: EventTypes): EventType | "care_protocol" => {
    const eventTypeMapping = {
        [EventTypes.Exercise]: EventType.exercise,
        [EventTypes.Questionnaire]: EventType.questionnaire,
        [EventTypes.Reflection]: EventType.reflection,
        [EventTypes.Tracker]: EventType.tracker,
        [EventTypes.Appointment]: EventType.appointment,
        [EventTypes.Mood]: EventType.mood,
        [EventTypes.Task]: EventType.task,
        [EventTypes.EducationalContent]: EventType.educational_content,
        [EventTypes.Unit]: EventType.unit,
        [EventTypes.Protocol]: "care_protocol" as const
    }

    return eventTypeMapping?.[type]
}

export const getEventTypeByProtocolElement = (type: UnitChapterElementType) => {
    return {
        [UnitChapterElementType.EXERCISE]: EventTypes.Exercise,
        [UnitChapterElementType.QUESTIONNAIRE]: EventTypes.Questionnaire,
        [UnitChapterElementType.EDUCATIONAL_CONTENT]: EventTypes.EducationalContent
    }[type]
}

export const getEventTypeBySequentialElement = (type: ElementType) => {
    return {
        [ElementType.EXERCISE]: EventTypes.Exercise,
        [ElementType.QUESTIONNAIRE]: EventTypes.Questionnaire,
        [ElementType.UNIT]: EventTypes.Unit
    }[type]
}

export const getEventTitle = (event: CarePlanEventResponse) => {
    const titlesTypes: Record<EventType, string> = {
        [EventType.exercise]: event?.exercise?.library_template?.title ?? "",
        [EventType.appointment]: event?.appointment?.title ?? "",
        [EventType.task]: event?.task?.title ?? "",
        [EventType.questionnaire]: event?.questionnaire?.questionnaire_def?.title ?? "",
        [EventType.educational_content]: event?.educational_content?.library_template?.title ?? "",
        [EventType.mood]: "",
        [EventType.tracker]: "",
        [EventType.reflection]: "",
        [EventType.unit]: event?.unit?.library_template?.title ?? ""
    }

    return titlesTypes[event.type]
}

export const getEventTypeName = (type: EventType | "care_protocol"): string => {
    const titlesTypes: { [key in EventType | "care_protocol"]: string } = {
        [EventType.exercise]: i18next.t("eventTypes.exercise", { ns: "patient" }),
        [EventType.appointment]: i18next.t("eventTypes.appointment", { ns: "patient" }),
        [EventType.task]: i18next.t("eventTypes.task", { ns: "patient" }),
        [EventType.questionnaire]: i18next.t("eventTypes.questionnaire", { ns: "patient" }),
        [EventType.educational_content]: i18next.t("eventTypes.educational_content", { ns: "patient" }),
        [EventType.mood]: i18next.t("eventTypes.mood", { ns: "patient" }),
        [EventType.tracker]: i18next.t("eventTypes.tracker", { ns: "patient" }),
        [EventType.reflection]: i18next.t("eventTypes.reflection", { ns: "patient" }),
        [EventType.unit]: i18next.t("eventTypes.unit", { ns: "patient" }),
        care_protocol: i18next.t("eventTypes.protocol", { ns: "patient" })
    }

    return titlesTypes[type]
}

export const getEventTitleByProtocolElement = (event: CareProtocolElementResponse) => {
    const titlesTypes: Record<ElementType, string> = {
        [ElementType.EXERCISE]: event?.exercise_element?.title ?? "",
        [ElementType.QUESTIONNAIRE]: event?.questionnaire_element?.title ?? "",
        [ElementType.UNIT]: event?.unit_element?.title ?? ""
    }

    return titlesTypes[event.element_type]
}

export const getLocalDate = (date: Date) =>
    Boolean(date) ? localeFormat(new Date(date), i18next.language === "he" ? "d בMMMM" : "MMMM d", i18next) : null

export const getLocalDateWithYear = (date: Date) =>
    Boolean(date)
        ? localeFormat(
              new Date(date),
              i18next.language === "he"
                  ? "d בMMMM yyyy" // Формат для івриту (день, місяць, рік)
                  : "MMMM d yyyy", // Формат для інших мов (місяць, день, рік)
              i18next
          )
        : null

export const mapEvent = (event: any) => {
    const { type, date_on_timeline, recurrence, ...rest } = event
    const localDate = getLocalDate(date_on_timeline)
    return {
        title: getEventTitle(event),
        when: localDate,
        time: recurrence?.interval ? getRecurrenceDescriptionShort(recurrence?.interval) : null,
        eventType: getEventType(type),
        isLocked: false,
        type,
        date_on_timeline,
        recurrence,
        ...rest
    }
}

export const mapUnitChapterSequentialEvent = (event: any) => {
    const { type, date_on_timeline, recurrence, ...rest } = event
    return {
        title: event?.library_template?.title ?? event?.questionnaire?.title,
        when: null,
        time: null,
        eventType: getEventTypeByProtocolElement(event.element_type),
        isLocked: false,
        ...rest
    }
}

export const mapSequentialEvent = (event: any) => {
    const { id, element, trigger_type, update_time, status, ...rest } = event
    const isLocked = trigger_type === TriggerType.SEQUENTIAL_MANUAL
    const isTriggered = status === SequentialEventStatuses.TRIGGERED

    const addDays = (date: Date, days: number): any => {
        const result = new Date(date)
        result.setDate(result.getDate() + days)

        return result.toISOString().replace("T", " ").split(".")[0]
    }

    return {
        ...rest,
        id,
        element,
        title: getEventTitleByProtocolElement(element),
        when: isTriggered ? getLocalDate(addDays(event.update_time, element.delay)) : null,
        time: element?.recurrence_interval ? getRecurrenceDescriptionShort(element.recurrence_interval) : null,
        eventType: getEventTypeBySequentialElement(element.element_type),
        isLocked,
        isSequential: true
    }
}

export const formatPastDate = (date: Date): { day: number; monthShort: string; month: string; year: number } => {
    const day = date.getDate()
    const monthShort = date.toLocaleString("en-US", { month: "short" }).toUpperCase()
    const month = date.toLocaleString("en-US", { month: "long" })
    const year = date.getFullYear()

    return { day, month, year, monthShort }
}
