import { getEducationalContentByTemplateId, getEducationalContentHtmlById } from "api"
import React, { FC, useEffect, useState } from "react"
import { LibraryTemplateResponse } from "types/Redux"

interface IUnitElementPreviewEducationalContentProps {
    id?: number
    libraryTemplate?: LibraryTemplateResponse
}

const PreviewEducationalContent: FC<IUnitElementPreviewEducationalContentProps> = ({ id, libraryTemplate }) => {
    const [htmlEduc, setHtmlEduc] = useState<null | string>(null)
    const [isLoading, setIsLoading] = useState(false)

    const libraryTemplateId = libraryTemplate?.id || id

    useEffect(() => {
        const fetchHtml = async () => {
            if (!libraryTemplateId || htmlEduc || isLoading) return
            setIsLoading(true)
            const educationalContentData = await getEducationalContentByTemplateId(id)
            const response = await getEducationalContentHtmlById(educationalContentData.id)
            setHtmlEduc(response.html)
            setIsLoading(false)
        }
        fetchHtml()
    }, [isLoading, htmlEduc])
    return (
        <div className="p-4 mt-3">
            {libraryTemplate && <p className="text-med-black pb-6 text-xl font-bold">{libraryTemplate.title}</p>}
            <div className="px-2 pb-6">
                {htmlEduc && (
                    <div className="no-tailwind h-full break-words" dangerouslySetInnerHTML={{ __html: htmlEduc }} />
                )}
            </div>
        </div>
    )
}

export default PreviewEducationalContent
