import { Placeholder } from "@aws-amplify/ui-react"
import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2 } from "components/v2"
import { classNames } from "utils"
const MinutesQuestionResponse = ({
    question,
    screeningAnswer,
    sybQuestion = false,
    isLastQuestion = false,
    placeholder = "-"
}: {
    question: any
    screeningAnswer: ScreeningAnswer
    sybQuestion?: boolean
    isLastQuestion?: boolean
    placeholder?: string
}) => {
    return (
        <div
            className={classNames(
                "bg-white border-slate-300 py-4 px-3",
                sybQuestion ? "border-b" : "border rounded-lg",
                !isLastQuestion && "border-none"
            )}
        >
            <TypographyV2 type="sm" className="font-bold">
                {question.question}
            </TypographyV2>
            <TypographyV2 className="text-[#6B7280] mt-2" type="base">
                {screeningAnswer?.value ? `${screeningAnswer.value} Minutes` : placeholder}
            </TypographyV2>
        </div>
    )
}

export default MinutesQuestionResponse
