import { SVGProps } from "react"

interface ProtocolIconProps extends SVGProps<SVGSVGElement> {
    variant?: "default" | "light-gray"
}

const ProtocolIcon = ({ variant = "default", ...props }: ProtocolIconProps) => {
    const pathFill = variant === "default" ? "#EAB308" : "#9CA3AF"
    return (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 1C6.55116 1 6.17016 1.29608 6.04415 1.70478C6.01557 1.79748 6 1.89646 6 2H9C9 1.89646 8.98443 1.79748 8.95585 1.70478C8.82984 1.29608 8.44884 1 8 1H7ZM5.22467 1.07843C5.55779 0.437952 6.22727 0 7 0H8C8.77273 0 9.44221 0.437952 9.77533 1.07843C9.92516 1.08879 10.0747 1.10017 10.224 1.11256C11.2502 1.19774 12 2.06855 12 3.07214V10C12 11.1046 11.1046 12 10 12H9V12.75C9 13.4404 8.44036 14 7.75 14H1.25C0.559644 14 0 13.4404 0 12.75V5.25C0 4.55964 0.559644 4 1.25 4H3V3.07215C3 2.06855 3.74984 1.19774 4.77603 1.11256C4.9253 1.10017 5.07485 1.08879 5.22467 1.07843ZM5.00468 2.09736C4.956 2.10118 4.90736 2.1051 4.85875 2.10914C4.37682 2.14914 4 2.56243 4 3.07215V4H7.75C8.44036 4 9 4.55964 9 5.25V11H10C10.5523 11 11 10.5523 11 10V3.07214C11 2.56243 10.6232 2.14914 10.1413 2.10914C10.0926 2.1051 10.044 2.10118 9.99532 2.09736C9.94637 2.60396 9.51944 3 9 3H6C5.48056 3 5.05363 2.60396 5.00468 2.09736ZM8 5.25C8 5.11193 7.88807 5 7.75 5H1.25C1.11193 5 1 5.11193 1 5.25V12.75C1 12.8881 1.11193 13 1.25 13H7.75C7.88807 13 8 12.8881 8 12.75V5.25ZM2 7C2 6.72386 2.22386 6.5 2.5 6.5H2.505C2.78114 6.5 3.005 6.72386 3.005 7V7.005C3.005 7.28114 2.78114 7.505 2.505 7.505H2.5C2.22386 7.505 2 7.28114 2 7.005V7ZM3.5 7C3.5 6.72386 3.72386 6.5 4 6.5H6.5C6.77614 6.5 7 6.72386 7 7C7 7.27614 6.77614 7.5 6.5 7.5H4C3.72386 7.5 3.5 7.27614 3.5 7ZM2 9C2 8.72386 2.22386 8.5 2.5 8.5H2.505C2.78114 8.5 3.005 8.72386 3.005 9V9.005C3.005 9.28114 2.78114 9.505 2.505 9.505H2.5C2.22386 9.505 2 9.28114 2 9.005V9ZM3.5 9C3.5 8.72386 3.72386 8.5 4 8.5H6.5C6.77614 8.5 7 8.72386 7 9C7 9.27614 6.77614 9.5 6.5 9.5H4C3.72386 9.5 3.5 9.27614 3.5 9ZM2 11C2 10.7239 2.22386 10.5 2.5 10.5H2.505C2.78114 10.5 3.005 10.7239 3.005 11V11.005C3.005 11.2811 2.78114 11.505 2.505 11.505H2.5C2.22386 11.505 2 11.2811 2 11.005V11ZM3.5 11C3.5 10.7239 3.72386 10.5 4 10.5H6.5C6.77614 10.5 7 10.7239 7 11C7 11.2761 6.77614 11.5 6.5 11.5H4C3.72386 11.5 3.5 11.2761 3.5 11Z"
                fill={pathFill}
            />
        </svg>
    )
}

export default ProtocolIcon
