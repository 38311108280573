import { UnitChapterElementType } from "@prisma/client"
import { BadgeV2, TypographyV2 } from "components/v2/components/common"
import { useIsHeLanguage } from "hooks"
import React, { useMemo } from "react"
import { useUnitSelector } from "store/hooks"
import { classNames } from "utils"
import TagWithIcon from "components/v2/components/TagWithIcon"
import { eventType } from "../../unitConstants"
import { PreviewEducationalContent, PreviewExercise, PreviewQuestionnaire } from "../../../library-template"
import { useTranslation } from "react-i18next"

const PreviewSelectedElement = () => {
    const {
        addElementModal: { selectedElement, elementType, selectedQuestionnaireElement }
    } = useUnitSelector()
    const { isHeLanguage } = useIsHeLanguage()

    const { t } = useTranslation("common")

    const data = useMemo(() => {
        if (selectedElement) {
            return {
                diagnoses: selectedElement.diagnoses.map(({ diagnose }) => ({
                    name: diagnose.name_en,
                    id: diagnose.id
                })),
                title: selectedElement.title,
                description: selectedElement.description
            }
        }
        if (selectedQuestionnaireElement) {
            return {
                diagnoses: selectedQuestionnaireElement.related_diagnoses,
                title: selectedQuestionnaireElement.title,
                description: selectedQuestionnaireElement.professional_description
            }
        }
        return null
    }, [])

    return (
        <div className="p-6 h-120 overflow-hidden">
            <div className="flex gap-2 h-full">
                <div className="w-2/5 p-4 h-full overflow-y-auto">
                    <div className="flex gap-2 pb-4">
                        {data.diagnoses.map(({ id, name }) => (
                            <BadgeV2 key={id} text={name} color="darkBlue" className="px-1.5 py-0.5" />
                        ))}
                    </div>
                    <div>
                        <div className="flex gap-2 items-center pb-1">
                            <TagWithIcon eventType={eventType[elementType]} />
                            <TypographyV2
                                type="sm"
                                width="bold"
                                className={classNames(
                                    isHeLanguage ? "text-right" : "text-left",
                                    "w-auto overflow-hidden text-ellipsis"
                                )}
                            >
                                {data.title}
                            </TypographyV2>
                        </div>
                        <TypographyV2
                            type="sm"
                            className={classNames(
                                "text-gray-400 mt-1",
                                isHeLanguage ? "text-right" : "text-left",
                                "w-auto overflow-hidden text-ellipsis"
                            )}
                        >
                            {data.description}
                        </TypographyV2>
                    </div>
                </div>
                <div className="w-3/5 flex flex-col h-full overflow-hidden">
                    <TypographyV2 width="semibold">{t("eventBuilder.content")}</TypographyV2>
                    <div className="rounded-lg h-full mt-3 bg-light-background border border-light-border overflow-auto">
                        {elementType === UnitChapterElementType.EDUCATIONAL_CONTENT && (
                            <PreviewEducationalContent id={selectedElement.id} />
                        )}
                        {elementType === UnitChapterElementType.QUESTIONNAIRE && (
                            <PreviewQuestionnaire withoutTitle id={selectedQuestionnaireElement.id} />
                        )}
                        {elementType === UnitChapterElementType.EXERCISE && <PreviewExercise id={selectedElement.id} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewSelectedElement
