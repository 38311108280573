import { DialogV2 } from "components/v2"
import IntakeDialogHeaderStepList from "components/v2/components/IntakeScreening/IntakeDialogHeaderStepList/IntakeDialogHeaderStepList"
import { useState, useEffect } from "react"
import { getStepsList } from "../common/addScheduleElementModalConstants"
import ConfigureCPElement from "./ConfigureCPElement"
import { useForm } from "react-hook-form"
import { LibraryTemplateResponse } from "types/Redux"
import { LibraryTemplateType, TriggerType } from "@prisma/client"
import i18n from "locales"
import { PreviewCPElement, SelectCPElement } from "../common"
import { TFunction } from "i18next"

interface AddScheduleElementModalProps {
    isOpened: boolean
    onClose: () => void
    t: TFunction
    list: LibraryTemplateResponse[]
    libraryTemplateType: LibraryTemplateType | "QUESTIONNAIRE"
    onAddNewElement: (body: any) => void
    cpElement: any
}

const AddScheduleElementModal: React.FC<AddScheduleElementModalProps> = ({
    isOpened,
    onClose,
    t,
    list,
    libraryTemplateType,
    onAddNewElement,
    cpElement
}) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const steps = getStepsList()
  
    const getSelectedElementId = () => {
        if (!cpElement) return null
        return (
            cpElement?.questionnaire_element_library_template_id ||
            cpElement?.exercise_element_library_template_id ||
            cpElement?.unit_element_library_template_id ||
            null
        )
    }

    const {
        control,
        formState: { errors },
        getValues,
        watch,
        reset
    } = useForm({
        mode: "onChange",
        defaultValues: {
            numberOfDelay: "0",
            delayType: "1",
            numberOfAvailable: "1",
            availableType: "1",
            repeatsType: "0",
            numberOfRepeats: "0",
            selectedElementId: getSelectedElementId()
        }
    })

    useEffect(() => {
        if (cpElement) {
            reset({
                numberOfDelay: cpElement?.delay?.toString() || "0",
                delayType: "1",
                numberOfAvailable: cpElement?.available_for?.toString() || "1",
                availableType: "1",
                repeatsType: cpElement?.recurrence_interval?.toString() || "0",
                numberOfRepeats: cpElement?.recurrence_end?.toString() || "0",
                selectedElementId: getSelectedElementId()
            })
        }
    }, [cpElement?.id, reset])

    const handleBack = () => {
        setCurrentStepIndex(currentStepIndex - 1)
    }

    const selectedElementId = watch("selectedElementId")

    const handleNext = () => {
        if (!selectedElementId) return
        // TODO: add validation
        setCurrentStepIndex(currentStepIndex + 1)
    }

    const handleDismiss = () => {
        setCurrentStepIndex(0)
        reset()
        onClose()
    }

    const handleAddNewElement = () => {
        const { numberOfDelay, delayType, numberOfAvailable, availableType, repeatsType, numberOfRepeats } = getValues()

        const delay_type = Number(delayType)
        const delay = Number(numberOfDelay) * delay_type
        const available_type = Number(availableType)
        const available_for = Number(numberOfAvailable) * available_type
        const recurrence_interval = Number(repeatsType)
        const recurrence_end = Number(numberOfRepeats) * recurrence_interval
        const elementId = { [`${libraryTemplateType.toLowerCase()}_element_library_template_id`]: selectedElementId }

        try {
            onAddNewElement({
                id: cpElement?.id,
                delay,
                available_for,
                recurrence_interval,
                recurrence_end,
                element_type: libraryTemplateType,
                trigger_type: TriggerType.SCHEDULED,
                ...elementId
            })
        } catch (error) {
            console.log(error)
        } finally {
            handleDismiss()
        }
    }

    return (
        <DialogV2
            title={
                <IntakeDialogHeaderStepList
                    title={i18n.t("addToCareProtocol", {
                        ns: "careProtocol",
                        element: t(`eventTypes.${libraryTemplateType?.toLowerCase()}`)
                    })}
                    step={currentStepIndex}
                    stepList={steps}
                />
            }
            classList={{
                title: "w-[1200px]"
            }}
            isOpen={isOpened}
            onClose={onClose}
            onProceed={currentStepIndex === steps.length - 1 ? handleAddNewElement : handleNext}
            onDismiss={handleDismiss}
            onBack={currentStepIndex === 0 ? undefined : handleBack}
            backType="light-gray"
            backText={currentStepIndex === 0 ? undefined : t("intake:common.back")}
            dismissType="light-gray"
            proceedType={selectedElementId ? "primary" : "disabled"}
            proceedText={currentStepIndex === steps.length - 1 ? t("create_element") : t("continue")}
            type="wide"
        >
            <div className="h-[677px] overflow-y-auto w-full">
                {currentStepIndex === 0 && <SelectCPElement t={t} list={list} control={control} />}
                {currentStepIndex === 1 && (
                    <PreviewCPElement libraryElement={list?.find((el) => el.id === selectedElementId)} />
                )}
                {currentStepIndex === 2 && <ConfigureCPElement t={t} control={control} errors={errors} />}
            </div>
        </DialogV2>
    )
}

export default AddScheduleElementModal
