import { TypographyV2 } from "components/v2/components/common"
import React from "react"
import { UnitChapterResponse } from "types/Redux"
import { UnitDetailsChapterItem } from "./components"
import { useTranslation } from "react-i18next"

const UnitItemDetails = ({ unitChapters }: { unitChapters: UnitChapterResponse[] }) => {
    const { t } = useTranslation("unit")
    return (
        <>
            <TypographyV2 className="text-base-dark mt-6" type="xs" width="semibold">
                {t("changeWillAffect")}
            </TypographyV2>
            <div className="bg-light-background p-4 rounded-2xl border border-light-border mb-6 mt-2 flex flex-col gap-6">
                {unitChapters.map((chapter, index) => (
                    <UnitDetailsChapterItem chapter={chapter} key={index} />
                ))}
            </div>
        </>
    )
}

export default UnitItemDetails
