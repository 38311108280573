import { DateSegment } from "@react-stately/datepicker"

export const useGetTimeFieldSegments = (segments: DateSegment[]): DateSegment[] => {
    const hourSegment = segments.find((segment) => segment.type === "hour")
    const minuteSegment = segments.find((segment) => segment.type === "minute")
    const periodSegment = segments.find((segment) => segment.type === "dayPeriod")
    const separatorSegment = segments.find((segment) => segment.type === "literal")
    const separatorSegment2 = segments.findLast((segment) => segment.type === "literal")

    if (!hourSegment || !minuteSegment) {
        throw new Error("Invalid date field segments")
    }

    const formattedHourSegment = {
        ...hourSegment,
        text: Number.isNaN(hourSegment.text) ? hourSegment.text : hourSegment.text.padStart(2, "0")
    }
    const formattedMinuteSegment = {
        ...minuteSegment,
        text: Number.isNaN(minuteSegment.text) ? minuteSegment.text : minuteSegment.text.padStart(2, "0")
    }

    return [formattedHourSegment, separatorSegment, formattedMinuteSegment, separatorSegment2, periodSegment]
}
