import { AddBottomLineButton } from "components/v2/components/common"
import {
    AddChapterSummaryField,
    CreateUnitChapterElementPopover,
    DeleteElementModal,
    DeleteItemConfirmationDialogue,
    InsertFIrstUnitChapterElement,
    UnitChapterNameField
} from "components/v2/components/features"
import { UnitChapterElementList } from "components/v2/components/features/unit/UnitChapterElementList"
import { AddUnitContentFormSidebar } from "./AddUnitContentFormSidebar"
import { useEffect } from "react"
import { getChaptersListByUnitIdAction } from "store/unit/actions"
import { useDispatch } from "store"
import { useLibraryTemplateSelector, useUnitSelector } from "store/hooks"

const AddUnitContentForm = () => {
    const { libraryTemplate: libraryElementDto } = useLibraryTemplateSelector()

    const { elements } = useUnitSelector()

    const dispatch = useDispatch()
    useEffect(() => {
        libraryElementDto && dispatch(getChaptersListByUnitIdAction(libraryElementDto.id))
    }, [libraryElementDto])

    return (
        <>
            <div className="flex gap-6 h-full overflow-hidden">
                <AddUnitContentFormSidebar />
                <div className="overflow-scroll flex-1 pt-16">
                    <div className="w-200 mx-auto">
                        <UnitChapterNameField />
                        <AddChapterSummaryField />
                        {elements.length ? (
                            <>
                                <UnitChapterElementList />
                                <AddBottomLineButton
                                    text="Add Content"
                                    PopoverComponent={CreateUnitChapterElementPopover}
                                />
                            </>
                        ) : (
                            <InsertFIrstUnitChapterElement />
                        )}
                    </div>
                </div>
            </div>
            <DeleteItemConfirmationDialogue />
            <DeleteElementModal />
        </>
    )
}

export default AddUnitContentForm
