import React, { useMemo, useState } from "react"
import { BadgeV2, CardV2, DeleteIconV2, DropdownMenuV2, TagWithIcon, TypographyV2 } from "components/v2/index"
import { ClockIcon, DragIndicator, EditUnitDetailsIcon, ElipsisIcon, LockedIcon, ScheduleIcon } from "components/icons"
import { EventTypes } from "types"
import ProtocolIcon from "components/icons/ProtocolIcon"
import EventUnlockIcon from "components/icons/EventUnlockIcon"
import { UnitEventChaptersList, UnitSequentialChaptersList } from "./components"
import { closeModal, openDeleteEventModal, openEditEventModal, openUnlockEventModal } from "store/modal/actions"
import { useDispatch } from "react-redux"
import { CarePlanEventResponse, SequentialEventResponse } from "forms/types"
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import { EventCompletionStatus } from "../../../../../constants"
import { useTranslation } from "react-i18next"
import { useIsHeLanguage } from "../../../../../../../hooks"
import { classNames as classNamesUtil } from "utils"
import EventTypeGrayLabel from "../../../../../../EventTypeGrayLabel"
import { editEventCarePlanAction } from "../../../../../../../store/care-plan/actions"

type CombinedContentItemProps = {
    id: number
    title: string
    time: string
    when: string
    eventStatus?: EventCompletionStatus
    isLocked: boolean
    eventType: EventTypes
    isVerticalView?: boolean
    isDraggable?: boolean
    variant?: "light-gray" | "default"
    isEditOptionEnabled?: boolean
    numberOfEvents?: number
    classNames?: string
    withLabel?: boolean
    isUnitItem?: boolean
    isSequential?: boolean
    hideAction?: boolean
    isPastEvent?: boolean
}

type CombinedEventProps = any

const ScheduleItemCard: React.FC<CombinedEventProps> = ({
    isDraggable = false,
    isVerticalView = false,
    isLocked = false,
    isUnitItem = false,
    variant = "default",
    isEditOptionEnabled = false,
    withLabel = false,
    classNames,
    hideAction = false,
    isSequential = false,
    isPastEvent = false,
    ...event
}) => {
    const { id, title, time, when, completed_at, eventType } = event
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    const { isHeLanguage } = useIsHeLanguage()
    const [isExpanded, setIsExpanded] = useState(true)
    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
    }

    const dropdownList = useMemo(() => {
        const mainList = []

        if (isSequential) {
            mainList.unshift({
                title: t("unlock", { ns: "carePlan" }),
                icon: EventUnlockIcon,
                onClick: () => {
                    dispatch(closeModal())

                    dispatch(
                        openUnlockEventModal({
                            eventId: id,
                            eventTitle: title
                        }) as any
                    )
                }
            })
        }

        if (!event?.care_plan_protocol_assignment_id && !event?.protocol_id) {
            if (!isSequential) {
                mainList.push({
                    title: t("edit_event", { ns: "carePlan" }),
                    icon: EditUnitDetailsIcon,
                    onClick: () => {
                        dispatch(editEventCarePlanAction(event) as any)
                    }
                })
            }
        }

        mainList.push({
            // border: true,
            title: t("delete_from_timeline", { ns: "carePlan" }),
            icon: DeleteIconV2,
            onClick: () => {
                dispatch(closeModal())

                dispatch(
                    openDeleteEventModal({
                        event,
                        title,
                        isSequential
                    }) as any
                )
            }
        })

        return mainList
    }, [isLocked, id, event, title, isSequential])

    const isUnit = eventType === EventTypes.Unit

    const isCompleted = !!completed_at

    const unitChapters = event?.unit_chapters

    const allUnitEvents = useMemo(
        () =>
            Array.isArray(unitChapters) ? unitChapters.reduce((accum, { events }) => [...accum, ...events], []) : [],
        [unitChapters]
    )

    const totalCount = allUnitEvents?.length
    const completedEvents = allUnitEvents?.filter((event: any) => !!event?.completed_at)

    const bagePriviewText =
        (event?.unitPreview
            ? `${isHeLanguage ? event?.unitPreview?.allEvents : event?.unitPreview?.allCompletedEvents} / ${
                  isHeLanguage ? event?.unitPreview?.allCompletedEvents : event?.unitPreview?.allEvents
              } `
            : "") +
        (event.eventStatus === EventCompletionStatus.Skipped
            ? t("skipped")
            : event.eventStatus === EventCompletionStatus.Deleted
            ? t("deleted")
            : event.eventStatus === EventCompletionStatus.InProgress
            ? t("in_progress")
            : t("completed"))

    const isProtocolElement = !!event?.care_plan_protocol_assignment_id || !!event?.protocol_id

    const bageColor =
        event.eventStatus === EventCompletionStatus.Deleted || event.eventStatus === EventCompletionStatus.Skipped
            ? "red"
            : event.eventStatus === EventCompletionStatus.InProgress
            ? "blue"
            : "green"

    return (
        <CardV2 className={classNames}>
            {isVerticalView && (
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        {!isPastEvent && isUnit && !event?.unitPreview && (
                            <div onClick={toggleExpand}>
                                {isExpanded ? (
                                    <ChevronDownIcon
                                        className={classNamesUtil("w-4 h-4", isHeLanguage ? "ml-1.5" : " mr-1.5")}
                                    />
                                ) : (
                                    <ChevronRightIcon
                                        className={classNamesUtil("w-4 h-4", isHeLanguage ? "ml-1.5" : " mr-1.5")}
                                    />
                                )}
                            </div>
                        )}

                        {isDraggable && <DragIndicator className="mr-3" />}
                        <div className="flex items-center">
                            {!!eventType && (
                                <TagWithIcon className="!bg-none" variant={variant} eventType={eventType} />
                            )}

                            <div>
                                <div className={isHeLanguage ? "mr-3" : "ml-3"}>
                                    <TypographyV2 width="semibold">{title}</TypographyV2>
                                    {!isUnitItem && isProtocolElement && (
                                        <div className="mt-1">
                                            <EventTypeGrayLabel type={EventTypes.Protocol} />
                                        </div>
                                    )}
                                </div>

                                {withLabel && (
                                    <div className="w-fit ml-2">
                                        <BadgeV2
                                            text={"Protocol"}
                                            color="gray"
                                            icon={<ProtocolIcon variant="light-gray" />}
                                            className="!p-[4px]"
                                            // className="!border-0 !pl-[0px] !pr-[10px] !gap-0 !py-0 flex !justify-start"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {!isUnitItem ? (
                        <div className="flex items-center justify-end">
                            {isUnit ? (
                                <>
                                    {!event?.unitPreview && (
                                        <TypographyV2 className="text-gray-500 mr-1">
                                            {isHeLanguage ? totalCount : completedEvents?.length} /{" "}
                                            {isHeLanguage ? completedEvents?.length : totalCount}
                                        </TypographyV2>
                                    )}
                                </>
                            ) : (
                                <>
                                    {!!when && (
                                        <div className="flex items-center mr-1">
                                            <ClockIcon />
                                            <TypographyV2 className="mx-1 text-gray-400">{when}</TypographyV2>
                                        </div>
                                    )}
                                </>
                            )}

                            {!!time && (
                                <div className="flex items-center ml-2">
                                    <ScheduleIcon />
                                    <TypographyV2
                                        className={classNamesUtil("text-gray-400", isHeLanguage ? "mr-1" : "ml-1")}
                                    >
                                        {time}
                                    </TypographyV2>
                                </div>
                            )}
                            {isLocked && (
                                <div className="ml-3">
                                    <LockedIcon />
                                </div>
                            )}
                            {!!event?.eventStatus && (
                                <BadgeV2 text={bagePriviewText} color={bageColor} className="ml-2" />
                            )}
                            <>
                                {(!isEditOptionEnabled || !isPastEvent) && !hideAction && !!dropdownList.length && (
                                    <DropdownMenuV2
                                        menuItems={dropdownList}
                                        menuClassName="ml-2"
                                        clickComponent={<ElipsisIcon />}
                                    />
                                )}
                            </>
                        </div>
                    ) : (
                        <>
                            {isCompleted && (
                                <BadgeV2 text={t("completed")} className="bg-emerald-50 text-emerald-500" />
                            )}
                            {!isUnitItem && isPastEvent && !isCompleted && (
                                <BadgeV2 text={t("skipped")} className="bg-red-50 text-red-500" />
                            )}
                        </>
                    )}
                </div>
            )}
            {!isVerticalView && (
                <>
                    <div className="flex justify-between items-center">
                        <TagWithIcon eventType={eventType} variant={variant} />
                        {!!event?.eventStatus && <BadgeV2 text={bagePriviewText} color={bageColor} className="ml-2" />}
                        {!isEditOptionEnabled && (
                            <DropdownMenuV2 menuItems={dropdownList} clickComponent={<ElipsisIcon />} />
                        )}
                    </div>
                    <div className="mt-8">
                        <TypographyV2 width="bold">{title}</TypographyV2>
                    </div>
                    <div className="flex justify-space-between align-items-center mt-3">
                        <div className="flex items-center w-2/4">
                            <ScheduleIcon />
                            <TypographyV2 className={classNamesUtil("text-gray-400", isHeLanguage ? "mr-1" : "ml-1")}>
                                {time}
                            </TypographyV2>
                        </div>
                        <div className="flex items-center w-2/4">
                            <ClockIcon />
                            <TypographyV2 className={classNamesUtil("text-gray-400", isHeLanguage ? "mr-1" : "ml-1")}>
                                {when}
                            </TypographyV2>
                        </div>
                    </div>
                </>
            )}
            {isExpanded && isUnit && (
                <>
                    {isSequential ? (
                        <UnitSequentialChaptersList chapters={event?.element?.unit_element?.unit_chapter} />
                    ) : (
                        <>
                            {!!unitChapters?.length && (
                                <UnitEventChaptersList chapters={unitChapters} isPastEvent={isPastEvent} />
                            )}
                        </>
                    )}
                </>
            )}
        </CardV2>
    )
}

export default ScheduleItemCard
