import { FC } from "react"
import { CardV2, TypographyV2 } from "components/v2/index"
import { useCreateExerciseQuestionPopover } from "./useCreateExerciseQuestionPopover"
import { ICreateQuestionPopoverProps } from "./createQuestionPopoverTypes"

const CreateCareProtocolEventPopover: FC<ICreateQuestionPopoverProps> = (props) => {
    const { isHeLanguage, questionTypesWithLocale, onAddEventClick } = useCreateExerciseQuestionPopover(props)

    return (
        <CardV2 className="px-0 py-0 pr-0">
            <div className="flex flex-wrap">
                {questionTypesWithLocale.map(({ title, id, icon: Icon }) => (
                    <div key={id} className="w-1/2 p-4 flex items-center cursor-pointer" onClick={onAddEventClick(id)}>
                        <Icon className="text-gray-500" />
                        <TypographyV2 className={isHeLanguage ? "mr-2.5" : "ml-2.5"} type="sm">
                            {title}
                        </TypographyV2>
                    </div>
                ))}
            </div>
        </CardV2>
    )
}

export default CreateCareProtocolEventPopover
