import {
    $applyNodeReplacement,
    DecoratorNode,
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedLexicalNode,
    Spread
} from "lexical"
import { MediaObject } from "@prisma/client"
import VideoComponent from "./VideoComponent"
import { Suspense } from "react"

export type SerializedVideoNode = Spread<
    {
        mediaObject: MediaObject
        type: "video"
        version: 1
        width: number
        height: number
    },
    SerializedLexicalNode
>

function convertVideoElement(domNode: Node): null | DOMConversionOutput {
    const video = domNode as HTMLVideoElement
    const { width, height } = video
    const node = $createVideoNode({ width, height })
    return { node }
}

export class VideoNode extends DecoratorNode<JSX.Element> {
    __mediaObject: MediaObject
    __width: number
    __height: number

    static getType(): string {
        return "video"
    }

    static clone(node: VideoNode): VideoNode {
        return new VideoNode(node.__mediaObject, node.__key, node.__width, node.__height)
    }

    static importJSON(serializedNode: SerializedVideoNode): VideoNode {
        return new VideoNode(serializedNode.mediaObject, undefined, serializedNode.width, serializedNode.height)
    }

    exportJSON(): SerializedVideoNode {
        return {
            mediaObject: this.__mediaObject,
            width: this.__width,
            height: this.__height,
            type: "video",
            version: 1
        }
    }

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement("span")
        const theme = config.theme
        const className = theme.video
        if (className !== undefined) {
            span.className = className
        }
        return span
    }

    constructor(mediaObject: MediaObject, key?: NodeKey, width: number = 400, height: number = 300) {
        super(key)
        this.__mediaObject = mediaObject
        this.__width = width
        this.__height = height
    }

    setWidthAndHeight(width: number, height: number): void {
        const writable = this.getWritable()
        writable.__width = width
        writable.__height = height
    }

    getWidth(): number {
        return this.__width
    }

    getHeight(): number {
        return this.__height
    }

    updateDOM(): false {
        return false
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement("video")
        element.setAttribute("width", this.__width.toString())
        element.setAttribute("height", this.__height.toString())
        element.setAttribute("controls", "true")

        const source = document.createElement("source")
        source.setAttribute("src", this.__mediaObject?.saved_file_path || "")
        source.setAttribute("type", "video/mp4") // Adjust type if needed

        element.appendChild(source)
        return { element }
    }

    static importDOM(): DOMConversionMap | null {
        return {
            video: (node: Node) => ({
                conversion: convertVideoElement, // Calls the function below
                priority: 0
            })
        }
    }

    decorate(): JSX.Element {
        return (
            <Suspense fallback={null}>
                <VideoComponent
                    height={this.__height}
                    width={this.__width}
                    nodeKey={this.getKey()}
                    mediaObject={this.__mediaObject}
                />
            </Suspense>
        )
    }
}

export function $createVideoNode({
    mediaObject,
    width = 600,
    height = 500
}: {
    mediaObject?: MediaObject
    width?: number
    height?: number
}): VideoNode {
    return $applyNodeReplacement(new VideoNode(mediaObject, undefined, width, height))
}

export function $isVideoNode(node: LexicalNode | null | undefined): node is VideoNode {
    return node instanceof VideoNode
}
