import { EventTypes } from "types"
import { CarePlanEventResponse } from "../../forms/types"
import { CarePlanProtocolAssignment } from "@prisma/client"

export const OPEN_ASSIGN_EVENT_MODAL = "carePlan/OPEN_ASSIGN_EVENT_MODAL"
export const CLOSE_ASSIGN_EVENT_MODAL = "carePlan/CLOSE_ASSIGN_EVENT_MODAL"
export const SET_CARE_PLAN_EVENTS_LIST = "carePlan/SET_CARE_PLAN_EVENTS_LIST"
export const SET_PATIENT_ACTIVE_CARE_PROTOCOL = "carePlan/SET_PATIENT_ACTIVE_CARE_PROTOCOL"

interface OpenAssignEventModalAction {
    type: typeof OPEN_ASSIGN_EVENT_MODAL
    payload: EventTypes
}

interface CloseAssignEventModalAction {
    type: typeof CLOSE_ASSIGN_EVENT_MODAL
    payload: null
}

interface SetCarePlanEventsListAction {
    type: typeof SET_CARE_PLAN_EVENTS_LIST
    payload: CarePlanEventResponse[]
}

interface SetPatientActiveCareProtocol {
    type: typeof SET_PATIENT_ACTIVE_CARE_PROTOCOL
    payload: CarePlanProtocolAssignment
}

export type CarePlanActionTypes =
    | OpenAssignEventModalAction
    | CloseAssignEventModalAction
    | SetCarePlanEventsListAction
    | SetPatientActiveCareProtocol
