export enum EventTypes {
    Questionnaire = "Questionnaire",
    Exercise = "Exercise",
    Protocol = "Protocol",
    Task = "Task",
    Appointment = "Appointment",
    Mood = "Mood",
    Unit = "Unit",
    Tracker = "Tracker",
    Reflection = "Reflection",
    "EducationalContent" = "EducationalContent"
}
