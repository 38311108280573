import React from "react"
import { DndContext } from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import {
    ExerciseQuestionItem,
    AddBottomLineButton,
    CreateExerciseQuestionPopover,
    CreateFirstExerciseQuestionList
} from "components/v2"
import { useExerciseQuestionsList } from "./useExerciseQuestionsList"
import { Loader } from "@aws-amplify/ui-react"
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers"
import { isEditable } from "@testing-library/user-event/utils/edit/isEditable"

const ExerciseQuestionsList = () => {
    const { t, isEditDisabled, isLoading, sensors, questions, onDragEnd } = useExerciseQuestionsList()

    if (isLoading)
        return (
            <div className="w-full flex justify-center">
                <Loader />
            </div>
        )

    if (!questions.length) return <CreateFirstExerciseQuestionList />

    return (
        <div className="flex flex-col w-full">
            <DndContext
                sensors={sensors}
                onDragEnd={onDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <div className="flex flex-col gap-2.5">
                    <SortableContext items={questions} strategy={verticalListSortingStrategy}>
                        {questions?.map((item) => (
                            <ExerciseQuestionItem key={item.id} {...item} />
                        ))}
                    </SortableContext>
                </div>
            </DndContext>
            {!isEditDisabled && (
                <AddBottomLineButton
                    text={t("addContent")}
                    PopoverComponent={({ close }: { close: () => void }) => (
                        <CreateExerciseQuestionPopover onClose={close} />
                    )}
                />
            )}
        </div>
    )
}

export default ExerciseQuestionsList
