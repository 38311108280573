import { EducationalContentResponse, ExerciseUpdateSearchAction, LibraryTemplateResponse } from "types/Redux"
import { Diagnosis, LibraryTemplateType, User } from "@prisma/client"

export interface IOpenLibraryTemplatePayload {
    libraryTemplate: LibraryTemplateResponse | null
    type: LibraryTemplateType
}

export const SET_ACTIVE_TAB = "library_template/SET_ACTIVE_TAB"
export const OPEN_LIBRARY_TEMPLATE_BUILDER = "library_template/OPEN_LIBRARY_TEMPLATE_BUILDER"
export const SET_LIBRARY_TEMPLATE_TYPE = "library_template/SET_LIBRARY_TEMPLATE_TYPE"
export const SET_LIBRARY_TEMPLATE_FORM_ERROR = "library_template/SET_LIBRARY_TEMPLATE_FORM_ERROR"
export const SET_LIBRARY_TEMPLATE_FORM_DIRTY = "library_template/SET_LIBRARY_TEMPLATE_FORM_DIRTY"
export const CLOSE_LIBRARY_TEMPLATE_BUILDER = "library_template/CLOSE_LIBRARY_TEMPLATE_BUILDER"
export const SET_LIBRARY_TEMPLATE_LIST = "library_template/SET_LIBRARY_TEMPLATE_LIST"
export const SET_LIBRARY_TEMPLATE_SEARCH_PARAMS = "library_template/SET_LIBRARY_TEMPLATE_SEARCH_PARAMS"
export const SET_DIAGNOSES_LIST = "library_template/SET_DIAGNOSES_LIST"
export const SET_CREATORS_LIST = "library_template/SET_CREATORS_LIST"
export const SET_EDUCATIONAL_CONTENT = "library_template/SET_EDUCATIONAL_CONTENT"
export const SET_IS_LIBRARY_LIST_LOADING = "library_template/SET_IS_LIBRARY_LIST_LOADING"
export const SET_LIBRARY_TEMPLATE = "library_template/SET_LIBRARY_TEMPLATE"

interface OpenLibraryTemplateBuilderAction {
    type: typeof OPEN_LIBRARY_TEMPLATE_BUILDER
    payload: LibraryTemplateResponse | null
}

interface CloseLibraryTemplateAction {
    type: typeof CLOSE_LIBRARY_TEMPLATE_BUILDER
    payload: null
}

export enum LibraryTemplateTab {
    BASIC_INFO = "basic-info",
    CONTENT = "content",
    PREVIEW = "preview"
}

interface SetLibraryTemplateFormErrorAction {
    type: typeof SET_LIBRARY_TEMPLATE_FORM_ERROR
    payload: boolean
}

interface SetLibraryTemplateFormDirtyAction {
    type: typeof SET_LIBRARY_TEMPLATE_FORM_DIRTY
    payload: boolean
}

interface SetLibraryTemplateListAction {
    type: typeof SET_LIBRARY_TEMPLATE_LIST
    payload: LibraryTemplateResponse[]
}

interface SetLibraryTemplateSearchParams {
    type: typeof SET_LIBRARY_TEMPLATE_SEARCH_PARAMS
    payload: ExerciseUpdateSearchAction
}

interface SetDiagnosisParamsAction {
    type: typeof SET_DIAGNOSES_LIST
    payload: Diagnosis[]
}

interface SetCreatorsParamsAction {
    type: typeof SET_CREATORS_LIST
    payload: User[]
}

interface SetLibraryTemplateTypeAction {
    type: typeof SET_LIBRARY_TEMPLATE_TYPE
    payload: LibraryTemplateType
}

interface SetEducationalContentAction {
    type: typeof SET_EDUCATIONAL_CONTENT
    payload: EducationalContentResponse
}

interface SetIsLibraryListLoading {
    type: typeof SET_IS_LIBRARY_LIST_LOADING
    payload: boolean
}

interface SetActiveTabAction {
    type: typeof SET_ACTIVE_TAB
    payload: LibraryTemplateTab
}

interface SetLibraryTemplateItemAction {
    type: typeof SET_LIBRARY_TEMPLATE
    payload: LibraryTemplateResponse
}

export type LibraryTemplateActionTypes =
    | OpenLibraryTemplateBuilderAction
    | SetLibraryTemplateFormErrorAction
    | CloseLibraryTemplateAction
    | SetLibraryTemplateListAction
    | SetLibraryTemplateSearchParams
    | SetDiagnosisParamsAction
    | SetCreatorsParamsAction
    | SetLibraryTemplateFormDirtyAction
    | SetLibraryTemplateTypeAction
    | SetEducationalContentAction
    | SetIsLibraryListLoading
    | SetActiveTabAction
    | SetLibraryTemplateItemAction
