import { ControlledScaleSliderV2, TypographyV2 } from "components/v2"
import { ScreeningQuestionScale, ScreeningTemplateQuestion } from "@prisma/client"
import { Control, useController } from "react-hook-form"
import { useScreenSize } from "hooks"
import { TableListV2 } from "components/v2"

interface IScaleSliderQuestionProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: ScreeningTemplateQuestion & { scale: ScreeningQuestionScale }
    isDisabled?: boolean
}

const ScaleSliderQuestionCard = ({ question, control, error, isDisabled }: IScaleSliderQuestionProps) => {
    const { is_required, question: question_text, scale } = question
    const isDesktop = useScreenSize()

    const scaleMinValue = scale?.min || 0
    const scaleMaxValue = scale?.max || 10
    const scaleStep = 1

    const {
        field: { value }
    } = useController({
        name: `screeningAnswers.${question.id}`,
        control,
        rules: { required: is_required },
        defaultValue: scaleMinValue
    })

    const labels = (scale?.labels as any)?.[0] || []
    const minLabel = labels[0] || ""
    const maxLabel = labels[labels.length - 1] || ""

    if (!scale) {
        return null
    }

    if (isDesktop) {
        return (
            <TableListV2
                headerCells={[]}
                listComponent={
                    <tr className="border-b border-gray-200">
                        <td className="py-12 px-6">
                            <div className="flex flex-col gap-4">
                                <TypographyV2 type="base">{question_text}</TypographyV2>
                                {question?.description && (
                                    <TypographyV2 type="xs" className="text-gray-500">
                                        {question.description}
                                    </TypographyV2>
                                )}
                            </div>
                        </td>
                        <td className="py-12 px-6">
                            <div className="w-full flex flex-col items-center justify-center">
                                <span className="text-[28px] font-semibold leading-9 mb-4">
                                    {value || scaleMinValue}
                                </span>
                                <ControlledScaleSliderV2
                                    isDisabled={isDisabled}
                                    name={`screeningAnswers.${question.id}`}
                                    control={control}
                                    isRequired={is_required}
                                    min={scaleMinValue}
                                    max={scaleMaxValue}
                                    step={scaleStep}
                                    minLabel={minLabel}
                                    maxLabel={maxLabel}
                                    error={error}
                                    classList={{ wrapper: "w-full" }}
                                />
                            </div>
                        </td>
                    </tr>
                }
            />
        )
    }

    return (
        <div className="flex flex-col items-start justify-center gap-[40px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <div className="w-full flex flex-col items-center justify-center">
                <span className="text-[28px] font-semibold leading-9">{value || scaleMinValue}</span>
                <ControlledScaleSliderV2
                    name={`screeningAnswers.${question.id}`}
                    control={control}
                    isRequired={is_required}
                    min={scaleMinValue}
                    max={scaleMaxValue}
                    step={scaleStep}
                    minLabel={minLabel}
                    maxLabel={maxLabel}
                    error={error}
                    classList={{ wrapper: "w-full" }}
                />
            </div>
        </div>
    )
}

export default ScaleSliderQuestionCard
