import { SVGProps } from "react"

const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M1.875 10.0003L9.33709 2.53826C9.7032 2.17214 10.2968 2.17214 10.6629 2.53826L18.125 10.0003M3.75 8.12535V16.5628C3.75 17.0806 4.16973 17.5003 4.6875 17.5003H8.125V13.4378C8.125 12.9201 8.54473 12.5003 9.0625 12.5003H10.9375C11.4553 12.5003 11.875 12.9201 11.875 13.4378V17.5003H15.3125C15.8303 17.5003 16.25 17.0806 16.25 16.5628V8.12535M6.875 17.5003H13.75"
            stroke="#6B7280"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default DashboardIcon
