import React, { FC, useEffect } from "react"
import { useExercisePreview } from "./useExercisePreview"
import { ExerciseQuestionResponse, LibraryTemplateResponse } from "types/Redux"
import { getExerciseQuestionsByExerciseId } from "api"

interface IUnitElementPreviewEducationalContentProps {
    id?: number
    libraryTemplate?: LibraryTemplateResponse
}
const PreviewExercise: FC<IUnitElementPreviewEducationalContentProps> = ({ id, libraryTemplate }) => {
    const [questions, setQuestions] = React.useState<ExerciseQuestionResponse[]>([])

    const libraryTemplateId = libraryTemplate?.id || id

    useEffect(() => {
        const fetchQuestions = async () => {
            if (!libraryTemplateId) return
            const response = await getExerciseQuestionsByExerciseId(libraryTemplateId)
            setQuestions(response)
        }
        fetchQuestions()
    }, [])

    const cards = useExercisePreview(questions)
    return (
        <div className="p-4 mt-3 flex gap-2 flex-col">
            {libraryTemplate && <p className="text-med-black pb-6 text-xl font-bold">{libraryTemplate.title}</p>}
            <div className="px-2 pb-6">
                {cards.map((card, id) => (
                    <div key={`exercise_builder_question_preview_${id}`}>{card}</div>
                ))}
            </div>
        </div>
    )
}

export default PreviewExercise
