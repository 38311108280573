import React from "react"
import { classNames } from "utils"

interface IToolbarIconProps {
    isActive?: boolean
    icon: string
    onClick: () => void
}

const ToolbarIcon: React.FC<IToolbarIconProps> = ({ isActive, icon, onClick }) => {
    return (
        <div
            className={classNames(
                "cursor-pointer flex items-center p-0.5 rounded-sm",
                isActive ? "bg-blue-100" : "bg-white"
            )}
            onClick={onClick}
        >
            <img src={icon} className="w-[20px] h-[20px]" />
        </div>
    )
}

export default ToolbarIcon
