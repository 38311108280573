import { createChat } from "api"
import { Spinner } from "components/common"
import { SendIcon } from "components/icons"
import isHotkey from "is-hotkey"
import { ChatProps } from "pages/chat"
import { FormEvent, KeyboardEvent, MutableRefObject, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import TextareaAutosize from "react-textarea-autosize"
import { toast } from "react-toastify"
import { VariableSizeList } from "react-window"
import store, { useDispatch } from "store"
import { updateConversationSidebar } from "store/sidebars/actions"
import { ConversationStore, SequelConversation } from "utils/conversations"
import { useIsChatDisabled } from "../../store/hooks"
import { ConversationDisplayProps } from "./ConversationDisplay"
import AttachIcon from "../../components/icons/Attach"

const isLineBreak = isHotkey("mod+enter")

interface ChatMessageInputProps extends ConversationDisplayProps {
    messagesRef: MutableRefObject<VariableSizeList>
    itemCount: number
}

const ChatMessageInput = ({
    conversation,
    newChatWith,
    messagesRef,
    itemCount,
    patientId,
    updateChatProps
}: ChatMessageInputProps) => {
    const { t } = useTranslation("patient")
    const ref = useRef<HTMLTextAreaElement>()
    const dispatch = useDispatch()
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const patient_id = conversation?.patientId ?? patientId
    const isChatDisabled = useIsChatDisabled(patient_id)

    useEffect(() => {
        setMessage("")
    }, [conversation?.patientId, patientId])

    const createConversation = async (callback: (c: SequelConversation) => void) => {
        try {
            setLoading(true)
            const currentUser = store.getState().global.user
            const { conversationId } = await createChat(
                [...newChatWith.map(({ id }) => id), currentUser.id],
                patient_id
            )
            const unsubscribe = ConversationStore.subscribe("conversations", (conversations) => {
                const conversation = conversations.find((conversation) => conversation.sid === conversationId)
                if (!conversation) return // The data for this conversation hasn't been fetched yet

                unsubscribe()
                const newChatProps = { mode: "chat", conversation, newChatWith: null } as ChatProps
                updateChatProps
                    ? updateChatProps({ ...newChatProps, patientId: undefined })
                    : dispatch(updateConversationSidebar(newChatProps))
                callback(conversation)
                setLoading(false)
            })
        } catch (error) {
            setLoading(false)
            return toast(t("chat.create.error"), { type: "error" })
        }
    }

    const sendMessage = async (e?: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLTextAreaElement>) => {
        e?.preventDefault()

        const sendMessage = async (conversation: SequelConversation) => {
            if (!message) return
            await conversation.sendMessage(message)
            if (itemCount) messagesRef.current?.scrollToItem(itemCount)
            setMessage("")
        }

        if (!newChatWith) return sendMessage(conversation)

        // We need to create a new conversation before sending the message
        await createConversation((c) => sendMessage(c))
    }

    const sendMedia = async (file?: File) => {
        const sendMediaImpl = async (conversation: SequelConversation) => {
            if (!file) return
            await conversation.sendMedia(file)
        }

        if (!newChatWith) return sendMediaImpl(conversation)

        await createConversation((c) => sendMediaImpl(c))
    }

    return (
        <form className="flex gap-2 items-end px-4 w-full pt-4 pb-6" onSubmit={sendMessage}>
            <TextareaAutosize
                disabled={loading}
                ref={ref}
                minRows={1.1}
                maxRows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full border-transparent rounded-lg bg-bg py-2.5 px-3 focus:border-dark-blue focus:ring-0 resize-none"
                placeholder={t("chat.messagePlaceholder")}
                onKeyDown={(e) => e.key === "Enter" && (!isLineBreak(e) ? sendMessage(e) : setMessage(message + "\n"))}
            />
            <label className="bg-med-blue rounded-lg w-12 h-12 flex items-center justify-center text-white cursor-pointer hover:bg-dark-blue rtl:rotate-180 disabled:cursor-auto disabled:hover:bg-gray-400 disabled:bg-gray-400">
                <AttachIcon className="p-2" fill="white" />
                <input
                    type="file"
                    hidden
                    accept=".jpg,.jpeg,.png"
                    onChange={(event) => sendMedia(event.target.files[0])}
                />
            </label>
            <button
                className="bg-med-blue rounded-lg w-12 h-12 flex items-center justify-center text-white cursor-pointer hover:bg-dark-blue rtl:rotate-180 disabled:cursor-auto disabled:hover:bg-gray-400 disabled:bg-gray-400"
                disabled={patient_id && isChatDisabled}
            >
                {!loading ? <SendIcon className="text-2xl translate-x-1" /> : <Spinner size={6} />}
            </button>
        </form>
    )
}

export default ChatMessageInput
