import globalReducer from "./global/reducer"
import patientReducer from "./patient/reducer"
import notificationsReducer from "./notifications/reducer"
import sidebarsReducer from "./sidebars/reducer"
import modalReducer from "./modal/reducer"
import questionsReducer from "./questions/reducer"
import libraryTemplateReducer from "./library-template/reducer"
import careProtocolReducer from "./care-protocol/reducer"
import carePlanReducer from "./care-plan/reducer"

import { unitReducer } from "./unit/reducer"

const allReducers = {
    global: globalReducer,
    patient: patientReducer,
    notifications: notificationsReducer,
    sidebars: sidebarsReducer,
    modal: modalReducer,
    questions: questionsReducer,
    libraryTemplate: libraryTemplateReducer,
    unit: unitReducer,
    carePlan: carePlanReducer,
    careProtocol: careProtocolReducer
}

export default allReducers
