type HasId = {
    id: number
    position?: number
}

type ReferenceItem = {
    screening_template_question_id?: number
    questionId?: number
}

export const sortQuestionsByPositionAndAnswers = <T extends HasId, R extends ReferenceItem>(
    items: T[],
    referenceArray: R[],
    getRefId = (item: R) => item.screening_template_question_id ?? item.questionId
): T[] => {
    return [...items].sort((a, b) => {
        // First try to sort by position if available
        if (a.position !== undefined && b.position !== undefined) {
            return a.position - b.position
        }

        // Fall back to reference array order
        const aIndex = referenceArray.findIndex((ref) => getRefId(ref) === a.id)
        const bIndex = referenceArray.findIndex((ref) => getRefId(ref) === b.id)
        return aIndex - bIndex
    })
}
