import { PlusIcon } from "components/icons"
import { TypographyV2 } from "components/v2/components/common"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "types/Redux"
import CareProtocolElementList from "components/v2/components/features/careProtocol/careProtocolElement/CareProtocolElemetList"

const AddCareProtocolElementForm = () => {
    const { t } = useTranslation("exercise")

    const { params: exerciseDto } = useSelector((state: RootState) => state.modal)

    return (
        <div className="w-200 mx-auto my-auto">
            <TypographyV2 className="text-med-black w-auto overflow-hidden text-ellipsis" type="3xl" width="bold">
                {exerciseDto.title}
            </TypographyV2>
            <button className="bg-base-lighter  mt-4 text-base-dark rounded-md p-1 flex items-center gap-0.5">
                <PlusIcon />
                <TypographyV2 type="xs">
                    {t("addBriefSummary")} ({t("optional")})
                </TypographyV2>
            </button>
            <div className="mt-10 w-[860px]">
                <CareProtocolElementList elementId={exerciseDto.id} />
            </div>
        </div>
    )
}

export default AddCareProtocolElementForm
