import { ExerciseType, PreviousInstance } from "@prisma/client"
import { ProfessionalInfo, AudienceIcon, EyeIcon } from "components/icons"
import BulbIcon from "components/icons/BulbIcon"
import AttentionCard from "components/v2/components/AttentionCard"
import {
    TypographyV2,
    ControlledTextInputV2,
    ControlledSelectV2,
    ControlledTextAreaV2,
    ControlledRadioCircleGroupV2
} from "components/v2/components/common"
import { NavigationList } from "components/v2/components/navigation"
import { RadioGroupMode } from "types/exercises/ExerciseDef"
import { useLibraryTemplateForm } from "./useLibraryTemplateForm"
import { maxAgeList, minAgeList } from "./exerciseBasicInfoFormConst"
import i18next from "i18next"

interface LibraryTemplateFormProps {
    formId: string
}

const LibraryTemplateForm = ({ formId }: LibraryTemplateFormProps) => {
    const {
        t,
        control,
        errors,
        activeStepIndex,
        sectionRefs,
        stepsList,
        diagnosesList,
        languagesList,
        rolesList,
        gendersWithLocale,
        isExercise,
        type,
        submitHandler,
        handleSubmit,
        isUnit
    } = useLibraryTemplateForm()

    return (
        <form className="max-w-screen-lg pt-16 pl-8" id={formId} onSubmit={handleSubmit(submitHandler)}>
            <div className="flex gap-6">
                <div className="w-1/4 sticky h-full top-0">
                    <NavigationList activeStepIndex={activeStepIndex} stepsList={stepsList} />
                </div>
                <div className="w-3/4">
                    <div
                        ref={(el: HTMLDivElement | null) => {
                            if (el) sectionRefs.current[0] = el
                        }}
                        className="py-8 border-b border-border-blue"
                    >
                        <div className="flex gap-2 mb-6 items-center">
                            <ProfessionalInfo className="text-[#6B7280]" />
                            <TypographyV2 type="base" width="semibold" className="text-med-black">
                                {t("createModal.professionalInfo")}
                            </TypographyV2>
                        </div>
                        <div className="pb-6">
                            <AttentionCard
                                text={
                                    <>
                                        {t("createModal.visibleOnlyTo")}{" "}
                                        <TypographyV2 className="inline font-bold">
                                            {t("createModal.therapists")}
                                        </TypographyV2>{" "}
                                        {t("createModal.forGuidanceOnUnit")}
                                    </>
                                }
                            />
                            <div className="mt-6 grid grid-cols-2 gap-6">
                                <ControlledTextInputV2
                                    name="title"
                                    control={control}
                                    label={i18next.t("createModal.exerciseName", {
                                        ns: "exercise",
                                        type: t(`consts.${type}`)
                                    })}
                                    placeholder={i18next.t("createModal.exerciseNamePlaceholder", {
                                        ns: "exercise",
                                        type: t(`consts.${type}`).toLowerCase()
                                    })}
                                    error={errors.title?.message}
                                />
                                <div>
                                    <div className="flex flex-col">
                                        <div className="mt-1">
                                            <ControlledSelectV2
                                                mode="multiple"
                                                isAutocompleteEnabled
                                                name="diagnose_ids"
                                                control={control}
                                                label={t("createModal.linkDiagnoses")}
                                                placeholder={t("createModal.linkDiagnosesPlaceholder")}
                                                options={diagnosesList}
                                                error={errors.diagnose_ids?.message}
                                                tooltipVisible
                                            />
                                        </div>
                                    </div>
                                </div>
                                <ControlledTextAreaV2
                                    name="description"
                                    control={control}
                                    label={t("professionalDescription")}
                                    placeholder={t("createModal.professionalDescriptionPlaceholder")}
                                    classList={{ wrapper: "col-span-2" }}
                                    error={errors.description?.message}
                                />
                            </div>
                            <div className="flex flex-col gap-6 mt-6">
                                <div className="w-48">
                                    <ControlledSelectV2
                                        name="language"
                                        control={control}
                                        label={t("createModal.language")}
                                        placeholder={t("createModal.languagePlaceholder")}
                                        options={languagesList}
                                    />
                                </div>
                                {isExercise && (
                                    <>
                                        <div>
                                            <ControlledRadioCircleGroupV2
                                                label={t("createModal.exerciseType")}
                                                name="exercise_type"
                                                control={control}
                                                options={[
                                                    {
                                                        value: ExerciseType.EXERCISE_ONLY,
                                                        labelComponent: (
                                                            <TypographyV2 type="sm">
                                                                {t("createModal.exerciseOnly")}
                                                            </TypographyV2>
                                                        )
                                                    },
                                                    {
                                                        value: ExerciseType.EXERCISE_JOURNAL,
                                                        labelComponent: (
                                                            <TypographyV2 type="sm">
                                                                {t("createModal.exerciseAndJournal")}
                                                            </TypographyV2>
                                                        )
                                                    }
                                                ]}
                                            />
                                            <AttentionCard
                                                text={t("createModal.journalingLetsAssign")}
                                                Icon={BulbIcon}
                                            />
                                        </div>
                                        <div>
                                            <ControlledRadioCircleGroupV2
                                                name="previous_instance"
                                                control={control}
                                                options={[
                                                    {
                                                        value: PreviousInstance.CURRENT_ANSWERS_ONLY,
                                                        labelComponent: (
                                                            <TypographyV2 type="sm">
                                                                {t("createModal.doNotShowPrevAnswer")}
                                                            </TypographyV2>
                                                        )
                                                    },
                                                    {
                                                        value: PreviousInstance.PREVIOUS_ANSWERS,
                                                        labelComponent: (
                                                            <TypographyV2 type="sm">
                                                                {t("createModal.displayPrevAnswer")}
                                                            </TypographyV2>
                                                        )
                                                    }
                                                ]}
                                            />
                                            <AttentionCard
                                                text={t("createModal.displayPreviousInstances")}
                                                Icon={BulbIcon}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        ref={(el: HTMLDivElement | null) => {
                            if (el) sectionRefs.current[1] = el
                        }}
                        className="py-8 border-b border-border-blue"
                    >
                        <div className="mb-6">
                            <div className="flex gap-2 items-center">
                                <AudienceIcon className="text-[#6B7280]" />
                                <TypographyV2 type="base" width="semibold" className="text-med-black">
                                    {t("audience")}
                                </TypographyV2>
                            </div>
                            <TypographyV2 type="sm" className="text-[#6B7280]">
                                {t("createModal.selectWhoWillBeAbleToAccessTheUnit")}
                            </TypographyV2>
                        </div>
                        <div className="grid grid-cols-2 gap-6 pb-6">
                            <div className="flex flex-col">
                                <div className="mt-1">
                                    <ControlledSelectV2
                                        name="role_ids"
                                        control={control}
                                        label={t("createModal.recipients")}
                                        placeholder={t("createModal.recipientsPlaceholder")}
                                        options={rolesList}
                                        mode="multiple"
                                        error={errors.role_ids?.message}
                                    />
                                </div>
                            </div>
                            <div className="col-start-1">
                                <ControlledRadioCircleGroupV2
                                    label={t("createModal.age")}
                                    placeholder={t("createModal.agePlaceholder")}
                                    control={control}
                                    name="age_mode"
                                    error={errors?.max_age?.message}
                                    options={[
                                        {
                                            value: RadioGroupMode.ALL,
                                            labelComponent: (
                                                <TypographyV2 type="sm">{t("createModal.all")}</TypographyV2>
                                            )
                                        },
                                        {
                                            value: RadioGroupMode.CUSTOM,
                                            labelComponent: (
                                                <div className="flex gap-2 items-center">
                                                    <TypographyV2 className="mr-2" type="sm">
                                                        {t("createModal.custom")}
                                                    </TypographyV2>
                                                    <div className="w-20">
                                                        <ControlledSelectV2
                                                            name="min_age"
                                                            control={control}
                                                            options={minAgeList}
                                                            placeholder="min"
                                                            mode="single"
                                                        />
                                                    </div>
                                                    <div className="w-20">
                                                        <ControlledSelectV2
                                                            name="max_age"
                                                            control={control}
                                                            options={maxAgeList}
                                                            placeholder="max"
                                                            mode="single"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    ]}
                                />
                            </div>
                            <div>
                                <ControlledRadioCircleGroupV2
                                    control={control}
                                    label={t("common:gender")}
                                    name="gender_mode"
                                    options={[
                                        {
                                            value: RadioGroupMode.ALL,
                                            labelComponent: (
                                                <TypographyV2 type="sm">{t("createModal.all")}</TypographyV2>
                                            )
                                        },
                                        {
                                            value: RadioGroupMode.CUSTOM,
                                            labelComponent: (
                                                <div className="flex gap-2 items-center">
                                                    <TypographyV2 className="mr-2" type="sm">
                                                        {t("createModal.custom")}
                                                    </TypographyV2>
                                                    <div className="w-52">
                                                        <ControlledSelectV2
                                                            control={control}
                                                            name="gender_custom"
                                                            options={gendersWithLocale}
                                                            placeholder="Select..."
                                                            mode="single"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        ref={(el: HTMLDivElement | null) => {
                            if (el) sectionRefs.current[2] = el
                        }}
                        className="py-8 border-b border-border-blue"
                    >
                        <div className="mb-6">
                            <div className="flex gap-2 items-center">
                                <EyeIcon className="text-[#6B7280]" />
                                <TypographyV2 type="base" width="semibold" className="text-med-black">
                                    {t("createModal.audienceView")}
                                </TypographyV2>
                            </div>
                        </div>
                        <div className="pb-6">
                            <AttentionCard
                                text={
                                    <>
                                        {t("createModal.visibleOnlyToThe")}{" "}
                                        <TypographyV2 className="inline font-bold">
                                            {t("createModal.selectedAudience")}
                                        </TypographyV2>{" "}
                                        {t("createModal.onTheMobileApp")}
                                    </>
                                }
                            />
                            <div className="mt-6 flex flex-col gap-6">
                                <ControlledTextInputV2
                                    name="public_name"
                                    control={control}
                                    label={i18next.t("createModal.publicName", {
                                        ns: "exercise",
                                        type: t(`consts.${type}`)
                                    })}
                                    placeholder={i18next.t("createModal.publicNamePlaceholder", {
                                        ns: "exercise",
                                        type: t(`consts.${type}`).toLowerCase()
                                    })}
                                    error={errors.public_name?.message}
                                />
                                <ControlledTextAreaV2
                                    name="brief_summary"
                                    control={control}
                                    label={t("createModal.briefSummary")}
                                    placeholder={t("createModal.briefSummaryPlaceholder")}
                                    classList={{ wrapper: "col-span-2" }}
                                    error={errors.brief_summary?.message}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default LibraryTemplateForm
