import { UnitChapterElementType } from "@prisma/client"
import { getAllLibraryTemplates } from "api/LibraryTemplate"
import {
    PreviewEducationalContent,
    PreviewExercise,
    PreviewQuestionnaire
} from "components/v2/components/features/library-template"
import { useEffect, useState } from "react"
import { LibraryTemplateResponse, UnitChapterElementResponse } from "types/Redux"

export const useUnitBuilderPreviewElements = (elements: UnitChapterElementResponse[]) => {
    const [libraryTemplates, setLibraryTemplates] = useState<LibraryTemplateResponse[]>([])

    useEffect(() => {
        const fetchLibraryTemplates = async () => {
            const response = await getAllLibraryTemplates()
            setLibraryTemplates(response)
        }
        fetchLibraryTemplates()
    }, [])

    return elements.map((element) => {
        const libraryTemplate = libraryTemplates.find((template) => template.id === element.library_template_id)
        if (element.element_type === UnitChapterElementType.EXERCISE)
            return (
                <PreviewExercise id={element.library_template_id} key={element.id} libraryTemplate={libraryTemplate} />
            )
        if (element.element_type === UnitChapterElementType.EDUCATIONAL_CONTENT)
            return (
                <PreviewEducationalContent
                    id={element.library_template_id}
                    key={element.id}
                    libraryTemplate={libraryTemplate}
                />
            )

        if (element.element_type === UnitChapterElementType.QUESTIONNAIRE)
            return <PreviewQuestionnaire id={element.questionnaire_id} key={element.id} />
    })
}
