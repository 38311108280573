import { ApiDelete, ApiPatch, ApiPost } from "api/ApiRequest"
import { IUnitChapterElementRequest } from "types"
import { UnitChapterElementResponse } from "types/Redux"

export const addElementToChapter = async (data: IUnitChapterElementRequest): Promise<UnitChapterElementResponse> => {
    const instance = new ApiPost<UnitChapterElementResponse>(`/unit-chapter/element`, data)
    instance.callerName = addElementToChapter.name
    return instance.run()
}

export const deleteUnitChapterElement = async (id: number) => {
    const instance = new ApiDelete(`/unit-chapter/element/${id}`)
    instance.callerName = deleteUnitChapterElement.name
    return instance.run()
}

export const reorderUnitChapterElement = async (newList: { id: number; pos: number }[], chapterId: number) => {
    const instance = new ApiPatch("/unit-chapter/element/update-positions", { newList, chapterId })
    instance.callerName = reorderUnitChapterElement.name
    return instance.run()
}
