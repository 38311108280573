import React from "react"
import { ButtonV2 } from "components/v2"
import { useDispatch } from "store"
import { ExerciseStatuses } from "@prisma/client"
import { useTranslation } from "react-i18next"
import { useLibraryTemplateSelector } from "store/hooks"
import { closeLibraryTemplateBuilderAction, setLibraryTemplateActiveTab } from "store/library-template/actions"
import { openModal } from "store/modal/actions"
import { getLibraryTemplateNameByType } from "components/v2/constants"
import { LibraryTemplateTab } from "store/library-template/types"

const LibraryElementBuilderFooter = ({
    isCreateNew,
    basicInfoFormId,
    elementStatus
}: {
    isCreateNew: boolean
    basicInfoFormId: string
    elementStatus: string
}) => {
    const { t } = useTranslation("common")

    const { isFormError, isFormDirty, libraryTemplate, type, activeTab } = useLibraryTemplateSelector()

    const dispatch = useDispatch()

    const eventType = getLibraryTemplateNameByType(type)

    const publishHandler = () => {
        switch (activeTab) {
            case LibraryTemplateTab.BASIC_INFO:
                dispatch(setLibraryTemplateActiveTab(LibraryTemplateTab.CONTENT))
                break
            case LibraryTemplateTab.PREVIEW:
                if (libraryTemplate) {
                    dispatch(openModal("publish-library-template", libraryTemplate))
                } else {
                    console.error("Library template data is missing.")
                }
                break
            default:
                dispatch(setLibraryTemplateActiveTab(LibraryTemplateTab.PREVIEW))
        }
    }

    return (
        <div className="p-4 bg-light-background flex justify-between">
            <div>
                <ButtonV2
                    text={`${t("back")} ${t("to")} ${t(`eventType.${eventType}`).toLocaleLowerCase()}`}
                    variant="light-gray"
                    onClick={() => dispatch(closeLibraryTemplateBuilderAction())}
                />
            </div>
            <div className="flex items-center">
                {!isCreateNew && (
                    <ButtonV2
                        className="mr-1"
                        form={basicInfoFormId}
                        isDisabled={!isFormDirty || isFormError}
                        text={`${t("update")} ${t(`eventType.${eventType}`)}`}
                    />
                )}
                {elementStatus !== ExerciseStatuses.PUBLISHED &&
                    (isCreateNew ? (
                        <ButtonV2
                            form={basicInfoFormId}
                            isDisabled={isFormError}
                            text={`${t("createNew")} ${t(`eventType.${eventType}`)}`}
                        />
                    ) : (
                        <ButtonV2
                            isDisabled={elementStatus === ExerciseStatuses.PUBLISHED}
                            text={t("publish")}
                            onClick={publishHandler}
                        />
                    ))}
            </div>
        </div>
    )
}

export default LibraryElementBuilderFooter
