import { ControlledSelectV2 } from "components/v2/components/common/form-controls"
import { Control, useController } from "react-hook-form"
import { REPEATS_OPTIONS } from "../../common"
import { TagV2, TooltipV2, TypographyV2 } from "components/v2/components/common"
import { ArrowRightEndOnRectangleIcon, CursorArrowRaysIcon, InformationCircleIcon } from "@heroicons/react/24/outline"
import { LibraryTemplateType, TriggerType, UnitChapter } from "@prisma/client"
import { classNames } from "utils"
import { TFunction } from "i18next"
import { NUMBER_OF_REPEATS_OPTIONS, TIME_OPTIONS } from "../../common/addScheduleElementModalConstants"
import { LibraryTemplateResponse, UnitChapterElementResponse, UnitChapterResponse } from "types/Redux"
import { useMemo } from "react"
interface ConfigureCPElementProps {
    t: TFunction
    control: Control<any>
    errors: any
    libraryTemplateType: LibraryTemplateType | "QUESTIONNAIRE"
    selectedElement: LibraryTemplateResponse | null
}

const ConfigureCPElement = ({ t, control, errors, libraryTemplateType, selectedElement }: ConfigureCPElementProps) => {
    const {
        field: { value, onChange }
    } = useController({ control, name: "triggerType" })

    const unitCompletionEventTriggerOptions = useMemo(() => {
        if (!selectedElement || libraryTemplateType !== LibraryTemplateType.UNIT) return []
        return ((selectedElement as any).unit_chapter || []).map((unit: UnitChapterResponse, unitIndex: number) => ({
            groupName: unit.name,
            options: unit.unit_chapter_element.map((element: UnitChapterElementResponse, elementIndex: number) => ({
                id: `${element.id}-${unitIndex}-${elementIndex}`,
                title: element?.library_template?.title || element?.questionnaire?.title
            }))
        }))
    }, [selectedElement, libraryTemplateType])

    return (
        <div className="flex flex-col w-full gap-y-[34px]">
            <div className="flex flex-col gap-y-2">
                <TypographyV2 type="xs" width="medium">
                    {t("triggerEventType")}
                </TypographyV2>
                <div className="flex flex-row gap-x-2">
                    <div
                        className={classNames(
                            "flex flex-col border rounded-lg p-3 bg-white border-lightest-gray flex-1",
                            value === TriggerType.SEQUENTIAL_TRIGGERED &&
                                "border-med-blue shadow-[0px_0px_8px_0px_rgba(60,127,249,0.5)]"
                        )}
                        onClick={() => onChange(TriggerType.SEQUENTIAL_TRIGGERED)}
                    >
                        <TagV2
                            icon={
                                <ArrowRightEndOnRectangleIcon
                                    className={classNames(
                                        "w-4 h-4 text-[#9CA3AF]",
                                        value === TriggerType.SEQUENTIAL_TRIGGERED && "text-[#3C7FF9]"
                                    )}
                                />
                            }
                            className={classNames(
                                "bg-[#F3F4F6] mb-3",
                                value === TriggerType.SEQUENTIAL_TRIGGERED && "bg-[#DBEAFE]"
                            )}
                        />
                        <TypographyV2 type="xs" width="semibold">
                            {t("completionPreviosEventTitle")}
                        </TypographyV2>
                        <TypographyV2 type="xs" width="normal">
                            {t("completionPreviosEventSubtitle")}
                        </TypographyV2>
                    </div>
                    <div
                        className={classNames(
                            "flex flex-col border rounded-lg p-3 bg-white border-lightest-gray flex-1",
                            value === TriggerType.SEQUENTIAL_MANUAL &&
                                "border-med-blue shadow-[0px_0px_8px_0px_rgba(60,127,249,0.5)]"
                        )}
                        onClick={() => onChange(TriggerType.SEQUENTIAL_MANUAL)}
                    >
                        <TagV2
                            icon={
                                <CursorArrowRaysIcon
                                    className={classNames(
                                        "w-4 h-4 text-[#9CA3AF]",
                                        value === TriggerType.SEQUENTIAL_MANUAL && "text-[#3C7FF9]"
                                    )}
                                />
                            }
                            className={classNames(
                                "bg-[#F3F4F6] mb-3",
                                value === TriggerType.SEQUENTIAL_MANUAL && "bg-[#DBEAFE]"
                            )}
                        />
                        <TypographyV2 type="xs" width="semibold">
                            {t("manualEventTitle")}
                        </TypographyV2>
                        <TypographyV2 type="xs" width="normal">
                            {t("manualEventSubtitle")}
                        </TypographyV2>
                    </div>
                </div>
            </div>
            {libraryTemplateType === LibraryTemplateType.UNIT && (
                <div className="flex flex-col gap-y-2">
                    <div className="flex flex-row items-center gap-x-2">
                        <TypographyV2 type="xs" width="medium">
                            {t("unitTriggerType")}
                        </TypographyV2>
                        <TooltipV2 content={t("unitTriggerTypeTooltip")}>
                            <InformationCircleIcon className="w-4 h-4 text-[#9CA3AF]" />
                        </TooltipV2>
                    </div>
                    <ControlledSelectV2
                        control={control}
                        name="unitCompletionEventTrigger"
                        options={unitCompletionEventTriggerOptions}
                        // error={errors.numberOfDelay?.message}
                        classList={{ wrapper: "w-[244px]" }}
                        placeholder={t("consts.selectEvent")}
                    />
                </div>
            )}
            <div className="flex flex-col w-full gap-y-5">
                <div className="flex flex-row items-center gap-x-2">
                    <TypographyV2 type="xs" width="medium" className="text-base-dark">
                        {t("scheduleDetails")}
                    </TypographyV2>
                    <TooltipV2 content={t("scheduleDetailsTooltip")}>
                        <InformationCircleIcon className="w-4 h-4 text-[#9CA3AF]" />
                    </TooltipV2>
                </div>
                <div className="flex flex-row w-full gap-x-2 items-center">
                    <ControlledSelectV2
                        control={control}
                        name="numberOfDelay"
                        options={NUMBER_OF_REPEATS_OPTIONS(t)}
                        error={errors.numberOfDelay?.message}
                        classList={{ wrapper: "w-[109px]" }}
                        placeholder={t("0")}
                    />
                    <ControlledSelectV2
                        control={control}
                        name="delayType"
                        options={TIME_OPTIONS(t)}
                        error={errors.delayType?.message}
                        classList={{ wrapper: "w-[140px]" }}
                        placeholder={t("consts.days")}
                    />
                </div>
            </div>
            <div className="flex flex-row w-full gap-x-6">
                <div className="flex flex-row">
                    <div className="w-[416px]">
                        <ControlledSelectV2
                            control={control}
                            name="repeatsType"
                            options={REPEATS_OPTIONS(t)}
                            error={errors.repeatsType?.message}
                            label={t("consts.repeats")}
                            placeholder={t("0")}
                        />
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="w-[195px]">
                        <ControlledSelectV2
                            control={control}
                            name="numberOfRepeats"
                            options={NUMBER_OF_REPEATS_OPTIONS(t)}
                            error={errors.numberOfRepeats?.message}
                            label={t("consts.numberOfRepeats")}
                            placeholder={t("consts.select")}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="flex bg-base-lighter rounded-md h-10 items-center px-4 gap-x-2">
                    <InformationCircleIcon className="w-5 h-5 text-[#2563EB]" />
                    <TypographyV2 type="xs">{t("triggerNotification")}</TypographyV2>
                </div>
            </div>
        </div>
    )
}

export default ConfigureCPElement
