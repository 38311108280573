import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $getSelection, $isRangeSelection, $isElementNode, COMMAND_PRIORITY_EDITOR, $isTextNode } from "lexical"
import { useEffect } from "react"

import { createCommand } from "lexical"

export const CHANGE_PARAGRAPH_DIRECTION_COMMAND = createCommand("CHANGE_PARAGRAPH_DIRECTION")

export default function DirectionPlugin(): JSX.Element | null {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        return editor.registerCommand<"ltr" | "rtl">(
            CHANGE_PARAGRAPH_DIRECTION_COMMAND,
            (direction) => {
                editor.update(() => {
                    const selection = $getSelection()
                    if ($isRangeSelection(selection)) {
                        const nodes = selection.getNodes()
                        nodes.forEach((node) => {
                            const element = $isElementNode(node) ? node : node.getParentOrThrow()
                            if (element.getType() === "paragraph") {
                                // Set text alignment
                                element.setFormat(direction === "rtl" ? "right" : "left")

                                // Set direction attribute on the DOM element
                                const dom = editor.getElementByKey(element.getKey())
                                if (dom instanceof HTMLElement) {
                                    // Set the direction
                                    dom.dir = direction
                                    // Add text-align property
                                    dom.style.textAlign = direction === "rtl" ? "right" : "left"
                                    // Add unicode-bidi property to ensure proper bidirectional text handling
                                    dom.style.unicodeBidi = "embed"
                                    // Set writing mode for better RTL support
                                    dom.setAttribute("writing-mode", "horizontal-tb")
                                }

                                // Handle text node direction
                                if ($isTextNode(node)) {
                                    const textContent = node.getTextContent()
                                    const textNode = node.getLatest()
                                    textNode.setTextContent(textContent)
                                }
                            }
                        })
                    }
                })
                return true
            },
            COMMAND_PRIORITY_EDITOR
        )
    }, [editor])

    // Add CSS to the editor root to ensure proper RTL behavior
    useEffect(() => {
        editor.update(() => {
            const editorElement = editor.getRootElement()
            if (editorElement) {
                editorElement.style.direction = "inherit"
                editorElement.style.unicodeBidi = "embed"
            }
        })
    }, [editor])

    return null
}
