import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2 } from "components/v2"
import { classNames } from "utils"
const FreeTextQuestionResponse = ({
    question,
    screeningAnswer,
    sybQuestion = false,
    isLastQuestion = false,
    placeholder = "-"
}: {
    question: any
    screeningAnswer: ScreeningAnswer
    sybQuestion?: boolean
    isLastQuestion?: boolean
    placeholder?: string
}) => {
    return (
        <div className="">
            <div
                className={classNames(
                    "bg-white border-slate-300 py-4 px-3",
                    sybQuestion ? "border-b" : "border rounded-lg",
                    !isLastQuestion && "border-none"
                )}
            >
                <TypographyV2 type="sm" className="text-[#6B7280]">
                    {question.question}
                </TypographyV2>
                <TypographyV2 className="text-[#6B7280] mt-2" type="base">
                    {screeningAnswer?.value || placeholder}
                </TypographyV2>
            </div>
        </div>
    )
}

export default FreeTextQuestionResponse
