import { FC, Fragment, ReactChild, ReactNode, useRef } from "react"
import { DialogPanel, DialogTitle, Dialog as RawDialog, Transition, TransitionChild } from "@headlessui/react"
import { useTranslation } from "react-i18next"
import { XIcon } from "components/icons"
import { ButtonV2 } from ".."
import { classNames } from "utils"
import { btnVariantStyles } from "components/v2/components/common/buttons/ButtonV2/buttonConstantsV2"

interface IDialogV2Props {
    isOpen: boolean
    onClose: () => void
    title: ReactNode
    type?: "wide" | "thin"
    onProceed?: () => void
    onDismiss?: () => void
    onBack?: () => void
    proceedText?: string
    dismissText?: string
    backText?: string
    proceedDisabled?: boolean
    proceedType?: keyof typeof btnVariantStyles
    dismissType?: keyof typeof btnVariantStyles
    backType?: keyof typeof btnVariantStyles
    isFooterHidden?: boolean
    statuses?: React.ReactElement
    children: ReactNode
    classList?: {
        dialog?: string
        title?: string
        content?: string
        footer?: string
    }
}

const DialogV2: FC<IDialogV2Props> = ({
    isOpen,
    onClose,
    title,
    children,
    type,
    onProceed,
    onDismiss,
    onBack,
    proceedText,
    dismissText,
    backText,
    proceedDisabled = false,
    proceedType,
    dismissType,
    backType,
    isFooterHidden = false,
    statuses,
    classList
}) => {
    const { t } = useTranslation("common")
    const cancelButtonRef = useRef(null)

    const onConfirmClick = async () => {
        if (onProceed) {
            onProceed()
        }
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <RawDialog
                as="div"
                className={classNames("fixed z-[60] inset-0 overflow-y-auto", classList?.dialog)}
                initialFocus={cancelButtonRef}
                onClose={onClose}
            >
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="flex items-end justify-center min-h-screen text-center bg-gray-500 bg-opacity-75 transition-opacity">
                        <DialogPanel
                            className={classNames(
                                "fixed inset-0 top-[35%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-lg shadow-xl isolate",
                                type === "wide" ? "w-[1040px]" : "w-[562px]",
                                classList?.dialog
                            )}
                        >
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div
                                    className={classNames(
                                        "bg-[#F9FAFB] absolute align-bottom rounded-2xl shadow-xl transform transition-all sm:align-middle w-full overflow-hidden",
                                        classList?.title
                                    )}
                                >
                                    <DialogTitle
                                        as="div"
                                        className={
                                            "border-b-solid border-b border-lightest-gray text-base leading-6 font-bold w-full p-4 flex items-center justify-between flex-row"
                                        }
                                    >
                                        {title}
                                        <div className="flex flex-row items-center gap-x-2.5">
                                            {statuses}
                                            <XIcon className="cursor-pointer" onClick={onClose} />
                                        </div>
                                    </DialogTitle>
                                    <div
                                        className={classNames(
                                            "flex gap-6 p-6 ltr:text-left rtl:text-right",
                                            classList?.content
                                        )}
                                    >
                                        {children}
                                    </div>
                                    {!isFooterHidden && (
                                        <div
                                            className={classNames(
                                                "p-4 border-t-solid border-t border-lightest-gray flex gap-2 items-center justify-between bg-[#F3F4F6]",
                                                classList?.footer
                                            )}
                                        >
                                            <div className="flex-grow">
                                                {onBack && (
                                                    <ButtonV2
                                                        onClick={onBack}
                                                        variant={backType}
                                                        text={backText || t("close")}
                                                    />
                                                )}
                                            </div>
                                            <div className="flex gap-2 items-center justify-between shrink-0">
                                                {(onDismiss || onClose) && (
                                                    <ButtonV2
                                                        onClick={onDismiss || onClose}
                                                        variant={dismissType}
                                                        text={dismissText || t("cancel")}
                                                    />
                                                )}
                                                {onProceed && (
                                                    <ButtonV2
                                                        onClick={onConfirmClick}
                                                        variant={proceedType}
                                                        isDisabled={proceedDisabled}
                                                        text={proceedText || t("confirm")}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </TransitionChild>
                        </DialogPanel>
                    </div>
                </TransitionChild>
            </RawDialog>
        </Transition>
    )
}

export default DialogV2
