import { AnyAction, Reducer } from "redux"

import { LibraryTemplateState } from "types/Redux"
import * as types from "./types"

const initialState: LibraryTemplateState = {
    activeTab: types.LibraryTemplateTab.BASIC_INFO,
    type: null,
    educationalContent: null,
    libraryTemplate: null,
    isBuilderOpen: false,
    isFormError: false,
    isFormDirty: false,
    list: [],
    isLoading: false,
    diagnoses: [],
    creators: [],
    searchParams: {
        text: null,
        diagnose: null,
        status: null,
        creator: null
    }
}

const libraryTemplateReducer: Reducer<LibraryTemplateState, AnyAction> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_ACTIVE_TAB: {
            return { ...state, activeTab: payload }
        }
        case types.OPEN_LIBRARY_TEMPLATE_BUILDER: {
            return { ...state, isBuilderOpen: true, libraryTemplate: payload }
        }
        case types.SET_LIBRARY_TEMPLATE: {
            return { ...state, libraryTemplate: payload }
        }
        case types.SET_LIBRARY_TEMPLATE_FORM_ERROR: {
            return { ...state, isFormError: payload }
        }
        case types.SET_LIBRARY_TEMPLATE_FORM_DIRTY: {
            return { ...state, isFormDirty: payload }
        }
        case types.CLOSE_LIBRARY_TEMPLATE_BUILDER: {
            return { ...state, isBuilderOpen: false }
        }
        case types.SET_LIBRARY_TEMPLATE_LIST: {
            return { ...state, list: payload }
        }
        case types.SET_IS_LIBRARY_LIST_LOADING: {
            return { ...state, isLoading: payload }
        }
        case types.SET_DIAGNOSES_LIST: {
            return { ...state, diagnoses: [...payload] }
        }
        case types.SET_LIBRARY_TEMPLATE_TYPE: {
            return { ...state, type: payload }
        }
        case types.SET_CREATORS_LIST: {
            return { ...state, creators: [...payload] }
        }
        case types.SET_EDUCATIONAL_CONTENT: {
            return { ...state, educationalContent: payload }
        }
        case types.SET_LIBRARY_TEMPLATE_SEARCH_PARAMS: {
            const { key, value } = payload
            return { ...state, searchParams: { ...state.searchParams, [key]: value } }
        }
        default:
            return state
    }
}

export default libraryTemplateReducer
