import { useState, useEffect } from "react"
import { useScreenSize } from "hooks"
import { useTranslation } from "react-i18next"
import { Control } from "react-hook-form"
import { ScreeningQuestionType } from "@prisma/client"
import {
    FreeTextQuestionCard,
    ImageQuestionCard,
    MinutesQuestionCard,
    MultipleChoiceQuestionCard,
    NumberQuestionCard,
    PercentQuestionCard,
    ScaleQuestionCard,
    ScaleSliderQuestionCard,
    SelectQuestionCard,
    TimeOfDayQuestionCard,
    YesNoQuestionCard
} from "components/v2/components/IntakeScreeningCandidate/IntakeCardsContent"

const cardBuilder = (questions: any[], control: Control<Record<string, string | (string | number)[]>>) => {
    const isDesktop = useScreenSize()
    const [extendedQuestions, setExtendedQuestions] = useState<any[]>([])

    const extractQuestionnaireSubQuestionsById = (): any[] => {
        return []
    }

    let questionnaireIndex = 0
    // This way we can extend the regular questions with the questionnaire questions for the mobile view.
    useEffect(() => {
        if (!isDesktop) {
            const unifiedQuestions = questions.reduce((acc, question) => {
                if (question.type === ScreeningQuestionType.questionnaire) {
                    const subQuestions = extractQuestionnaireSubQuestionsById()
                    return [...acc, ...subQuestions]
                } else {
                    return [...acc, question]
                }
            }, [])
            setExtendedQuestions(unifiedQuestions)
        } else {
            setExtendedQuestions(questions)
        }
    }, [isDesktop, questions])

    const cards: React.ReactNode[] = []
    extendedQuestions
        .sort((a, b) => a.position - b.position)
        .forEach((question) => {
            switch (question.type) {
                case ScreeningQuestionType.free_text:
                    cards.push(
                        <FreeTextQuestionCard
                            isDisabled={true}
                            key={question.question}
                            question={question}
                            control={control}
                        />
                    )
                    break
                case ScreeningQuestionType.yes_no:
                    cards.push(
                        <YesNoQuestionCard key={question.question} question={question} control={control} isDisabled />
                    )
                    break
                case ScreeningQuestionType.select:
                    cards.push(
                        <SelectQuestionCard key={question.question} question={question} control={control} isDisabled />
                    )
                    break
                case ScreeningQuestionType.multiple_choice:
                    cards.push(
                        <MultipleChoiceQuestionCard
                            key={question.question}
                            question={question}
                            control={control}
                            error={undefined}
                            isDisabled
                        />
                    )
                    break
                case ScreeningQuestionType.number:
                    cards.push(
                        <NumberQuestionCard
                            key={question.question}
                            question={question}
                            control={control}
                            error={undefined}
                            isDisabled
                        />
                    )
                    break
                case ScreeningQuestionType.percent:
                    cards.push(
                        <PercentQuestionCard
                            key={question.question}
                            question={question}
                            control={control}
                            error={undefined}
                            isDisabled
                        />
                    )
                    break
                case ScreeningQuestionType.minutes:
                    cards.push(
                        <MinutesQuestionCard
                            key={question.question}
                            question={question}
                            control={control}
                            error={undefined}
                            isDisabled
                        />
                    )
                    break
                case ScreeningQuestionType.time_of_day:
                    cards.push(
                        <TimeOfDayQuestionCard
                            key={question.question}
                            question={question}
                            control={control}
                            error={undefined}
                            isDisabled
                        />
                    )
                    break
                case ScreeningQuestionType.scale:
                    cards.push(<ScaleQuestionCard key={question.question} question={question} control={control} />)
                    break
                case ScreeningQuestionType.image:
                    cards.push(
                        <ImageQuestionCard
                            key={question.question}
                            question={question}
                            control={control}
                            error={undefined}
                        />
                    )
                    break
                case ScreeningQuestionType.scale_slider:
                    cards.push(
                        <ScaleSliderQuestionCard
                            isDisabled
                            key={question.question}
                            question={question}
                            control={control}
                        />
                    )
                    break
            }
        })

    return cards
}

export default cardBuilder
