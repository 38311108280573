import { EventTypes } from "types"
import {
    EducationalContentIcon,
    ExerciseIcon,
    QuestionaryIcon,
    TaskIcon,
    UnitIcon,
    AppointmentlIcon,
    TrackerEventIcon
} from "components/icons"
import ProtocolIcon from "components/icons/ProtocolIcon"

export const eventTypeColors: { [key in EventTypes]: string | null } = {
    [EventTypes.Questionnaire]: "bg-lime-50",
    [EventTypes.Exercise]: "bg-violet-100",
    [EventTypes.Unit]: "bg-teal-50",
    [EventTypes.EducationalContent]: "bg-light-blue-100",
    [EventTypes.Protocol]: "bg-amber-50",
    [EventTypes.Task]: "bg-violet-100",
    [EventTypes.Appointment]: "bg-violet-100",
    [EventTypes.Tracker]: "bg-violet-100",
    [EventTypes.Mood]: null,
    [EventTypes.Reflection]: null
}

export const eventTypeIcons: { [key in EventTypes]: React.ComponentType<any> | null } = {
    [EventTypes.Questionnaire]: QuestionaryIcon,
    [EventTypes.Exercise]: ExerciseIcon,
    [EventTypes.Unit]: UnitIcon,
    [EventTypes.Protocol]: ProtocolIcon,
    [EventTypes.Task]: TaskIcon,
    [EventTypes.Appointment]: AppointmentlIcon,
    [EventTypes.EducationalContent]: EducationalContentIcon,
    [EventTypes.Tracker]: TrackerEventIcon,
    [EventTypes.Mood]: null,
    [EventTypes.Reflection]: null
}
