import ScheduleItemCard from "../../ScheduleItemCard"
import { mapEvent } from "../../../../../../../../CareplanSection/constants"
import { TypographyV2 } from "components/v2"
import React, { memo, useMemo } from "react"
import { UnitEventToChapterResponse } from "forms/types"
import { useIsHeLanguage } from "hooks"

interface IUnitEventChaptersListProps {
    isPastEvent: boolean
    chapters: UnitEventToChapterResponse[]
}

const UnitEventChaptersList: React.FC<IUnitEventChaptersListProps> = memo(({ chapters, isPastEvent }) => {
    const { isHeLanguage } = useIsHeLanguage()
    const sortedСhapters = useMemo(() => [...chapters].sort((a, b) => a.chapter.pos - b.chapter.pos), [chapters])

    if (!sortedСhapters?.length) {
        return null
    }

    return (
        <div className="mt-5 px-1">
            {sortedСhapters?.map(({ events, chapter }) => {
                const totalCount = events?.length

                const sortedEvents = [...events].sort(
                    (a, b) => a.unit_event_to_element_chapter.pos - b.unit_event_to_element_chapter.pos
                )
                const completedEvents = events?.filter((event) => !!event?.completed_at)
                return (
                    <div className="mb-4">
                        <div className="flex justify-between items-center">
                            <TypographyV2 width="semibold" type="xs" className="mb-2.5 ">
                                {chapter?.name}
                            </TypographyV2>
                            <TypographyV2 className="text-gray-500">
                                {isHeLanguage ? totalCount : completedEvents.length} /{" "}
                                {isHeLanguage ? completedEvents.length : totalCount}
                            </TypographyV2>
                        </div>
                        <div>
                            {sortedEvents?.map((event) => {
                                return (
                                    <ScheduleItemCard
                                        {...mapEvent(event)}
                                        isPastEvent={isPastEvent}
                                        isUnitItem
                                        isVerticalView
                                        variant="light-gray"
                                        classNames="mb-2"
                                    />
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
})

export default UnitEventChaptersList
