import classNames from "classnames"
import { EditUnitDetailsIcon, PlusIcon } from "components/icons"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useUnitSelector } from "store/hooks"
import { updateChapterAction } from "store/unit/actions"
import { useDispatch } from "store"
import { TypographyV2 } from "../../../../common"
import { useTranslation } from "react-i18next"

const AddChapterSummaryField = () => {
    const { chapters, selectedChapter: selectedChapterId } = useUnitSelector()

    const { t } = useTranslation("unit")

    const dispatch = useDispatch()

    const [value, setValue] = useState("")

    const inputRef = useRef<HTMLSpanElement>(null)

    const [editable, setEditable] = useState(false)

    const selectedChapter = useMemo(
        () => chapters.find((chapter) => chapter.id === selectedChapterId),
        [chapters, selectedChapterId]
    )

    const {
        control,
        reset,
        watch,
        setValue: setFormValue
    } = useForm({
        defaultValues: {
            description: selectedChapter?.description ?? ""
        }
    })

    useEffect(() => {
        reset({ description: selectedChapter?.description ?? "" })
        setValue(selectedChapter?.description ?? "")
    }, [selectedChapter, control])

    useEffect(() => {
        editable && inputRef.current && inputRef.current.focus()
    }, [editable])

    return (
        <div>
            <div className="p-1.5 mt-2 bg-black/[0.02] inline-flex gap-2.5 rounded-md items-center">
                {value || editable ? (
                    <>
                        <span
                            ref={inputRef}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Escape") {
                                    e.currentTarget.blur()
                                }
                            }}
                            className={classNames(
                                "text-sm outline-none overflow-hidden text-ellipsis",
                                !editable && "cursor-default"
                            )}
                            contentEditable={editable}
                            suppressContentEditableWarning={editable}
                            onInput={(e) => {
                                setValue(e.currentTarget.textContent ?? "")
                            }}
                            onBlur={() => {
                                setEditable(false)
                                setFormValue("description", value)

                                if (value !== selectedChapter?.description)
                                    dispatch(
                                        updateChapterAction(selectedChapter.id, {
                                            name: selectedChapter.name,
                                            description: watch().description,
                                            unit_library_template_id: selectedChapter.unit_library_template_id
                                        })
                                    )
                            }}
                        >
                            {watch().description}
                        </span>
                        <button
                            className="pl-0 p-1 cursor-pointer"
                            onClick={() => {
                                setEditable(true)
                            }}
                        >
                            <EditUnitDetailsIcon height={16} width={16} />
                        </button>
                    </>
                ) : (
                    <button
                        className="text-base-dark rounded-md flex items-center gap-0.5"
                        onClick={() => setEditable(true)}
                    >
                        <PlusIcon />
                        <TypographyV2 type="xs">{`${t("addBriefSummary")} (${t("optional")})`}</TypographyV2>
                    </button>
                )}
            </div>
        </div>
    )
}

export default AddChapterSummaryField
