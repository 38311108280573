import { useState } from "react"
import { UploadFileV2, TypographyV2 } from "components/v2"
import { Control } from "react-hook-form"
import { ScreeningTemplateQuestion } from "@prisma/client"
import { useScreenSize } from "hooks"
import { TableListV2 } from "components/v2"

interface ImageQuestionCardProps {
    question: ScreeningTemplateQuestion
    control: Control<Record<string, string | (string | number)[]>>
    error: string | undefined
}

const ImageQuestionCard = ({ question, control, error }: ImageQuestionCardProps) => {
    const { question: question_text } = question
    const [file, setFile] = useState(null)
    const isDesktop = useScreenSize()

    if (isDesktop) {
        return (
            <TableListV2
                headerCells={[]}
                listComponent={
                    <tr className="border-b border-gray-200">
                        <td className="py-12 px-6">
                            <div className="flex flex-col gap-4">
                                <TypographyV2 type="base">{question_text}</TypographyV2>
                                {question?.description && (
                                    <TypographyV2 type="xs" className="text-gray-500">
                                        {question.description}
                                    </TypographyV2>
                                )}
                            </div>
                        </td>
                        <td className="py-12 px-6">
                            <div className="h-[136px] w-full">
                                <UploadFileV2 value={file} setValue={(newFile) => setFile(newFile)} />
                            </div>
                            {error && (
                                <TypographyV2 type="xs" width="medium" className="text-red-500">
                                    {error}
                                </TypographyV2>
                            )}
                        </td>
                    </tr>
                }
            />
        )
    }

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <div className="h-[136px] w-full">
                <UploadFileV2 value={file} setValue={(newFile) => setFile(newFile)} />
            </div>
            {error && (
                <TypographyV2 type="xs" width="medium" className="text-red-500">
                    {error}
                </TypographyV2>
            )}
        </div>
    )
}

export default ImageQuestionCard
