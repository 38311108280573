import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { pushToLibraryStack } from "store/patient/actions"
import DetailsScreen from "."
import { eventToTemplate } from "../utils"
import { useEffect, useState } from "react"
import { LibraryTemplateResponse } from "../../../types/Redux"
import { getLibraryTemplateById } from "api/LibraryTemplate"
import { Loader } from "@aws-amplify/ui-react"
import LexicalRenderer from "components/v2/components/common/RichTextEditorV2/ui/LexicalRenderer"
import HtmlRenderer from "components/v2/components/common/RichTextEditorV2/ui/HtmlRenderer"

// Define types for educational content
interface EducationalContentItem {
    id: number;
    content_tree?: any;
    html?: string;
    library_template_id: number;
}

interface ExtendedLibraryTemplateResponse extends LibraryTemplateResponse {
    educational_content?: EducationalContentItem[];
}


const EducationalContentDetails = ({
    educationalContentId,
    withBack
}: {
    educationalContentId: number
    withBack: boolean
}) => {
    const [educationalContent, setEducationalContent] = useState<ExtendedLibraryTemplateResponse | null>(null)
    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()
    const { t } = useTranslation("patient")

    useEffect(() => {
        const fetchEducationalContent = async () => {
            setIsLoading(true)
            const response = await getLibraryTemplateById(educationalContentId)
            setEducationalContent(response as ExtendedLibraryTemplateResponse)
            setIsLoading(false)
        }

        fetchEducationalContent()
    }, [educationalContentId])

    if (isLoading) {
        return (
            <div className="w-full flex justify-center">
                <Loader />
            </div>
        )
    }

    const hasContent = educationalContent?.educational_content && 
                      educationalContent.educational_content.length > 0;
    
    const content = hasContent ? educationalContent.educational_content[0] : null;
    
    const renderContent = () => {
        if (!content) return <div>No content available</div>;
        
        if (content.content_tree) {
            return <LexicalRenderer contentTree={content.content_tree} />;
        } else if (content.html) {
            return <HtmlRenderer html={content.html} />;
        } else {
            return <div>No content available</div>;
        }
    };

    return (
        <DetailsScreen
            title={educationalContent.title}
            description={educationalContent.description}
            withBack={withBack}
            min_age={educationalContent.min_age}
            max_age={educationalContent.max_age}
            related_diagnoses={educationalContent.diagnoses.map((diagnose) => ({
                name: diagnose.diagnose.name_en,
                id: diagnose.diagnose.id
            }))}
            onAdd={() =>
                dispatch(
                    pushToLibraryStack({
                        view: "config",
                        eventTemplates: eventToTemplate("exercise", educationalContent),
                        patientId: "preserve"
                    })
                )
            }
        >
            <div>
                <div className="text-lg pb-6 text-dark-blue font-medium">
                    {t("contentLib.insideEducationalContent")}
                </div>
                <div className="educational-content-container">
                    {renderContent()}
                </div>
            </div>
        </DetailsScreen>
    )
}

export default EducationalContentDetails
