import { DndContext, DragEndEvent, MouseSensor, useSensor, useSensors } from "@dnd-kit/core"
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import React from "react"
import ChapterListItem from "./ChapterListItem"
import { BoldPlusIcon } from "components/icons"
import { TypographyV2 } from "components/v2/components/common"
import { useLibraryTemplateSelector, useUnitSelector } from "store/hooks"
import { useDispatch } from "store"
import { addNewChapterToUnitAction, updateChapterPositionsAction } from "store/unit/actions"
import { useTranslation } from "react-i18next"

const ChaptersList = () => {
    const { chapters } = useUnitSelector()
    const { t } = useTranslation("unit")

    const {
        libraryTemplate: { id }
    } = useLibraryTemplateSelector()

    const dispatch = useDispatch()

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 5 }
        })
    )

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (!over) return

        const activeId = active.id as number
        const overId = over.id as number

        if (activeId !== overId) {
            dispatch(updateChapterPositionsAction({ activeId, overId, unitId: id }))
            // do something
        }
    }
    return (
        <div className="flex flex-col w-full px-4 pb-4 h-full overflow-scroll">
            <DndContext
                sensors={sensors}
                onDragEnd={onDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <div className="flex flex-col gap-2.5">
                    <SortableContext items={chapters} strategy={verticalListSortingStrategy}>
                        {chapters?.map((item, index) => (
                            <ChapterListItem index={index} key={item.id} {...item} />
                        ))}
                    </SortableContext>
                </div>
            </DndContext>
            <button
                className="py-2 flex gap-1 items-center text-med-blue mt-2"
                onClick={() => {
                    dispatch(addNewChapterToUnitAction(id))
                }}
            >
                <div className="p-1.5">
                    <BoldPlusIcon width={8} height={8} />
                </div>
                <TypographyV2 type="xs" width="medium">
                    {t("addChapter")}
                </TypographyV2>
            </button>
        </div>
    )
}

export default ChaptersList
