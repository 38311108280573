import { SVGProps } from "react"

interface UnitIconProps extends SVGProps<SVGSVGElement> {
    variant?: "default" | "light-gray"
}

const UnitIcon = ({ variant = "default", ...props }: UnitIconProps) => {
    const pathFill = variant === "default" ? "#14B8A6" : "#9CA3AF"

    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.85798 2.86335C2.85798 1.60099 3.88133 0.577637 5.14369 0.577637H10.858C12.1203 0.577637 13.1437 1.60099 13.1437 2.86335V3.16931C13.8264 3.56425 14.2866 4.30239 14.2866 5.14907V5.45502C14.9693 5.84996 15.4294 6.58811 15.4294 7.43478V12.0062C15.4294 13.2686 14.4061 14.2919 13.1437 14.2919H2.85798C1.59561 14.2919 0.572266 13.2686 0.572266 12.0062V7.43478C0.572266 6.58811 1.03238 5.84996 1.71512 5.45502V5.14907C1.71512 4.30239 2.17524 3.56425 2.85798 3.16931V2.86335ZM4.00084 2.86335H12.0008C12.0008 2.23217 11.4892 1.72049 10.858 1.72049H5.14369C4.51251 1.72049 4.00084 2.23217 4.00084 2.86335ZM2.85798 5.14907H13.1437C13.1437 4.65235 12.8265 4.22825 12.3818 4.07109C12.2634 4.02924 12.1354 4.00621 12.0008 4.00621H4.00084C3.86624 4.00621 3.73826 4.02924 3.61983 4.07109C3.17518 4.22825 2.85798 4.65235 2.85798 5.14907ZM2.85798 6.29192C2.72338 6.29192 2.5954 6.31495 2.47698 6.35681C2.03232 6.51397 1.71512 6.93807 1.71512 7.43478V12.0062C1.71512 12.6374 2.2268 13.1491 2.85798 13.1491H13.1437C13.7749 13.1491 14.2866 12.6374 14.2866 12.0062V7.43478C14.2866 6.93807 13.9693 6.51397 13.5247 6.35681C13.4063 6.31495 13.2783 6.29192 13.1437 6.29192H2.85798Z"
                fill={pathFill}
            />
        </svg>
    )
}

export default UnitIcon
