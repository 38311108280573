import { SVGProps } from "react"

interface QuestionaryIconProps extends SVGProps<SVGSVGElement> {
    variant?: "default" | "light-gray"
}

const QuestionaryIcon = ({ variant = "default", ...props }: QuestionaryIconProps) => {
    const pathFill = variant === "default" ? "#84CC16" : "#9CA3AF"
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6809_448)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.16211 12.6244C7.16211 12.3254 7.40448 12.083 7.70345 12.083H11.212C11.511 12.083 11.7534 12.3254 11.7534 12.6244C11.7534 12.9233 11.511 13.1657 11.212 13.1657H7.70345C7.40448 13.1657 7.16211 12.9233 7.16211 12.6244Z"
                    fill={pathFill}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.16211 2.79867C7.16211 2.49969 7.40448 2.25732 7.70345 2.25732H13.3172C13.6161 2.25732 13.8585 2.49969 13.8585 2.79867C13.8585 3.09764 13.6161 3.34001 13.3172 3.34001H7.70345C7.40448 3.34001 7.16211 3.09764 7.16211 2.79867Z"
                    fill={pathFill}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.16211 5.60628C7.16211 5.30731 7.40448 5.06494 7.70345 5.06494H11.212C11.511 5.06494 11.7534 5.30731 11.7534 5.60628C11.7534 5.90526 11.511 6.14763 11.212 6.14763H7.70345C7.40448 6.14763 7.16211 5.90526 7.16211 5.60628Z"
                    fill={pathFill}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.16211 9.81624C7.16211 9.51727 7.40448 9.2749 7.70345 9.2749H13.3172C13.6161 9.2749 13.8585 9.51727 13.8585 9.81624C13.8585 10.1152 13.6161 10.3576 13.3172 10.3576H7.70345C7.40448 10.3576 7.16211 10.1152 7.16211 9.81624Z"
                    fill={pathFill}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.38759 2.63933C1.29902 2.63933 1.22722 2.71113 1.22722 2.7997V5.60655C1.22722 5.69512 1.29902 5.76693 1.38759 5.76693H4.19445C4.28302 5.76693 4.35482 5.69512 4.35482 5.60655V2.7997C4.35482 2.71113 4.28302 2.63933 4.19445 2.63933H1.38759ZM0.144531 2.7997C0.144531 2.11318 0.701067 1.55664 1.38759 1.55664H4.19445C4.88097 1.55664 5.4375 2.11318 5.4375 2.7997V5.60655C5.4375 6.29308 4.88097 6.84961 4.19445 6.84961H1.38759C0.701067 6.84961 0.144531 6.29308 0.144531 5.60655V2.7997Z"
                    fill={pathFill}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.38759 9.65544C1.29902 9.65544 1.22722 9.72724 1.22722 9.81581V12.6227C1.22722 12.7112 1.29902 12.783 1.38759 12.783H4.19445C4.28302 12.783 4.35482 12.7112 4.35482 12.6227V9.81581C4.35482 9.72724 4.28302 9.65544 4.19445 9.65544H1.38759ZM0.144531 9.81581C0.144531 9.12929 0.701067 8.57275 1.38759 8.57275H4.19445C4.88097 8.57275 5.4375 9.12929 5.4375 9.81581V12.6227C5.4375 13.3092 4.88097 13.8657 4.19445 13.8657H1.38759C0.701067 13.8657 0.144531 13.3092 0.144531 12.6227V9.81581Z"
                    fill={pathFill}
                />
            </g>
            <defs>
                <clipPath id="clip0_6809_448">
                    <rect width="13.7143" height="13.7143" fill="white" transform="translate(0.142578 0.577637)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default QuestionaryIcon
