import { AnyAction, Reducer } from "redux"
import { CareProtocolState } from "types/Redux"

import * as types from "./types"

const initialState: CareProtocolState = {
    isLoading: true,
    list: [],
    addNewElementModal: {
        isOpenedScheduleModal: false,
        isOpenedSequenceModal: false,
        libraryTemplateType: null,
        elementId: null
    }
}

const careProtocolReducer: Reducer<CareProtocolState, AnyAction> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.OPEN_ADD_CARE_PROTOCOL_ELEMENT_MODAL: {
            return {
                ...state,
                addNewElementModal: {
                    ...state.addNewElementModal,
                    ...payload
                }
            }
        }
        case types.EDIT_CARE_PROTOCOL_ELEMENT_TO_LIST: {
            const newList = [...state.list].map((element) => (element.id === payload.id ? payload : element))
            return { ...state, list: newList }
        }
        case types.ARCHIVE_CARE_PROTOCOL_ELEMENT_TO_LIST: {
            const newList = [...state.list].filter((element) => element.id !== payload.id)
            return { ...state, list: newList }
        }
        case types.CLOSE_ADD_CARE_PROTOCOL_ELEMENT_MODAL: {
            return { ...state, addNewElementModal: initialState.addNewElementModal }
        }
        case types.SET_CARE_PROTOCOL_ELEMENTS_LIST: {
            return { ...state, list: [...payload], isLoading: false }
        }
        case types.CLEAR_CARE_PROTOCOL_ELEMENTS_LIST: {
            return { ...initialState }
        }
        case types.ADD_NEW_CARE_PROTOCOL_ELEMENT_TO_LIST: {
            return { ...state, list: [...state.list, payload] }
        }
        default:
            return state
    }
}

export default careProtocolReducer
