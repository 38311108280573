import { useDispatch } from "store"
import {
    openAddCareProtocolElementModalAction,
    archiveCareProtocolElementAction,
    addNewCareProtocolElementAction
} from "store/care-protocol/actions"
import { useMemo } from "react"
import { careProtocolEventTypesList, CareProtocolElementModalType } from "components/v2/constants"
import { useTranslation } from "react-i18next"
import { useIsHeLanguage } from "hooks"
import { LibraryTemplateType, TriggerType } from "@prisma/client"

export const useCreateFirstCareProtocolEventList = () => {
    const { t } = useTranslation("careProtocol")
    const dispatch = useDispatch()
    const { isHeLanguage } = useIsHeLanguage()

    const careProtocolElementTypesWithLocale = useMemo(
        () => careProtocolEventTypesList.map(({ title, ...rest }) => ({ ...rest, title: t(`eventTypes.${title}`) })),
        []
    )

    const onAddElementClick =
        (type: CareProtocolElementModalType, libraryTemplateType: LibraryTemplateType | "QUESTIONNAIRE") => () => {
            dispatch(
                openAddCareProtocolElementModalAction({
                    isOpenedScheduleModal: type === CareProtocolElementModalType.SCHEDULE,
                    isOpenedSequenceModal: type === CareProtocolElementModalType.SEQUENCE,
                    libraryTemplateType
                })
            )
        }

    const onEditElementClick = (element: any) => () => {
        dispatch(
            openAddCareProtocolElementModalAction({
                isOpenedScheduleModal: element.trigger_type === TriggerType.SCHEDULED,
                isOpenedSequenceModal:
                    element.trigger_type === TriggerType.SEQUENTIAL_MANUAL ||
                    element.trigger_type === TriggerType.SEQUENTIAL_TRIGGERED,
                libraryTemplateType: element.element_type,
                elementId: element.id
            })
        )
    }

    const onCopyElementClick = (element: any) => () => {
        const {
            available_for,
            cp_library_template_id,
            delay,
            element_type,
            questionnaire_element_library_template_id,
            recurrence_end,
            recurrence_interval,
            trigger_type,
        } = element
        dispatch(
            addNewCareProtocolElementAction({
                available_for,
                cp_library_template_id,
                delay,
                element_type,
                questionnaire_element_library_template_id,
                recurrence_end,
                recurrence_interval,
                trigger_type
            })
        )
    }

    const onArchiveElementClick = (element: any) => () => {
        dispatch(archiveCareProtocolElementAction(element))
    }

    return {
        t,
        isHeLanguage,
        careProtocolElementTypesWithLocale,
        onAddElementClick,
        onEditElementClick,
        onCopyElementClick,
        onArchiveElementClick
    }
}
