import { ScreeningAnswer, ScreeningQuestionType, ScreeningTemplateQuestion } from "@prisma/client"
import {
    FreeTextQuestionResponse,
    MinutesQuestionResponse,
    PercentageQuestionResponse,
    ScreeningQuestionResponse,
    ScaleSliderQuestionResponse,
    QuestionnaireResponse,
    MultipleChoiceQuestionResponse
} from "."
import ScaleQuestionResponse from "./ScaleQuestionResponse"
import YesNoQuestionResponse from "./YesNoQuestionResponse"
import { TypographyV2 } from "components/v2"
import { sortQuestionsByPositionAndAnswers } from "utils/sorting"
import { useCurrentPreferences } from "store/hooks"
import { useTranslation } from "react-i18next"
import { getDateLocaleFormat } from "utils/date"

const buildIntakeFormResponse = (questions: any, screeningAnswers: ScreeningAnswer[]) => {
    const sortedQuestions = sortQuestionsByPositionAndAnswers(questions, screeningAnswers)
    const dateFormat = useCurrentPreferences()?.date_format
    const { i18n } = useTranslation("common")
    return sortedQuestions.map((question: any, index: number) => {
        const screeningAnswer = screeningAnswers.find((answer) => answer.screening_template_question_id === question.id)

        switch (question.type) {
            case ScreeningQuestionType.free_text:
            case ScreeningQuestionType.number:
                return (
                    <FreeTextQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.time_of_day:
                const timeOfDayAnswer = screeningAnswer.value
                const date =
                    timeOfDayAnswer == "undefined" || timeOfDayAnswer == null
                        ? ""
                        : getDateLocaleFormat(new Date(timeOfDayAnswer), dateFormat, i18n)

                return (
                    <FreeTextQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={{ ...screeningAnswer, value: date }}
                    />
                )
            case ScreeningQuestionType.select:
                return (
                    <ScreeningQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.multiple_choice:
                return (
                    <MultipleChoiceQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.minutes:
                const minutesAnswer = screeningAnswer.value
                const minutes = minutesAnswer == "undefined" || minutesAnswer == null ? "" : minutesAnswer
                return (
                    <MinutesQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={{ ...screeningAnswer, value: minutes }}
                    />
                )
            case ScreeningQuestionType.percent:
                return (
                    <PercentageQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.scale:
                return (
                    <ScaleQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.yes_no:
                return (
                    <YesNoQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.scale_slider:
                return (
                    <ScaleSliderQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.text_only:
                return (
                    <div key={`buildIntakeFormResponse_${index}`} className="mt-6">
                        <TypographyV2 type="sm" width="bold" className="font-bold">
                            {question?.question}
                        </TypographyV2>
                    </div>
                )
            case ScreeningQuestionType.questionnaire:
                return (
                    <QuestionnaireResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswers={screeningAnswers.find(
                            (answer) => answer.questionnaire_id === question.definition.questionnaire_id
                        )}
                    />
                )
        }
    })
}
export default buildIntakeFormResponse
