import { ControlledSelectV2 } from "components/v2/components/common/form-controls"
import { Control } from "react-hook-form"
import { NUMBER_OF_REPEATS_OPTIONS, REPEATS_OPTIONS, TIME_OPTIONS } from "../../common/addScheduleElementModalConstants"
import { TypographyV2 } from "components/v2/components/common"
import { TFunction } from "i18next"

interface ConfigureCPElementProps {
    t: TFunction
    control: Control<any>
    errors: any
}

const ConfigureCPElement = ({ t, control, errors }: ConfigureCPElementProps) => {
    return (
        <div className="flex flex-col w-full gap-y-[34px]">
            <div className="flex flex-col w-full gap-y-5">
                <TypographyV2 type="xs" width="medium" className="text-base-dark">
                    {t("scheduleDetails")}
                </TypographyV2>
                <div className="flex flex-row w-full gap-x-2 items-center">
                    <TypographyV2 type="sm" width="medium" className="text-base-dark w-[127px]">
                        {t("consts.delayBy")}
                    </TypographyV2>
                    <ControlledSelectV2
                        control={control}
                        name="numberOfDelay"
                        options={NUMBER_OF_REPEATS_OPTIONS(t)}
                        error={errors.numberOfDelay?.message}
                        classList={{ wrapper: "w-[109px]" }}
                        placeholder={t("0")}
                    />
                    <ControlledSelectV2
                        control={control}
                        name="delayType"
                        options={TIME_OPTIONS(t)}
                        error={errors.delayType?.message}
                        classList={{ wrapper: "w-[140px]" }}
                        placeholder={t("consts.days")}
                    />
                </div>
                <div className="flex flex-row w-full gap-x-2 items-center">
                    <TypographyV2 type="sm" width="medium" className="text-base-dark w-[127px]">
                        {t("consts.avaiableFor")}
                    </TypographyV2>
                    <ControlledSelectV2
                        control={control}
                        name="numberOfAvailable"
                        options={NUMBER_OF_REPEATS_OPTIONS(t)}
                        error={errors.numberOfAvailable?.message}
                        classList={{ wrapper: "w-[73px]" }}
                        placeholder={t("0")}
                    />
                    <ControlledSelectV2
                        control={control}
                        name="availableType"
                        options={TIME_OPTIONS(t)}
                        error={errors.availableType?.message}
                        classList={{ wrapper: "w-[179px]" }}
                        placeholder={t("consts.days")}
                    />
                </div>
            </div>
            <div className="flex flex-row w-full gap-x-6">
                <div className="flex flex-row">
                    <div className="w-[416px]">
                        <ControlledSelectV2
                            control={control}
                            name="repeatsType"
                            options={REPEATS_OPTIONS(t)}
                            error={errors.repeatsType?.message}
                            label={t("consts.repeats")}
                            placeholder={t("0")}
                        />
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="w-[195px]">
                        <ControlledSelectV2
                            control={control}
                            name="numberOfRepeats"
                            options={NUMBER_OF_REPEATS_OPTIONS(t)}
                            error={errors.numberOfRepeats?.message}
                            label={t("consts.numberOfRepeats")}
                            placeholder={t("consts.select")}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigureCPElement
