import { Modal } from "components/common"
import { CloseIcon } from "components/icons"
import { StepsV2 } from "components/v2/components"
import { ButtonV2, TypographyV2 } from "components/v2/components/common"
import { useIsHeLanguage } from "hooks"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { AddChapterElementList, PreviewSelectedElement } from "./components"
import { useUnitSelector } from "store/hooks"
import {
    addElementToChapterAction,
    closeAddElementModalAction,
    removeSelectedElementFromAddVariableModalAction
} from "store/unit/actions"
import { eventTypeLocals } from "../unitConstants"
import { UnitChapterElementType } from "@prisma/client"

const SelectChapterElementModal = () => {
    const { t } = useTranslation("unit")
    const dispatch = useDispatch()

    const { addElementModal, selectedChapter } = useUnitSelector()

    const { isHeLanguage } = useIsHeLanguage()

    const isStepOneCompleted = useMemo(
        () => Boolean(addElementModal.selectedElement || addElementModal.selectedQuestionnaireElement),
        [addElementModal]
    )

    const steps = useMemo(
        () => [
            {
                label: t(`selectElementModal.select`),
                isCompleted: isStepOneCompleted
            },
            { label: t("selectElementModal.preview"), isCompleted: false }
        ],
        [isStepOneCompleted]
    )

    const onClose = () => {
        dispatch(closeAddElementModalAction())
    }

    const insertElement = () => {
        if (addElementModal.elementType === UnitChapterElementType.QUESTIONNAIRE) {
            dispatch(
                addElementToChapterAction({
                    element_type: UnitChapterElementType.QUESTIONNAIRE,
                    questionnaire_id: addElementModal.selectedQuestionnaireElement.id,
                    unit_chapter_id: selectedChapter
                })
            )
        } else {
            dispatch(
                addElementToChapterAction({
                    element_type: addElementModal.elementType,
                    library_template_id: addElementModal.selectedElement.id,
                    unit_chapter_id: selectedChapter
                })
            )
        }
    }

    return (
        <Modal show={addElementModal.isOpened} onClose={onClose} centered cardClassName="w-3/4 max-w-screen-lg">
            <div className="p-4 flex justify-between border-b border-border-blue">
                <TypographyV2 width="bold" type="base" className="text-med-black">
                    {t(`selectElementModal.${eventTypeLocals[addElementModal.elementType]}`)}
                </TypographyV2>
                <div className="flex items-center">
                    <div className={isHeLanguage ? "ml-3.5" : "mr-3.5"}>
                        <StepsV2 viewType="row" steps={steps} currentStep={isStepOneCompleted ? 2 : 1} />
                    </div>
                    <CloseIcon onClick={onClose} className="cursor-pointer text-[#9CA3AF]" height={20} width={20} />
                </div>
            </div>
            {!isStepOneCompleted && (
                <div className="p-6 h-120 overflow-y-auto bg-gray-50">
                    <TypographyV2 width="bold" type="xs" className="mb-2">
                        {t("selectFromLibrary")}
                    </TypographyV2>
                    <AddChapterElementList />
                </div>
            )}
            {isStepOneCompleted && (
                <>
                    <PreviewSelectedElement />
                    <div className="p-4 flex justify-between border-t bg-base-lighter border-border-blue">
                        <ButtonV2
                            classNameText="text-xs"
                            text={t("common:back")}
                            variant="light-gray"
                            onClick={() => dispatch(removeSelectedElementFromAddVariableModalAction())}
                        />
                        <div className="flex gap-2.5">
                            <ButtonV2
                                text={t("common:cancel")}
                                classNameText="text-xs"
                                variant="light-gray"
                                onClick={onClose}
                            />
                            <ButtonV2 text={t("common:insert")} classNameText="text-xs px-4" onClick={insertElement} />
                        </div>
                    </div>
                </>
            )}
        </Modal>
    )
}

export default SelectChapterElementModal
