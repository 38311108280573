import React, { useEffect, useMemo, useRef, useState } from "react"
import { ControlledTextInputV2, TypographyV2 } from "../../../../common"
import { useUnitSelector } from "store/hooks"
import { EditUnitDetailsIcon } from "components/icons"
import { useForm } from "react-hook-form"
import { classNames } from "utils"
import { useDispatch } from "store"
import { updateChapterAction } from "store/unit/actions"

const UnitChapterNameField = () => {
    const { chapters, selectedChapter: selectedChapterId } = useUnitSelector()

    const dispatch = useDispatch()

    const [value, setValue] = useState("")

    const inputRef = useRef<HTMLSpanElement>(null)

    const [editable, setEditable] = useState(false)

    const selectedChapter = useMemo(
        () => chapters.find((chapter) => chapter.id === selectedChapterId),
        [chapters, selectedChapterId]
    )

    const {
        control,
        reset,
        watch,
        setValue: setFormValue
    } = useForm({
        defaultValues: {
            name: selectedChapter?.name ?? ""
        }
    })

    useEffect(() => {
        reset({ name: selectedChapter?.name ?? "" })
        setValue(selectedChapter?.name ?? "")
    }, [selectedChapter, control])

    useEffect(() => {
        editable && inputRef.current && inputRef.current.focus()
    }, [editable])
    return (
        <div className="p-1.5 bg-black/[0.02] inline-flex gap-2.5 rounded-md items-center">
            <span
                ref={inputRef}
                onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Escape") {
                        e.currentTarget.blur()
                    }
                }}
                className={classNames(
                    "text-3xl font-bold outline-none overflow-hidden text-ellipsis",
                    !editable && "cursor-default line-clamp-1"
                )}
                contentEditable={editable}
                suppressContentEditableWarning={editable}
                onInput={(e) => {
                    setValue(e.currentTarget.textContent ?? "")
                }}
                onBlur={() => {
                    setEditable(false)
                    setFormValue("name", value)

                    if (watch().name !== selectedChapter?.name)
                        dispatch(
                            updateChapterAction(selectedChapter.id, {
                                name: watch().name,
                                unit_library_template_id: selectedChapter.unit_library_template_id
                            })
                        )
                }}
            >
                {watch().name}
            </span>
            <button
                className="pl-0 p-1 cursor-pointer"
                onClick={() => {
                    setEditable(true)
                }}
            >
                <EditUnitDetailsIcon height={16} width={16} />
            </button>
        </div>
    )
}

export default UnitChapterNameField
