import { UnitChapterElementType } from "@prisma/client"
import { EventTypes } from "types"

export const eventType = {
    [UnitChapterElementType.EXERCISE]: EventTypes.Exercise,
    [UnitChapterElementType.EDUCATIONAL_CONTENT]: EventTypes.EducationalContent,
    [UnitChapterElementType.QUESTIONNAIRE]: EventTypes.Questionnaire
}

export const elementType: { [key in EventTypes]: UnitChapterElementType | null } = {
    [EventTypes.Exercise]: UnitChapterElementType.EXERCISE,
    [EventTypes.EducationalContent]: UnitChapterElementType.EDUCATIONAL_CONTENT,
    [EventTypes.Questionnaire]: UnitChapterElementType.QUESTIONNAIRE,
    [EventTypes.Protocol]: null,
    [EventTypes.Task]: null,
    [EventTypes.Appointment]: null,
    [EventTypes.Mood]: null,
    [EventTypes.Unit]: null,
    [EventTypes.Tracker]: null,
    [EventTypes.Reflection]: null
}

export const eventTypeLocals = {
    [UnitChapterElementType.EXERCISE]: "exercise",
    [UnitChapterElementType.EDUCATIONAL_CONTENT]: "educational_content",
    [UnitChapterElementType.QUESTIONNAIRE]: "questionnaire",
    [EventTypes.Exercise]: "exercise",
    [EventTypes.EducationalContent]: "educational_content",
    [EventTypes.Questionnaire]: "questionnaire"
}
