import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useEffect } from "react"
import {
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_LOW,
    createCommand,
    LexicalCommand,
    $isNodeSelection,
    SELECTION_CHANGE_COMMAND,
    $getSelection,
    $isRangeSelection,
    $isElementNode
} from "lexical"
import { mergeRegister } from "@lexical/utils"
import { $createBoxColorNode, $isBoxColorNode, BoxColorNode } from "../../nodes/BoxColorNode"

export type InsertBoxColorPayload = {
    backgroundColor: string
    padding: string
}

export const INSERT_BOX_COLOR_COMMAND: LexicalCommand<InsertBoxColorPayload> = createCommand("INSERT_BOX_COLOR_COMMAND")

export default function BoxColorPlugin(): JSX.Element | null {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        if (!editor.hasNodes([BoxColorNode])) {
            throw new Error("BoxColorPlugin: BoxColorNode not registered on editor")
        }

        return mergeRegister(
            editor.registerCommand<InsertBoxColorPayload>(
                INSERT_BOX_COLOR_COMMAND,
                (payload) => {
                    editor.update(() => {
                        const selection = $getSelection()

                        if ($isRangeSelection(selection)) {
                            const nodes = selection.getNodes()

                            if (nodes.length === 0) return false

                            // Get the parent paragraph node
                            const firstNode = nodes[0]
                            const paragraphNode = $isElementNode(firstNode) ? firstNode : firstNode.getParentOrThrow()

                            if ($isBoxColorNode(paragraphNode.getParent())) {
                                // If already wrapped in BoxColorNode, just update the color
                                const boxColorNode = paragraphNode.getParent() as BoxColorNode
                                boxColorNode.setBackgroundColor(payload.backgroundColor)
                                boxColorNode.setPadding(payload.padding)
                            } else {
                                // Create new BoxColorNode and wrap the paragraph
                                const boxColorNode = $createBoxColorNode(payload.backgroundColor, payload.padding)

                                // Get all content from the paragraph
                                const children = paragraphNode.getChildren()

                                // Move children to new BoxColorNode
                                boxColorNode.append(...children)

                                // Replace paragraph content with BoxColorNode
                                paragraphNode.append(boxColorNode)
                            }
                        }
                        return true
                    })
                    return true
                },
                COMMAND_PRIORITY_EDITOR
            ),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    const selection = $getSelection()
                    if ($isNodeSelection(selection)) {
                        const nodes = selection.getNodes()
                        const node = nodes[0]
                        if ($isBoxColorNode(node)) {
                            return true
                        }
                    }
                    return false
                },
                COMMAND_PRIORITY_LOW
            )
        )
    }, [editor])

    return null
}
