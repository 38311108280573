import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { closeModal } from "store/modal/actions"
import { DialogV2, DividerV2, SwitchV2, TypographyV2 } from "components/v2"
import { useIsHeLanguage } from "../../hooks"
import { classNames } from "../../utils"
import Switch from "../../components/common/Switch"

interface CarePlanFeaturesSettingsModalProps {
    isOpen: boolean
    params?: any
}

const CarePlanFeaturesSettingsModal: React.FC<CarePlanFeaturesSettingsModalProps> = ({ isOpen, params }) => {
    const { t } = useTranslation("common")
    const { isHeLanguage } = useIsHeLanguage()
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(closeModal())
    }

    const handleUpdate = () => {
        // Add update logic here
        handleClose()
    }

    return (
        <DialogV2
            classList={{
                dialog: classNames(isHeLanguage ? "!translate-x-[-0%]" : "", "!shadow-none"),
                title: isHeLanguage ? "!translate-x-[-50%]" : ""
            }}
            title={t("feature_settings", { ns: "carePlan" })}
            isOpen={isOpen}
            onClose={handleClose}
            onProceed={handleUpdate}
            proceedText={t("update")}
            proceedType="primary"
            dismissType="light-gray"
            dismissText={t("cancel")}
        >
            <div className="px-2 w-full">
                <div className="my-4 mb-4 w-full">
                    <div className="flex items-center my-8">
                        <SwitchV2 isLarge isChecked={true} onChange={() => {}} />
                        <div className={isHeLanguage ? "mr-2" : "ml-2"}>
                            <TypographyV2 width="medium" type="sm">
                                {t("questionnaire_results", { ns: "carePlan" })}
                            </TypographyV2>
                        </div>
                    </div>
                    <DividerV2 />
                    <div className="flex items-center my-8">
                        <SwitchV2 isLarge isChecked={true} onChange={() => {}} />
                        <div className={isHeLanguage ? "mr-2" : "ml-2"}>
                            <TypographyV2 width="medium" type="sm">
                                {t("reflection", { ns: "carePlan" })}
                            </TypographyV2>
                        </div>
                    </div>
                    <DividerV2 />

                    <div className="flex items-center my-8">
                        <SwitchV2 isLarge isChecked={true} onChange={() => {}} />
                        <div className={isHeLanguage ? "mr-2" : "ml-2"}>
                            <TypographyV2 width="medium" type="sm">
                                {t("chat", { ns: "carePlan" })}
                            </TypographyV2>
                        </div>
                    </div>
                    <DividerV2 />

                    <div className="flex items-center my-8">
                        <SwitchV2 isLarge isChecked={true} onChange={() => {}} />
                        <div className={isHeLanguage ? "mr-2" : "ml-2"}>
                            <TypographyV2 width="medium" type="sm">
                                {t("tracker", { ns: "carePlan" })}
                            </TypographyV2>
                        </div>
                    </div>
                </div>
            </div>
        </DialogV2>
    )
}

export default CarePlanFeaturesSettingsModal
