import React, { useCallback, useMemo, useEffect } from "react"
import { debounce } from "lodash"

import { useDispatch } from "react-redux"
import { useLibraryTemplateSelector } from "store/hooks"
import { setEducationalContent, updateEduContentByIdAction } from "store/library-template/actions"
import { RichTextEditorV2 } from "components/v2"
import { AppDispatch } from "store"
import { $prepopulatedRichText } from "./constants"
import sanitizeHtml from "sanitize-html"

const EducationalContentEditor = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { educationalContent } = useLibraryTemplateSelector()

    const initialValue = useMemo(() => {
        if (educationalContent?.content_tree) return JSON.parse(educationalContent.content_tree as string)
        return $prepopulatedRichText
    }, [educationalContent])

    const sanitizeConfig = {
        allowedTags: [
            // Text formatting
            "p",
            "div",
            "span",
            "strong",
            "em",
            "b",
            "i",
            "u",
            // Headers
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
            // Lists
            "ul",
            "ol",
            "li",
            // Media
            "img",
            "video",
            "source",
            // Other common elements
            "br",
            "hr"
        ],
        allowedAttributes: {
            "*": ["class", "style"],
            img: ["src", "alt", "width", "height", "mediaobject"],
            video: ["controls", "width", "height", "mediaobject"],
            source: ["src", "type"]
        },
        allowedStyles: {
            "*": {
                color: [/.*/],
                "text-align": [/.*/],
                "font-size": [/.*/],
                margin: [/.*/],
                padding: [/.*/],
                "background-color": [/.*/],
                "border-radius": [/.*/],
                "--box-color": [/.*/]
            }
        }
    }

    const onChange = useCallback(
        debounce((content_tree: string, html: string) => {
            if (educationalContent) {
                const sanitizedHtml = sanitizeHtml(html, sanitizeConfig)

                dispatch(
                    updateEduContentByIdAction(educationalContent.id, {
                        html: sanitizedHtml,
                        content_tree
                    })
                )
            }
        }, 1000),
        [educationalContent]
    )

    useEffect(() => {
        return () => {
            dispatch(setEducationalContent(null))
        }
    }, [])

    return (
        <div className="h-full">
            <RichTextEditorV2 {...{ initialValue, onChange }} />
        </div>
    )
}

export default EducationalContentEditor
