import React from "react"
import { CardV2, TypographyV2 } from "components/v2/index"
import { useDispatch } from "store"
import { classNames } from "utils"
import { useIsHeLanguage } from "hooks"
import { selectElementForAddVariableModalAction } from "store/unit/actions"
import { QuestionnaireListItem } from "@sequel-care/types"

const ChapterElementItemQuestionnaire: React.FC<QuestionnaireListItem> = (element) => {
    const { title, description } = element

    const dispatch = useDispatch()

    const { isHeLanguage } = useIsHeLanguage()

    const onExerciseClick = () => {
        dispatch(selectElementForAddVariableModalAction(element))
    }

    return (
        <CardV2 className="flex flex-col justify-between border-none" onClick={onExerciseClick}>
            <div>
                <TypographyV2
                    type="sm"
                    width="bold"
                    className={classNames(
                        isHeLanguage ? "text-right" : "text-left",
                        "w-auto overflow-hidden text-ellipsis"
                    )}
                >
                    {title}
                </TypographyV2>
                <TypographyV2
                    type="sm"
                    className={classNames(
                        "text-gray-400 mt-1",
                        isHeLanguage ? "text-right" : "text-left",
                        "w-auto overflow-hidden text-ellipsis"
                    )}
                >
                    {description}
                </TypographyV2>
            </div>
        </CardV2>
    )
}

export default ChapterElementItemQuestionnaire
