import { useCallback, useEffect, useRef, useState } from "react"

interface UseScrollStepperProps {
    questionsLength: number
    validateStep: (step: number) => Promise<boolean>
    sidebarRef?: React.RefObject<HTMLDivElement>
}

interface UseScrollStepperReturn {
    currentStep: number
    questionsRef: React.RefObject<HTMLDivElement>
    getQuestionRef: (index: number) => (el: HTMLDivElement | null) => void
    handleScroll: () => void
    handleStepChange: (step: number) => Promise<void>
}

export const useScrollStepper = ({
    questionsLength,
    validateStep,
    sidebarRef
}: UseScrollStepperProps): UseScrollStepperReturn => {
    const [currentStep, setCurrentStep] = useState(0)
    const questionsRef = useRef<HTMLDivElement>(null)
    const questionRefs = useRef<(HTMLDivElement | null)[]>([])

    useEffect(() => {
        questionRefs.current = questionRefs.current.slice(0, questionsLength)
    }, [questionsLength])

    const handleScroll = useCallback(() => {
        if (!questionsRef.current) return

        const containerHeight = questionsRef.current.clientHeight
        let maxVisibleHeight = 0
        let mostVisibleQuestionIndex = 0

        questionRefs.current.forEach((ref, index) => {
            if (!ref) return
            const rect = ref.getBoundingClientRect()
            const visibleHeight = Math.min(rect.bottom, containerHeight) - Math.max(rect.top, 0)

            if (visibleHeight > maxVisibleHeight) {
                maxVisibleHeight = visibleHeight
                mostVisibleQuestionIndex = index
            }
        })

        const lastRef = questionRefs.current[questionRefs.current.length - 1]
        if (lastRef) {
            const lastRect = lastRef.getBoundingClientRect()
            const containerRect = questionsRef.current.getBoundingClientRect()

            if (
                lastRect.top >= containerRect.top &&
                lastRect.bottom <= containerRect.bottom &&
                mostVisibleQuestionIndex === questionRefs.current.length - 2
            ) {
                mostVisibleQuestionIndex = questionRefs.current.length - 1
            }
        }

        setCurrentStep(mostVisibleQuestionIndex)
        if (sidebarRef?.current) {
            const sidebarItems = sidebarRef.current.children
            if (sidebarItems[mostVisibleQuestionIndex]) {
                sidebarItems[mostVisibleQuestionIndex].scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                })
            }
        }
    }, [sidebarRef])

    const handleStepChange = useCallback(
        async (step: number) => {
            // const isValid = await validateStep(currentStep)
            // if (!isValid) return

            if (!questionsRef.current || !questionRefs.current[step]) return

            const targetElement = questionRefs.current[step]
            targetElement.scrollIntoView({ behavior: "auto", block: "start" })

            setCurrentStep(step)
        },
        [validateStep]
    )

    const getQuestionRef = useCallback(
        (index: number) => (el: HTMLDivElement | null) => {
            questionRefs.current[index] = el
        },
        []
    )

    return {
        currentStep,
        questionsRef,
        getQuestionRef,
        handleScroll,
        handleStepChange
    }
}
