import { useState, useCallback, FC } from "react"
import { ListV2, SearchInputV2 } from "../common"
import HorizontalItemWithSearchV2 from "./HorizontalItemWithSearchV2"
import { Diagnosis, User } from "@prisma/client"

export interface IContentItem {
    id: number
    title: string
    subtitle: string
    creator: { user: User }
    diagnoses: { diagnose: Diagnosis }[]
}

interface IContentListProps {
    isVerticalView?: boolean
    isFiltering?: boolean
    list: IContentItem[]
    onClick: (id: number) => void
}

const HorizontalListWithSearchV2: FC<IContentListProps> = ({ list, isFiltering, onClick }) => {
    const [filterQuery, setFilterQuery] = useState("")

    const filterFunction = useCallback(
        (item: IContentItem) => {
            const regex = new RegExp(filterQuery, "i")
            return regex.test(item.title)
        },
        [filterQuery]
    )

    return (
        <>
            {isFiltering && (
                <SearchInputV2
                    className="text-sm p-0.5"
                    iconClassName="w-5"
                    searchText={filterQuery}
                    onChange={(value) => setFilterQuery(value)}
                />
            )}
            <div className="mt-2">
                <ListV2 renderItem={HorizontalItemWithSearchV2} {...{ list, isFiltering, filterFunction, onClick }} />
            </div>
        </>
    )
}

export default HorizontalListWithSearchV2
