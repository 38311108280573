import { TypographyV2 } from "components/v2/components/common"
import React, { useState } from "react"
import UnitDetailsElementItem from "./UnitDetailsElementItem"
import { UnitChapterResponse } from "types/Redux"
import { ChevronDownIcon } from "@heroicons/react/24/solid"

const UnitDetailsChapterItem = ({ chapter }: { chapter: UnitChapterResponse }) => {
    const [isOpen, setIsOpen] = useState(true)

    return (
        <div>
            <button className="flex gap-1 w-full mb-1" onClick={() => setIsOpen((prev) => !prev)}>
                <ChevronDownIcon
                    className={`transition-all duration-200 ${isOpen ? "rotate-0" : "-rotate-90"}`}
                    height={16}
                />
                <TypographyV2 className="text-med-black" type="xs" width="semibold">
                    {chapter.name}
                </TypographyV2>
            </button>
            <div className={`grid transition-all duration-500 ${isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"}`}>
                <div className={"flex flex-col gap-2 overflow-hidden"}>
                    {chapter.unit_chapter_element.map((element) => (
                        <UnitDetailsElementItem element={element} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default UnitDetailsChapterItem
