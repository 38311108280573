import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2, RadioCircleGroupV2 } from "components/v2"
import { classNames } from "utils"

const ScreeningQuestionResponse = ({
    question,
    screeningAnswer,
    sybQuestion = false,
    isLastQuestion = false
}: {
    question: any
    screeningAnswer: ScreeningAnswer
    sybQuestion?: boolean
    isLastQuestion?: boolean
}) => {
    const isSelect = question.definition.type === "select"
 
    return (
        <div
            className={classNames(
                "bg-white border-slate-300 pt-4 px-3",
                sybQuestion ? "border-b" : "border rounded-lg",
                !isLastQuestion && "border-none"
            )}
        >
            <TypographyV2 type="base" className="text-[#6B7280]">
                {question.question}
            </TypographyV2>

            <div className="pt-2 flex flex-col gap-4">
                {isSelect ? (
                    <TypographyV2 type="base">{screeningAnswer?.value}</TypographyV2>
                ) : (
                    <RadioCircleGroupV2
                        name="relaxing-pre-sleep"
                        onChange={() => {}}
                        readOnly
                        value={screeningAnswer?.value}
                        options={question.definition.options.map((option: any) => ({
                            value: option?.value || option,
                            labelComponent: option?.label || option
                        }))}
                    />
                )}
            </div>
        </div>
    )
}

export default ScreeningQuestionResponse
