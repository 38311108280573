import { Recurrence } from "@sequel-care/types/Event"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

import { useCurrentUser } from "store/hooks"
import { getDateAtHour0, getWeekDayByDayNumber, localeFormat } from "utils/dates"

export const getRecurrenceDescriptionShort = (interval: number, weekDay?: number) => {
    if (!interval) return null
    const unit = interval >= 7 ? "weeks" : "days"
    let currentUser: any
    const day =
        unit === "weeks" && weekDay
            ? i18next.t(`dates:dayLabels.${getWeekDayByDayNumber(weekDay, currentUser.preferences.week_start)}`)
            : null

    const intervalString =
        interval === 1
            ? i18next.t("patient:recurrence.daily")
            : interval === 7
            ? i18next.t("patient:recurrence.weekly")
            : interval === 364
            ? i18next.t("patient:recurrence.annually")
            : !(interval % 7)
            ? i18next.t("patient:recurrence.everyXWeeks", { interval: interval / 7 })
            : i18next.t("patient:recurrence.everyXDays", { interval })

    return `${intervalString} ${day ? `${i18next.t("common:onUpper")} ${day}` : ""}`
}

export const useRecurrenceDescriptionShort = (interval: number, weekDay?: number) => {
    const { t } = useTranslation("patient")
    const currentUser = useCurrentUser()

    if (!interval) return t("recurrence.none")
    const unit = interval >= 7 ? "weeks" : "days"
    const day =
        unit === "weeks" && weekDay
            ? t(`dates:dayLabels.${getWeekDayByDayNumber(weekDay, currentUser.preferences.week_start)}`)
            : null

    const intervalString =
        interval === 1
            ? t("recurrence.daily")
            : interval === 7
            ? t("recurrence.weekly")
            : interval === 364
            ? t("recurrence.annually")
            : !(interval % 7)
            ? t("recurrence.everyXWeeks", { interval: interval / 7 })
            : t("recurrence.everyXDays", { interval })

    return `${intervalString} ${day ? `${t("common:onUpper")} ${day}` : ""}`
}

export const useRecurrenceDescriptionLong = (
    recurrence: Pick<Recurrence<string | Date>, "interval" | "start" | "end">
) => {
    const { t, i18n } = useTranslation("patient")
    if (!recurrence) return null

    const intervalKey =
        recurrence?.interval === 1 ? "dailyFull" : recurrence?.interval === 7 ? "weeklyFull" : "everyXDaysFull"

    return t(`recurrence.${intervalKey}`, {
        start: localeFormat(getDateAtHour0(recurrence.start), "MMMM do", i18n),
        end: localeFormat(getDateAtHour0(recurrence.end), "MMMM do", i18n),
        interval: recurrence.interval
    })
}
