import React from "react"
import { TagWithIcon, CardV2, DropdownMenuV2, TypographyV2 } from "components/v2/index"
import {
    ClockIcon,
    ElipsisIcon,
    ScheduleIcon,
    DragIndicator,
    ArchiveIcon,
    CopyIcon,
    EditUnitDetailsIcon
} from "components/icons"
import { EventTypes } from "types"
import { IListPropType } from "components/v2/components/common/DropdownMenuV2/dropDownMenuTypesV2"

interface IContentItemProps {
    title: string
    time: string
    when: string
    eventType: EventTypes
    isVerticalView?: boolean
    isDraggable?: boolean
    dropDownActionsList?: IListPropType[]
}

const ScheduleItemCard: React.FC<IContentItemProps> = ({
    isDraggable = false,
    isVerticalView = false,
    title,
    time,
    when,
    eventType,
    dropDownActionsList
}) => (
    <CardV2>
        {isVerticalView && (
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    {isDraggable && <DragIndicator className="mr-3" />}
                    <div className="flex items-center">
                        <TagWithIcon eventType={eventType} />
                        <TypographyV2 className="ml-3" width="bold">
                            {title}
                        </TypographyV2>
                    </div>
                </div>
                <div className="flex items-center justify-end">
                    {!!when && (
                        <div className="flex items-cente">
                            <ScheduleIcon />
                            <TypographyV2 className="ml-1 text-gray-400">{time}</TypographyV2>
                        </div>
                    )}
                    <DropdownMenuV2
                        menuItems={dropDownActionsList}
                        menuClassName="ml-2"
                        clickComponent={<ElipsisIcon />}
                    />
                </div>
            </div>
        )}
        {!isVerticalView && (
            <>
                <div className="flex justify-between items-center">
                    <TagWithIcon eventType={eventType} />
                    <DropdownMenuV2 menuItems={dropDownActionsList} clickComponent={<ElipsisIcon />} />
                </div>
                <div className="mt-8">
                    <TypographyV2 width="bold">{title}</TypographyV2>
                </div>
                <div className="flex justify-space-between align-items-center mt-3">
                    <div className="flex items-center w-2/4">
                        <ScheduleIcon />
                        <TypographyV2 className="ml-1 text-gray-400">{time}</TypographyV2>
                    </div>
                    <div className="flex items-center w-2/4">
                        <ClockIcon />
                        <TypographyV2 className="ml-1 text-gray-400">{when}</TypographyV2>
                    </div>
                </div>
            </>
        )}
    </CardV2>
)

export default ScheduleItemCard