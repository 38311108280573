import React, { forwardRef } from "react"
import { BadgeV2 } from "components/v2"
import { CloseIcon } from "components/icons"
import { IControlledTextInputWithLabelV2Props } from "./types"
import { useControlledTextInputWithLabelV2 } from "./useControlledTextInputWithLabelV2"
import FormInputWrapper from "../FormInputWrapper"
import { classNames } from "utils"
import { errorInputClassNames, inputClassNames } from "components/v2/constants"

const ControlledTextInputWithLabelV2 = forwardRef<HTMLInputElement, IControlledTextInputWithLabelV2Props>((props) => {
    const { isRequired, label, pillLabels, classList, rightLabelComponent, onLabelClick, ...inputProps } = props
    const { error } = inputProps

    const { prevLabelRef, nextLabelRef, after, prev, handleOnPrevChange, handleOnNextChange } =
        useControlledTextInputWithLabelV2(props)

    return (
        <FormInputWrapper data-no-drag-indicator {...{ label, error, isRequired, classList: classList?.wrapper, rightLabelComponent }}>
            <div
                data-no-drag-indicator
                className={classNames(
                    inputClassNames,
                    error && errorInputClassNames,
                    "flex hover:border-[1px] hover:border-primary hover:shadow-primary-focus hover:outline-none"
                )}
            >
                <div
                    data-no-drag-indicator
                    className="block overflow-auto w-full flex-wrap p-0 outline-none focus:outline-none whitespace-normal break-words"
                >
                    <span
                        data-no-drag-indicator
                        ref={prevLabelRef}
                        className="inline relative overflow-hidden break-all items-center  min-w-[20px] outline-none "
                        contentEditable
                        suppressContentEditableWarning
                        onInput={handleOnPrevChange}
                    >
                        {prev}
                    </span>
                    {pillLabels.map((label, idx) => (
                        <BadgeV2
                            data-no-drag-indicator
                            key={idx}
                            text={label}
                            color="blue"
                            icon={<CloseIcon className="!inline h-[24px] w-[24px] text-gray-500" />}
                            className="!inline-flex !relative flex-row-reverse h-[28px] justify-between max-w-[200px] mx-2"
                            classNameLabel="!inline !relative w-11/12 overflow-hidden overflow-ellipsis !text-sm whitespace-nowrap"
                            onClick={onLabelClick}
                        />
                    ))}
                    <span
                        data-no-drag-indicator
                        ref={nextLabelRef}
                        contentEditable
                        suppressContentEditableWarning
                        className="inline relative overflow-hidden break-all items-center min-w-[20px] outline-none"
                        onInput={handleOnNextChange}
                    >
                        {after}
                    </span>
                </div>
            </div>
        </FormInputWrapper>
    )
})

export default ControlledTextInputWithLabelV2
