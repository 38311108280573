import { TypographyV2 } from "components/v2"
import React, { ReactNode } from "react"

interface IToolbarSectionProps {
    title: string
    children: ReactNode
}

const ToolbarSection: React.FC<IToolbarSectionProps> = ({ title, children }) => {
    return (
        <div className="flex-col mb-3">
            <TypographyV2 width="semibold" type="base">
                {title}
            </TypographyV2>
            <div className="flex gap-2 mt-3">{children}</div>
        </div>
    )
}

export default ToolbarSection
