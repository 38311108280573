import { useController, useFieldArray, useForm, useWatch } from "react-hook-form"
import { useEffect, useMemo } from "react"
import { ISelectFormProps, ISelectDefinition, ISelectDefinitionResponse } from "./selectFormTypes"
import { useTranslation } from "react-i18next"
import { useIsHeLanguage } from "hooks"
import { isEqual } from "lodash"

export const useSelectForm = ({ name, control }: ISelectFormProps) => {
    const { t } = useTranslation("question")

    const { isHeLanguage } = useIsHeLanguage()

    const {
        field: { onChange, value }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    const defaultValues = useMemo(
        () => (value as ISelectDefinitionResponse)?.options?.map((option) => ({ option })),
        [value]
    )
    const {
        control: newControl,
        formState: { isDirty }
    } = useForm<ISelectDefinition>({
        defaultValues: {
            options: defaultValues ?? []
        }
    })

    const { fields, append, remove } = useFieldArray({
        control: newControl,
        name: "options"
    })

    const formValues = useWatch({
        control: newControl
    })

    useEffect(() => {
        if (!isDirty) return
        const options = formValues.options.map(({ option }) => option)
        const newValue = { options }

        if (isEqual(value, newValue)) return
        onChange(newValue);
    }, [isDirty, formValues, onChange, value])

    const onAddClick = () => {
        append({ option: "" })
    }

    const onRemoveClick = (idx: number) => () => {
        remove(idx)
    }

    return { t, isHeLanguage, newControl, fields, onAddClick, onRemoveClick }
}
